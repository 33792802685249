import { createStore, combineReducers, applyMiddleware } from 'redux';

import Messages from './reducers/messages';
import User from './reducers/user';
import UI from './reducers/ui';
import Rooms from './reducers/rooms';
import RecentChats from './reducers/recentChats';
import Organizations from './reducers/organizations';
import Teams from './reducers/teams';
import EVV from './reducers/evv';
// import AdminPanal from './reducers/adminpanal';
import Activities from './reducers/activity';
import UserStatus from './reducers/usersStatus';
// import AdminMessages from './reducers/adminMessages';
import formData from './reducers/formData';
import tasks from './reducers/tasks';
import chatroomData from './reducers/chatroomData';
import conference from './reducers/conference';
import SubChannels from './reducers/subChannels';
import Payallocation from './reducers/payallocation';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

export const ConfigureStore = () => {
    let middleware = process.env.NODE_ENV === 'development' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

    const appReducer = combineReducers({
        messages: Messages,
        user: User,
        ui: UI,
        rooms: Rooms,
        chats: RecentChats,
        teams: Teams,
        organizations: Organizations,
        // adminpanal: AdminPanal,
        activities: Activities,
        // adminMessages: AdminMessages,
        formData: formData,
        tasks: tasks,
        chatroomData: chatroomData,
        evv: EVV,
        userStatus: UserStatus,
        conference: conference,
        subChannels: SubChannels,
        payallocation: Payallocation,
    });

    const rootReducer = (state, action) => {
        if (action.type === 'SET_LOGGEDOUT') {
            state = undefined;
        }

        return appReducer(state, action);
    };

    const store = createStore(rootReducer, middleware);
    return store;
};
