import React from 'react'; // useRef
import { css } from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

// helpers
import {
    fetchWrapper,
    getInitials,
    getRandomColor,
    getSortConfig,
    getTeamIdFromURL,
    isAdminPanel,
    isTeamSettings,
} from '../../../../_helpers';

// redux
import { connect } from 'react-redux';
import { GetClients } from '../../../../_redux/actions/ActionEVV';

// components
import ClientList from './ClientList';
import Pagination from '../../Pagination';
import EvvSearchModalComponent from '../EvvSearchModal';
import { Button, Nav } from 'react-bootstrap';
import CreateClientPicker from '../../../Features/BulkUpload/Client';
import { useQuery } from '../../../../_helpers/hooks/useQuery';
import { toast } from 'react-toastify';

const Client = ({ GetClients, isLoading, clients, currUser, teams }) => {
    const history = useHistory();
    const teamId = getTeamIdFromURL();
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    const evvBasePath = isTeamSettings() ? 'user-evv' : 'evv';
    const isValidAdmin = isAdminPanel() && !teamId && !isTeamSettings() && currUser?.role === 'admin';

    const pushPage = (page) => {
        history.push(`/${evvBasePath}?page=${page || 1}`);
    };
    const { query, updateQuery, handleSort } = useQuery(GetClients, {
        page: currentPage,
        firstName: '',
        lastName: '',
        user: null,
        providerInfo: null,
        provider: null,
        state: 'active',
        sortBy: 'createdAt:desc',
        team: teamId,
    });

    const [provider, setProvider] = useState();

    const handleNameChange = (event) => {
        const page = 1;
        if (event.target.name === 'firstName') {
            updateQuery({ firstName: event.target.value, page });
        } else if (event.target.name === 'lastName') {
            updateQuery({ lastName: event.target.value, page });
        }
        pushPage(page);
    };

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
        updateQuery({ provider: selectedProvider?.value, page: 1 });
    };

    const handleClearAll = () => {
        const page = 1;
        updateQuery({ firstName: '', lastName: '', provider: null, page });
        pushPage(page);
    };

    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            pushPage(data.currentPage);
        }
    };

    const handleArchiveStatus = (state) => {
        const page = 1;
        if (state) {
            updateQuery({ state, page });
        }
        pushPage(page);
    };

    useEffect(() => {
        updateQuery({ page: currentPage });
    }, [currentPage]);

    useEffect(() => {
        if (!isTeamSettings || !teamId) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        fetchWrapper
            .get(`evv/provider?team=${teamId}`)
            .then(({ results: [response] }) => {
                setProvider({
                    value: response?._id,
                    label: response?.agencyName,
                    providerId: response?._id,
                    teamId: team?.id,
                    agencyName: response?.agencyName,
                    team: response?._id,
                    state: response?.state,
                    payroll: response?.payroll,
                    evv: response?.evv,
                });
                updateQuery({ provider: response?._id });
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                toast.error("Couldn't fetch provider data");
            });
    }, [teams, isTeamSettings]);

    const clientsList = useMemo(
        () =>
            clients &&
            clients.clients.map((client) => {
                client.initials = getInitials(`${client.firstName} ${client.lastName}`);
                client.color = getRandomColor(client.id);
                return client;
            }),
        [clients]
    );

    return (
        <div>
            <div className="d-flex flex-row align-items-center mb-4 justify-content-between">
                <EvvSearchModalComponent
                    type="Client"
                    firstName={query.firstName}
                    lastName={query.lastName}
                    provider={provider}
                    handleNameChange={handleNameChange}
                    handleProviderSelect={handleProviderSelect}
                    handleClearAll={handleClearAll}
                    isTeamSettings={isTeamSettings()}
                    team={teamId}
                />
                <Nav
                    variant="pills"
                    className="p-2"
                    css={css`
                        background: #e1e1e1;
                        border-radius: 5px;
                    `}
                >
                    <Nav.Item>
                        <Nav.Link
                            as={Button}
                            variant={query.state === 'active' ? 'primary' : 'link'}
                            onClick={() => handleArchiveStatus('active')}
                            style={{ fontSize: '1.6rem' }}
                            className={query.state === 'active' ? 'bg-primary text-white' : ''}
                        >
                            Active
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ml-1">
                        <Nav.Link
                            as={Button}
                            variant={query.state === 'archieved' ? 'primary' : 'link'}
                            onClick={() => handleArchiveStatus('archieved')}
                            style={{ fontSize: '1.6rem' }}
                            className={query.state === 'archieved' ? 'bg-primary text-white' : ''}
                        >
                            Archived
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                {isValidAdmin && <CreateClientPicker provider={provider} />}
            </div>
            <div
                className="custom-card card-table d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <ClientList
                    list={clientsList}
                    isLoading={isLoading}
                    sortConfig={getSortConfig(query.sortBy)}
                    currentPage={currentPage}
                    clientState={query.state}
                    setStaffState={(state) => {
                        handleArchiveStatus(state);
                    }}
                    handleSort={handleSort}
                    isTeamSettings={isTeamSettings()}
                    teamId={teamId}
                    team={teamId}
                    isValidAdmin={isValidAdmin}
                />

                {Boolean(clients.totalClients) && (
                    <div className="m-4">
                        <Pagination
                            pageNo={currentPage}
                            totalRecords={clients.totalClients}
                            pageLimit={10}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    clients: state.evv.clients,
    currUser: state.user.user,
    teams: state.teams.teams,
});

export default connect(mapStateToProps, { GetClients })(Client);
