import { useState, useEffect, useRef } from 'react';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import {
    fetchWrapper,
    getDateTimeFromDateTime,
    getTeamIdFromURL,
    toastWrapper,
    getChannelIdFromSettingsURL,
} from '../../../../../../_helpers';
import { FormPdf } from '../../../../../../_elements/FormPdf';

import { DocumentsStyles } from '../../styles/DocumentsStyled';
import Filters from './Filters';
import Table from './Table';

export const submissionTypes = [
    {
        label: 'Draft Completed Forms',
        value: 'completed',
    },
    {
        label: 'Draft Pending Forms',
        value: 'draft',
    },
    {
        label: 'URL Submissions',
        value: 'urlSubmission',
    },
];
const generateUrl = ({
    sortBy = { key: 'createdAt', direction: 'desc' },
    currentPage = 1,
    teamId,
    formName,
    member,
    channel,
    startDate,
    endDate,
    searchTags = [],
    submissionType,
    channelIdFromUrl,
    limit,
}) => {
    let url = `/userFormData?sortBy=${sortBy.key}:${sortBy.direction}&limit=${limit || 10}&page=${currentPage}`;

    if (formName?.value) url += `&form=${formName.value}`;
    if (member?.value) url += `&user=${member.value}`;
    if (channel?.value || channelIdFromUrl) {
        url += `&chatroom=${channel?.value || channelIdFromUrl}`;
    } else if (teamId) {
        url += `&team=${teamId}`;
    }
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    if (searchTags?.length) url += `&tags=${searchTags.join(',')}`;
    if (submissionType?.value) {
        if (submissionType.value === 'urlSubmission') {
            url += `&type=normal&urlSubmission=true`;
        } else {
            url += `&submissionType=${submissionType.value}&type=normal`;
        }
    }

    return url;
};

const TeamSettingSubmittedForms = ({ search, channelDetails, managerView }) => {
    const teamId = getTeamIdFromURL();
    let formCounter = 0;
    const [sortBy, setSortBy] = useState({
        key: 'createdAt',
        direction: 'desc',
    });
    const scrollRef = useRef(null);

    const [form, setForm] = useState(false);
    const [endDate, setEndDate] = useState();
    const [member, setMember] = useState(null);
    const [members, setMembers] = useState([]);
    const [startDate, setStartDate] = useState();
    const [channel, setChannel] = useState(channelDetails?.id ? channelDetails : null);
    const [filteredForms, setFilteredForms] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [totalForms, setTotalForms] = useState(11);
    const [currentPage, setCurrentPage] = useState(1);
    const [formNames, setFormNames] = useState([]);
    const [formName, setFormName] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [searchTags, setSearchTags] = useState([]);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const handleCloseTagsModal = () => setShowTagsModal(false);
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    const [submissionType, setSubmissionType] = useState(null);
    const updateTagsInSubmittedForms = (updatedFormResponse) => {
        const updatedForms = filteredForms.map((form) =>
            (form?._id || form?.id) === (updatedFormResponse?._id || updatedFormResponse?.id)
                ? { ...form, ...updatedFormResponse }
                : form
        );
        setFilteredForms(updatedForms);
    };

    const generatePdfAndDownloadZip = async (singleFile = true) => {
        setIsDownloading(true);
        try {
            const url = generateUrl({
                sortBy,
                currentPage: 1, // or the appropriate value
                teamId,
                formName,
                member,
                channel,
                startDate,
                endDate,
                searchTags,
                submissionType,
                channelIdFromUrl,
                limit: 3000,
            });
            const response = await fetchWrapper.get(url);

            const userForms = response.results;

            // console.log("DOWNLOADING FORMS => ", userForms)

            const pdfName = `${formName?.label || ''}${member?.label ? '_' + member.label : ''}${
                channel?.label ? '_' + channel.label : ''
            }${startDate ? '_' + new Date(startDate).toLocaleDateString() : ''}${
                endDate ? '_' + new Date(endDate).toLocaleDateString() : ''
            }.pdf`;

            if (singleFile) {
                const blob = await FormPdf(userForms, 'combined');
                return FileSaver.saveAs(blob, pdfName);
            }

            const formPdfBlobs = await Promise.all(
                userForms.map(async (userForm) => {
                    return await FormPdf(userForm);
                })
            );

            const zip = JSZip();
            formPdfBlobs.forEach((pdfBlob, i) => {
                zip.file(
                    `${getDateTimeFromDateTime(userForms[i].updatedAt)}-${userForms[i].userData.name}-${
                        userForms[i].formData.name
                    }.pdf`,
                    pdfBlob
                );
            });
            zip.generateAsync({ type: 'blob' }).then((zipFile) => {
                const currentDate = new Date().getTime();
                const fileName = `Bulk-${currentDate}.zip`;
                return FileSaver.saveAs(zipFile, fileName);
            });
        } catch (error) {
            console.log('ERROR IN PDF GENERATION', error);
        } finally {
            setIsDownloading(false);
        }
    };

    const onScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                console.log('reached bottom');
            }
        }
    };

    const onPageChanged = ({ currentPage }) => {
        if (currentPage < 1) {
            currentPage = 1;
        }
        setCurrentPage(currentPage);
    };
    const handleEndDateChange = (date) => {
        if (!date) {
            setEndDate(null);
            return;
        }
        setEndDate(date.toISOString());
    };

    const handleStartDateChange = (date) => {
        if (!date) {
            setStartDate(null);
            return;
        }
        setStartDate(date.toISOString());
    };

    const handleEndDateClear = () => {
        setEndDate(null);
    };
    const handleStartDateClear = () => {
        setStartDate(null);
    };

    const handleSubmissionType = (e) => {
        setSubmissionType(e || null);
    };
    useEffect(() => {
        const getSubmittedUserForms = async () => {
            setLoadingFiles(true);
            try {
                const url = generateUrl({
                    sortBy,
                    currentPage,
                    teamId,
                    formName,
                    member,
                    channel,
                    startDate,
                    endDate,
                    searchTags,
                    submissionType,
                    channelIdFromUrl,
                });

                const response = await fetchWrapper.get(url);
                setFilteredForms(response.results);
                setTotalForms(response.totalResults);
            } catch (error) {
                console.log(error);
                if (error.name !== 'AbortError') {
                    toastWrapper.error(error.message || 'Failed to load submitted forms');
                }
            } finally {
                setLoadingFiles(false);
            }
        };
        const timer = setTimeout(() => {
            getSubmittedUserForms();
        }, 250);

        return () => clearTimeout(timer);
    }, [currentPage, sortBy, formName, member, channel, startDate, endDate, searchTags, submissionType]);

    const hideFormPreview = () => {
        setForm(false);
    };

    useEffect(() => {
        const getTeamMembers = async () => {
            let FormOptions;
            if (channelIdFromUrl) {
                FormOptions = await fetchWrapper.get(
                    `userFormData/filterOptions/${teamId}?channelId=${channelIdFromUrl}`
                );
            } else {
                FormOptions = await fetchWrapper.get(`userFormData/filterOptions/${teamId}`);
            }
            setMembers(FormOptions?.submittedBy);
            setFormNames(FormOptions?.formName);
        };
        getTeamMembers();
    }, [teamId]);

    const handleChangeMember = (value) => {
        setMember(value);
    };
    const handleClearMember = () => {
        setMember(null);
    };
    const handleFormName = (value) => {
        setFormName(value);
    };
    const handleClearFormName = () => {
        setFormName(null);
    };
    const handleChangeChannel = (value) => {
        setChannel(value);
    };
    const handleSort = (fieldName) => {
        setSortBy({ direction: sortBy.direction === 'asc' ? 'desc' : 'asc', key: fieldName });
    };
    const handleTagSelect = (values) => {
        setSearchTags(values?.map((tag) => tag?.value));
    };

    return (
        <div css={DocumentsStyles}>
            <Filters
                form={form}
                formName={formName}
                member={member}
                channel={channel}
                startDate={startDate}
                endDate={endDate}
                searchTags={searchTags}
                submissionType={submissionType}
                channelIdFromUrl={channelIdFromUrl}
                formNames={formNames}
                members={members}
                filteredForms={filteredForms}
                sortBy={sortBy}
                submissionTypes={submissionTypes}
                channelDetails={channelDetails}
                isDownloading={isDownloading}
                loadingFiles={loadingFiles}
                handleFormName={handleFormName}
                showTagsModal={showTagsModal}
                handleClearFormName={handleClearFormName}
                handleChangeChannel={handleChangeChannel}
                handleStartDateClear={handleStartDateClear}
                handleEndDateChange={handleEndDateChange}
                handleChangeMember={handleChangeMember}
                handleEndDateClear={handleEndDateClear}
                handleStartDateChange={handleStartDateChange}
                handleSubmissionType={handleSubmissionType}
                handleTagSelect={handleTagSelect}
                handleCloseTagsModal={handleCloseTagsModal}
                handleSort={handleSort}
                handleClearMember={handleClearMember}
                generatePdfAndDownloadZip={generatePdfAndDownloadZip}
            />
            <Table
                form={form}
                teamId={teamId}
                channel={channel}
                member={member}
                filteredForms={filteredForms}
                loadingFiles={loadingFiles}
                totalForms={totalForms}
                currentPage={currentPage}
                scrollRef={scrollRef}
                formCounter={formCounter}
                startDate={startDate}
                endDate={endDate}
                setForm={setForm}
                onScroll={onScroll}
                onPageChanged={onPageChanged}
                updateTagsInSubmittedForms={updateTagsInSubmittedForms}
                hideFormPreview={hideFormPreview}
            />
        </div>
    );
};

export default TeamSettingSubmittedForms;
