import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from 'twin.macro';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';
import { ToolTip } from '../../../../../_elements/ToolTip';

//import components
import { AdminTable, ActionStyles } from '../../../../Admin/reusableStyles';
import { TeamSettingsActivityLibraryStyled } from './styles/TeamSettingActivityLibraryStyled';
import { fetchWrapper, getTeamIdFromURL, toastWrapper, getChannelIdFromSettingsURL } from '../../../../../_helpers';
import SortedTableHeaderIcon from '../../../../Admin/SortedTableHeaderIcon';
import { getRoomsByTeam } from '../../../../../_redux';
import EventModal from '../../../../Admin/Tasks/EventModal';
import PreviewEventModal from '../../../../Admin/Tasks/PreviewEventModal';
import { postATask, editTheTask } from '../../../../../_redux/actions/ActionTasks';
import ViewEventChannels from '../../../../Admin/Tasks/eventchannel/ViewEventChannels';
import AdminSearchBox from '../../../../Admin/reusableComponents/AdminSearchBox';
import LoadingSpiner from '../../../../../_elements/LoadingSpiner';
import Pagination from '../../../../Admin/Pagination';

import TagsModal from '../../../Common/Tags/TagsModal';
import TagSelectFilter from '../../../Common/Tags/TagSelectFilter';
import { TagPreviewWithContainer, AddTagsTooltip } from '../../../Common/Tags/TagsPreview';

export const EventPackageTable = styled(AdminTable)`
    thead {
        position: sticky;
        top: 0;
        z-index: 2;
    }
    &.top-30 {
        thead {
            top: 25px !important;
        }
    }
`;

export const ScrollContainer = styled.div`
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const spin = css`
    animation: spin 2s ease infinite;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const TeamSettingsActivityLibrary = ({ CurrUser, postATask, editTheTask }) => {
    const [channel, setChannel] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const { channelId } = useParams();
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    const [showModal, setShowModal] = useState(null);
    const [events, setEvents] = useState([]);
    const [loadingEventPackage, setLoadingEventPackage] = useState(false);
    const scrollRef = useRef(null);
    const teamId = getTeamIdFromURL();
    const [sortBy, setSortBy] = useState({
        key: 'createdAt',
        direction: 'desc',
    });
    const [searchTags, setSearchTags] = useState([]);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const handleShowTagsModal = (id) => setShowTagsModal(id);
    const handleCloseTagsModal = () => setShowTagsModal(false);

    const updateTagsInEvent = (updatedResponse) => {
        const updatedEvents = events.map((event) =>
            (event?._id || event?.id) === (updatedResponse?._id || updatedResponse?.id)
                ? { ...event, ...updatedResponse }
                : event
        );
        setEvents(updatedEvents);
    };

    const [searchText, setSearchText] = useState('');
    const inputRef = useRef();

    const onSearch = (val) => {
        setSearchText(val);
    };

    const handleSort = (fieldName) => {
        setSortBy({ direction: sortBy.direction === 'asc' ? 'desc' : 'asc', key: fieldName });
    };

    const [fetchedTasks, setFetchedTasks] = useState([]);

    const handleFetchTask = async (id) => {
        if (!id) {
            return;
        }
        const response = await fetchWrapper.get(`/task/${id}`);
        setEvents((prev) => prev.map((task) => (task._id === id ? { ...task, ...response } : task)));
        setFetchedTasks((prev) => [...prev, id]);
    };

    const handleUpdateTask = (eventId, updatedResponse = {}) => {
        setEvents((prev) => prev.map((task) => (task._id === eventId ? { ...task, ...updatedResponse } : task)));
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);

    const getEventPackage = async (page = 1, searchText = '', sortBy = { key: 'createdAt', direction: 'desc' }) => {
        try {
            setLoadingEventPackage(true);
            setCurrentPage(page);
            await fetchWrapper
                .get(
                    `task?team=${teamId}&sortBy=${sortBy.key}:${sortBy.direction}&limit=10&page=${page}${searchText ? `&title=${searchText}` : ''}${searchTags?.length ? `&tags=${searchTags}` : ''}${channelIdFromUrl ? `&assignedChannel=${channelIdFromUrl}` : ''}`
                )
                .then((res) => {
                    setEvents(res?.results || []);
                    setTotalRecords(res?.totalResults || 0);
                    setLoadingEventPackage(false);
                });
        } catch (error) {
            setLoadingEventPackage(false);
            if (error.name === 'AbortError') {
                return;
            }
            toastWrapper.error(error.message || 'Failed to load Team Plan');
        }
    };

    useEffect(() => {
        const getResult = setTimeout(() => {
            searchText && getEventPackage(1, searchText, sortBy);
        }, 1000);
        return () => clearTimeout(getResult);
    }, [searchText, searchTags]);

    useEffect(() => {
        getEventPackage(currentPage, searchText, sortBy);
    }, [sortBy, searchTags]);

    useEffect(() => {
        // getEventPackage();
        if (teamId) {
            getRoomsByTeam(teamId);
        }
    }, [teamId]);

    const handleSubmitTask = async (task) => {
        let creator = {
            avatar: CurrUser.avatar,
            id: CurrUser.id,
            name: CurrUser.name,
            onlineStatus: CurrUser.onlineStatus,
        };
        let response = await postATask(task, creator);
        getEventPackage();
        return response;
    };

    useEffect(() => {
        if (CurrUser) {
            let userTeam = CurrUser.teamList.filter((team) => team.team && team.team?.id === channel?.team);
            let userChannel = CurrUser.roomList.filter((room) => room.room && room.room.id === channelId);
            if (CurrUser.role === 'admin') {
                setUserRole('admin');
            } else if (userTeam.length) {
                if (userTeam[0].role === 'owner' || userTeam[0].role === 'teamManager') {
                    setUserRole(userTeam[0].role);
                } else if (userChannel.length) {
                    setUserRole(userChannel[0].role);
                }
            } else if (userChannel.length) {
                setUserRole(userChannel[0].role);
            } else {
                setUserRole(null);
            }
        }
    }, [CurrUser, channel?.team, channelId]);

    useEffect(() => {
        if (channelId) {
            fetchWrapper.get(`/chatrooms/${channelId}`).then((res) => setChannel(res));
        }
    }, [channelId]);

    const handleEditClick = async (id, newTasks) => {
        let response = await editTheTask(id, newTasks);
        getEventPackage();
        return response;
    };

    const handleShowEvent = (id) => {
        setShowModal(id);
    };

    const handleClose = () => {
        setShowModal(null);
    };

    const handleEventChannel = (id) => {
        if (id) {
            window.location.href = window.location.href.replace(
                /teams\/.*\/settings#\/events\//,
                `event-channel/${id}`
            );
            // history.push(`/event-channel/${id}`);
        }
    };

    const handleTagSelect = (values) => {
        setSearchTags(values?.map((tag) => tag?.value));
    };

    return (
        <TeamSettingsActivityLibraryStyled>
            <div className="d-flex justify-content-between mb-4">
                <div className="d-flex ">
                    <AdminSearchBox
                        search={undefined}
                        onSearch={onSearch}
                        placeholder="Search Events"
                        inputRef={inputRef}
                    />

                    <div
                        css={css`
                            display: flex;
                            flex-direction: row;
                            padding: 0 1rem;
                            z-index: 3;
                        `}
                    >
                        {/* <div css={css`display:flex;align-items:center;`}>Tags:</div> */}
                        <div
                            css={css`
                                min-width: 250px;
                                width: clamp(250px, max-content);
                                margin-right: 4rem;
                                margin-left: 0.5rem;
                            `}
                        >
                            <TagSelectFilter
                                className="bolder"
                                css={css`
                                    width: 250px;
                                `}
                                handleSelectCallback={handleTagSelect}
                                type="event"
                                disabled={loadingEventPackage}
                            />
                        </div>
                    </div>
                </div>
                <EventModal channel={channel} handleSubmitTask={handleSubmitTask} userRole={userRole} />
            </div>

            {loadingEventPackage ? (
                // <h4 className="text-center">
                // 	<VscLoading css={spin} fontSize="42" />
                // </h4>
                <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                    <LoadingSpiner size={42} height={200} />
                </div>
            ) : events?.length > 0 ? (
                <div className="custom-card">
                    <EventPackageTable className="w-100">
                        <thead>
                            <tr>
                                <th
                                    onClick={() => handleSort('createdAt')}
                                    css={css`
                                        color: inherit;
                                        cursor: pointer;
                                    `}
                                >
                                    S.N {''}
                                    <SortedTableHeaderIcon sortConfig={sortBy} fieldName={'createdAt'} />
                                </th>
                                <th>Event Name</th>
                                <th>Sub Channel</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events?.length > 0 ? (
                                <Fragment>
                                    {events.map((eventData, index) => {
                                        return (
                                            <tr key={`${eventData._id}_${index}`}>
                                                <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                                                <td>
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            flex-direction: row;
                                                        `}
                                                    >
                                                        {eventData.title}{' '}
                                                        {(eventData?.eventPackage?._id ||
                                                            eventData?.eventPackage?.id ||
                                                            eventData?.eventPackage) && (
                                                            <span
                                                                className="badge badge-warning"
                                                                style={{ padding: '4px 6px', marginLeft: '4px' }}
                                                            >
                                                                Plan Event
                                                            </span>
                                                        )}
                                                        {
                                                            <span
                                                                css={css`
                                                                    width: fit-content;
                                                                    cursor: pointer;
                                                                    margin-left: 10px;
                                                                `}
                                                                onClick={() =>
                                                                    handleShowTagsModal(eventData?._id || eventData?.id)
                                                                }
                                                            >
                                                                <AddTagsTooltip />
                                                            </span>
                                                        }
                                                    </div>
                                                    <div
                                                        css={css`
                                                            margin: 0.5rem 0;
                                                        `}
                                                    >
                                                        <TagPreviewWithContainer
                                                            tags={eventData?.tags || []}
                                                            style={{ maxWidth: '50rem' }}
                                                        />
                                                    </div>
                                                    {showTagsModal === (eventData?._id || eventData?.id) ? (
                                                        <TagsModal
                                                            show={!!showTagsModal}
                                                            handleClose={handleCloseTagsModal}
                                                            type={'event'}
                                                            tagAction={'assign'}
                                                            objectId={eventData?._id || eventData?.id}
                                                            prevTags={eventData?.tags}
                                                            responseCallback={updateTagsInEvent}
                                                        />
                                                    ) : null}
                                                </td>
                                                <td>
                                                    <ViewEventChannels
                                                        handleEventChannel={handleEventChannel}
                                                        eventId={eventData._id}
                                                        teamId={teamId}
                                                    />{' '}
                                                </td>
                                                <td
                                                    onClick={(event) => {
                                                        if (
                                                            !['SVG', 'PATH'].includes(
                                                                event.target?.tagName?.toUpperCase()
                                                            )
                                                        ) {
                                                            return;
                                                        }
                                                        handleFetchTask(eventData._id);
                                                    }}
                                                >
                                                    <ActionStyles className="mt-2">
                                                        <div>
                                                            <ToolTip
                                                                text={'View'}
                                                                cssStyles={
                                                                    'top:-70% !important; min-width: fit-content !important;'
                                                                }
                                                            >
                                                                <BsEye
                                                                    onClick={() => handleShowEvent(eventData._id)}
                                                                    className="icon view"
                                                                />
                                                            </ToolTip>
                                                        </div>

                                                        <EventModal
                                                            data={eventData}
                                                            handleSubmitTask={(newTasks) =>
                                                                handleEditClick(eventData?._id, newTasks)
                                                            }
                                                            userRole={userRole}
                                                        />
                                                        <EventModal
                                                            data={eventData}
                                                            handleSubmitTask={handleSubmitTask}
                                                            userRole={userRole}
                                                            shouldEdit={false}
                                                        />
                                                    </ActionStyles>
                                                </td>
                                                {showModal === eventData._id ? (
                                                    <PreviewEventModal
                                                        data={eventData}
                                                        handleClose={handleClose}
                                                        handleUpdateTask={handleUpdateTask}
                                                    />
                                                ) : null}
                                            </tr>
                                        );
                                    })}
                                </Fragment>
                            ) : (
                                <tr>
                                    <td colSpan="100">
                                        <h4 className="text-center">No Forms Found</h4>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </EventPackageTable>
                </div>
            ) : (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    `}
                >
                    <h2 className="text-center">No Events in this Team</h2>
                </div>
            )}
            {totalRecords ? (
                <div className="p-4">
                    <Pagination
                        totalRecords={totalRecords}
                        pageLimit={10}
                        pageNeighbours={1}
                        onPageChanged={async (data) => {
                            if (data.currentPage && data.currentPage !== currentPage) {
                                await getEventPackage(data.currentPage, searchText, sortBy);
                            }
                        }}
                    />
                </div>
            ) : null}
        </TeamSettingsActivityLibraryStyled>
    );
};
const mapStateToProps = (state) => ({
    rooms: state.rooms.teamRooms,
    CurrUser: state.user.user,
});

export default connect(mapStateToProps, { getRoomsByTeam, postATask, editTheTask })(TeamSettingsActivityLibrary);
