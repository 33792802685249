import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchWrapper, getTeamIdFromURL } from '../../../../../_helpers';
import { VscLoading } from 'react-icons/vsc';
import { StyledContainer, TableCSS } from '../styles/MembersStyled';
import { toast } from 'react-toastify';
import CreateContactPicker from '../../../BulkUpload/Contact';
import SingleContactModal from '../../../BulkUpload/Contact/SingleModal';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import { ActionStyles } from '../../../../Admin/reusableStyles';
import { BootstrapToolTip } from '../../../../../_elements/ToolTip';
import { BsPencil } from 'react-icons/bs';
import { GoToChannel } from '../../../../../_elements/Links';

const getSortedContacts = (contacts) => {
    return contacts.sort((a, b) => {
        if (a?.name < b?.name) return -1;
        if (a?.name > b?.name) return 1;
        return 0;
    });
};

const ContactRow = ({ contact, index, setShowModal, setFilteredContacts, teamId }) => {
    const [isBusy, setIsBusy] = useState(false);
    const handleContactStatus = async (contact) => {
        try {
            if (!contact?._id) return;
            setIsBusy(contact);
            const status = contact?.status === 'archive' ? 'Active' : 'Archive';
            await fetchWrapper.patch(`contacts/status/${contact._id}?team=${teamId}`);
            toast.success(`Contact status updated to ${status}`);
            setFilteredContacts((prev) =>
                prev.map((_contact) =>
                    _contact._id === contact._id ? { ..._contact, status: status?.toLocaleLowerCase() } : _contact
                )
            );
        } catch (err) {
            toast.error(err?.message || 'Something went wrong');
        } finally {
            setIsBusy(false);
        }
    };
    return (
        <tr key={index}>
            <td>{contact?.name}</td>
            <td>{contact?.phone}</td>
            <td>{contact?.email || '-'}</td>
            <td>
                <GoToChannel channel={contact?.chatroomInfo} />
            </td>
            <td>
                <span className={`badge badge-${contact?.status === 'archive' ? 'danger' : 'success'}`}>
                    {contact?.status === 'archive' ? 'archived' : 'active'}
                </span>
            </td>
            <td>
                <div className="d-flex">
                    <ActionStyles>
                        <BootstrapToolTip text="Edit Contact">
                            <BsPencil className="icon edit" onClick={() => setShowModal(contact)} />
                        </BootstrapToolTip>

                        <div>
                            {isBusy && isBusy?._id === contact._id ? (
                                <VscLoading className="spin" />
                            ) : (
                                <Fragment>
                                    <BootstrapToolTip
                                        text={contact?.status === 'archive' ? 'Unarchive Contact' : 'Archive Contact'}
                                    >
                                        {contact?.status === 'archive' ? (
                                            <BiArchiveOut
                                                className="icon archiveOut"
                                                onClick={() => handleContactStatus(contact)}
                                            />
                                        ) : (
                                            <BiArchiveIn
                                                className="icon archiveIn"
                                                onClick={() => handleContactStatus(contact)}
                                            />
                                        )}
                                    </BootstrapToolTip>
                                </Fragment>
                            )}
                        </div>
                    </ActionStyles>
                </div>
            </td>
        </tr>
    );
};
const TeamContacts = ({ teams }) => {
    const [filteredContacts, setFilteredContacts] = useState(null);
    const [contacts, setContacts] = useState(null);
    const [contactSearch, setContactSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const teamId = getTeamIdFromURL();
    let teamArr = teams.filter((team) => team.id === teamId);
    let team = teamArr[0];

    const getTeamContacts = async () => {
        try {
            const response = await fetchWrapper.get(`contacts?team=${teamId}&showArchive=true&limit=500`);
            setContacts(response || []);
            setFilteredContacts(getSortedContacts(response || []));
        } catch (error) {
            console.log('ERROR', error);
            if (error?.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
        }
    };

    useEffect(() => {
        if (teamId) {
            getTeamContacts();
        }
    }, [team, teamId]);

    const handleSearchChange = (e) => {
        const value = e.target.value || '';
        setContactSearch(value);
        const _temp = (contacts || []).filter(
            (contact) =>
                contact?.name?.toLowerCase().includes(value.toLowerCase()) ||
                contact?.phone?.toLowerCase().includes(value.toLowerCase()) ||
                contact?.email?.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredContacts(getSortedContacts(_temp));
    };

    return (
        <StyledContainer>
            <h1 className="mb-4">Contacts</h1>
            <Fragment>
                <div className="d-flex justify-content-between ">
                    <input
                        className="input"
                        onChange={handleSearchChange}
                        value={contactSearch}
                        placeholder="Search for Contact"
                    />
                    <CreateContactPicker onCreateOrUpdate={getTeamContacts} />
                </div>
            </Fragment>
            <Fragment>
                {!filteredContacts ? (
                    <h4 className="text-center">
                        <VscLoading className="spin" fontSize="42" />
                    </h4>
                ) : (
                    <div className="custom-card mt-4">
                        <TableCSS>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Channel</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    {/* <th>Channel</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredContacts && filteredContacts.length > 0 ? (
                                    <Fragment>
                                        {filteredContacts.map((contact, index) => {
                                            return (
                                                <ContactRow
                                                    key={contact?._id || index}
                                                    contact={contact}
                                                    teamId={teamId}
                                                    setShowModal={setShowModal}
                                                    setFilteredContacts={setFilteredContacts}
                                                />
                                            );
                                        })}
                                    </Fragment>
                                ) : (
                                    <tr>
                                        <td colSpan="100">
                                            <h4 className="text-center">Oops! No Contact Found</h4>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </TableCSS>
                    </div>
                )}
                <SingleContactModal
                    data={showModal}
                    onCreateOrUpdate={getTeamContacts}
                    show={!!showModal}
                    setShow={setShowModal}
                />
            </Fragment>
        </StyledContainer>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    userRole: state.user?.user?.role,
});

export default connect(mapStateToProps, {})(TeamContacts);
