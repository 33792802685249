export const IMAGESTYPES = 'image/*';
export const VIDEOTYPES = 'video/mp4,video/x-m4v,audio/mp3';
export const FILETYPES = '.pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv';

export const MAX_IMAGE_SIZE = 20;
export const MAX_VIDEO_SIZE = 200;
export const MAX_FILE_SIZE = 20;

export const IMAGE_EXTENSIONS = [
    '.exr',
    '.apng',
    '.avci',
    '.avcs',
    '.avif',
    '.bmp',
    '.cgm',
    '.drle',
    '.emf',
    '.fits',
    '.g3',
    '.gif',
    '.heic',
    '.heics',
    '.heif',
    '.heifs',
    '.hej2',
    '.hsj2',
    '.ief',
    '.jls',
    '.jp2',
    '.jpg2',
    '.jpeg',
    '.jpg',
    '.jpe',
    '.jph',
    '.jhc',
    '.jpm',
    '.jpx',
    '.jpf',
    '.jxr',
    '.jxra',
    '.jxrs',
    '.jxs',
    '.jxsc',
    '.jxsi',
    '.jxss',
    '.ktx',
    '.ktx2',
    '.png',
    '.btif',
    '.pti',
    '.sgi',
    '.svg',
    '.svgz',
    '.t38',
    '.tif',
    '.tiff',
    '.tfx',
    '.psd',
    '.azv',
    '.uvi',
    '.uvvi',
    '.uvg',
    '.uvvg',
    '.djvu',
    '.djv',
    '.sub',
    '.dwg',
    '.dxf',
    '.fbs',
    '.fpx',
    '.fst',
    '.mmr',
    '.rlc',
    '.ico',
    '.dds',
    '.mdi',
    '.wdp',
    '.npx',
    '.b16',
    '.tap',
    '.vtf',
    '.wbmp',
    '.xif',
    '.pcx',
    '.webp',
    '.wmf',
    '.3ds',
    '.ras',
    '.cmx',
    '.fh',
    '.fhc',
    '.fh4',
    '.fh5',
    '.fh7',
    '.jng',
    '.sid',
    '.pbm',
    '.pnm',
    '.pgm',
    '.ppm',
    '.rgb',
    '.tga',
    '.xbm',
    '.xpm',
    '.xwd',
];
