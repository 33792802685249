import React, { useState } from 'react';
import { connect } from 'react-redux';
import EvvCreateOptions from '../../../Admin/EVVContainer/EvvCreateModal/EvvCreateOptions';
import { AddMemberStyle } from '../../Teams/TeamSettings/Members';
import EvvCreateModal from '../../../Admin/EVVContainer/EvvCreateModal/EvvCreateModal';
import BulkModal from './BulkModal';
import CreateStaff from '../../../Admin/EVVContainer/Staff/CreateStaff';
const bulkCategory = 'staff';

const Staff = ({ provider, onCreateOrUpdate = () => {} }) => {
    const [showModal, setShowModal] = useState(false);
    const [createMode, setCreateMode] = useState(null);

    const handleModalShow = () => {
        setCreateMode(null);
        setShowModal(true);
    };
    const handleCloseAllModal = () => {
        setShowModal(false);
    };

    const handleGoBack = () => {
        setCreateMode(null);
    };

    let content = null;
    switch (createMode) {
        default:
            content = (
                <EvvCreateOptions type={bulkCategory} setCreateProviderMode={setCreateMode} fileType="excel (.xlsx)" />
            );
            break;
    }

    return (
        <>
            <div css={AddMemberStyle}>
                <button type="button" className="btn button-green" onClick={handleModalShow}>
                    Create New Staff
                </button>
            </div>
            {showModal && !createMode && (
                <EvvCreateModal
                    type={bulkCategory}
                    showModal={showModal}
                    hideModal={handleCloseAllModal}
                    content={content}
                    goBack={createMode ? handleGoBack : null}
                />
            )}
            {createMode === 'individual' && (
                <CreateStaff
                    hideModal={handleCloseAllModal}
                    show={showModal}
                    setShow={setShowModal}
                    onCreateOrUpdate={onCreateOrUpdate}
                    provider={provider}
                />
            )}
            {createMode === 'bulk' && (
                <BulkModal
                    bulkCategory={bulkCategory}
                    hideModal={handleCloseAllModal}
                    show={showModal}
                    setShow={setShowModal}
                    onCreateOrUpdate={onCreateOrUpdate}
                    provider={provider}
                />
            )}
        </>
    );
};

export default connect(null, {})(Staff);
