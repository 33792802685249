import React, { useState, useRef, Fragment, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'twin.macro';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import { BsPencil } from 'react-icons/bs';
import { VscNotebookTemplate } from 'react-icons/vsc';
import { AiOutlineCopy } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';
import { connect } from 'react-redux';
import { ToolTip } from '../../../../../_elements/ToolTip';

//reusable component
import { TeamSettingsActivityPackageStyled } from './styles/TeamSettingActivityPackageStyled';
import AdminSearchBox from '../../../../Admin/reusableComponents/AdminSearchBox';
import { AdminTable } from '../../../../Admin/reusableStyles';
import LoadingSpiner from '../../../../../_elements/LoadingSpiner';
import { fetchWrapper } from '../../../../../_helpers';
import { ActionStyles } from '../../../../Admin/reusableStyles';
import ActivityPackagePreviewModal from '../../../../Admin/activity/ActivityPackagePreviewModal';
import { getTeamIdFromURL } from '../../../../../_helpers';
import AssignChannelToPackage from '../../../../Admin/activity/AssignChannelToPackage';
import { BsEye } from 'react-icons/bs';
import Pagination from '../../../../Admin/Pagination';

const TeamSettingsActivityPackage = ({ CurrUser }) => {
    const userId = CurrUser.id || CurrUser._id;
    const { role } = CurrUser;
    const [searchText, setSearchText] = useState('');
    const inputRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState({ packages: 10, templates: 10 });
    const [activityPackages, setActivityPackages] = useState([]);
    const [activityPackageTemplates, setActivityPackageTemplates] = useState([]);
    const teamId = getTeamIdFromURL();
    const [activeTab, setActiveTab] = useState('packages');

    const onSearch = (val) => {
        setSearchText(val);
    };

    const fetchActivityPackages = useCallback(
        async (page = 1) => {
            setIsLoading(true);
            if (page < 1) page = 1;
            setCurrentPage(page);
            const res = await fetchWrapper.get(
                `activityPackages?team=${teamId}&sortBy=${
                    searchText ? 'name:asc' : 'createdAt:desc'
                }&limit=10&page=${page}${searchText ? `&name=${searchText}` : ''}`
            );
            setActivityPackages(res.results);
            setTotalRecords((prev) => ({ ...prev, packages: res.totalResults }));
            setIsLoading(false);
        },
        [searchText, teamId]
    );

    const fetchActivityPackageTemplates = useCallback(
        async (page = 1) => {
            setIsLoading(true);
            if (page < 1) page = 1;
            setCurrentPage(page);
            const res = await fetchWrapper.get(
                `activityPackageTemplates?team=${teamId}&sortBy=${
                    searchText ? 'name:asc' : 'createdAt:desc'
                }&limit=10&page=${page}${searchText ? `&name=${searchText}` : ''}`
            );
            setActivityPackageTemplates(res.results);
            setTotalRecords((prev) => ({ ...prev, templates: res.totalResults }));
            setIsLoading(false);
        },
        [searchText, teamId]
    );
    useEffect(() => {
        const getResult = setTimeout(
            () => {
                ({ packages: fetchActivityPackages, templates: fetchActivityPackageTemplates })[activeTab]();
            },
            searchText ? 1000 : 0
        );
        return () => clearTimeout(getResult);
    }, [searchText, activeTab]);

    const [show, setShow] = useState(false);
    const [handleId, setHandleId] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ShowClickedModal = (id) => setHandleId(id);

    return (
        <TeamSettingsActivityPackageStyled>
            <div className="buildNewForm_Div mb-3 d-flex justify-content-between">
                <AdminSearchBox
                    search={undefined}
                    onSearch={onSearch}
                    placeholder={`Search Activity ${{ packages: 'Plan', templates: 'Templates' }[activeTab]}`}
                    inputRef={inputRef}
                />
                <Button
                    className="text-capitalize"
                    disabled={isLoading}
                    onClick={() => {
                        setActiveTab((prev) => (prev === 'packages' ? 'templates' : 'packages'));
                    }}
                >
                    Show {activeTab === 'packages' ? 'templates' : 'plans'}
                </Button>
                <Link to={`/event-planner-builder`}>
                    <button className="btn button-green">Build a New Plan</button>
                </Link>
            </div>
            <Fragment>
                <Row>
                    <Col>
                        <div className="custom-card card-table d-flex flex-column justify-content-between">
                            <AdminTable className="mb-5 w-100">
                                <thead>
                                    <tr>
                                        <th className="text-center pl-4">S.N.</th>
                                        <th className="text-left text-capitalize pl-4">
                                            {activeTab === 'packages' ? 'Plan' : 'Template'} Name
                                        </th>
                                        <th className="text-left">Events</th>
                                        {activeTab === 'packages' && <th className="text-left">Channels</th>}
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-light-grey">
                                    {isLoading ? (
                                        <td colSpan={7}>
                                            <LoadingSpiner size={42} height={200} />
                                        </td>
                                    ) : (
                                        <Fragment>
                                            {(() => {
                                                const RenderData = {
                                                    packages: {
                                                        data: activityPackages,
                                                        RowComponent: PackageRow,
                                                    },
                                                    templates: {
                                                        data: activityPackageTemplates,
                                                        RowComponent: TemplateRow,
                                                    },
                                                }[activeTab];
                                                return RenderData.data && RenderData.data.length > 0 ? (
                                                    RenderData.data.map((item, index) => (
                                                        <RenderData.RowComponent
                                                            key={item._id}
                                                            item={item}
                                                            index={index}
                                                            currentPage={currentPage}
                                                            handleId={handleId}
                                                            show={show}
                                                            handleClose={handleClose}
                                                            role={role}
                                                            userId={userId}
                                                            fetchActivityPackages={fetchActivityPackages}
                                                            ShowClickedModal={ShowClickedModal}
                                                            handleShow={handleShow}
                                                        />
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <div
                                                                className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                                                css={css`
                                                                    margin-top: 50px;
                                                                    width: 100%;
                                                                `}
                                                            >
                                                                No Plan Found
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })()}
                                        </Fragment>
                                    )}
                                </tbody>
                            </AdminTable>
                        </div>
                    </Col>
                </Row>
            </Fragment>
            {totalRecords[activeTab] ? (
                <div className="p-4">
                    <Pagination
                        totalRecords={totalRecords[activeTab]}
                        pageLimit={10}
                        pageNeighbours={1}
                        pageNo={currentPage}
                        onPageChanged={async (data) => {
                            if (data.currentPage && data.currentPage !== currentPage) {
                                await { packages: fetchActivityPackages, templates: fetchActivityPackageTemplates }[
                                    activeTab
                                ](data.currentPage);
                            }
                        }}
                    />
                </div>
            ) : null}
        </TeamSettingsActivityPackageStyled>
    );
};

function TemplateRow({ item, index, currentPage, handleId, show, handleClose, role, ShowClickedModal, handleShow }) {
    return (
        <tr>
            <td className="text-center p-1 pl-4">{(currentPage - 1) * 10 + (index + 1)}</td>
            <td className="w-25 text-left p-1 pl-4">
                <span>{item.name}</span>
            </td>
            <td className="text-left p-1">
                {item.taskData.map((task, task_idx) => {
                    return (
                        <Badge key={task._id || task_idx} className="mx-2 p-1" variant="primary">
                            {task.title}
                        </Badge>
                    );
                })}
            </td>
            <td
                className="action p-1"
                css={css`
                    min-width: 200px;
                `}
            >
                <ActionStyles>
                    <ToolTip text={'View'} cssStyles={'top:-70% !important; min-width: fit-content !important;'}>
                        <BsEye
                            className="icon"
                            onClick={() => {
                                ShowClickedModal(item._id);
                                handleShow();
                            }}
                        />
                    </ToolTip>
                    {item._id === handleId ? (
                        <ActivityPackagePreviewModal
                            packageData={item}
                            show={show}
                            handleClose={handleClose}
                            title={'template'}
                        />
                    ) : null}
                    <Fragment>
                        {role === 'admin' ? (
                            <Link to={`/event-planner-template/edit/${item._id}`}>
                                <ToolTip
                                    text={'Edit'}
                                    side={'top'}
                                    cssStyles={'top:-70% !important; min-width: fit-content !important'}
                                >
                                    <BsPencil className="icon edit" />
                                </ToolTip>
                            </Link>
                        ) : (
                            <Fragment />
                        )}
                    </Fragment>

                    <Link to={`/event-planner-template/build/${item._id}`}>
                        <ToolTip text={'Build'} cssStyles={'top:-70% !important; min-width: fit-content !important'}>
                            <VscNotebookTemplate className="icon" />
                        </ToolTip>
                    </Link>
                </ActionStyles>
            </td>
            <td className="verification-status"></td>
        </tr>
    );
}

function PackageRow({
    item,
    index,
    currentPage,
    userId,
    fetchActivityPackages,
    handleId,
    show,
    handleClose,
    role,
    ShowClickedModal,
    handleShow,
}) {
    return (
        <tr key={item._id}>
            <td className="text-center p-1 pl-4">{(currentPage - 1) * 10 + (index + 1)}</td>
            <td className="w-25 text-left p-1 pl-4">
                <span>{item.name}</span>
            </td>
            <td className="text-left p-1">
                {item.taskData.map((task) => {
                    return (
                        <Badge key={task._id} className="mx-2 p-1" variant="primary">
                            {task.title}
                        </Badge>
                    );
                })}
            </td>
            <td className="action p-1">
                <ActionStyles>
                    {item.createdBy?.id === userId || role === 'admin' ? (
                        <AssignChannelToPackage
                            packageData={item}
                            // activityPackages={activityPackages}
                            fetchActivityPackages={fetchActivityPackages}
                        />
                    ) : (
                        <BiLinkExternal className="icon disabled" />
                    )}
                </ActionStyles>
            </td>
            <td
                className="action p-1"
                css={css`
                    min-width: 200px;
                `}
            >
                <ActionStyles>
                    <ToolTip text={'View'} cssStyles={'top:-70% !important; min-width: fit-content !important;'}>
                        <BsEye
                            className="icon"
                            onClick={() => {
                                ShowClickedModal(item._id);
                                handleShow();
                            }}
                        />
                    </ToolTip>
                    {item._id === handleId ? (
                        <ActivityPackagePreviewModal
                            packageData={item}
                            show={show}
                            handleClose={handleClose}
                            title={'package'}
                        />
                    ) : null}
                    <Fragment>
                        {item.createdBy?.id === userId || role === 'admin' ? (
                            <Link to={`/event-planner/edit/${item._id}`}>
                                <ToolTip
                                    text={'Edit'}
                                    side={'top'}
                                    cssStyles={'top:-70% !important; min-width: fit-content !important'}
                                >
                                    <BsPencil className="icon edit" />
                                </ToolTip>
                            </Link>
                        ) : (
                            <ToolTip
                                text={'Edit'}
                                side={'top'}
                                cssStyles={'top:-70% !important; min-width: fit-content !important'}
                            >
                                <BsPencil className="icon disabled" />
                            </ToolTip>
                        )}
                    </Fragment>

                    <Link to={`/event-planner/copy/${item._id}`}>
                        <ToolTip text={'Copy'} cssStyles={'top:-70% !important; min-width: fit-content !important'}>
                            <AiOutlineCopy className="icon" />
                        </ToolTip>
                    </Link>
                </ActionStyles>
            </td>
            <td className="verification-status"></td>
        </tr>
    );
}

const mapStateToProps = (state) => ({
    rooms: state.rooms.teamRooms,
    CurrUser: state.user.user,
});

export default connect(mapStateToProps)(TeamSettingsActivityPackage);
