import { css } from 'twin.macro';

export const DocumentsStyles = css`
    padding: 20px;
    padding-top: 0;

    .top-items {
        margin: 0 0 20px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .input {
        border-color: #dadada;
        border-style: solid;
        padding: 0.51em;
        border-radius: 5px;
        outline: none;
        transition: border-color 300ms;
        :focus {
            border-color: #50aeb0;
        }
    }
`;

export const spin = css`
    animation: spin 2s ease infinite;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export const ModalCSS = css`
    .modal-header {
        color: white;
        background: #50aeb0;
        button {
            color: white;
        }
        h4 {
            font-size: 2.4rem;
        }
    }
    .modal-body {
        .input-group,
        .search-container {
            margin-bottom: 1rem;
        }
        .form-label,
        .search-title {
            font-weight: bold;
            color: #242424;
        }
        .react-datetime-picker {
            width: 250px;
            .react-datetime-picker__wrapper {
                border: thin solid #cdcdcd;
                padding: 0.2rem 0.5rem;
                border-radius: 3px;
            }
        }
    }
    .tab-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #dee2e6 !important;
        border-top: none !important;
        padding: 2rem 1rem !important;
        min-height: 140px;
    }
`;
