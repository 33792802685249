import { css } from 'twin.macro';
import { Fragment, useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { fetchWrapper, removeMongoidAndTimestamp, toastWrapper } from '../../../../../_helpers';

import { connect } from 'react-redux';
import { DocumentsStyles } from '../../../../../../src/components/Features/Teams/TeamSettings/styles/DocumentsStyled';

//assets
import { VscLoading } from 'react-icons/vsc';
import {
    FormPackageTable,
    ScrollContainer,
    spin,
} from '../../../../../../src/components/Features/Teams/TeamSettings/TeamSettingFormPackage';
import FileMessage from '../../../../../../src/components/Features/Common/FileMessage';
import dayjs from 'dayjs';
import SortedTableHeaderIcon from '../../../../../../src/components/Admin/SortedTableHeaderIcon';
import Pagination from '../../../../../../src/components/Admin/Pagination';
import { TagPreviewWithContainer } from '../../../../../../src/components/Features/Common/Tags/TagsPreview';
import { ShareBtnAction } from '../../../../ShareBtn/ShareBtn';
import { ToolTip } from '../../../../../_elements/ToolTip';
import Channels from './actions/Channels';
import { FormLabel, InputGroup } from 'react-bootstrap';
import TagSelectFilter from '../../../Common/Tags/TagSelectFilter';
import { FaFilePdf, FaFileImage } from 'react-icons/fa';
import { RiVideoFill } from 'react-icons/ri';

export const MediaTypeSelect = ({ value, onChange }) => (
    <Select
        value={value}
        onChange={onChange}
        options={[
            {
                label: (
                    <div>
                        <FaFileImage /> Image
                    </div>
                ),
                value: 'image',
            },
            {
                label: (
                    <div>
                        <RiVideoFill /> Video
                    </div>
                ),
                value: 'video',
            },
            {
                label: (
                    <div>
                        <FaFilePdf /> PDF
                    </div>
                ),
                value: 'pdf',
            },
            { label: 'Other', value: 'other' },
        ]}
        placeholder="Media Type"
        isClearable
    />
);

const generateTeamFilesUrl = (props = {}, selectedTeams, sortBy, currentPage) => {
    const limit = props?.limit || 10;
    const teams = selectedTeams.map((team) => team?.value || team?._id);
    const teamIds = teams.join(',');
    console.log('selectedTeams', selectedTeams);
    console.log('teamIdsXX', teamIds);
    let url = `chatrooms/${teams[0]}/files?sortBy=${sortBy.key}:${sortBy.direction}&limit=${limit}&page=${currentPage}&teamIds=${teamIds}`;
    if (props.fileName) url += `&searchText=${props.fileName}`;
    if (props.userId) url += `&user=${props.userId}`;
    if (props.channel) url += `&chatroom=${props.channel}`;
    if (props.refModel) url += `&refModel=${props.refModel}`;
    if (props.mediaType) url += `&mediaType=${props.mediaType}`;
    if (props.startDate) url += `&startDate=${props.startDate}`;
    if (props.endDate) url += `&endDate=${props.endDate}`;
    if (props.searchTags?.length) url += `&tags=${props.searchTags.join(',')}`;
    return url;
};

const Documents = ({
    search,
    channelDetails = {},
    selectedTeams,
    selectedRoom,
    managedTeams,
    handleFetchAllRoomsData,
    allRoomsData,
    currUser,
    allTeamIds,
}) => {
    const scrollRef = useRef(null);

    let fileCounter = 0;

    const [endDate, setEndDate] = useState();
    const [members, setMembers] = useState([]);
    const [member, setMember] = useState(null);
    const [channel, setChannel] = useState(selectedRoom?.id ? selectedRoom : null);
    const [refModel, setRefModel] = useState(null);
    const [mediaType, setMediaType] = useState(null);
    const [startDate, setStartDate] = useState();
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [sortBy, setSortBy] = useState({
        key: 'createdAt',
        direction: 'desc',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalResults, setTotalResults] = useState(10);
    const [searchTags, setSearchTags] = useState([]);

    const onScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                console.log('reached bottom');
            }
        }
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (!(selectedTeams || []).length) {
                return;
            }
            setLoadingFiles(true);
            const url = generateTeamFilesUrl(
                {
                    fileName: search,
                    startDate,
                    endDate,
                    channel: channel?.value || channel?._id || selectedRoom?.value || selectedRoom?._id,
                    userId: member?.value,
                    refModel: refModel?.value,
                    mediaType: mediaType?.value,
                    searchTags,
                },
                selectedTeams,
                sortBy,
                currentPage
            );
            try {
                const response = await fetchWrapper.get(url);
                setTotalResults(response?.totalResults);
                setFilteredFiles(response?.results);
            } catch (error) {
                toastWrapper.error(error?.message || 'Failed to load Team Plan');
            } finally {
                setLoadingFiles(false);
            }
        }, 250);

        return () => clearTimeout(timer);
    }, [
        selectedTeams,
        sortBy,
        currentPage,
        search,
        member,
        selectedRoom,
        startDate,
        endDate,
        refModel,
        mediaType,
        searchTags,
    ]);

    const handleSort = (fieldName) => {
        setSortBy({ direction: sortBy.direction === 'asc' ? 'desc' : 'asc', key: fieldName });
    };

    if (!(selectedTeams || []).length) {
        return (
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100px;
                `}
            >
                <h1> Select team to view documents</h1>
            </div>
        );
    }

    const handleTagSelect = (values) => {
        setSearchTags((values ?? []).map((tag) => tag?.value).filter((x) => !!x));
    };
    const handleChangeRefModel = (value) => {
        setRefModel(value);
    };
    const handleChangeMediaType = (value) => {
        setMediaType(value);
    };

    return (
        <div css={DocumentsStyles}>
            <div
                className="d-flex"
                css={css`
                    gap: 8px;
                `}
            >
                <div
                    css={css`
                        min-width: 264px;
                        width: max-content;
                        z-index: 3;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        {/* <FormLabel className="mr-2 ">Tags: </FormLabel> */}
                        <TagSelectFilter
                            key={`${selectedTeams?.map((team) => team?.value)?.join(',')}`}
                            className="bolder"
                            tagCss={css`
                                width: 264px;
                                z-index: 3;
                            `}
                            handleSelectCallback={handleTagSelect}
                            type="file"
                            disabled={loadingFiles}
                            teamId={selectedTeams?.map((team) => team?.value)?.join(',')}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: max-content;
                        z-index: 3;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <Select
                            value={refModel}
                            onChange={handleChangeRefModel}
                            options={[
                                { label: 'Message', value: 'Message' },
                                { label: 'Form Submission', value: 'UserFormData' },
                                { label: 'Form', value: 'Form' },
                            ]}
                            placeholder="Submission Type"
                            isClearable
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: max-content;
                        z-index: 3;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <MediaTypeSelect value={mediaType} onChange={handleChangeMediaType} />
                    </InputGroup>
                </div>
            </div>

            {loadingFiles ? (
                <h4 className="text-center">
                    <VscLoading css={spin} fontSize="42" />
                </h4>
            ) : filteredFiles?.length ? (
                <div className="custom-card">
                    <ScrollContainer ref={scrollRef} onScroll={onScroll}>
                        <FormPackageTable className="w-100">
                            <thead>
                                <tr>
                                    <th>S.N</th>
                                    <th>File</th>
                                    <th>Type</th>
                                    <th>Sender</th>
                                    <th>Team</th>
                                    <th>Channel</th>
                                    <th
                                        css={css`
                                            color: inherit;
                                            cursor: pointer;
                                        `}
                                        onClick={() => handleSort('createdAt')}
                                    >
                                        {' '}
                                        Date <SortedTableHeaderIcon sortConfig={sortBy} fieldName={'createdAt'} />
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Fragment>
                                    {filteredFiles?.length > 0 &&
                                        filteredFiles.map((file, index) => {
                                            fileCounter++;
                                            file.displayName = removeMongoidAndTimestamp(file.name?.split('/').at(-1));
                                            return (
                                                <tr key={`${file._id}_${fileCounter}`}>
                                                    <td>{10 * (currentPage - 1) + index + 1}</td>
                                                    <td>
                                                        <FileMessage
                                                            file={file.url}
                                                            displayMessage={file.displayName}
                                                            documentSettings={true}
                                                        />
                                                        <div
                                                            css={css`
                                                                display: flex;
                                                                margin-top: 4px;
                                                                gap: 4px;
                                                            `}
                                                        >
                                                            <TagPreviewWithContainer
                                                                tags={file?.tags || []}
                                                                style={{ maxWidth: '50rem' }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {
                                                            {
                                                                Message: 'Message',
                                                                Form: 'Form',
                                                                UserFormData: 'Form Submission',
                                                            }[file.refModel]
                                                        }
                                                    </td>
                                                    <td>{file.user ? file.user.name : '-'}</td>
                                                    <td>
                                                        <span className="badge badge-primary">
                                                            {
                                                                (managedTeams || []).find((team) => {
                                                                    const fileTeamId =
                                                                        Array.isArray(file?.teams) && file.teams.length
                                                                            ? file.teams[0]
                                                                            : 'not';
                                                                    return team?.team?._id === fileTeamId;
                                                                })?.team?.name
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div
                                                            css={css`
                                                                max-height: 12rem;
                                                                overflow: auto;
                                                            `}
                                                        >
                                                            {file.rooms?.map((room) => (
                                                                <a
                                                                    key={room?.id}
                                                                    css={css`
                                                                        font-size: 16px;
                                                                        display: block;
                                                                    `}
                                                                    href={`/teams/${room?.id}`}
                                                                    className={`${room?.id ? '' : 'disabled'}`}
                                                                >
                                                                    {room?.title}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </td>
                                                    <td>{dayjs(file.createdAt).format('MMM D, YYYY')}</td>
                                                    <td>
                                                        <div
                                                            css={css`
                                                                display: flex;
                                                                align-items: center;
                                                                gap: 6px;
                                                            `}
                                                        >
                                                            <ToolTip
                                                                text="Copy Link"
                                                                side="left"
                                                                cssStyles={`top:-90% !important; min-width: 126px !important; left: -50px !important;`}
                                                            >
                                                                <ShareBtnAction url={file?.url || ''} />
                                                            </ToolTip>
                                                            <Channels
                                                                file={file}
                                                                handleFetchAllRoomsData={handleFetchAllRoomsData}
                                                                allRoomsData={allRoomsData}
                                                                allTeamIds={allTeamIds}
                                                                currUser={currUser}
                                                                selectedTeams={selectedTeams}
                                                                handleSendDocumentToChannel={() => {
                                                                    setSortBy({ key: 'createdAt', direction: 'desc' });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </Fragment>
                            </tbody>
                        </FormPackageTable>
                    </ScrollContainer>
                </div>
            ) : (
                <div
                    css={css`
                        background-color: white;
                        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
                        border-radius: 5px;
                        padding: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    `}
                >
                    <h2 className="text-center">No matching results</h2>
                    <div className="text-center">Try another search, or use search filters to find the document.</div>
                </div>
            )}
            <div className="p-4">
                <Pagination
                    totalRecords={totalResults}
                    key={totalResults}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={(data) => {
                        setCurrentPage(Math.max(1, data.currentPage));
                    }}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    rooms: state.rooms.teamRooms,
});

export default connect(mapStateToProps, {})(Documents);
