import { getUniqueShiftUUID } from './PayallocationUtils';

function getHourlyVisitShift(visitItem, options) {
    const workLocations = options?.workLocations || [];
    const visitEndTime = new Date(visitItem?.ScheduleEndTime || Date.now());
    const visitStartTime = new Date(visitItem?.ScheduleStartTime || Date.now());
    const totalHours = getTotalHours(visitItem);
    const workLocationID =
        workLocations.find((x) => x && x?.work_site_id === visitItem?.client?.identifier)?.workLocationID ||
        workLocations.length
            ? workLocations[0]?.workLocationID
            : '';
    return {
        type: 'hourly',
        hours: totalHours?.toFixed(2) || 0,
        amount: 0,
        wage: visitItem?.extraInfo?.staffRate,
        time: visitEndTime,
        custom_name: `${visitItem?._id || ''}_${Date.now()}`,
        workLocationID,
        metadata: {
            label: 'visit',
            _id: visitItem?._id,
            id: visitItem?.id,
            auto: true,
            uuid: getUniqueShiftUUID(),
        },
    };
}
function getPtoShift(visitItem) {
    const start = visitItem?.extraInfo?.startDate;
    const end = visitItem?.extraInfo?.endDate;
    const totalHours = getTotalHours(visitItem);
    return {
        type: 'pto',
        totalHours,
        rate: visitItem?.extraInfo?.staffRate,
        time: end,
        metadata: {
            label: 'pto',
            _id: visitItem?._id,
            auto: true,
            uuid: getUniqueShiftUUID(),
        },
    };
}

function getReimbursementShift(visitItem) {
    if (visitItem?.extraInfo?.mileageData && visitItem?.provider?.payroll?.metadata?.mileageReimbursementRate) {
        const visitEndTime = new Date(visitItem?.ScheduleEndTime || Date.now());
        const visitStartTime = new Date(visitItem?.ScheduleStartTime || Date.now());
        return {
            type: 'reimbursement',
            time: visitEndTime,
            amount:
                ((visitItem?.extraInfo?.mileageData?.distance || 0) *
                    visitItem?.provider?.payroll?.metadata?.mileageReimbursementRate) /
                1609.34,
            metadata: {
                label: 'reimbursement',
                _id: visitItem?._id,
                auto: true,
                uuid: getUniqueShiftUUID(),
            },
        };
    }
}

export function getFormattedShiftData(visitItem, options) {
    const shiftFunctions = {
        visit: getHourlyVisitShift,
        pto: getPtoShift,
        reimbursement: getReimbursementShift,
    };

    const shiftFunction = shiftFunctions[visitItem?.label];
    return shiftFunction ? shiftFunction(visitItem, options) : undefined;
}

export function getTotalHours(item) {
    if (item?.label === 'pto') {
        return item?.extraInfo?.requestedBalance;
    } else return item?.extraInfo?.staffRate ? (item?.extraInfo?.staffAmount || 0) / item?.extraInfo?.staffRate : 0;
}

export function isEmployeeOnboarded(employee) {
    if (
        employee?.payroll?.type === 'zeal' &&
        employee?.payroll?.metadata?.employeeID &&
        employee?.payroll?.metadata?.onboarded
    ) {
        return true;
    }
    return false;
}

export function groupFunction(data, options) {
    const aggregatedData = {};
    (data || [])?.forEach((visitItem) => {
        const employeeId = visitItem?.employee?._id;
        const totalHours = getTotalHours(visitItem);
        if (totalHours <= 0) {
            return;
        }
        const currentShiftData = getFormattedShiftData(visitItem, options);
        console.log('currentShiftData', currentShiftData);

        const shifts = [currentShiftData].filter((shift) => {
            if (shift?.type === 'reimbursement' && shift?.amount < 0.005) {
                return false;
            }
            return !!shift;
        });

        if (!aggregatedData[employeeId]) {
            aggregatedData[employeeId] = {
                totalAmount: visitItem?.extraInfo?.staffAmount || 0,
                totalRate: visitItem?.extraInfo?.staffRate,
                totalHours,
                shifts,
                count: 1,
                ...visitItem,
            };
        } else {
            aggregatedData[employeeId] = {
                totalAmount: aggregatedData[employeeId]?.totalAmount + visitItem?.extraInfo?.staffAmount,
                totalRate: aggregatedData[employeeId]?.totalRate + visitItem?.extraInfo?.staffRate,
                count: aggregatedData[employeeId]?.count + 1,
                totalHours: totalHours + aggregatedData[employeeId]?.totalHours,
                shifts: [...aggregatedData[employeeId].shifts, ...shifts],
                ...visitItem,
            };
        }
    });

    return Object.keys(aggregatedData).map((employeeId) => {
        const { client, employee, shifts, totalAmount, totalHours, totalRate, visitId } = aggregatedData[employeeId];
        return {
            client,
            employee,
            shifts,
            totalAmount,
            totalHours,
            totalRate,
            visitId,
            disbursementMethod: 'direct_deposit',
            employeeID: employee?.payroll?.metadata?.employeeID,
        };
    });
}

export function getTotalHoursFromEmployeeCheckShifts(shifts = []) {
    const totalHours = shifts.reduce((acc, shift) => {
        if (shift?.hourly) {
            return acc + parseFloat(shift?.hourly?.hours || 0);
        } else if (shift?.type === 'hourly') {
            return acc + parseFloat(shift?.hours || 0);
        }
        return acc;
    }, 0);
    return (totalHours || 0).toFixed(2);
}

const getDay = (date) => new Date(date).getDay();

export const isWeekend = (date) => [0, 6].includes(getDay(date));

export const getNextFriday = () => {
    const today = new Date();
    const daysUntilFriday = (5 - today.getDay() + 7) % 7 || 7;
    today.setDate(today.getDate() + daysUntilFriday);
    return today.toISOString().slice(0, 10);
};
