import ID from '../../../_helpers/uuid';

export const evv = () => {
    return {
        defaultVisitBackupFormElements: [
            {
                data: [],
                id: ID.uuid(),
                key: 'Header',
                fieldName: 'EVV Visit Backup Form',
                type: 'textbox',
                instruction: '#Note: This form should be submitted by Employee only, on Client Channel',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'DateTimePicker',
                fieldName: 'Start of Service',
                type: 'datetime',
                instruction: '',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'DateTimePicker',
                fieldName: 'End of Service',
                type: 'datetime',
                instruction: '',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Service Code',
                type: 'textbox',
                instruction: 'Manually Enter Service Code',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Summary of services',
                type: 'textbox',
                instruction: '',
            },
            {
                fieldName: 'Signature',
                id: ID.uuid(),
                key: 'Signature',
                type: 'signature',
                required: true,
            },
            {
                fieldName: 'Signature',
                id: ID.uuid(),
                key: 'Signature',
                type: 'signature',
                required: true,
            },
            {
                id: ID.uuid(),
                key: 'Select',
                fieldName: 'Reason For Backup Visit Form',
                type: 'select',
                data: [
                    {
                        text: 'Participant Refusal',
                        value: 'Participant Refusal',
                    },
                    {
                        text: 'Participant Unavailable',
                        value: 'Participant Unavailable',
                    },
                    {
                        text: 'Direct Care Worker Error',
                        value: 'Direct Care Worker Error',
                    },
                    {
                        text: 'Mobile Device Issue',
                        value: 'Mobile Device Issue',
                    },
                    {
                        text: 'System Related Issue',
                        value: 'System Related Issue',
                    },
                    {
                        text: 'Telephony Issue',
                        value: 'Telephony Issue',
                    },
                    {
                        text: 'Connection Issue',
                        value: 'Connection Issue',
                    },
                    {
                        text: 'Service Rendered Outside the Home',
                        value: 'Service Rendered Outside the Home',
                    },
                    {
                        text: 'Billing System Limitation',
                        value: 'Billing System Limitation',
                    },
                ],
                instruction: '',
                required: true,
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Questions/Comments/Concerns',
                type: 'textbox',
                instruction: 'Optional',
            },
        ],
        defaultClientSetupFormElements: [
            {
                data: [],
                id: ID.uuid(),
                key: 'Header',
                fieldName: 'EVV Client Setup Form',
                type: 'textbox',
            },
            {
                id: ID.uuid(),
                required: true,
                data: [
                    {
                        key: 'firstName',
                        name: 'First Name',
                        type: 'text',
                    },
                    {
                        key: 'lastName',
                        name: 'Last Name',
                        type: 'text',
                    },
                ],
                key: 'FullName',
                fieldName: 'Client Name',
                type: 'textbox',
                instruction: '',
                config: {
                    specialityKey: 'fullName',
                },
            },
            {
                id: ID.uuid(),
                required: false,
                data: [],
                key: 'InputField',
                fieldName: 'Client Middle Name',
                type: 'textbox',
                config: {
                    specialityKey: 'middleName',
                },
            },
            {
                id: ID.uuid(),
                data: [],
                required: false,
                key: 'InputField',
                fieldName: 'Street 1',
                type: 'textbox',
                instruction: 'Eg. 1234 CAROLINA PL',
                config: {
                    specialityKey: 'street1',
                },
            },
            {
                id: ID.uuid(),
                data: [],
                required: false,
                key: 'InputField',
                fieldName: 'Street 2',
                type: 'textbox',
                config: {
                    specialityKey: 'street2',
                },
            },
            {
                id: ID.uuid(),
                data: [],
                required: false,
                key: 'InputField',
                fieldName: 'City',
                type: 'textbox',
                instruction: 'Eg. Downtown',
                config: {
                    specialityKey: 'city',
                },
            },
            {
                id: ID.uuid(),
                data: [],
                required: false,
                key: 'InputField',
                fieldName: 'State',
                type: 'textbox',
                instruction: 'Eg. PA',
                config: {
                    specialityKey: 'state',
                },
            },
            {
                id: ID.uuid(),
                data: [],
                required: false,
                key: 'InputField',
                fieldName: 'Zip Code',
                type: 'textbox',
                instruction: 'Eg. 123456789',
                config: {
                    specialityKey: 'zip',
                },
            },
            {
                id: ID.uuid(),
                required: true,
                data: [],
                key: 'InputField',
                fieldName: 'Client Channel Name',
                type: 'textbox',
                instruction: 'Name of client channel within your team. ( Will be created if not exists )',
                config: {
                    specialityKey: 'channel',
                },
            },
        ],
        defaultStaffSetupFormElements: [
            {
                id: ID.uuid(),
                data: [],
                key: 'Header',
                fieldName: 'EVV Staff Setup Form',
                type: 'textbox',
                instruction: '#Note: User will be created if not exists',
            },
            {
                id: ID.uuid(),
                required: true,
                data: [
                    {
                        key: 'firstName',
                        name: 'First Name',
                        type: 'text',
                    },
                    {
                        key: 'lastName',
                        name: 'Last Name',
                        type: 'text',
                    },
                ],
                key: 'FullName',
                fieldName: 'Full Name',
                type: 'textbox',
                instruction: 'Staff Full Name',
                config: {
                    specialityKey: 'fullName',
                },
            },
            {
                id: ID.uuid(),
                required: false,
                data: [],
                key: 'InputField',
                fieldName: 'Phone Number',
                type: 'textbox',
                instruction: 'Eg. +11234567890, If country code is not added, it will default to +1',
                config: {
                    specialityKey: 'phone',
                },
            },
            {
                id: ID.uuid(),
                required: false,
                data: [],
                key: 'Email',
                fieldName: 'Email',
                type: 'textbox',
                instruction: 'Valid email address is in the format yourname@example.com',
                config: {
                    specialityKey: 'email',
                },
            },
            {
                id: ID.uuid(),
                data: [],
                required: false,
                key: 'InputField',
                fieldName: 'Full Social Security Number (SSN)',
                type: 'textbox',
                instruction: 'SSN must be a 9 digit number',
                config: {
                    specialityKey: 'ssn',
                },
            },
            {
                id: ID.uuid(),
                required: true,
                data: [],
                key: 'InputField',
                fieldName: 'Pay Amount',
                type: 'textbox',
                instruction: 'Pay Amount must be a number',
                config: {
                    specialityKey: 'staffRate',
                },
            },
            {
                id: ID.uuid(),
                required: true,
                data: [],
                key: 'InputField',
                fieldName: 'Staff Channel Name',
                type: 'textbox',
                instruction: 'Name of staff channel within your team. ( Will be created if not exists )',
                config: {
                    specialityKey: 'channel',
                },
            },
        ],
    };
};
