import { css } from 'twin.macro';
import React, { Fragment, useMemo, useState } from 'react';
import { FloatingContainer, ModalFormContainer } from '../styles';
import { ExpandableButton } from '@/_elements/Button';
import { Modal } from 'react-bootstrap';
import { GENERIC_FIELD_DATA } from '../constants';
import { MdEdit, MdAdd, MdMinimize } from 'react-icons/md';
import { connect } from 'react-redux';
import { SMART_PDF_ALLOWED_ELEMENT_KEYS } from '../constants';
import { useDispatch } from 'react-redux';
import { getOptionConfig } from '../utils';
import { CiCircleMinus } from 'react-icons/ci';

const MapExistingField = ({ formElements, currentPage }) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const unmappedFields = useMemo(() => {
        return (formElements || []).filter((element) => {
            if ((element?.config || {}).hasTemplateConfig) {
                return false;
            }
            return !(element?.config || {}).rectConfig;
        });
    }, [formElements]);
    const handleFieldMapping = (field) => {
        let updatedField = JSON.parse(JSON.stringify(field));
        const isCheckboxOrRadio = field?.key === 'Checkbox' || field?.key === 'Radio';
        if (isCheckboxOrRadio) {
            updatedField.config = {
                hasTemplateConfig: true,
            };
            updatedField.data = (updatedField.data || []).map((option, optionIndex) => {
                const tempDataConfig = {
                    ...option,
                    ...(getOptionConfig(optionIndex, currentPage) || {}),
                };
                tempDataConfig.config.parentFormElement = {
                    id: updatedField?._id || updatedField?.id,
                    key: updatedField?.key,
                };
                return tempDataConfig;
            });
        } else {
            updatedField.config = GENERIC_FIELD_DATA.generic.config;
            updatedField.config.rectConfig.page = currentPage || 1;
            updatedField.config.templateConfig.page = currentPage || 1;
            const temp = JSON.parse(JSON.stringify(updatedField));
            updatedField = temp;
        }

        dispatch({ type: 'ADD_FORM_DATA', payload: updatedField });
    };
    return (
        <Fragment>
            <FloatingContainer top={'75px'} onClick={() => handleShow()}>
                <ExpandableButton
                    icon={<MdEdit size={20} />}
                    text="Map Existing Form Elements"
                    backgroundColor="#fff"
                    borderColor="#eee"
                    textColor="#ea9941"
                />
            </FloatingContainer>
            <Modal
                show={show}
                onHide={() => handleClose()}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
            >
                <ModalFormContainer>
                    <Modal.Header closeButton>
                        <Modal.Title className="w-100 text-center">
                            Click on the form element you want to map to PDF
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            css={css`
                                min-height: 400px;
                            `}
                        >
                            {unmappedFields.length > 0 ? (
                                <div className="list-group">
                                    {unmappedFields.map((field, index) => (
                                        <div
                                            key={field?._id || field?.id}
                                            className="list-group-item d-flex justify-content-between align-items-center mb-4 shadow"
                                        >
                                            <div>
                                                <span>{index + 1}.</span>
                                                <span className="font-weight-bold ml-2">{field?.fieldName}</span>
                                                <span className="badge badge-warning ml-2">{field?.key || ''}</span>
                                            </div>
                                            {SMART_PDF_ALLOWED_ELEMENT_KEYS.includes(field?.key) ? (
                                                <div>
                                                    <button
                                                        className="btn btn-sm btn-success"
                                                        css={css`
                                                            font-size: 1.5rem;
                                                            padding: 0.5rem 1rem;
                                                        `}
                                                        onClick={() => handleFieldMapping(field)}
                                                    >
                                                        <MdAdd size={24} /> Map To PDF ( Page: {currentPage} )
                                                    </button>
                                                    {/* <button
                                                        className="btn btn-sm btn-danger ml-2"
                                                        css={css`
                                                            font-size: 1.5rem;
                                                        `}
                                                        onClick={() => handleFieldUnMapping(field)}
                                                        disabled

                                                    >
                                                        <CiCircleMinus size={24} /> Unmap from PDF
                                                    </button> */}
                                                </div>
                                            ) : (
                                                <button
                                                    className="btn btn-sm btn-warning"
                                                    css={css`
                                                        font-size: 1.5rem;
                                                    `}
                                                    disabled
                                                >
                                                    Not Supported Yet
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center text-muted">No unmapped fields available.</div>
                            )}
                        </div>
                    </Modal.Body>
                </ModalFormContainer>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    formElements: state.formData.formElements,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapExistingField);
