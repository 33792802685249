import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DateTimePicker from 'react-datetime-picker';
import AsyncSelect from 'react-select/async';

//Form Elements
import ProviderSelector from '../ProviderSelector';
import { CreateAClient, EditAClient } from '../../../../_redux/actions/ActionEVV';
import ProviderChannelSelector from '../ProviderChannelSelector';
import EvvFormBuilder from '../components/EvvFormBuilder';
import { VscLoading } from 'react-icons/vsc';
import { fetchWrapper } from '../../../../_helpers';
import { StyledLinkBtn } from '../../Tasks/EventModalStyles';
import { HiPlusCircle, HiMinusCircle } from 'react-icons/hi';
import { SandataStatusCheck } from './SandataStatusCheck';

const typeOptions = {
    evv: {
        sandata: { label: 'Sandata', value: 'sandata' },
        therap: { label: 'Therap', value: 'therap' },
        optonome: { label: 'Optonome', value: 'optonome' },
    },
};

const ClientForm = ({
    formStructure,
    setShowModal,
    CreateAClient,
    EditAClient,
    client,
    formType,
    isTeamSettings,
    userClientProvider,
    team,
    channel,
}) => {
    // const evvState = useSelector((state) => state.evv.evvState);
    const [provider, setProvider] = useState(client ? client.provider : null);
    const [providerId, setProviderId] = useState(client ? client?.provider?.id : null);
    const [providerTeamId, setProviderTeamId] = useState(
        client?.provider?.team?._id || client?.provider?.team || userClientProvider?.teamId
    );
    const [providerError, setProviderError] = useState(null);
    const [clientChannel, setClientChannel] = useState(client ? client?.clientChannel : channel);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [forceExpandAllEvv, setForceExpandAllEvv] = useState(false);

    // immediately set force expand all to false after it is set to true
    useEffect(() => {
        const timeout = setTimeout(() => {
            setForceExpandAllEvv(false);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [forceExpandAllEvv]);

    const [type, setType] = useState({ evv: client?.evv?.type || provider?.evv?.type });

    const [defaultData, setDefaultData] = useState({ evv: client?.evv || {} });
    const [isMapping, setIsMapping] = useState(false);

    const setTypeFunc = async (formType, value = '') => {
        if (!type.evv || !value) {
            return;
        }
        const presentData = getValues();
        let mappedData = {};
        try {
            setIsMapping(true);
            mappedData = await fetchWrapper.post(
                `evv/map/client/${defaultData[formType]?.type || 'optonome'}/${value}?team=${providerTeamId}`,
                (presentData[`_${formType}`] || {})[type[formType]]
            );
        } catch (error) {
        } finally {
            setIsMapping(false);
        }

        setDefaultData((prev) => ({ ...prev, [formType]: { type: value, data: mappedData } }));
        return setType((prev) => ({ ...prev, [formType]: value }));
    };

    const { register, handleSubmit, errors, setValue, watch, setError, clearErrors, getValues } = useForm({
        // mode: 'onBlur',
    });

    const handleSelectProvider = (provider) => {
        setProviderError(null);
        setProviderId(provider.providerId);
        setProvider(provider);
        setType((prev) => ({ evv: prev.evv || provider?.evv?.type || 'optonome' }));
        setProviderTeamId(provider.teamId);
    };

    const handleClientSelect = (clientChannel) => {
        setClientChannel(clientChannel);
    };

    const onSubmit = async (data) => {
        setSubmitLoading(true);
        try {
            // if (isTeamSettings) {
            // 	data.provider = client.provider;
            // 	data._evv = { [client.evv?.type || "optonome"]: client.evv?.data };
            // 	data.sendTo = client.sendTo;
            // 	data.team = client.team;
            // }
            let body = { ...data };
            let evv = {};

            if (formType === 'Edit') {
                evv = { ...(client.evv || {}), ...evv };
            }

            if (!providerId) {
                setProviderError('Please select Provider!');
                return toast.error('Provider must be selected');
            }
            body.provider = providerId;

            if (body._evv) {
                evv = {
                    type: type?.evv,
                    data: body._evv?.[type?.evv],
                };
                body.firstName = evv.data?.firstName || evv.data?.ClientFirstName || body?.firstName;
                body.lastName = evv.data?.lastName || evv.data?.ClientLastName || body?.lastName;
                body.middleName = evv.data?.middleName || body?.middleName;
                // body.caregiverName = evv.data?.caregiverName || body?.caregiverName;
            }

            body._evv = evv;
            body.sendTo = type?.evv;

            const modifiedFields = fields
                .filter((data) => !isNaN(data.amount))
                .map(({ id, ...rest }) => ({ ...rest, service: rest.service?.id }));
            body.units = modifiedFields;

            // if (isTeamSettings) {
            // body.firstName = client.firstName;
            // body.lastName = client.lastName;
            // body.middleName = client.middleName;
            // body.sendTo = client?.sendTo;
            // body.team = team;
            // }
            let result;
            if (formType === 'Edit') {
                result = await EditAClient(body, client._id ?? client.id, team);
            } else {
                if (clientChannel) {
                    body.chatroom = clientChannel;
                }
                result = await CreateAClient(body, team);
            }

            if (result) {
                setShowModal(false);
            }
        } catch (error) {
            toast.error(error?.message || 'Something went wrong');
        } finally {
            setSubmitLoading(false);
        }
    };

    const [fields, setFields] = useState(client?.units || []);

    // Function to add a new field
    const addField = () => {
        const newField = {
            id: Date.now(),
            amount: 0,
            validFrom: new Date(),
            validTo: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            service: 'null',
        };
        setFields([...fields, newField]);
    };

    // Function to delete a field
    const deleteField = (id) => {
        const updatedFields = fields.filter((field) => field.id !== id);
        setFields(updatedFields);
    };

    // Function to handle input value change
    const handleInputChange = (id, amount) => {
        const updatedFields = fields.map((field) => (field.id === id ? { ...field, amount } : field));
        setFields(updatedFields);
    };

    const handleValidFromDateTimeChange = (id, validFrom) => {
        const updatedFields = fields.map((field) => (field.id === id ? { ...field, validFrom } : field));
        setFields(updatedFields);
    };
    const handleValidToDateTimeChange = (id, validTo) => {
        const updatedFields = fields.map((field) => (field.id === id ? { ...field, validTo } : field));
        setFields(updatedFields);
    };

    // Function to handle Service value change
    const handleServiceChange = (id, service) => {
        const updatedFields = fields.map((field) =>
            field.id === id
                ? {
                      ...field,
                      service: {
                          description: service.label.split(' - ')[1]?.trim(),
                          code: service.label.split(' - ').at(0)?.trim(),
                          id: service.value,
                      },
                  }
                : field
        );
        setFields(updatedFields);
    };

    const loadServices = (inputValue, callback = () => {}) => {
        setTimeout(async () => {
            const tempArray = [];
            try {
                if (provider?.state) {
                    const services = await fetchWrapper.get(
                        `evv/service?sortBy=name:asc&state=${provider?.state}&limit=100&${
                            inputValue ? `&HCPCS=${inputValue}` : ''
                        }${team ? `&team=${team}` : ''}`
                    );
                    services?.results?.forEach((element) => {
                        tempArray.push({
                            label: `${element.HCPCS} - ${element.Description}`,
                            value: element.id,
                        });
                    });
                }
            } catch (error) {
            } finally {
                callback(tempArray);
            }
        }, 1000);
    };

    const [defaultServices, setDefultServices] = useState([]);

    useEffect(() => {
        loadServices('', setDefultServices);
    }, [provider]);

    useEffect(() => {
        if (userClientProvider) {
            setProvider(userClientProvider);
            setProviderId(userClientProvider.providerId);
            setProviderTeamId(userClientProvider.teamId);
        }
    }, [userClientProvider]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {client?.evv?.type === 'sandata' && client.evv?.metadata?.uuid ? (
                <SandataStatusCheck evv={client?.evv} type={'client'} id={client?.id} isTeamSettings={isTeamSettings} />
            ) : null}
            {!providerId && <Alert variant="info">Please select the provider to assign the client to.</Alert>}

            <div className={`${formType === 'Edit' ? 'abs_hidden' : ''}`}>
                <ProviderSelector
                    isDisabled={formType === 'View' || isTeamSettings}
                    selectProvider={handleSelectProvider}
                    provider={JSON.stringify(client?.provider || provider || {})}
                    team={team}
                />
                <p className="text-danger">{providerError}</p>
            </div>
            {providerId && (
                <>
                    {formType !== 'Edit' && (
                        <Fragment>
                            {formType === 'View' ? (
                                <Fragment>
                                    {client?.chatroom?.id && (
                                        <div>
                                            <Form.Label>Client Channel</Form.Label>
                                            <div>
                                                <a href={`/teams/${client.chatroom.id}`}>{client?.chatroom?.title}</a>
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <ProviderChannelSelector
                                    type="Client"
                                    providerId={providerId}
                                    providerTeamId={providerTeamId}
                                    isDisabled={formType === 'View'}
                                    selectChannel={handleClientSelect}
                                    channel={channel}
                                />
                            )}
                        </Fragment>
                    )}

                    <EvvFormBuilder
                        formType="evv"
                        formStructure={formStructure?.evv}
                        isEditMode={!isMapping && !submitLoading && formType === 'Edit'}
                        isViewMode={isMapping || submitLoading || formType === 'View'}
                        type={type?.evv}
                        setTypeFunc={setTypeFunc}
                        typeOptions={typeOptions}
                        allowedTypes={
                            provider?.evv
                                ? ['optonome', defaultData?.evv?.type || 'optonome'].concat(
                                      Object.keys(typeOptions?.evv).filter((key) => key === provider?.evv?.type)
                                  )
                                : ['optonome', defaultData?.evv?.type]
                        }
                        typeData={defaultData?.evv || {}}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        forceExpandAll={forceExpandAllEvv}
                    />

                    {formType !== 'View' || fields.length ? (
                        <div className="d-flex flex-column">
                            <hr />
                            <label>Units</label>
                            <div>
                                {fields?.map((field) => (
                                    <div key={field.id} className="d-flex flex-column mb-3">
                                        <div
                                            className="d-flex flex-column"
                                            style={{
                                                gap: '3px',
                                                alignItems: 'flex-end',
                                                padding: '6px 6px',
                                                borderRadius: '4px',
                                                margin: '6px 0px',
                                                border: '1px solid #ccc',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    width: '100%',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '1rem',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            gap: '6px',
                                                        }}
                                                    >
                                                        <span>Service : </span>
                                                        <div
                                                            style={{
                                                                minWidth: '112px',
                                                                flex: '1',
                                                            }}
                                                        >
                                                            <AsyncSelect
                                                                key={provider?.state}
                                                                loadOptions={formType !== 'View' && loadServices}
                                                                onChange={(service) =>
                                                                    handleServiceChange(field.id, service)
                                                                }
                                                                value={
                                                                    field?.service?.code
                                                                        ? {
                                                                              label: `${field.service.code} - ${field.service?.description}`,
                                                                              value: field?.service?.id,
                                                                          }
                                                                        : null
                                                                }
                                                                defaultOptions={defaultServices}
                                                                isMulti={false}
                                                                className="w-100"
                                                                isDisabled={
                                                                    !provider?.state || formType === 'View' || isMapping
                                                                }
                                                            />
                                                        </div>
                                                        <span>
                                                            Amount: <span className="text-danger">*</span>
                                                        </span>
                                                        <input
                                                            type="number"
                                                            step="1"
                                                            className="input-amount"
                                                            style={{
                                                                width: '17%',
                                                                padding: '0 6px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                                height: '3.5rem',
                                                            }}
                                                            value={field.amount}
                                                            onChange={(e) =>
                                                                handleInputChange(field.id, e.target.value)
                                                            }
                                                            disabled={formType === 'View' || isMapping}
                                                            monthPlaceholder="MM"
                                                            dayPlaceholder="DD"
                                                            yearPlaceholder="YYYY"
                                                            required
                                                            onWheel={(event) => event.currentTarget.blur()}
                                                            clearIcon={null}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '6px',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <span>
                                                                Validity Starts From:{' '}
                                                                <span className="text-danger">*</span>
                                                            </span>
                                                            <DateTimePicker
                                                                onChange={(date) =>
                                                                    handleValidFromDateTimeChange(field.id, date)
                                                                }
                                                                value={
                                                                    field?.validFrom
                                                                        ? new Date(field.validFrom)
                                                                        : new Date()
                                                                }
                                                                maxDate={field?.validTo && new Date(field.validTo)}
                                                                format="MM-dd-y hh:mm a"
                                                                disableClock={true}
                                                                calendarType="US"
                                                                disabled={formType === 'View' || isMapping}
                                                                monthPlaceholder="MM"
                                                                dayPlaceholder="DD"
                                                                yearPlaceholder="YYYY"
                                                                required
                                                                clearIcon={null}
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <span>
                                                                Validity Ends At: <span className="text-danger">*</span>
                                                            </span>
                                                            <DateTimePicker
                                                                onChange={(date) =>
                                                                    handleValidToDateTimeChange(field.id, date)
                                                                }
                                                                value={
                                                                    field.validTo
                                                                        ? new Date(field.validTo)
                                                                        : new Date(
                                                                              new Date().setFullYear(
                                                                                  new Date().getFullYear() + 1
                                                                              )
                                                                          )
                                                                }
                                                                minDate={
                                                                    new Date(
                                                                        field?.validFrom
                                                                            ? new Date(field.validFrom)
                                                                            : new Date()
                                                                    )
                                                                }
                                                                format="MM-dd-y hh:mm a"
                                                                disableClock={true}
                                                                calendarType="US"
                                                                disabled={formType === 'View' || isMapping}
                                                                monthPlaceholder="MM"
                                                                dayPlaceholder="DD"
                                                                yearPlaceholder="YYYY"
                                                                required
                                                                clearIcon={null}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {formType !== 'View' && (
                                                <StyledLinkBtn className="danger" onClick={() => deleteField(field.id)}>
                                                    <HiMinusCircle />
                                                    Remove
                                                </StyledLinkBtn>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {formType !== 'View' && (
                                    <StyledLinkBtn onClick={addField} style={{ width: 'max-content' }}>
                                        <HiPlusCircle />
                                        Add Units
                                    </StyledLinkBtn>
                                )}
                            </div>
                        </div>
                    ) : null}
                    <div className="d-flex justify-content-center">
                        {formType === 'View' ? null : (
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={submitLoading}
                                onClick={() => {
                                    setForceExpandAllEvv(true);
                                }}
                            >
                                {submitLoading ? (
                                    <VscLoading className="spin" />
                                ) : (
                                    <Fragment>{formType === 'Edit' ? 'Edit' : 'Create'}</Fragment>
                                )}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </Form>
    );
};

const mapStateToProps = (state) => ({
    formStructure: state.evv.evvForms.client,
});
export default connect(mapStateToProps, { CreateAClient, EditAClient })(ClientForm);
