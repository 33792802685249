import React, { useCallback, useRef } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { css } from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Nav } from 'react-bootstrap';

// helpers
import {
    fetchWrapper,
    getInitials,
    getRandomColor,
    getSortConfig,
    getTeamIdFromURL,
    toastWrapper,
} from '../../../../_helpers';

// redux
import { connect } from 'react-redux';
import { GetEmployees } from '../../../../_redux/actions/ActionEVV';

// components
import StaffList from './StaffList';
import Pagination from '../../Pagination';
import EvvSearchModalComponent from '../EvvSearchModal';
import CreateStaffPicker from '../../../Features/BulkUpload/Staff';
import { useQuery } from '../../../../_helpers/hooks/useQuery';

const Staff = ({ GetEmployees, isLoading, employees, teams }) => {
    const isTeamSettings = !!useLocation().pathname.match(/user-evv/);
    const teamId = getTeamIdFromURL();
    const evvBasePath = isTeamSettings ? 'user-evv' : 'evv';
    const pushPage = (page) => {
        history.push(`/${evvBasePath}?page=${page || 1}`);
    };

    const history = useHistory();
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

    const { query, updateQuery, handleSort } = useQuery(GetEmployees, {
        page: currentPage,
        firstName: '',
        lastName: '',
        userEmployee: null,
        user: null,
        providerInfo: null,
        provider: null,
        state: 'active',
        sortBy: 'createdAt:desc',
        team: teamId,
    });

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const [provider, setProvider] = useState();

    useEffect(() => {
        if (!isTeamSettings || !teamId) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        fetchWrapper
            .get(`evv/provider?team=${teamId}`)
            .then(({ results: [response] }) => {
                setProvider({
                    value: response?._id,
                    label: response?.agencyName,
                    providerId: response?._id,
                    teamId: team?.id,
                    agencyName: response?.agencyName,
                    team: response?._id,
                    state: response?.state,
                    payroll: response?.payroll,
                    evv: response?.evv,
                });
                updateQuery({ provider: response?._id });
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                toastWrapper.error("Couldn't fetch provider data");
            });
    }, [teams, isTeamSettings]);

    const handleNameChange = (event) => {
        const page = 1;
        if (event.target.name === 'firstName') {
            updateQuery({ firstName: event.target.value, page });
        } else if (event.target.name === 'lastName') {
            updateQuery({ lastName: event.target.value, page });
        }
        pushPage(page);
    };

    const handleUserEmployeeSelect = (selectedUser) => {
        updateQuery({
            userEmployee: selectedUser,
            user: selectedUser ? selectedUser?.id || selectedUser?._id : null,
            page: 1,
        });
    };

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
        updateQuery({ provider: selectedProvider?.value, page: 1 });
    };

    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            pushPage(data.currentPage);
        }
    };

    const handleArchiveStatus = (state) => {
        const page = 1;
        if (state) {
            updateQuery({ state, page });
        }
        pushPage(page);
    };

    const handleClearAll = () => {
        const page = 1;
        updateQuery({ firstName: '', lastName: '', userEmployee: null, user: null, provider: null, page });
        pushPage(page);
    };

    useEffect(() => {
        updateQuery({ page: currentPage });
    }, [currentPage]);

    const employeesList = useMemo(
        () =>
            employees.employees &&
            employees.employees.map((employee) => {
                employee.initials = getInitials(`${employee.firstName} ${employee.lastName}`);
                employee.color = getRandomColor(employee.id);
                return employee;
            }),
        [employees]
    );

    return (
        <div>
            <div className="d-flex flex-row align-items-center mb-4 justify-content-between ">
                <EvvSearchModalComponent
                    type="Staff"
                    firstName={query.firstName}
                    firstNameRef={firstNameRef}
                    lastName={query.lastName}
                    lastNameRef={lastNameRef}
                    userEmployee={query.userEmployee}
                    provider={provider}
                    handleNameChange={handleNameChange}
                    handleUserEmployeeSelect={handleUserEmployeeSelect}
                    handleProviderSelect={handleProviderSelect}
                    handleClearAll={handleClearAll}
                    isTeamSettings={isTeamSettings}
                    team={teamId}
                />
                <Nav
                    variant="pills"
                    className="p-2"
                    css={css`
                        background: #e1e1e1;
                        border-radius: 5px;
                    `}
                >
                    <Nav.Item>
                        <Nav.Link
                            as={Button}
                            variant={query.state === 'active' ? 'primary' : 'link'}
                            onClick={() => handleArchiveStatus('active')}
                            style={{ fontSize: '1.6rem' }}
                            className={query.state === 'active' ? 'bg-primary text-white' : ''}
                        >
                            Active
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="ml-1">
                        <Nav.Link
                            as={Button}
                            variant={query.state === 'archieved' ? 'primary' : 'link'}
                            onClick={() => handleArchiveStatus('archieved')}
                            style={{ fontSize: '1.6rem' }}
                            className={query.state === 'archieved' ? 'bg-primary text-white' : ''}
                        >
                            Archived
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <CreateStaffPicker provider={provider} />
            </div>
            <div
                className="custom-card card-table d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <StaffList
                    list={employeesList}
                    handleSort={handleSort}
                    isLoading={isLoading}
                    currentPage={currentPage}
                    staffState={query.state}
                    setStaffState={(state) => {
                        handleArchiveStatus(state);
                    }}
                    teamId={teamId}
                    sortConfig={getSortConfig(query.sortBy)}
                />
                {employees?.employees?.length > 0 && (
                    <div className="m-4">
                        <Pagination
                            pageNo={currentPage}
                            totalRecords={employees.totalEmployees}
                            pageLimit={10}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    employees: state.evv.employees,
    teams: state.teams.teams,
});

export default connect(mapStateToProps, { GetEmployees })(Staff);
