import React, { useState } from 'react';
import { Button, Modal, Table, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import Avatar from '../../../../Admin/reusableComponents/Avatar';
import { VscLoading } from 'react-icons/vsc';
import { css } from 'twin.macro';
import Swal from 'sweetalert2';
import FileUploader from './FileUploader';
import { fetchWrapper, getTeamIdFromURL } from '../../../../../_helpers';
import { toast } from 'react-toastify';

const cleanUpString = (value) => {
    const _value = String(value || '');
    return _value.replace(/\t/g, ' ').replace(/\s+/g, ' ').trim();
};

const createUser = (_id, role) => (_id ? { _id, role } : null);

const getTeamUserFromName = (teamMembers, label) => {
    const normalizedName = (label || '').trim().toLowerCase();
    if (!normalizedName) return { label: '', error: true };

    const exactMatch = teamMembers.find((user) => user?.name.trim().toLowerCase() === normalizedName);
    if (exactMatch) return { label, ...exactMatch, error: '' };

    const nameParts = normalizedName.split(' ').filter(Boolean);
    const partialMatch = teamMembers.find((user) =>
        nameParts.every((part) => user.name.trim().toLowerCase().includes(part))
    );

    return partialMatch ? { label, ...partialMatch, error: '' } : { label, error: 'Member not found' };
};

const prepareChannelPayload = (channels, team) => {
    return (channels || [])
        .map((channel) => {
            const { name = {}, description = {} } = channel;
            const title = name?.label || '';
            const subTitle = description?.label || '';
            if (!title || !subTitle) return null;
            const roles = [
                { ...channel.supervisor1, role: 'supervisor' },
                { ...channel.supervisor2, role: 'supervisor' },
                { ...channel.user1 },
                { ...channel.user2 },
            ];
            const users = roles.map(({ id, _id, role }) => createUser(_id || id, role)).filter(Boolean);
            return {
                chatroom: {
                    title,
                    subTitle,
                },
                users,
            };
        })
        .filter(Boolean);
};

const UserCell = ({ user = {} }) => {
    return (
        <td>
            {user?.label}
            {user?.email && (
                <div>
                    <p className="text-primary">{user.email}</p>
                    <Avatar item={user} />
                </div>
            )}
            <p className="text-danger font-bold">{user.error}</p>
        </td>
    );
};

const BulkChannelModal = ({ onSubmit = () => {}, teamMembers, getTeamChannels }) => {
    const [show, setShow] = useState(true);
    const [fileData, setFileData] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [channels, setChannels] = useState([]);
    const [errors, setErrors] = useState([]);
    const teamId = getTeamIdFromURL();

    const handleFileUpload = async (formData) => {
        try {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (jsonData.length > 0) {
                    const tempChannels = jsonData
                        .slice(1)
                        .map((row) => {
                            const cleanedRow = row.map(cleanUpString);
                            const [
                                title,
                                subTitle1,
                                subTitle2,
                                subTitle3,
                                subTitle4,
                                subTitle5,
                                subTitle6,
                                subTitle7,
                                subTitle8,
                                subTitle9,
                                subTitle10,
                                supervisor1Name,
                                supervisor2Name,
                                user1Name,
                                user2Name,
                            ] = cleanedRow;
                            const subTitle = [
                                subTitle1,
                                subTitle2,
                                subTitle3,
                                subTitle4,
                                subTitle5,
                                subTitle6,
                                subTitle7,
                                subTitle8,
                                subTitle9,
                                subTitle10,
                            ]
                                .filter(Boolean)
                                .join(' ');

                            const supervisor1 = getTeamUserFromName(teamMembers, supervisor1Name);
                            const supervisor2 = getTeamUserFromName(teamMembers, supervisor2Name);
                            const user1 = getTeamUserFromName(teamMembers, user1Name);
                            const user2 = getTeamUserFromName(teamMembers, user2Name);

                            return !title && !subTitle
                                ? null
                                : {
                                      name: { label: title, error: !title && 'Name is required' },
                                      description: {
                                          label: subTitle,
                                          error: !subTitle && 'Description is required',
                                      },
                                      supervisor1,
                                      supervisor2,
                                      user1,
                                      user2,
                                  };
                        })
                        .filter(Boolean);

                    setChannels(tempChannels);
                    setErrors([]);
                } else {
                    setErrors(['No data found in the file']);
                }
                setFileData(jsonData);
                setIsUploading(false);
            };

            reader.onerror = () => {
                setErrors(['Failed to read the file. Please try again.']);
                setIsUploading(false);
            };

            reader.readAsBinaryString(formData.get('file'));
        } catch (error) {
            setErrors(['An error occurred while processing the file.']);
            setIsUploading(false);
            console.error('Error parsing Excel file:', error);
        }
    };

    const handleCancelUpload = () => {
        setFileData(null);
        setFile(null);
        setChannels([]);
        setErrors([]);
    };

    const handleSubmit = async () => {
        try {
            const preparedChannels = prepareChannelPayload(channels || [], teamId);
            const response = await Swal.fire({
                title: 'Confirm creating channels',
                html: `
                    <div>
                    <p style="font-size: 14px;">
                        Total Channels Count: ${(channels || []).length}
                    </p>
                    <p style="font-size: 14px; color:green">
                        Valid Channels Count: ${(preparedChannels || []).length}
                    </p>
                     <p style="font-size: 14px;color:red;">
                        Invalid Channels Count: ${(channels || []).length - (preparedChannels || []).length}
                    </p>
                    </div>
                `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });

            if (!response.isConfirmed) {
                return;
            }
            if (!teamId) {
                return toast.error('Team ID is missing');
            }
            const res = await fetchWrapper.post(`chatrooms/bulk/rooms-users?team=${teamId}`, preparedChannels);
            if (res?.error) {
                toast.error(res.error);
            }
            toast.success(res?.message);
            getTeamChannels();
        } catch (error) {
            toast.error(error?.message);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Bulk Channel Create</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!fileData ? (
                    <>
                        <FileUploader
                            accept=".xlsx, .xls"
                            onFileUpload={handleFileUpload}
                            buttonText="Upload Excel File"
                            loadingText="Uploading..."
                            successMessage="File uploaded successfully!"
                            errorMessage="File upload failed. Please try again."
                            fileLimit={5}
                            showTips={true}
                            tips={[
                                'Ensure the file is in .xlsx format.',
                                'Check that each channel has a valid title and description.',
                            ]}
                        />

                        {isUploading && (
                            <div className="d-flex justify-content-center">
                                <VscLoading className="spin" size={30} />
                            </div>
                        )}
                        {errors.length > 0 && (
                            <div className="alert alert-danger mt-3">
                                <ul>
                                    {errors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        css={css`
                            width: 100%;
                            overflow-x: auto;
                        `}
                    >
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    {channels.length > 0 &&
                                        Object.keys(channels[0]).map((header, idx) => (
                                            <th
                                                key={idx}
                                                style={{
                                                    minWidth:
                                                        header === 'name' || header === 'description'
                                                            ? '250px'
                                                            : '200px',
                                                }}
                                            >
                                                {header}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {channels.map((channel, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {channel.name?.label}
                                            <p className="text-danger font-bold ">{channel.name?.error}</p>
                                        </td>
                                        <td>
                                            {channel.description?.label}
                                            <p className="text-danger font-bold">{channel.description?.error}</p>
                                        </td>

                                        <UserCell user={channel.supervisor1} />
                                        <UserCell user={channel.supervisor2} />
                                        <UserCell user={channel.user1} />
                                        <UserCell user={channel.user2} />
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                {fileData && (
                    <Button variant="secondary" onClick={handleCancelUpload}>
                        Upload Another File
                    </Button>
                )}
                <Button variant="primary" onClick={handleSubmit} disabled={isUploading || !fileData}>
                    {isUploading ? <Spinner animation="border" size="sm" /> : 'Create Bulk Channel'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    teamMembers: state.teams.teamMembers,
});

export default connect(mapStateToProps)(BulkChannelModal);
