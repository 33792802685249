import { css } from 'twin.macro';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import Documents from './Documents';
import TeamSettingFormPackage from './TeamSettingFormPackage';
import TeamSettingsVisitTemplate from './VisitTemplate/TeamSettingsVisitTemplate';
import { DocumentsStyles } from './styles/DocumentsStyled';
import { useEffect } from 'react';
import { fetchWrapper, getTeamIdFromURL, getChannelIdFromSettingsURL } from '../../../../_helpers';
import AllDocuments from './AllDocuments/AllDocuments';
import TeamSettingSubmittedForms from './Documents/SubmittedForms';
import { ModalCSS } from './styles/DocumentsStyled';

function TeamSettingsForm({ teamRooms, currUser, channelDetails }) {
    const [key, setKey] = useState('form_package');
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState({});
    const teamId = getTeamIdFromURL();
    const channelIdFromUrl = getChannelIdFromSettingsURL();

    const onSearch = (val) => {
        setSearch(val);
        if (val === '') {
            if (key === 'all') {
                setKey('form_package');
            }
        }
    };

    const hideSearchModel = () => {
        setShowSearchModal(false);
    };

    useEffect(() => {
        async function fetchDocuments() {
            try {
                const response = await fetchWrapper.get(
                    `chatrooms/${teamId}/files/search/${search ? `?searchText=${encodeURIComponent(search)}` : ''}${channelIdFromUrl ? `&chatroom=${channelIdFromUrl}` : ''}`
                );
                setSearchData(response);
            } catch (error) {
                console.log(error);
            }
        }
        if (search) {
            fetchDocuments();
        }
    }, [search, teamId]);

    return (
        <div css={DocumentsStyles}>
            <h1 className="mb-4">Documents</h1>
            <div className="top-items">
                <input
                    onChange={(e) => onSearch(e.target.value)}
                    value={search}
                    placeholder="Search For documents"
                    className="input search-area-items"
                />
            </div>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                {search && (
                    <Tab eventKey="all" title="All">
                        {key === 'all' && (
                            <AllDocuments
                                search={search}
                                searchData={searchData}
                                teamId={teamId}
                                setKey={setKey}
                                channelDetails={channelDetails}
                            />
                        )}
                    </Tab>
                )}
                <Tab eventKey="form_package" title="Form Library">
                    {key === 'form_package' && (
                        <TeamSettingFormPackage search={search} channelDetails={channelDetails} />
                    )}
                </Tab>
                <Tab eventKey="submitted_forms" title="Submitted Forms">
                    {key === 'submitted_forms' && (
                        <TeamSettingSubmittedForms search={search} channelDetails={channelDetails} />
                    )}
                </Tab>
                <Tab eventKey="team_forms" title="Documents">
                    {key === 'team_forms' && <Documents search={search} channelDetails={channelDetails} />}
                </Tab>
                <Tab eventKey="form_template" title="Visit Template">
                    {key === 'form_template' && <TeamSettingsVisitTemplate search={search} />}
                </Tab>
            </Tabs>

            <Modal css={ModalCSS} show={showSearchModal} backdropClassName="bg-dark" onHide={hideSearchModel} centered>
                <Modal.Header closeButton>
                    <h4
                        css={css`
                            margin: 0;
                        `}
                    >
                        Search All Documents
                    </h4>
                </Modal.Header>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => ({
    teamRooms: state.rooms.teamRooms,
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(TeamSettingsForm);
