import React, { useState, useEffect } from 'react';
import { css } from 'twin.macro';
import Main from './Main';
import OptHome from '../assets/opto-home.svg';
import { FaSync } from 'react-icons/fa';

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const handleError = (event) => {
            if (event && event?.message) {
                setHasError(true);
            }
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);

    if (hasError) {
        return (
            <Main>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: 2rem;
                    `}
                >
                    <img
                        css={css`
                            width: 400px;
                        `}
                        src={OptHome}
                        alt="opto home"
                    />
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            .title {
                                font-size: 8rem;
                                color: #4fa7aa;
                            }
                        `}
                    >
                        <p
                            css={css`
                                font-size: 3rem;
                                color: #636363;
                                margin-top: 8rem;
                            `}
                        >
                            An unexpected error occurred.
                        </p>
                        <div
                            css={css`
                                width: 70%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                p {
                                    text-align: center;
                                    display: inline-block;
                                    margin-bottom: 3rem;
                                    color: #727272;
                                }
                            `}
                        >
                            <button
                                className="btn btn-primary mb-4"
                                css={css`
                                    font-size: 2rem;
                                `}
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                <FaSync className="mr-4" />
                                Reload Page
                            </button>
                        </div>
                    </div>
                </div>
            </Main>
        );
    }

    return children;
};

export default ErrorBoundary;
