import { css } from 'twin.macro';

import { Fragment, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';
import Linkify from 'react-linkify';

import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { marginColor } from './Checkbox';
import { FaFileDownload } from 'react-icons/fa';
import { FILETYPES } from '../../../../contants/enums';
import { isImage, removeMongoidAndTimestamp } from '../../../../_helpers';
import ImageModal from '../../../Features/Teams/ImageModal';

const File = ({ data, elementRef, isPreview, error }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoomValue, setZoomValue] = useState(1);
    let ext, fileName;
    if (data.value) {
        ext = data.value?.slice(((data.value.lastIndexOf('.') - 1) >>> 0) + 2);
        try {
            fileName = data.value?.split('/')[data.value?.split('/').length - 1];
            fileName = removeMongoidAndTimestamp(fileName);
            const match = fileName.match(/_\d{13}_(.*)/);
            fileName = match[1] || fileName;
        } catch (err) {
            console.log(err);
            fileName = null;
        }
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const PDFDisplayCss = css`
        text-align: center;
        .react-pdf__Document {
            border: 3px solid #50aeb0;
            border-radius: 5px;
        }
        .react-pdf__Page {
            overflow: scroll !important;
        }
        .react-pdf__Page__canvas {
            width: 100% !important;
            height: 100% !important;
            transform-origin: left top !important;
            transform: scale(${zoomValue}) !important;
        }
        .react-pdf__Page__textContent {
            display: none !important;
        }
        .pgBtnDivContainer {
            padding-top: 2%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .pgBtnDiv {
            border: 2px solid lightgray;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            padding: 1%;
            width: 100%;
        }
        .pgBtnDiv p {
            margin: 0 !important;
            user-select: none;
        }
        .disabledBtn {
            pointer-events: none;
            color: grey;
        }
        .PgBtn {
            cursor: pointer;
            transition: 250ms color;
        }
        .PgBtn:hover {
            color: #50aeb0;
        }
        .backPgBtn {
            margin-right: 5px;
        }
        .nextPgBtn {
            margin-left: 5px;
        }
        .paginationDiv {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .paginationDiv a {
            margin: 0 5% !important;
        }
    `;

    const handleBackPage = () => {
        setPageNumber((prevPageNumber) => {
            if (prevPageNumber !== 1) {
                return prevPageNumber - 1;
            } else {
                return prevPageNumber;
            }
        });
    };
    const handleNextPage = () => {
        setPageNumber((prevPageNumber) => {
            if (prevPageNumber !== numPages) {
                return prevPageNumber + 1;
            } else {
                return prevPageNumber;
            }
        });
    };

    const ZoomIn = () => {
        if (zoomValue <= 3) {
            setZoomValue((prevZoomValue) => prevZoomValue + 1);
        }
    };
    const ZoomOut = () => {
        if (zoomValue >= 1) {
            setZoomValue((prevZoomValue) => prevZoomValue - 1);
        }
    };
    return (
        <Fragment>
            {data.value ? (
                ext === 'pdf' ? (
                    <div css={PDFDisplayCss}>
                        <div
                            css={css`
                                text-align: left;
                            `}
                        >
                            <h5 className="warpIt">{data.fieldName}</h5>
                            {data.instruction && (
                                <pre className="small text-muted warpIt preStyles">
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {data.instruction}
                                    </Linkify>
                                </pre>
                            )}
                        </div>

                        <Document file={data.value} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>

                        {numPages && (
                            <div className="pgBtnDivContainer">
                                <div className="pgBtnDiv">
                                    <div className="paginationDiv">
                                        <span
                                            className={`PgBtn backPgBtn ${pageNumber === 1 ? 'disabledBtn' : ''}`}
                                            onClick={handleBackPage}
                                        >
                                            <AiOutlineArrowLeft />
                                        </span>
                                        <p>
                                            {pageNumber} of {numPages}
                                        </p>
                                        <span
                                            className={`PgBtn nextPgBtn ${
                                                pageNumber === numPages ? 'disabledBtn' : ''
                                            }`}
                                            onClick={handleNextPage}
                                        >
                                            <AiOutlineArrowRight />
                                        </span>
                                    </div>
                                    <div className="paginationDiv">
                                        <span
                                            className={`PgBtn backPgBtn ${zoomValue === 3 ? 'disabledBtn' : ''}`}
                                            onClick={ZoomIn}
                                        >
                                            <AiOutlineZoomIn />
                                        </span>
                                        <span
                                            className={`PgBtn nextPgBtn ${zoomValue === 1 ? 'disabledBtn' : ''}`}
                                            onClick={ZoomOut}
                                        >
                                            <AiOutlineZoomOut />
                                        </span>
                                        <a href={data.value} download target="_blank" rel="noreferrer">
                                            Download
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <Fragment>
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                            `}
                        >
                            <div
                                css={css`
                                    text-align: left;
                                `}
                            >
                                <h5 className="warpIt">{data.fieldName}</h5>
                                {data.instruction && (
                                    <pre className="small text-muted warpIt preStyles">
                                        <Linkify
                                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                                <a target="blank" href={decoratedHref} key={key}>
                                                    {decoratedText}
                                                </a>
                                            )}
                                        >
                                            {data.instruction}
                                        </Linkify>
                                    </pre>
                                )}
                            </div>
                            <div
                                css={css`
                                    margin-top: 1rem;
                                `}
                            >
                                <a href={data.value} target="_blank" rel="noreferrer">
                                    {ext?.toLowerCase() === 'pdf' ? (
                                        <i className="fas fa-file-pdf mx-3"></i>
                                    ) : (
                                        <FaFileDownload className="mx-3" size={24} />
                                    )}
                                    {fileName ? fileName : 'Download'}
                                </a>
                                {isImage(data?.value) && <ImageModal file={data?.value} />}
                            </div>
                        </div>
                    </Fragment>
                )
            ) : (
                <Form.Group controlId="formGridFile">
                    <label name={`${!isPreview ? `File-${data._id}` : 'Email'}`}>
                        <h5 className="warpIt">
                            {data.fieldName}
                            <span css={marginColor}>
                                {(data.hasOwnProperty('required') ? data.required : true) && '*'}
                            </span>
                        </h5>
                        {data.instruction && <span className="small text-muted">{data.instruction}</span>}
                    </label>
                    <br />
                    <input
                        accept={FILETYPES}
                        type="file"
                        name={`${!isPreview ? `File-${data._id}` : 'Email'}`}
                        disabled={isPreview || data.value}
                        ref={
                            elementRef &&
                            elementRef({
                                required: data.hasOwnProperty('required') ? data.required : true,
                            })
                        }
                    />
                    <Form.Text className="text-danger">
                        {error && error.type === 'required' && 'This field is required'}
                    </Form.Text>
                </Form.Group>
            )}
        </Fragment>
    );
};

export default File;
