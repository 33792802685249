import { Fragment, useState } from 'react';
import { css } from 'twin.macro';
import dayjs from 'dayjs';
import { VscLoading } from 'react-icons/vsc';
import { FaEye } from 'react-icons/fa';
import { FormPackageTable, ScrollContainer } from '../../TeamSettingFormPackage';
import Avatar from '../../../../../Admin/reusableComponents/Avatar';
import { AddTagsTooltip, TagPreviewWithContainer } from '../../../../Common/Tags/TagsPreview';
import TagsModal from '../../../../Common/Tags/TagsModal';
import { ViewUserForm } from '../../../../../Admin/Form/SubmittedForm';
import FormContributionHistory from '../../../../../Admin/Form/FormContributionHistory';
import FormSvg from '../../../../../../assets/forms.svg';
import Pagination from '../../../../../Admin/Pagination';
import BulkSubmissionModal from './BulkSubmissionModal';

const Table = ({
    managerView,
    formId,
    teamId,
    teamIds,
    channel,
    member,
    form,
    filteredForms,
    totalForms,
    scrollRef,
    onScroll,
    formCounter,
    setForm,
    currentPage,
    startDate,
    endDate,
    loadingFiles,
    onPageChanged,
    updateTagsInSubmittedForms,
    hideFormPreview,
}) => {
    const [showTagsModal, setShowTagsModal] = useState(false);
    const handleShowTagsModal = (id) => setShowTagsModal(id);
    const handleCloseTagsModal = () => setShowTagsModal(false);
    console.log('form', form);

    return (
        <Fragment>
            {loadingFiles ? (
                <h4 className="text-center">
                    <VscLoading className="spin" fontSize="42" />
                </h4>
            ) : filteredForms.length ? (
                <div className="custom-card">
                    <ScrollContainer ref={scrollRef} onScroll={onScroll}>
                        <FormPackageTable className="w-100">
                            <thead>
                                <tr>
                                    <th>S.N</th>
                                    <th>Submitted by</th>
                                    <th>Form name</th>
                                    {/* <th>Created At</th> */}
                                    {managerView && <th>Team</th>}
                                    <th>Channel</th>
                                    {!managerView && <th>Contribution</th>}
                                    <th>Status</th>
                                    <th>Submitted At</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Fragment>
                                    {filteredForms.length > 0 &&
                                        filteredForms.map((form, i) => {
                                            formCounter++;
                                            return (
                                                <tr key={`${form._id}_${formCounter}`}>
                                                    <td>{(currentPage - 1) * 10 + (i + 1)}</td>
                                                    <td
                                                        css={css`
                                                            flex: 1;
                                                        `}
                                                    >
                                                        <div
                                                            css={css`
                                                                display: flex;
                                                                flex-direction: column;
                                                                gap: 0.5rem;
                                                            `}
                                                        >
                                                            <span
                                                                className={`${
                                                                    form?.userData?.name ? '' : 'text-danger'
                                                                } d-flex flex-row align-items-center`}
                                                            >
                                                                <Avatar item={form?.userData} />
                                                                <span
                                                                    className="d-flex flex-column"
                                                                    css={css`
                                                                        margin-left: 0.1rem;
                                                                    `}
                                                                >
                                                                    <div
                                                                        css={css`
                                                                            display: flex;
                                                                        `}
                                                                    >
                                                                        <span
                                                                            className="ml-1"
                                                                            css={css`
                                                                                font-size: 18px;
                                                                            `}
                                                                        >
                                                                            {form?.userData?.name
                                                                                ? form?.userData?.name
                                                                                : 'Deleted User'}
                                                                        </span>
                                                                        {!managerView && (
                                                                            <span
                                                                                css={css`
                                                                                    width: fit-content;
                                                                                    cursor: pointer;
                                                                                    margin-left: 10px;
                                                                                `}
                                                                                onClick={() =>
                                                                                    handleShowTagsModal(
                                                                                        form._id || form.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <AddTagsTooltip />
                                                                            </span>
                                                                        )}
                                                                        {showTagsModal === (form?._id || form?.id) ? (
                                                                            <TagsModal
                                                                                show={!!showTagsModal}
                                                                                handleClose={handleCloseTagsModal}
                                                                                type={'userform'}
                                                                                tagAction={'assign'}
                                                                                objectId={form?._id || form?.id}
                                                                                prevTags={form?.tags}
                                                                                responseCallback={
                                                                                    updateTagsInSubmittedForms
                                                                                }
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                    <ViewUserForm id={form._id} form={form}>
                                                                        <span
                                                                            className="btn btn-link"
                                                                            css={css`
                                                                                font-size: 14px;
                                                                                padding: 0;
                                                                            `}
                                                                        >
                                                                            <FaEye className="mr-3" />
                                                                            View this submission
                                                                        </span>
                                                                    </ViewUserForm>
                                                                </span>
                                                            </span>
                                                            <TagPreviewWithContainer
                                                                tags={form?.tags || []}
                                                                style={{ maxWidth: '50rem' }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="d-flex flex-column">
                                                            <span
                                                                css={css`
                                                                    font-weight: 600;
                                                                    font-size: 16px;
                                                                `}
                                                            >
                                                                {form?.formData?.name}
                                                            </span>
                                                            <span
                                                                className="btn btn-link"
                                                                css={css`
                                                                    font-size: 14px;
                                                                    padding: 0;
                                                                    text-align: left;
                                                                `}
                                                                onClick={() => setForm(form.formData)}
                                                            >
                                                                <FaEye className="mr-3" />
                                                                View bulk submissions
                                                            </span>
                                                        </span>
                                                    </td>
                                                    {managerView ? (
                                                        <td
                                                            className={`${
                                                                (form?.teamData || {})?.name ? '' : 'text-danger'
                                                            } text-left`}
                                                            css={css`
                                                                max-width: 300px;
                                                            `}
                                                        >
                                                            {(form?.teamData || {})?.name
                                                                ? form?.teamData?.name
                                                                : 'Team Deleted'}
                                                        </td>
                                                    ) : null}

                                                    <td
                                                        className={`${
                                                            form?.chatroomData?.title ? '' : 'text-danger'
                                                        } text-left`}
                                                        css={css`
                                                            max-width: 300px;
                                                        `}
                                                    >
                                                        {form?.chatroomData?.title
                                                            ? form?.chatroomData?.title
                                                            : 'Channel Deleted'}
                                                    </td>
                                                    {!managerView && (
                                                        <td>
                                                            {form?.extraInfo?.history &&
                                                            form?.extraInfo?.history?.length ? (
                                                                <FormContributionHistory
                                                                    id={form?._id || form?.id}
                                                                    form={form}
                                                                >
                                                                    <span
                                                                        className="btn btn-link"
                                                                        css={css`
                                                                            font-size: 14px;
                                                                            padding: 0;
                                                                        `}
                                                                    >
                                                                        View Contribution
                                                                    </span>
                                                                </FormContributionHistory>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </td>
                                                    )}

                                                    <td>
                                                        {form?.submissionType === 'draft' ? (
                                                            <span className="badge badge-warning">draft</span>
                                                        ) : (
                                                            <span>-</span>
                                                        )}
                                                    </td>
                                                    <td>{dayjs(form?.createdAt).format('MMMM D, YYYY h:mm A')}</td>
                                                </tr>
                                            );
                                        })}
                                </Fragment>
                            </tbody>
                        </FormPackageTable>
                    </ScrollContainer>
                </div>
            ) : (
                <div
                    css={css`
                        background-color: white;
                        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
                        border-radius: 5px;
                        padding: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    `}
                >
                    <img
                        src={FormSvg}
                        alt={'no documents found'}
                        css={css`
                            width: 350px;
                            margin: 1rem 0;
                        `}
                    />
                    <h2 className="text-center">No matching results</h2>
                    <div className="text-center">Try another search, or use search filters to find the document.</div>
                </div>
            )}
            <BulkSubmissionModal
                form={form}
                teamId={teamId}
                teamIds={teamIds}
                channel={channel}
                member={member}
                startDate={startDate}
                endDate={endDate}
                hideFormPreview={hideFormPreview}
            />
            <div className="p-4">
                <Pagination
                    totalRecords={totalForms}
                    key={totalForms}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={onPageChanged}
                />
            </div>
        </Fragment>
    );
};

export default Table;
