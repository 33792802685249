import React from 'react';
import { css } from 'twin.macro';
import { connect } from 'react-redux';

// Helpers
import { getAllUser } from '../../../../_redux/actions/ActionUser';
import { ArchiveAStaff, UnarchiveAStaff } from '../../../../_redux/actions/ActionEVV';

// Components
import AdminTablesImg from '../../reusableComponents/AdminTablesImg';
import WorklioTaxSetup from '../components/WorklioTaxSetup';

import SortedTableHeaderIcon from '../../SortedTableHeaderIcon';
import LoadingSpiner from '../../../../_elements/LoadingSpiner';
import EditStaff from './EditStaff';
import ViewStaff from './ViewStaff';
import { ActionStyles, AdminTable } from '../../reusableStyles';

// assets
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import Swal from 'sweetalert2';
import ChangeStaffChannel from './ChangeStaffChannel';
import ZealTaskSetup from '../components/ZealTaxSetup';

const StaffList = ({
    list,
    isLoading,
    handleSort = () => {},
    ArchiveAStaff,
    UnarchiveAStaff,
    currentPage,
    setStaffState,
    staffState,
    teamId,
    sortConfig,
}) => {
    const handleArchive = (employee) => {
        Swal.fire({
            title: `Are you sure you want to archive "${employee.firstName} ${employee.lastName}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, archive it!',
        }).then((result) => {
            if (result.isConfirmed) {
                ArchiveAStaff(employee.id, currentPage, teamId);
                Swal.fire('Archieved!', 'Your employee has been archived', 'success');
            }
        });
    };

    const handleUnarchive = (employee) => {
        Swal.fire({
            title: `Are you sure you want to unarchive "${employee.firstName} ${employee.lastName}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, unarchive it!',
        }).then((result) => {
            if (result.isConfirmed) {
                UnarchiveAStaff(employee.id, currentPage, teamId);
                setStaffState('active');
                Swal.fire('UnArchived!', 'Your employee has been unarchived', 'success');
            }
        });
    };

    return (
        <>
            <AdminTable className="w-100 text-dark-grey">
                <thead>
                    <tr>
                        <th>Avatar</th>
                        <th
                            onClick={() => handleSort('firstName')}
                            className="pointer costume-tooltip text-center"
                            data-tooltip="sort by firstName"
                        >
                            User
                            <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="firstName" key="firstName" />
                        </th>
                        <th>Provider</th>
                        <th>EVV Type</th>
                        <th>Payroll Type</th>
                        <th
                            onClick={() => handleSort('createdAt')}
                            className="pointer costume-tooltip"
                            data-tooltip="sort by created date"
                        >
                            Created At
                            <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="createdAt" key="createdAt" />
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className="text-light-grey">
                    {isLoading ? (
                        <tr
                            css={css`
                                border: none !important;
                            `}
                        >
                            <td colSpan={7}>
                                <LoadingSpiner size={42} height={200} />
                            </td>
                        </tr>
                    ) : list && list.length ? (
                        list.map((employee) => {
                            let createdDate = employee.createdAt.split('T')[0].split('-');
                            let mmddyyyyFormatedDate = createdDate[1] + '/' + createdDate[2] + '/' + createdDate[0];
                            const isEmployee = employee?.payroll?.data?.type === 'employee';
                            const zealEmployeeID = employee?.payroll?.metadata?.employeeID;
                            const zealContractorID = employee?.payroll?.metadata?.contractorID;
                            return (
                                <tr key={employee.id}>
                                    <td style={{ width: '100px' }}>
                                        <AdminTablesImg item={employee} />
                                    </td>
                                    <td className="text-center">
                                        {employee.firstName || employee.user?.name} {employee.lastName}
                                    </td>
                                    <td>{employee?.provider?.agencyName ? employee?.provider?.agencyName : 'none'}</td>
                                    <td>{employee?.evv?.type}</td>
                                    <td>{employee?.payroll?.type}</td>
                                    <td>{mmddyyyyFormatedDate}</td>
                                    <td className="action">
                                        <ActionStyles>
                                            <ViewStaff employee={employee} />
                                            <EditStaff employee={employee} />
                                            <ChangeStaffChannel employee={employee} />

                                            {employee?.payroll?.type === 'worklio' &&
                                                employee?.provider?.payroll?.metadata?.companyId &&
                                                employee?.payroll?.metadata?.employeeId && (
                                                    <WorklioTaxSetup
                                                        companyId={employee?.provider?.payroll?.metadata?.companyId}
                                                        employeeId={employee?.payroll?.metadata?.employeeId}
                                                        type="employee"
                                                    />
                                                )}
                                            {employee?.payroll?.type === 'zeal' &&
                                                employee?.provider?.payroll?.metadata?.companyID &&
                                                (isEmployee ? zealEmployeeID : zealContractorID) && (
                                                    <ZealTaskSetup
                                                        companyID={employee?.provider?.payroll?.metadata?.companyID}
                                                        employeeID={zealEmployeeID}
                                                        contractorID={zealContractorID}
                                                        isEmployee={isEmployee}
                                                        onboarded={employee?.payroll?.metadata?.onboarded}
                                                        teamId={teamId}
                                                        employee={employee}
                                                    />
                                                )}

                                            {staffState === 'archieved' ? (
                                                <BiArchiveOut
                                                    className="icon archiveOut"
                                                    onClick={() => handleUnarchive(employee)}
                                                />
                                            ) : (
                                                <BiArchiveIn
                                                    className="icon archiveIn"
                                                    onClick={() => handleArchive(employee)}
                                                />
                                            )}

                                            {/* // <EditUser user={user} />
											// {allUsers.user && allUsers.user.id !== user.id && (
											// 	<DeleteUser
											// 		user={user}
											// 		deleteUser={deleteUser}
											// 		currentPage={currentPage}
											// 		search={search}
											// 	/>
											// )} */}
                                        </ActionStyles>
                                    </td>
                                    <td className="verification-status"></td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={6}>
                                <div
                                    className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                    css={css`
                                        margin-top: 50px;
                                        width: 100%;
                                    `}
                                >
                                    No Staff Found
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </AdminTable>
        </>
    );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { getAllUser, ArchiveAStaff, UnarchiveAStaff })(StaffList);
