import { css } from 'twin.macro';
import PropTypes from 'prop-types';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useState, useMemo, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';

//redux
import { connect } from 'react-redux';
import { deleteUser, setUser } from '../../_redux/actions/ActionUser';

// bootstrap
import { fetchWrapper, updateValidationSchema } from '../../_helpers';
import ProfileInfo from './ProfileInfo';

// react-icons
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

// helpers
import { getInitials, getRandomColor } from '../../_helpers';

//images
import { loginUser, startLoading, stopLoading } from '../../_redux/actions/ActionAuthentication';
import { getUserData } from '../../_redux/actions/ActionUser';

import { formLabel } from '../../_elements/formLabel';
import formInput from '../../_elements/formInput';

import { forceResetPasswordValidation } from '../../_helpers';
import {
    bigButtons,
    editProfileBtn,
    editBtn,
    editProfileFormCSS,
    eye,
    popup,
    profileContainer,
    profileLabel,
} from './styles/ProfileStyles';
import AvatarUserPanel from '../Features/Common/AvatarUserPanel';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { IMAGESTYPES } from '../../contants/enums';
import { VscLoading } from 'react-icons/vsc';
import { ConsentToolTip } from '../../_elements/ToolTip';
// import { hoverPopover } from '../Admin/reusableStyles';

function Profile({
    user,
    deleteUser,
    setUser,
    startLoading,
    stopLoading,
    ui,
    fadeOutAvatarWarning,
    loginUser,
    getUserData,
}) {
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState('');
    const [changePassword, setChangePassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(true);
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [notifications, setNotifications] = useState({
        email: user?.emailNotification || false,
        push: user?.pushNotification || false,
        sms: user?.smsNotification || false,
    });
    const [notificationLoader, setNotificationLoader] = useState('');
    const [phoneError, setPhoneError] = useState(null);

    const [showPublicChannel, setShowPublicChannel] = useState();

    useEffect(() => {
        setShowPublicChannel(user.showPublicChannel);
    }, [user.showPublicChannel]);

    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(updateValidationSchema),
    });
    useEffect(() => {
        if (user) {
            setPhone(user.phone);
        }
    }, [user]);

    const fetchUserData = async () => {
        let userData = await getUserData();
        setNotifications({
            email: userData?.emailNotification || false,
            push: userData?.pushNotification || false,
            sms: userData?.smsNotification || false,
        });
    };

    useEffect(() => {
        if (show) {
            fetchUserData();
        }
    }, [show]);
    const {
        register: pass_register,
        handleSubmit: pass_handleSubmit,
        errors: pass_errors,
        reset,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(forceResetPasswordValidation),
    });

    const onSubmit = async (newUser) => {
        if ((phone?.length || 0) < 10) {
            return setPhoneError('Phone number is required');
        }
        startLoading();
        let userToken = JSON.parse(localStorage.getItem('user'));
        setPhoneError(null);
        let tempNewUser = {
            ...newUser,
            showPublicChannel: showPublicChannel,
        };
        if (phone !== user.phone) {
            tempNewUser.phone = '+' + phone;
        }
        await updateProfile(JSON.stringify(tempNewUser), 'data', {
            Authorization: `bearer ${userToken.token}`,
            'Content-Type': 'application/json',
        });
        stopLoading();
        setEdit(false);
    };

    const handleFile = async (e) => {
        const maxFileSize = 20 * 1024 * 1024;
        const imageFile = e.target.files[0];
        if (imageFile && imageFile.size < maxFileSize) {
            setLoading(true);
            let avatar = new FormData();
            avatar.append('avatar', imageFile);
            let user = JSON.parse(localStorage.getItem('user'));
            try {
                await updateProfile(avatar, 'avatar', {
                    Authorization: `bearer ${user.token}`,
                });
            } catch (err) {
                console.log(err);
            }
        } else if (imageFile) {
            toast.warn('Image Must be less than 20 mb in size!');
        }
    };

    const updateProfile = async (tempBody, type, header) => {
        try {
            console.log(tempBody, 'tempBody');
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'users/me', {
                method: 'PUT', // 'GET', 'PUT', 'DELETE', etc.
                headers: header,
                body: tempBody, // Coordinate the body type with 'Content-Type'
                credentials: 'same-origin',
            });
            const user = await response.json();
            const updateUserInfo = {
                name: user.name || '',
                address: user.address || '',
                email: user.email || '',
                about: user.about || '',
                avatar: user.avatar || '',
                showPublicChannel: user.showPublicChannel,
            };

            setUser(updateUserInfo);
            setLoading(false);
            toast.success(type === 'avatar' ? 'Image Updated Successfully' : 'Profile Updated Successfully');
            fadeOutAvatarWarning && fadeOutAvatarWarning();
        } catch (e) {
            toast.error('Something Went Wrong');
            setLoading(false);
        }
    };

    const handlePasswordChange = async (password) => {
        startLoading();
        let changingPassword = { oldPassword: password.currentPassword, newPassword: password.password };
        await updatePassword(changingPassword);
        reset();
        stopLoading();
        setChangePassword(false);
    };

    const updatePassword = async (body) => {
        try {
            const response = await fetchWrapper.put('users/me/update-password', body);
            toast.success(response.message);
            await loginUser(null, null, null, response);
        } catch (e) {
            toast.error(e?.message || 'Something went wrong!');
        }
    };

    const handleNotificationChange = async (type) => {
        if (!['email', 'push', 'sms'].includes(type)) {
            console.error('Invalid notification type');
            return;
        }

        const updatedValue = !notifications[type];
        const body = {
            pushNotification: notifications.push,
            emailNotification: notifications.email,
            smsNotification: notifications.sms,
            [`${type}Notification`]: updatedValue,
        };

        try {
            setNotificationLoader(type);
            await fetchWrapper.put('/users/me/notification-settings', body);
            setNotifications((prev) => ({ ...prev, [type]: updatedValue }));
            setUser({ [`${type}Notification`]: updatedValue });
            const action = updatedValue ? 'enabled' : 'disabled';
            toast.success(`${type} notification ${action} successfully`);
        } catch (err) {
            if (err.name === 'AbortError') {
                return;
            }
            toast.error(`Failed to change ${type} notification settings`);
            console.error(err);
        } finally {
            setNotificationLoader('');
        }
    };

    const handleSeePublicChannel = (event) => {
        setShowPublicChannel(!showPublicChannel);
    };
    return (
        <div css={profileContainer}>
            <Container fluid>
                <Row>
                    <div css={popup}>
                        {!edit ? (
                            <div className=" mobile-profile">
                                <ProfileInfo />
                                <div className="w-100 d-flex justify-content-center">
                                    <div onClick={() => setEdit(true)} css={editProfileBtn}>
                                        <i className="fas fa-pen"></i> Edit Profile
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="mobile-profile">
                                <div className="d-flex justify-content-center ">
                                    <label className="position-relative" css={editBtn}>
                                        <input
                                            onChange={(e) => handleFile(e)}
                                            className="d-none"
                                            accept={IMAGESTYPES}
                                            type="file"
                                            name="avatar"
                                        />
                                        <div
                                            className="edit "
                                            css={css`
                                                .rounded-editProfileBtn {
                                                    font-size: 3rem;
                                                }
                                            `}
                                        >
                                            <AvatarUserPanel
                                                customCss={'width: 120px; height: 120px;'}
                                                imageWidth={480}
                                                item={user}
                                                large={true}
                                            />

                                            {!loading ? (
                                                <i
                                                    className="fas fa-pen text-primary "
                                                    css={css`
                                                        font-size: 3rem;
                                                        opacity: 0;
                                                        position: absolute;
                                                        top: 50%;
                                                        left: 50%;
                                                        cursor: pointer;
                                                        transform: translate(-50%, -50%);
                                                    `}
                                                ></i>
                                            ) : (
                                                <div
                                                    css={css`
                                                        position: absolute;
                                                        top: 50%;
                                                        left: 50%;
                                                        transform: translate(-50%, -50%);
                                                    `}
                                                >
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </label>
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)} css={editProfileFormCSS}>
                                    <Form.Group as={Row} controlId="formBasicText1">
                                        <Form.Label column sm="3" css={profileLabel}>
                                            Name
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                defaultValue={user.name}
                                                ref={register}
                                            />
                                            <Form.Text className="text-danger">{errors.name?.message}</Form.Text>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formBasicEmail">
                                        <Form.Label column sm="3" css={profileLabel}>
                                            Email
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                defaultValue={user.email}
                                                ref={register}
                                                disabled
                                            />
                                            <Form.Text className="text-danger">{errors.email?.message}</Form.Text>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formBasicText2">
                                        <Form.Label column sm="3" css={profileLabel}>
                                            Address
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                type="text"
                                                name="address"
                                                defaultValue={user.address && user.address}
                                                maxLength={120}
                                                ref={register}
                                            />
                                            <Form.Text className="text-danger">{errors.address?.message}</Form.Text>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group
                                        as={Row}
                                        controlId="formBasicText2"
                                        css={css`
                                            .form-control {
                                                height: 4rem !important;
                                            }
                                            .country-name {
                                                color: black !important;
                                            }
                                            .react-tel-input {
                                                width: auto !important;
                                                margin: 0 2rem !important;
                                            }
                                            .react-tel-input {
                                                margin: 0 !important;
                                            }
                                        `}
                                    >
                                        <Form.Label column sm="3" css={formLabel}>
                                            Phone
                                        </Form.Label>
                                        <Col sm="9">
                                            <PhoneInput
                                                country={'us'}
                                                value={phone}
                                                onChange={(phone) => {
                                                    if (phone.length >= 10) {
                                                        setPhoneError(null);
                                                    }
                                                    setPhone(phone);
                                                }}
                                                disabled={true}
                                            />
                                            {phoneError && <Form.Text className="text-danger">{phoneError}</Form.Text>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formBasicText3">
                                        <Form.Label column sm="3" css={profileLabel}>
                                            About
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                as="textarea"
                                                defaultValue={user.about && user.about}
                                                name="about"
                                                rows="3"
                                                ref={register}
                                                maxLength={300}
                                            />
                                            <Form.Text className="text-danger">{errors.about?.message}</Form.Text>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formBasicText3">
                                        <Form.Label column sm="6" css={profileLabel}>
                                            <div className="d-flex">
                                                See public teams and rooms:
                                                <ConsentToolTip
                                                    text="You will only see teams that are associated with you, if you
                                                        uncheck and update."
                                                />
                                            </div>
                                        </Form.Label>
                                        <Col sm="3" className="checkbox-div">
                                            <div className="mr-3">
                                                <input
                                                    className="checkbox101"
                                                    type="checkbox"
                                                    defaultChecked={showPublicChannel}
                                                    onChange={handleSeePublicChannel}
                                                    name="publicChannel"
                                                />
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <hr />
                                    NOTIFICATIONS:
                                    <Form.Group as={Row} controlId="formBasicText3">
                                        <Form.Label column sm="4">
                                            <div className="d-flex">Email Notification:</div>
                                        </Form.Label>
                                        <Col sm="8" className="">
                                            {notificationLoader === 'email' ? (
                                                <p>
                                                    <VscLoading className="spin" />
                                                </p>
                                            ) : (
                                                <div className="d-flex justify-items-center">
                                                    <div className="d-flex">
                                                        <input
                                                            type="radio"
                                                            className="mx-1"
                                                            name="emailNotification"
                                                            checked={notifications.email}
                                                            onChange={() => handleNotificationChange('email')}
                                                            value="enable"
                                                        />
                                                        Opt-in
                                                        <ConsentToolTip
                                                            text="Opt-in: You will receive email notifications for Direct Chat messages."
                                                            color={'#50aeb0'}
                                                        />
                                                    </div>
                                                    <div className="d-flex ml-4">
                                                        <input
                                                            type="radio"
                                                            className="mx-1"
                                                            name="emailNotification"
                                                            checked={!notifications.email}
                                                            onChange={() => handleNotificationChange('email')}
                                                            value="disable"
                                                        />
                                                        Opt-out
                                                        <ConsentToolTip text="Opt-out: You will not receive any email notifications for Direct Chat messages." />
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formBasicText4">
                                        <Form.Label column sm="4" css={profileLabel}>
                                            <div className="d-flex">SMS Notification:</div>
                                        </Form.Label>
                                        <Col sm="8" className="">
                                            {notificationLoader === 'sms' ? (
                                                <p>
                                                    <VscLoading className="spin" />
                                                </p>
                                            ) : (
                                                <div className="d-flex justify-items-center">
                                                    <div className="d-flex">
                                                        <input
                                                            type="radio"
                                                            className="mx-1"
                                                            name="smsNotification"
                                                            checked={notifications.sms}
                                                            onChange={() => handleNotificationChange('sms', true)}
                                                        />
                                                        Opt-in
                                                        <ConsentToolTip
                                                            text="Opt-in: You will receive SMS notifications for messages, event reminders, and OTPs."
                                                            color="#50aeb0"
                                                        />
                                                    </div>
                                                    <div className="d-flex ml-4">
                                                        <input
                                                            type="radio"
                                                            className="mx-1"
                                                            name="smsNotification"
                                                            checked={!notifications.sms}
                                                            onChange={() => handleNotificationChange('sms', false)}
                                                        />
                                                        Opt-out
                                                        <ConsentToolTip text="Opt-out: You will not receive any SMS notifications." />
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Form.Group>
                                    {changePassword ? (
                                        <Form className="border shadow p-3 rounded">
                                            <div>
                                                <h3 className="text-primary mb-4 font-weight-bold">Change Password</h3>
                                            </div>

                                            <Form.Group controlId="formBasicToken">
                                                <Form.Label
                                                    css={formLabel}
                                                    className="text-muted "
                                                    styles={{ wordBreak: 'normal' }}
                                                >
                                                    Current Password
                                                </Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control
                                                        css={formInput}
                                                        type={showCurrentPassword ? 'password' : 'text'}
                                                        name="currentPassword"
                                                        isInvalid={pass_errors.currentPassword}
                                                        placeholder="Enter Current Password"
                                                        ref={pass_register}
                                                    />
                                                    <div css={eye}>
                                                        {showCurrentPassword ? (
                                                            <AiFillEyeInvisible
                                                                css={css`
                                                                    color: grey;
                                                                `}
                                                                onClick={() => setShowCurrentPassword(false)}
                                                            />
                                                        ) : (
                                                            <AiFillEye
                                                                css={css`
                                                                    color: grey;
                                                                `}
                                                                onClick={() => setShowCurrentPassword(true)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <Form.Text className="text-danger">
                                                    {pass_errors.currentPassword?.message}
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label
                                                    css={formLabel}
                                                    className="text-muted "
                                                    styles={{ wordBreak: 'normal' }}
                                                >
                                                    Password
                                                </Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control
                                                        css={formInput}
                                                        type={showPassword ? 'password' : 'text'}
                                                        name="password"
                                                        isInvalid={pass_errors.password}
                                                        placeholder="Enter Password"
                                                        ref={pass_register}
                                                        autoComplete="new-password"
                                                    />
                                                    <div css={eye}>
                                                        {showPassword ? (
                                                            <AiFillEyeInvisible
                                                                css={css`
                                                                    color: grey;
                                                                `}
                                                                onClick={() => setShowPassword(false)}
                                                            />
                                                        ) : (
                                                            <AiFillEye
                                                                css={css`
                                                                    color: grey;
                                                                `}
                                                                onClick={() => setShowPassword(true)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <Form.Text className="text-danger">
                                                    {pass_errors.password?.message}
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicConfirmPassword">
                                                <Form.Label
                                                    css={formLabel}
                                                    className="text-muted "
                                                    styles={{ wordBreak: 'normal' }}
                                                >
                                                    Confirm Password
                                                </Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control
                                                        css={formInput}
                                                        isInvalid={pass_errors.confirmPassword}
                                                        type={showConfirmPassword ? 'password' : 'text'}
                                                        name="confirmPassword"
                                                        placeholder="Confirm Password"
                                                        ref={pass_register}
                                                        autoComplete="new-password"
                                                    />
                                                    <div css={eye}>
                                                        {showConfirmPassword ? (
                                                            <AiFillEyeInvisible
                                                                css={css`
                                                                    color: grey;
                                                                `}
                                                                onClick={() => setShowConfirmPassword(false)}
                                                            />
                                                        ) : (
                                                            <AiFillEye
                                                                css={css`
                                                                    color: grey;
                                                                `}
                                                                onClick={() => setShowConfirmPassword(true)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <Form.Text className="text-danger">
                                                    {pass_errors.confirmPassword?.message}
                                                </Form.Text>
                                            </Form.Group>
                                            <div className="d-flex justify-content-around">
                                                <Button
                                                    css={bigButtons}
                                                    variant="primary"
                                                    type="button"
                                                    size="lg"
                                                    onClick={pass_handleSubmit(handlePasswordChange)}
                                                >
                                                    Reset
                                                </Button>
                                                <Button
                                                    css={bigButtons}
                                                    variant="secondary"
                                                    onClick={() => {
                                                        setChangePassword(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    ) : (
                                        <Row className="btns-control">
                                            <Button
                                                variant="outline-primary"
                                                css={bigButtons}
                                                onClick={() => setChangePassword(true)}
                                            >
                                                Change Password
                                            </Button>
                                        </Row>
                                    )}
                                    <Row className="btns-control pt-4">
                                        <Button
                                            css={bigButtons}
                                            variant="secondary"
                                            onClick={() => {
                                                setChangePassword(false);
                                                setEdit(false);
                                            }}
                                        >
                                            Back
                                        </Button>

                                        <Button css={bigButtons} variant="primary" type="submit">
                                            Update
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </div>
                </Row>
            </Container>
        </div>
    );
}
const mapStateToProps = (state) => ({
    user: state.user.user,
    ui: state.ui,
});

Profile.propTypes = {
    user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { loginUser, deleteUser, setUser, startLoading, stopLoading, getUserData })(
    Profile
);
