import { css } from 'twin.macro';
import dayjs from 'dayjs';
import { ViewUserForm } from '../../../../../Admin/Form/SubmittedForm';
import { FaWpforms } from 'react-icons/fa';
import { ActionStyles } from '../../../../../Admin/reusableStyles';
import { BootstrapToolTip } from '../../../../../../_elements/ToolTip';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';
import { Fragment, useState } from 'react';
import { VscLoading } from 'react-icons/vsc';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';

export function UserCertificateContainer({ certificate, showToggle, loadData = () => {} }) {
    const [isBusy, setIsBusy] = useState(false);

    const toggleCertificateStatus = async () => {
        try {
            setIsBusy(true);
            await fetchWrapper.patch(`user-certificates/${certificate._id}`);
            toast.success('Certificate Status Updated Successfully');
        } catch (e) {
            toast.error(e.message);
        } finally {
            await loadData();
            setIsBusy(false);
        }
    };
    return (
        <div
            className="d-flex w-100 custom-card"
            css={css`
                justify-content: space-between;
                gap: 8px;
                align-items: center;
                margin-top: 8px;
            `}
        >
            <div>
                <div>
                    Course Name:{' '}
                    <span
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        {certificate?.courseName}
                    </span>
                </div>
                <div>
                    Course Taken Date:{' '}
                    <span
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        {dayjs(certificate?.courseTakenAt).format('MMM D, YYYY')}
                    </span>
                </div>
                <div>
                    Objective:{' '}
                    <span
                        css={css`
                            font-weight: 700;
                        `}
                    >
                        {certificate?.trainingObjectives}
                    </span>
                </div>

                {showToggle && (
                    <div>
                        Status:{' '}
                        <span className={`badge badge-${certificate?.status === 'archive' ? 'danger' : 'success'}`}>
                            {certificate?.status}
                        </span>
                    </div>
                )}
                {/* {certificate?.team && showToggle && (
                    <div>
                       Team: <span className="badge badge-primary">{certificate?.team?.name}</span>
                    </div>
                )} */}
            </div>
            <div
                className="d-flex flex-column align-items-end"
                css={css`
                    justify-content: space-between;
                    gap: 8px;
                `}
            >
                <div className="d-flex">
                    {certificate?.form && (
                        <ViewUserForm id={certificate?.form}>
                            <BootstrapToolTip text="Certificate Form Submission">
                                <ActionStyles className="view-icon">
                                    <FaWpforms className="icon" />
                                </ActionStyles>
                            </BootstrapToolTip>
                        </ViewUserForm>
                    )}
                    {showToggle && (
                        <Fragment>
                            {isBusy ? (
                                <VscLoading className="spin" />
                            ) : (
                                <BootstrapToolTip
                                    text={
                                        certificate?.status === 'archive'
                                            ? 'Unarchive Certificate'
                                            : 'Archive Certificate'
                                    }
                                >
                                    <ActionStyles>
                                        {certificate?.status === 'archive' ? (
                                            <BiArchiveOut
                                                className="icon archiveOut"
                                                onClick={toggleCertificateStatus}
                                            />
                                        ) : (
                                            <BiArchiveIn className="icon archiveIn" onClick={toggleCertificateStatus} />
                                        )}
                                    </ActionStyles>
                                </BootstrapToolTip>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}
