import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';

const FieldOptions = ({ element, register }) => {
    return (
        <Fragment>
            {element.key === 'InputField' && (
                <Form.Group
                    controlId="formProperties"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Form.Label style={{ marginRight: '10px', marginBottom: '0' }}>
                        Maximum characters allowed:
                    </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder=""
                        defaultValue={(element?.options || {})?.maxLength}
                        name="options.maxLength"
                        ref={register}
                        onWheel={(event) => event.currentTarget.blur()}
                        min={0}
                        style={{ width: '200px' }}
                    />
                </Form.Group>
            )}
        </Fragment>
    );
};

export default FieldOptions;
