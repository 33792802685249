import React, { Fragment, useState } from 'react';
import { css } from 'twin.macro';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';
import { Button, Modal, Table, Spinner, Dropdown } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { cleanUpString, fetchWrapper, isValidEmail, isValidPhone } from '../../../../_helpers';
import FileUploader from '../../Common/FileUploader';
import { showBulkReport } from '../Client/BulkModal';
import { HiUsers } from 'react-icons/hi2';
import { BootstrapToolTip } from '../../../../_elements/ToolTip';
import { FaInfoCircle } from 'react-icons/fa';

const preparePayload = (items) => {
    return (items || [])
        .map((item) => {
            const { name: nameObj, phone: phoneObj, email: emailObj, role: roleObj } = item;
            const name = nameObj?.label;
            const phone = phoneObj?.label || undefined;
            const email = emailObj?.label || undefined;
            const role = roleObj?.label;
            if (!name || (!isValidPhone(phone) && !isValidEmail(email))) return null;
            return {
                name,
                phone,
                email,
                role,
            };
        })
        .filter(Boolean);
};

const getRole = ({ teamId, roomId, role = '' }) => {
    try {
        let selectedRole = 'user';

        if (!role) {
            return selectedRole;
        }
        const validRoles = roomId ? ['supervisor', 'doctor', 'family', 'user'] : ['user', 'teamManager'];
        const matchedRole = validRoles.find((validRole) => {
            const regex = new RegExp(`^${validRole}$`, 'i');
            return regex.test(role);
        });
        if (matchedRole) {
            selectedRole = matchedRole;
        }
        return selectedRole;
    } catch (error) {
        console.log('error', error);
        return 'user';
    }
};

const BulkModal = ({ callback = async () => {}, bulkCategory = 'User', roomId, teamId }) => {
    const [show, setShow] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState([]);

    const handleFileUpload = async (formData) => {
        try {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (jsonData.length > 0) {
                    const tempItems = jsonData
                        .slice(1)
                        .map((row) => {
                            const cleanedRow = row.map(cleanUpString);
                            const [name, phone, email, role] = cleanedRow;

                            return !phone && !name
                                ? null
                                : {
                                      name: { label: name, error: !name && 'Name is required' },
                                      phone: {
                                          label: phone ? (phone?.includes('+') ? phone : `+1${phone}`) : '',
                                          error: !phone
                                              ? 'Phone is missing'
                                              : !isValidPhone(phone) && 'Invalid phone number',
                                      },
                                      email: {
                                          label: email,
                                          error: !email ? 'Email is missing' : !isValidEmail(email) && 'Invalid email',
                                      },
                                      role: {
                                          //   label: (role || '').match('manager') ? 'teamManager' : 'user',
                                          label: getRole({ teamId, roomId, role }),
                                      },
                                  };
                        })
                        .filter(Boolean);

                    setItems(tempItems);
                    setErrors([]);
                } else {
                    setErrors(['No data found in the file']);
                }
                setFileData(jsonData);
                setIsUploading(false);
            };

            reader.onerror = () => {
                setErrors(['Failed to read the file. Please try again.']);
                setIsUploading(false);
            };

            reader.readAsBinaryString(formData.get('file'));
        } catch (error) {
            setErrors(['An error occurred while processing the file.']);
            setIsUploading(false);
            console.error('Error parsing Excel file:', error);
        }
    };

    const handleCancelUpload = () => {
        setFileData(null);
        setItems([]);
        setErrors([]);
    };

    const handleSubmit = async () => {
        try {
            if (!teamId && !roomId) {
                return toast.error('Team or Room is required.');
            }
            const data = preparePayload(items || []);
            const response = await Swal.fire({
                title: `Confirm creating ${bulkCategory}`,
                html: `
                    <div>
                    <p style="font-size: 14px;">
                        Total ${bulkCategory} Count: ${(items || []).length}
                    </p>
                    <p style="font-size: 14px; color:green">
                        Valid ${bulkCategory}  Count: ${(data || []).length}
                    </p>
                     <p style="font-size: 14px;color:red;">
                        Invalid ${bulkCategory}  Count: ${(items || []).length - (data || []).length}
                    </p>
                    </div>
                `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });

            if (!response.isConfirmed) {
                return;
            }
            let res;
            if (roomId) {
                res = await fetchWrapper.post(`chatrooms/${roomId}/invite/bulk`, data);
            } else {
                res = await fetchWrapper.post(
                    `organizations/5f3569c2647fb5000c019e42/teams/${teamId}/invite/bulk`,
                    data
                );
            }

            if (res.error) {
                toast.error(res?.error);
            }
            toast.success(res?.message);
            await showBulkReport(res);
            await callback();
        } catch (error) {
            console.log('ERROR => ', error);
            toast.error(error?.message);
        }
    };

    return (
        <Fragment>
            <div className="ml-2">
                <BootstrapToolTip text={`Bulk Invite To ${roomId ? 'Channel' : 'Team'}`}>
                    <Dropdown.Item onClick={() => setShow(true)} style={{ borderRadius: '4px' }}>
                        <HiUsers />
                    </Dropdown.Item>
                </BootstrapToolTip>
            </div>

            <Modal show={show} onHide={() => setShow(false)} size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Bulk {bulkCategory} Invite To {roomId ? 'Channel' : 'Team'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!fileData ? (
                        <>
                            <FileUploader
                                accept=".xlsx, .xls"
                                onFileUpload={handleFileUpload}
                                buttonText="Upload Excel File"
                                loadingText="Uploading..."
                                successMessage="File uploaded successfully!"
                                errorMessage="File upload failed. Please try again."
                                fileLimit={5}
                                showTips={true}
                                tips={['Ensure the file is in .xlsx format.']}
                            />

                            {isUploading && (
                                <div className="d-flex justify-content-center">
                                    <VscLoading className="spin" size={30} />
                                </div>
                            )}
                            {errors.length > 0 && (
                                <div className="alert alert-danger mt-3">
                                    <ul>
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    ) : (
                        <div
                            css={css`
                                width: 100%;
                                overflow-x: auto;
                            `}
                        >
                            <div># Note:</div>
                            <ul>
                                <li>
                                    <FaInfoCircle color={'orange'} /> If country code is not provided in phone,{' '}
                                    <strong>"+1"</strong> will be added by default.
                                </li>
                                <li>
                                    <FaInfoCircle color={'orange'} /> If valid role is not provided then,{' '}
                                    <strong>"user"</strong> will be default role.
                                </li>
                            </ul>
                            <br />

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>S.N.</th>
                                        {items.length > 0 &&
                                            Object.keys(items[0]).map((header, idx) => <th key={idx}>{header}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {item.name?.label}
                                                <p className="text-danger font-bold ">{item.name?.error}</p>
                                            </td>
                                            <td>
                                                {item.phone?.label}
                                                <p className="text-danger font-bold">{item.phone?.error}</p>
                                            </td>
                                            <td>
                                                {item?.email?.label}
                                                <p className="text-danger font-bold">{item.email?.error}</p>
                                            </td>
                                            <td>{item?.role?.label}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    {fileData && (
                        <Button variant="secondary" onClick={handleCancelUpload}>
                            Upload Another File
                        </Button>
                    )}
                    <Button variant="primary" onClick={handleSubmit} disabled={isUploading || !fileData}>
                        {isUploading ? <Spinner animation="border" size="sm" /> : `Invite Bulk ${bulkCategory} `}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    teamMembers: state.teams.teamMembers,
});

export default connect(mapStateToProps)(BulkModal);
