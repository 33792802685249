import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { css } from 'twin.macro';
import { AdminTable } from '../../reusableStyles';
import { getVisits, setLabels, getCostInfo } from '../../../../_redux/actions/ActionEVV';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CreateAVisit from './CreateAVisit';
import LoadingSpiner from '../../../../_elements/LoadingSpiner';
import Pagination from '../../Pagination';
import SortedTableHeaderIcon from '../../SortedTableHeaderIcon';
import 'twin.macro';
import { toast } from 'react-toastify';

//costume hooks
import { useSortData } from '../../useSortData';
import {
    fetchWrapper,
    getChannelIdFromSettingsURL,
    numberWithCommas,
    roundOffToTwoDecimalDigits,
} from '../../../../_helpers';
import EvvSearchModalComponent from '../EvvSearchModal';
import { Button } from 'react-bootstrap';
import VisitRow from './VisitRow';
import { VscLoading } from 'react-icons/vsc';
import VisitModal from './VisitModal';
import { getClientRooms } from '../../../../_redux/actions/ActionMessage';
import LabelDetail from './LabelDetail';
import VisitFormsModal from './VisitFormsModal';
import DoNotBillModal from './DoNotBillModal';
import ReimbursementStatus from './ReimbursementStatus';

const UserVisit = ({
    labels,
    teams,
    clientRooms,
    getVisits,
    getCostInfo,
    visits,
    costInfo,
    setLabels,
    isLoading,
    totalVisits,
    getClientRooms,
    teamId,
    channelDetails,
    providerInfo,
}) => {
    const history = useHistory();
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const isUserEvv = !!useLocation().pathname.match(/user-evv/);
    const isUserPayroll = !!useLocation().pathname.match(/user-payroll/);
    const isPayroll = !!useLocation().pathname.match(/payroll/);
    const isTeamSettings = isUserEvv || isUserPayroll;
    const isChannelSettings = getChannelIdFromSettingsURL();
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    const [team, setTeam] = useState([]);
    const [isManual, setIsManual] = useState(false);
    const [verified, setVerified] = useState(false);
    const [visitLabelFilter, setVisitLabelFilter] = useState(null);
    const [userEmployee, setUserEmployee] = useState();
    const [client, setClient] = useState(null);
    const [provider, setProvider] = useState({});
    const [verifingVisitsIds, setVerifingVisitsIds] = useState([]);
    const [reimbursementStatus, setReimbursementStatus] = useState({});
    const [loadingProvider, setLoadingProvider] = useState(false);

    const setVerifyVisitsId = (visitId) => {
        if (verifingVisitsIds.includes(visitId)) {
            setVerifingVisitsIds(verifingVisitsIds.filter((id) => id !== visitId));
        } else {
            setVerifingVisitsIds([...verifingVisitsIds, visitId]);
        }
    };

    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [showVisit, setShowVisit] = useState();

    const handleUserEmployeeSelect = (selectedUser) => {
        setUserEmployee(selectedUser);
    };

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
    };

    const handleVerifiedChange = (event) => {
        setVerified(event.target.checked);
    };
    const handleVisitLabelFilterChange = (value) => {
        setVisitLabelFilter(value);
    };
    const handleManualChange = (event) => {
        setIsManual(event.target.checked);
    };

    const handleClientSelect = (selectedClient) => {
        setClient(selectedClient);
    };
    const { requestSort, sortConfig } = useSortData(getVisits, {
        key: 'ScheduleStartTime',
        direction: 'desc',
    });

    const handleSearch = useCallback(() => {
        let canFetch = true;
        if (isTeamSettings) {
            if (!provider?.value) {
                canFetch = false;
            }
        }
        if (canFetch) {
            const fetchOptions = {
                client: client?.id,
                employee: userEmployee?.id,
                provider: provider?.value,
                endDate: endDate,
                startDate: startDate,
                sortBy: `${sortConfig.key}:${sortConfig.direction}`,
                verified: verified,
                visitLabelFilter: visitLabelFilter,
                isManual: isManual,
                team: teamId,
                isTeamSettings,
                getReimbursements: isPayroll,
                reimbursementStatus,
            };
            getVisits(fetchOptions);
            isTeamSettings && !isPayroll && getCostInfo(fetchOptions);
        }
        if (isTeamSettings) {
            if (!isUserEvv) {
                history.push(`/user-payroll?page=1`);
            } else {
                history.push(`/user-evv?page=1`);
            }
        } else {
            if (!isUserEvv) {
                history.push(`/payroll?page=1`);
            } else {
                history.push(`/evv?page=1`);
            }
        }
    }, [
        isTeamSettings,
        provider?.value,
        getVisits,
        client?.id,
        userEmployee?.id,
        endDate,
        startDate,
        sortConfig.key,
        sortConfig.direction,
        verified,
        visitLabelFilter,
        isManual,
        teamId,
        history,
        getCostInfo,
        reimbursementStatus,
    ]);

    const handleClose = () => {
        setShowVisit();
    };
    const handleShow = (visit, type = 'data') => {
        setShowVisit({ visit, type });
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            handleSearch();
        }, 500);
        return () => clearTimeout(timer);
    }, [handleSearch, history]);

    const handleClearAll = () => {
        setUserEmployee(null);
        if (!isTeamSettings) {
            setProvider(null);
        }
        setClient(null);
        handleEndDateClear();
        handleStartDateClear();
        handleClearVerified();
        handleClearVisitLabelFilter();
        handleClearIsManual();
    };

    const visitAddResult = (isSuccess) => {
        if (isSuccess) {
            if (isTeamSettings) {
                history.push(`/user-evv?page=1`);
            } else {
                history.push(`/evv?page=1`);
            }
            getVisits({
                client: client?.id,
                employee: userEmployee?.id,
                provider: provider?.value,
                endDate: endDate,
                startDate: startDate,
                sortBy: `${sortConfig.key}:${sortConfig.direction}`,
                verified: verified,
                visitLabelFilter: visitLabelFilter,
                isManual: isManual,
                team: teamId,
                isTeamSettings,
                getReimbursements: isPayroll,
                reimbursementStatus,
            });
        }
    };

    const handleSort = (sortMethod) => {
        requestSort({
            client: client?.id,
            employee: userEmployee?.id,
            provider: provider?.value,
            endDate: endDate,
            startDate: startDate,
            searchText: '',
            key: sortMethod,
            shouldFetch: currentPage === 1,
            team: teamId,
            isTeamSettings,
        });

        if (currentPage === 1) {
            return null;
        }

        if (isTeamSettings) {
            return history.push(`/user-evv?page=1`);
        } else {
            return history.push(`/evv?page=1`);
        }
    };
    const handleEndDateChange = (date) => {
        if (!date) {
            setEndDate(null);
            return;
        }
        setEndDate(date.toISOString());
    };
    const handleStartDateChange = (date) => {
        if (!date) {
            setStartDate(null);
            return;
        }
        setStartDate(date.toISOString());
    };
    const handleStartDateClear = () => {
        setStartDate(null);
    };
    const handleEndDateClear = () => {
        setEndDate(null);
    };

    const onPageChanged = (data) => {
        let canFetch = true;
        if (isTeamSettings) {
            if (!provider?.value) {
                canFetch = false;
            }
        }
        if (canFetch) {
            getVisits({
                client: client?.id,
                employee: userEmployee?.id,
                provider: provider?.value,
                endDate: endDate,
                startDate: startDate,
                sortBy: `${sortConfig.key}:${sortConfig.direction}`,
                verified: verified,
                visitLabelFilter: visitLabelFilter,
                isManual: isManual,
                page: data.currentPage,
                team: teamId,
                isTeamSettings,
                getReimbursements: isPayroll,
                reimbursementStatus,
            });
        }
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            if (isTeamSettings) {
                // history.push(`/user-evv?page=${data.currentPage}`);
                if (!isUserEvv) {
                    history.push(`/user-payroll?page=${data.currentPage}`);
                } else {
                    history.push(`/user-evv?page=${data.currentPage}`);
                }
            } else {
                history.push(`/evv?page=${data.currentPage}`);
            }
        }
    };
    const [isDataSetLoading, setIsDataSetLoading] = useState(false);

    const handleGenrateExcelData = async (groupBy = 'employee') => {
        try {
            setIsDataSetLoading(true);
            await fetchWrapper.download(
                `evv/visit/report?limit=${totalVisits}${client?.id || isChannelSettings ? `&client=${client?.id || channelDetails?.client?.id}` : ''}${
                    userEmployee?.id ? `&employee=${userEmployee?.id}` : ''
                }${teamId ? `&team=${teamId}` : ''}
							${verified ? '&verified=true' : ''}${isManual ? '&manual=true' : ''}
							${provider?.value ? `&provider=${provider?.value}` : ''}${endDate ? `&serviceEndAt=${endDate}` : ''}${
                                startDate ? `&serviceCreatedAt=${startDate}` : ''
                            }${visitLabelFilter ? `&label=${visitLabelFilter}` : ''}&groupBy=${groupBy}&tzOffset=${new Date().getTimezoneOffset()}`,
                'report.xlsx'
            );
            setIsDataSetLoading(false);
        } catch (err) {
            setIsDataSetLoading(false);
        }
    };

    const handleClearIsManual = () => {
        setIsManual(false);
    };
    const handleClearVerified = () => {
        setVerified(false);
    };
    const handleClearVisitLabelFilter = () => {
        setVisitLabelFilter(null);
    };

    useEffect(() => {
        if (teamId) {
            getClientRooms(teamId);
        }
    }, [getClientRooms, teamId]);

    useEffect(() => {
        let team = teams.find((team) => team.id === teamId);
        setTeam(team);
    }, [teams, teamId]);

    useEffect(() => {
        const getLabels = async () => {
            const response = await fetchWrapper.get('label');
            let labels = response.labels.visits;
            setLabels(labels);
        };
        getLabels();
    }, [setLabels]);

    const fetchProviderData = useCallback(async () => {
        if (!isTeamSettings || !teamId) {
            return;
        }
        const team = (teams || []).find((team) => team.id === teamId);

        try {
            setLoadingProvider(true);
            const {
                results: [response],
            } = await fetchWrapper.get(`evv/provider?team=${teamId}`);

            setProvider({
                value: response?._id,
                label: response?.agencyName,
                providerId: response?._id,
                teamId: team?.id,
                agencyName: response?.agencyName,
                team: response?._id,
                state: response?.state,
                payroll: response?.payroll,
            });
        } catch (error) {
            if (error.name !== 'AbortError') {
                toast.error("Couldn't fetch provider data");
            }
        } finally {
            setLoadingProvider(false);
        }
    }, [teamId]);

    useEffect(() => {
        if (isTeamSettings && (team?._id || team?.id)) {
            fetchProviderData();
        }
    }, [team, isTeamSettings]);
    useEffect(() => {
        if (!client?.value && channelDetails?.id) {
            const clientObjName = `${channelDetails?.client?.firstname || '-'} ${channelDetails?.client?.lastname || '-'}`;
            const clientObjId = channelDetails?.client?.id || channelDetails?.client?._id;
            const clientObj = {
                label: clientObjName,
                name: clientObjName,
                value: clientObjId,
                id: clientObjId,
            };
            setClient(clientObj);
        }
    }, [channelDetails?.id]);

    return (
        <div>
            <div
                className="d-flex flex-row align-items-center mb-4"
                css={css`
                    justify-content: space-between;
                `}
            >
                <EvvSearchModalComponent
                    type="Visit"
                    isTeamSettings={isTeamSettings}
                    isChannelSettings={isChannelSettings}
                    client={client}
                    userEmployee={userEmployee}
                    provider={provider}
                    handleUserEmployeeSelect={handleUserEmployeeSelect}
                    handleProviderSelect={handleProviderSelect}
                    handleClearAll={handleClearAll}
                    handleClientSelect={handleClientSelect}
                    endDate={endDate}
                    startDate={startDate}
                    handleEndDateChange={handleEndDateChange}
                    handleStartDateChange={handleStartDateChange}
                    handleStartDateClear={handleStartDateClear}
                    handleEndDateClear={handleEndDateClear}
                    labels={labels}
                    verified={verified}
                    visitLabelFilter={visitLabelFilter}
                    isManual={isManual}
                    handleVerifiedChange={handleVerifiedChange}
                    handleVisitLabelFilterChange={handleVisitLabelFilterChange}
                    handleManualChange={handleManualChange}
                    handleClearIsManual={handleClearIsManual}
                    handleClearVerified={handleClearVerified}
                    handleClearVisitLabelFilter={handleClearVisitLabelFilter}
                    team={teamId}
                />
                {isPayroll && (
                    <ReimbursementStatus
                        reimbursementStatus={reimbursementStatus}
                        setReimbursementStatus={setReimbursementStatus}
                    />
                )}
                {!isPayroll && (
                    <Fragment>
                        <div tw="flex flex-col w-5/12  md:flex-row  justify-center items-center">
                            <div tw="bg-gray-200 rounded p-5 mx-1 w-2/3">
                                Service Amount:{' '}
                                <span className="d-flex">
                                    $ {numberWithCommas(roundOffToTwoDecimalDigits(costInfo?.serviceAmount))}
                                </span>
                            </div>
                            <div tw="bg-gray-200 rounded p-5 mx-1 w-2/3">
                                StaffAmount:
                                <span className="d-flex">
                                    $ {numberWithCommas(roundOffToTwoDecimalDigits(costInfo?.staffAmount))}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <CreateAVisit
                                provider={provider}
                                visitAddResult={visitAddResult}
                                isTeamSettings={isTeamSettings}
                                isChannelSettings={isChannelSettings}
                                client={client}
                                team={teamId}
                            />
                        </div>
                    </Fragment>
                )}
            </div>
            <div
                className="custom-card card-table d-flex flex-column"
                css={css`
                    min-height: 675px;
                `}
            >
                {Boolean(visits?.length) && !isPayroll && (
                    <div
                        className="w-100 d-flex justify-content-end p-2"
                        css={css`
                            gap: 6px;
                        `}
                    >
                        <Button onClick={() => handleGenrateExcelData('employee')} disabled={isDataSetLoading}>
                            {isDataSetLoading ? (
                                <span>
                                    <VscLoading className="spin" />
                                    Generating Report...
                                </span>
                            ) : (
                                'Generate Employee Report'
                            )}
                        </Button>
                        <Button onClick={() => handleGenrateExcelData('client')} disabled={isDataSetLoading}>
                            {isDataSetLoading ? (
                                <span>
                                    <VscLoading className="spin" />
                                    Generating Report...
                                </span>
                            ) : (
                                'Generate Client Report'
                            )}
                        </Button>
                    </div>
                )}

                <AdminTable className="w-100 text-dark-grey">
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Agency</th>
                            <th>Employee</th>
                            <th onClick={() => handleSort('ScheduleStartTime')}>
                                Start Date
                                <SortedTableHeaderIcon
                                    sortConfig={sortConfig}
                                    fieldName="ScheduleStartTime"
                                    key="ScheduleStartTime"
                                />
                            </th>
                            <th onClick={() => handleSort('ScheduleEndTime')}>
                                End Date
                                <SortedTableHeaderIcon
                                    sortConfig={sortConfig}
                                    fieldName="ScheduleEndTime"
                                    key="ScheduleEndTime"
                                />
                            </th>
                            {isPayroll ? (
                                <Fragment>
                                    <th>Distance (miles)</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <th>Total Time</th>
                                    <th>Billable Time</th>
                                    <th>Billable</th>
                                    <th>Status</th>
                                </Fragment>
                            )}
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody className="text-light-grey">
                        {isLoading || loadingProvider ? (
                            <td colSpan={10}>
                                <LoadingSpiner size={42} height={200} />
                            </td>
                        ) : visits && visits.length ? (
                            visits.map((visit) => {
                                return (
                                    <VisitRow
                                        key={visit.id}
                                        labels={labels}
                                        visit={visit}
                                        handleShow={handleShow}
                                        setVerifyVisitsId={setVerifyVisitsId}
                                        clientRooms={clientRooms}
                                        isTeamSettings={isTeamSettings}
                                        team={teamId}
                                        provider={provider}
                                    />
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={9}>
                                    <div
                                        className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                        css={css`
                                            margin-top: 100px;
                                            width: 100%;
                                        `}
                                    >
                                        No Visits/Shifts Found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </AdminTable>
                {!isPayroll && (
                    <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-center align-items-center">
                            {labels &&
                                Object.keys(labels).map((labelKey) => (
                                    <LabelDetail key={labelKey} label={labelKey} color={labels[labelKey]} />
                                ))}
                        </div>
                    </div>
                )}

                {showVisit?.type === 'data' && (
                    <VisitModal show={!!showVisit} handleClose={handleClose} visit={showVisit?.visit} />
                )}
                {showVisit?.type === 'forms' && (
                    <VisitFormsModal show={!!showVisit} handleClose={handleClose} visit={showVisit?.visit} />
                )}
                {showVisit?.type === 'donotbill' && (
                    <DoNotBillModal
                        show={!!showVisit}
                        handleClose={handleClose}
                        visit={showVisit?.visit}
                        provider={provider}
                    />
                )}

                {totalVisits > 0 && (
                    <div className="m-4">
                        <Pagination
                            pageNo={currentPage}
                            totalRecords={totalVisits}
                            pageLimit={10}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    labels: state.evv.labels,
    visits: state.evv.visits.visits,
    costInfo: state.evv.visits.costInfo,
    totalVisits: state.evv.visits.totalVisits,
    teams: state.teams.teams,
    clientRooms: state.rooms.clientRooms,
    organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, { getVisits, getCostInfo, setLabels, getClientRooms })(UserVisit);
