import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';
import { css } from 'twin.macro';
import dayjs from 'dayjs';
import { setPayallocationDraft } from '../../../../../../_redux';
import { VscLoading } from 'react-icons/vsc';

const ResumePeriodModal = ({
    payallocation,
    setPayallocationDraft,
    onDraftSelect,
    setDefaultCheckDate,
    setStartDate,
    setEndDate,
}) => {
    const [isBusy, setIsBusy] = useState(false);
    const [show, setShow] = useState(false);
    const [drafts, setDrafts] = useState(null);
    const [team, setTeam] = useState(null);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const getPeriodDraft = async () => {
        try {
            setIsBusy(true);
            if (!team) {
                return toast.error('Please select a team');
            }
            const response = await fetchWrapper.get(`drafts?team=${team}`);
            setDrafts(response || []);
        } catch (error) {
            if (error?.name === 'AbortError') return;
            toast.error(error?.message || 'Something went wrong while saving period');
        } finally {
            setIsBusy(false);
        }
    };

    const handleResume = (draft) => {
        try {
            setPayallocationDraft({ ...(draft?.data?.payallocation || {}) });
            const {
                checkDate,
                endDate,
                startDate,
                // paySchedule,
                // reportingPeriodID,
                // speed,
            } = ((draft?.data || {})?.payallocation || {})?.config;
            if (checkDate) {
                setDefaultCheckDate(checkDate);
            }
            if (startDate) {
                setStartDate(startDate);
            }
            if (endDate) {
                setEndDate(endDate);
            }
            onDraftSelect();
        } catch (error) {}
    };

    useEffect(() => {
        const teamId = ((payallocation || {})?.provider || {})?.teamId;
        if (teamId) {
            setTeam(teamId);
        }
    }, [payallocation]);

    useEffect(() => {
        if (team && show) {
            getPeriodDraft();
        }
    }, [show]);
    return (
        <Fragment>
            <div>
                <Button
                    disabled={isBusy || !team}
                    variant="secondary"
                    onClick={() => {
                        handleShow();
                    }}
                    css={css`
                        width: 212px;
                        margin-top: 8px;
                    `}
                >
                    Resume Last Period
                </Button>
            </div>

            <Modal show={show} onHide={() => handleClose()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Resume Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {drafts && drafts.length ? (
                        (drafts || []).map((draft) => (
                            <div className="d-flex flex-column border-2" key={draft?._id}>
                                <div>Created/Updated At: {dayjs(draft?.updatedAtt).format('MMMM D, YYYY h:mm A')}</div>

                                {/* <div>Created By: {(draft?.createdBy || {})?.name}</div> */}
                                <div>Updated By: {(draft?.updatedBy || {})?.name}</div>
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => handleResume(draft)}>Use This Draft</Button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <span>{!drafts ? <VscLoading className="spin" /> : 'Draft Not Found'}</span>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            padding: 12px;
                            width: 100%;
                        `}
                    >
                        <Button className="btn btn-secondary" disabled={isBusy} onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    payallocation: state.payallocation,
});
const mapDispatchToProps = {
    setPayallocationDraft,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumePeriodModal);
