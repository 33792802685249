import ID from '../../../_helpers/uuid';

export const misc = () => {
    return {
        defaultUserCertificateFormElements: [
            {
                data: [],
                id: ID.uuid(),
                key: 'Header',
                fieldName: 'Certificate Form',
                type: 'textbox',
                instruction: '#Note: Submit this form in staff channel.',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'DateTimePicker',
                fieldName: 'Course Taken Date',
                type: 'datetime',
                instruction: '',
                config: {
                    specialityKey: 'courseTakenAt',
                },
            },
            {
                id: ID.uuid(),
                key: 'File',
                fieldName: 'Training Certificate',
                type: 'file',
                config: {
                    specialityKey: 'certificateUrl',
                },
                instruction: 'Supported Formats: Pdf and Image',
            },
            {
                required: true,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Course Name',
                type: 'textbox',
                instruction: '',
                config: {
                    specialityKey: 'courseName',
                },
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Course URL',
                type: 'textbox',
                instruction: '',
                config: {
                    specialityKey: 'courseUrl',
                },
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Trainer',
                type: 'textbox',
                instruction: '',
                config: {
                    specialityKey: 'trainer',
                },
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Training Objectives',
                type: 'textbox',
                instruction: '',
                config: {
                    specialityKey: 'trainingObjectives',
                },
            },
            {
                required: false,
                data: [],
                id: ID.uuid(),
                key: 'InputField',
                fieldName: 'Training Hours',
                type: 'textbox',
                instruction: '',
                config: {
                    specialityKey: 'trainingHours',
                },
            },
        ],
    };
};
