import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Provider } from 'react-redux';
import { ConfigureStore } from './_redux/configureStore';
import { swDev } from './swDev';
import SimpleReactLightbox from 'simple-react-lightbox';
import WebSocketProvider from './_redux/actions/ActionSocket';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import StoreProvider from './_context/contextStore';
import ErrorBoundary from './Layouts/ErrorBoundary';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <Provider store={ConfigureStore()}>
        <SimpleReactLightbox>
            <WebSocketProvider>
                <StoreProvider>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </StoreProvider>
            </WebSocketProvider>
        </SimpleReactLightbox>
    </Provider>,
    document.getElementById('root')
);

// Check for service worker
if ('serviceWorker' in navigator) {
    // Call serviceWorker Dev function to register service worker
    swDev().catch((err) => console.error(err));
}
