import React, { Fragment, useState } from 'react';
import { FloatingContainer, ModalFormContainer } from '../styles';
import { ExpandableButton } from '@/_elements/Button';
import { FaPlus } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import FormPicker from '../../FormPicker';
import { getGenericFieldData } from '../utils';
import { SMART_PDF_ALLOWED_ELEMENT_KEYS } from '../constants';

const AddNewField = ({ currentPage, addFormDataElement }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleOnEdit = ({ field }) => {
        addFormDataElement(field);
        handleClose();
    };
    return (
        <Fragment>
            <FloatingContainer top={'16px'} onClick={() => handleShow()}>
                <ExpandableButton
                    icon={<FaPlus size={20} />}
                    text="Add New Form Element"
                    backgroundColor="#fff"
                    borderColor="#eee"
                    textColor="#50aeb0"
                />
            </FloatingContainer>
            <Modal show={show} onHide={() => handleClose()} aria-labelledby="contained-modal-title-vcenter" centered>
                <ModalFormContainer>
                    <Modal.Header closeButton>
                        <Modal.Title className="w-100 text-center">Pick Form Element</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormPicker
                            openFormBuilder
                            smartpdfAllowedElements={SMART_PDF_ALLOWED_ELEMENT_KEYS}
                            handleOnEdit={handleOnEdit}
                            configData={{
                                data: getGenericFieldData(currentPage),
                                page: currentPage,
                            }}
                        />
                    </Modal.Body>
                </ModalFormContainer>
            </Modal>
        </Fragment>
    );
};

export default AddNewField;
