import { css } from 'twin.macro';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';

//redux
import { connect } from 'react-redux';
import { deleteUser, setUser } from '../../_redux/actions/ActionUser';

// bootstrap
import { fetchWrapper } from '../../_helpers';

//images
import { loginUser, startLoading, stopLoading } from '../../_redux/actions/ActionAuthentication';
import { getUserData } from '../../_redux/actions/ActionUser';

import { popup, profileContainer } from './styles/ProfileStyles';
import 'react-phone-input-2/lib/style.css';
import LoadingSpiner from '../../_elements/LoadingSpiner';
import { UserCertificateContainer } from '../Features/Teams/TeamSettings/TeamCourses/Common/CertificateComponents';
// import { hoverPopover } from '../Admin/reusableStyles';

function ProfileCertificates({ user, show }) {
    const [isBusy, setIsBusy] = useState(false);
    const [certificates, setCertificates] = useState([]);
    async function loadData() {
        try {
            if (!user?._id) {
                return;
            }

            let query = `?user=${user?._id}`;
            setIsBusy(true);
            const response = await fetchWrapper.get(`user-certificates${query}`);
            setCertificates(response);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
            setIsBusy(false);
        }
    }

    useEffect(() => {
        if (show) {
            loadData();
        }
    }, [user, show]);

    if (isBusy) {
        return <LoadingSpiner size={42} />;
    }

    return (
        <div>
            {certificates?.length > 0 ? (
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                    `}
                >
                    <div className="mt-4 ml-2">
                        <span
                            className="mt-4 ml-2"
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            Active Certificates:
                        </span>
                    </div>
                    {certificates
                        .filter((_c) => _c?.status !== 'archive')
                        .map((certificate, index) => (
                            <UserCertificateContainer certificate={certificate} loadData={loadData} showToggle={true} />
                        ))}
                    <hr />
                    <div className="mt-4 ml-2">
                        <span
                            className="mt-4 ml-2"
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            Archived Certificates:
                        </span>
                    </div>

                    {certificates
                        .filter((_c) => _c?.status === 'archive')
                        .map((certificate, index) => (
                            <UserCertificateContainer certificate={certificate} loadData={loadData} showToggle={true} />
                        ))}
                </div>
            ) : (
                <div className="custom-card">No Certificates</div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    ui: state.ui,
});

export default connect(mapStateToProps, { loginUser, deleteUser, setUser, startLoading, stopLoading, getUserData })(
    ProfileCertificates
);
