import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { css } from 'twin.macro';
import dayjs from 'dayjs';

import { AdminTable } from '../../reusableStyles';

import Loader from '../../containers/Loader';
import Pagination from '../../Pagination';

import { fetchWrapper, getTeamIdFromURL, toastWrapper } from '../../../../_helpers';
import SortedTableHeaderIcon from '../../SortedTableHeaderIcon';
import TeamSelectFilter from '../../TeamSelectFilter';
import ChannelSelectFilter from '../../ChannelSelectFilter';

import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import MessageModal from './MessageModal';
import { GoToChannel } from '../../../../_elements/Links';

const Received = () => {
    const [data, setData] = useState({ results: [], page: 1, totalResults: 0, limit: 10 });

    const [isLoading, setIsLoading] = useState(false);

    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    const teamId = getTeamIdFromURL();
    const history = useHistory();

    const currentDate = new Date();

    const [query, setQuery] = useState({
        team: { label: 'Select a Team', value: '' },
        room: { label: 'Select a Room', value: '' },
        user: { label: 'Type to search user...', value: '' },
        from: new Date(currentDate.getFullYear(), 0, 1).toISOString(),
        to: null,
    });

    const getReceivedMessages = async (data) => {
        try {
            setIsLoading(true);
            const response = await fetchWrapper.get(
                `sms/incomings?limit=10&page=${Math.max(1, data.page || 1)}${
                    teamId || data.team?.value ? `&team=${teamId || data.team.value}` : ''
                }${data.room?.value ? `&room=${data.room.value}` : ''}${
                    data.user?.value ? `&user=${data.user.value}` : ''
                }${data.from ? `&from=${data.from}` : ''}${`&to=${data.to || new Date().toISOString()}`}&sortBy=${
                    data.sortBy || 'createdAt:desc'
                }`
            );
            setData(response);
        } catch (err) {
            if (err?.name === 'AbortError') return;
            toastWrapper.error(err.message || err);
        } finally {
            setIsLoading(false);
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

    const handleSort = (key) => {
        if (sortConfig.key === key) {
            return setSortConfig((prev) => ({ ...prev, direction: prev.direction === 'asc' ? 'desc' : 'asc' }));
        }
        setSortConfig({ key, direction: 'desc' });
    };

    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            history.push(`/sms?page=${data.currentPage}`);
            getReceivedMessages({
                ...query,
                page: data.currentPage,
                sortBy: `${sortConfig.key}:${sortConfig.direction}`,
            });
        }
    };

    useEffect(() => {
        if (!isLoading) {
            getReceivedMessages({ ...query, page: currentPage, sortBy: `${sortConfig.key}:${sortConfig.direction}` });
        }
    }, [sortConfig, query]);

    return (
        <div>
            {teamId ? null : (
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        padding: 12px;
                        align-items: center;
                    `}
                >
                    <div
                        css={css`
                            background: white;
                            padding: 6px 12px;
                            border-radius: 12px;
                            display: flex;
                            gap: 12px;
                            align-items: center;
                        `}
                    >
                        <span className="text-primary" style={{ fontWeight: 'bold' }}>
                            Total Received Data:
                        </span>
                        <span className="badge badge-secondary px-4 py-2" style={{ fontSize: '14px' }}>
                            Total Messages: {data ? data?.totalResults : 0}
                        </span>
                    </div>
                    <div
                        className="d-flex flex-column mb-4"
                        css={css`
                            gap: 12px;
                        `}
                    >
                        <div style={{ position: 'relative', display: 'flex', gap: '12px', alignItems: 'center' }}>
                            <span>From </span>
                            <DateTimePicker
                                css={css`
                                    display: inline-block;
                                    width: 50%;
                                    background: ${isLoading ? 'transparent' : 'white'};
                                `}
                                onChange={(value) =>
                                    setQuery((prev) => ({ ...prev, from: value ? value.toISOString() : '' }))
                                }
                                maxDate={query.to ? new Date(query.to) : new Date()}
                                value={query.from ? new Date(query.from) : null}
                                format="MM-dd-y hh:mm a"
                                calendarType={'US'}
                            />
                            <span>To </span>
                            <DateTimePicker
                                css={css`
                                    display: inline-block;
                                    width: 50%;
                                    background: ${isLoading ? 'transparent' : 'white'};
                                `}
                                onChange={(value) =>
                                    setQuery((prev) => ({ ...prev, to: value ? value.toISOString() : '' }))
                                }
                                minDate={query.from ? new Date(query.from) : null}
                                maxDate={new Date()}
                                value={query.to ? new Date(query.to) : null}
                                format="MM-dd-y hh:mm a"
                                calendarType={'US'}
                            />
                        </div>
                        <div
                            className="d-flex flex-row justify-content-center"
                            css={css`
                                gap: 12px;
                            `}
                        >
                            <div
                                className="d-flex"
                                css={css`
                                    gap: 12px;
                                `}
                            >
                                <TeamSelectFilter
                                    selectedTeamOption={query.team}
                                    setSelectedTeamOption={(option) =>
                                        setQuery((prev) => ({
                                            ...prev,
                                            team: option,
                                            room: option?.value ? prev.room : { label: 'Select a Room' },
                                        }))
                                    }
                                    disabled={isLoading}
                                />
                                <ChannelSelectFilter
                                    teamIds={[query.team?.value]}
                                    selectedOption={query.room}
                                    onRoomSelect={(option) => setQuery((prev) => ({ ...prev, room: option }))}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className="custom-card card-table d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <AdminTable className="w-100 text-dark-grey">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th className="text-center">Team</th>
                            <th className="text-center">Room</th>
                            <th className="text-center">Phone Number (From - To)</th>
                            <th
                                className="text-center"
                                onClick={() => handleSort('characterLength')}
                                style={{ cursor: 'pointer' }}
                            >
                                Characters
                                <SortedTableHeaderIcon
                                    sortConfig={sortConfig}
                                    fieldName="characterLength"
                                    key="characterLength"
                                />
                            </th>

                            <th
                                className="text-center"
                                onClick={() => handleSort('createdAt')}
                                style={{ cursor: 'pointer' }}
                            >
                                Date
                                <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="createdAt" key="createdAt" />
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-light-grey">
                        {isLoading ? (
                            <tr>
                                <td>
                                    <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                                        <Loader size="sm" top={400} />
                                    </div>
                                </td>
                            </tr>
                        ) : data?.results?.length ? (
                            data.results.map((sms, index) => {
                                return (
                                    <tr
                                        key={sms.id || sms?._id}
                                        css={css`
                                            ${sms?.memo ? 'background-color: #eb3939b9;' : ''}
                                        `}
                                    >
                                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                                        <td className="text-center">{sms.team?.name || ''}</td>
                                        <td>
                                            <GoToChannel channel={sms?.room || {}} />
                                        </td>
                                        <td className="text-center">
                                            ({sms.fromPhoneNumber}) - ({sms.toPhoneNumber})
                                        </td>
                                        <td className="text-center">{sms.characterLength}</td>
                                        <td className="text-center ">
                                            {dayjs(sms?.createdAt).format('MMM DD, YYYY hh:mm A')}
                                        </td>
                                        <td>
                                            <MessageModal sms={sms} />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={9}>
                                    <div
                                        className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                        css={css`
                                            margin-top: 100px;
                                            width: 100%;
                                        `}
                                    >
                                        No Received SMS Found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </AdminTable>

                {data.totalResults ? (
                    <div className="m-4">
                        <Pagination
                            pageNo={currentPage}
                            totalRecords={data.totalResults}
                            pageLimit={10}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Received);
