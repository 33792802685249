import { css } from 'twin.macro';
import React, { useState, useEffect, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import LoadingSpiner from '../../../_elements/LoadingSpiner';
import { fetchWrapper } from '../../../_helpers';

const ProviderChannelSelector = ({ type, selectChannel, providerTeamId, forVisit, isDisabled, channel }) => {
    const [selectedChannel, setSelectedChannel] = useState();
    const [loading, setLoading] = useState(false);
    const [channelList, setChannelList] = useState(null);

    useEffect(() => {
        const fetchChannelList = async () => {
            setLoading(true);
            let response = await fetchWrapper.get(`/chatrooms?teamId=${providerTeamId}&limit=1000`);
            let results = response.results;
            let options;
            if (forVisit) {
                options = results
                    .filter((channel) => channel.client)
                    .map((channel) => ({
                        label: channel.title,
                        value: channel.id,
                    }));
            } else {
                options = results.map((channel) => ({
                    label: channel.title,
                    value: channel.id,
                }));
                options = [
                    ...options,
                    {
                        label: 'None',
                        value: null,
                    },
                ];
            }

            setLoading(false);
            if (channel) {
                setSelectedChannel(options.find((option) => option.value === channel));
            }
            return setChannelList(options);
        };
        if (providerTeamId) {
            fetchChannelList();
        }
    }, [providerTeamId, forVisit]);

    const handleSelect = (selected) => {
        selectChannel(selected.value);
        setSelectedChannel(selected);
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    {type} Channel{' '}
                    {forVisit ? null : (
                        <i className="lead">
                            <small
                                css={css`
                                    color: gray;
                                `}
                            >
                                (if {type} channel already present)
                            </small>
                        </i>
                    )}
                </Form.Label>

                {providerTeamId ? (
                    <Fragment>
                        {loading ? (
                            <LoadingSpiner />
                        ) : (
                            <Fragment>
                                {channelList ? (
                                    <Select
                                        name="provider"
                                        options={channelList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={handleSelect}
                                        value={selectedChannel}
                                        isDisabled={isDisabled || !!channel}
                                    />
                                ) : null}
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <div>Please Select Provider</div>
                )}
            </Form.Group>
        </div>
    );
};
export default ProviderChannelSelector;
