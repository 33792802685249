import ID from '../../../../_helpers/uuid';
import { isEmployeeOnboarded } from './PayrollUtils';
export function getRandomShiftId() {
    return `NEW_SHIFT_${Math.round(Math.random() * 1000)}`;
}
export const prepareAndFetchCheckData2 = async (employee, payallocationConfig, workLocations = []) => {
    try {
        console.log('payallocationConfig', employee);
        const employeeOnboarded = isEmployeeOnboarded(employee);
        if (employeeOnboarded) {
            return null;
        } else {
            return {
                // ...employee,
                employeeID: employee?.employeeID || employee?.employee?.payroll?.metadata?.employeeID,
                reportingPeriodID: payallocationConfig?.reportingPeriodID,
                check_date: payallocationConfig?.checkDate,
                approval_required: payallocationConfig?.approvalRequired,
                disbursement: {
                    method: 'direct_deposit',
                },
                // disbursement: {
                // Need to send this via the globalObject
                //     method: 'direct_deposit',
                // },
                flsa_ot_recommendation: payallocationConfig?.defaultFLSAOvertime,
                speed: payallocationConfig?.speed,
                shifts: (employee?.shifts || []).map((shift) => ({
                    shiftID: shift.shiftID || getRandomShiftId(),
                    time: shift?.end,
                    type: shift?.type,
                    hours: shift?.totalHours?.toFixed(2) || 0,
                    amount: shift?.amount?.toFixed(2) || 0,
                    wage: shift?.rate,
                    custom_name: `${shift?.visitId || ''}`,
                    reimbursementcustom_name: `${shift?.visitId || ''}`,
                    workLocationID:
                        shift?.type === 'pto'
                            ? null
                            : (workLocations || []).find(
                                  (workLocation) => workLocation?.work_site_id === employee?.client?.identifier
                              )?.workLocationID,
                    metadata: shift?.metadata || {},
                })),
            };
        }
    } catch (error) {
        return null;
    }
};

export const prepareCheckDataFromConfig = async (employee, payallocation) => {
    try {
        if (!employee || !employee?.employeeID) {
            return null;
        }

        const payallocationConfig = payallocation?.config || {};
        const disbursementMethod = payallocation?.employees[employee.employeeID]?.disbursementMethod;
        return {
            employeeID: employee?.employeeID || employee?.employee?.payroll?.metadata?.employeeID,
            reportingPeriodID: payallocationConfig?.reportingPeriodID,
            check_date: payallocationConfig?.checkDate,
            disbursement: {
                method: disbursementMethod || 'direct_deposit',
            },
            flsa_ot_recommendation: true,
            approval_required: false,
            // approval_required: payallocationConfig?.approvalRequired,
            // flsa_ot_recommendation: payallocationConfig?.defaultFLSAOvertime,
            speed: payallocationConfig?.speed,
            shifts: employee?.shifts || [],
        };
    } catch (error) {
        console.log('error', error);
        return null;
    }
};

export function getCombinedShifts({ payallocation, employeeID, shifts }) {
    try {
        const earningShifts = (payallocation?.employeesEarnings[employeeID] || {})?.shifts || [];
        const allShifts = [...(shifts || []), ...earningShifts];
        return allShifts;
    } catch (error) {
        return [];
    }
}

export function getEmployeeName(employeeRowDetails) {
    const previewName = [
        employeeRowDetails?.first_name,
        employeeRowDetails?.middle_initial,
        employeeRowDetails?.last_name,
    ]
        .filter(Boolean)
        .join(' ');

    if (previewName) {
        return previewName;
    }

    return employeeRowDetails?.employee?._id ? (
        employeeRowDetails?.employee?.user?.name
    ) : (
        <span className="text-danger">Employee deleted</span>
    );
}

export const getUniqueShifts = (shifts) => {
    if (!Array.isArray(shifts)) return [];

    return Array.from(new Map(shifts.map((shift) => [shift.metadata?.uuid, shift])).values());
};

export const getUniqueShiftUUID = () => `${ID.uuid()}_${Date.now()}`;

export const getAllFinalShiftsOfEmployee = ({ payallocation, employeeID }) => {
    try {
        if (!employeeID) {
            return [];
        }
        const autoShifts =
            getUniqueShifts(payallocation?.employees || []).find((emp) => emp?.employeeID === employeeID)?.shifts || [];
        const earningShifts = getUniqueShifts(payallocation?.employeesEarnings[employeeID] || {})?.shifts || [];
        return getUniqueShifts([...autoShifts, ...earningShifts]);
    } catch {
        return [];
    }
};
