import { useState, Fragment, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { BootstrapToolTip } from '../../../../../../_elements/ToolTip';
import { ActionStyles } from '../../../../reusableStyles';
import { BsPencil } from 'react-icons/bs';
import { connect } from 'react-redux';
import { updatePayallocationEmployee } from '../../../../../../_redux';
import { VscLoading } from 'react-icons/vsc';
const disbursementLabels = {
    prepaid: 'Prepaid',
    direct_deposit: 'Direct Deposit',
    download_check: 'Download Check',
};
function DisbursementModal({ employeeID, isBusy, disbursementMethod, updatePayallocationEmployee }) {
    const [show, setShow] = useState(false);
    const [_disbursementMethod, setDisbursementMethod] = useState(disbursementMethod || '');
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };

    const handleSave = () => {
        updatePayallocationEmployee({ employeeID, disbursementMethod: _disbursementMethod });
        handleClose();
    };

    useEffect(() => {
        setDisbursementMethod(disbursementMethod || '');
    }, [show]);

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <div className="capitalize">{disbursementLabels[disbursementMethod] || ''}</div>
                {isBusy ? (
                    <VscLoading className="spin" />
                ) : (
                    <ActionStyles>
                        <BootstrapToolTip text="Edit Disbursement Method">
                            <BsPencil className="icon edit" onClick={handleShow} />
                        </BootstrapToolTip>
                    </ActionStyles>
                )}
            </div>

            <Modal size="lg" centered show={show} onHide={handleClose} backdrop="static">
                <Modal.Header>Disbursement Method</Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control
                            as="select"
                            name="disbursement-method"
                            disabled={isBusy}
                            value={_disbursementMethod}
                            onChange={(e) => {
                                setDisbursementMethod(e.target.value);
                            }}
                        >
                            <option value="prepaid">Prepaid</option>
                            <option value="direct_deposit">Direct Deposit</option>
                            <option value="download_check">Download Check</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
const mapStateToProps = (state) => ({
    payallocation: state.payallocation,
});
const mapDispatchToProps = {
    updatePayallocationEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(DisbursementModal);
