import { OPTION_COORDINATES, GENERIC_FIELD_DATA, DEFAULT_FONT_SIZE, DEFAULT_LINE_HEIGHT_FACTOR } from './constants';

export const getResizeCursor = (position) => {
    switch (position) {
        case 'top-left':
            return 'nw-resize';
        case 'top-right':
            return 'ne-resize';
        case 'bottom-left':
            return 'sw-resize';
        case 'bottom-right':
            return 'se-resize';
        case 'top-center':
            return 'ns-resize';
        case 'bottom-center':
            return 'ns-resize';
        case 'left-center':
            return 'w-resize';
        case 'right-center':
            return 'w-resize';
        default:
            return 'auto';
    }
};

export const getOptionConfig = (index = 0, page = 1) => {
    try {
        const _optionCoordinates = JSON.parse(JSON.stringify(OPTION_COORDINATES));
        const _option = _optionCoordinates[index % OPTION_COORDINATES.length];
        _option.config.templateConfig.page = page || 1;
        _option.config.rectConfig.page = page || 1;
        return _option;
    } catch (error) {
        console.log('getOptionConfig', error);
        return {};
    }
};

export const getGenericFieldData = (page) => {
    const temp = { ...GENERIC_FIELD_DATA };
    temp.generic.config.templateConfig.page = page || 1;
    temp.checkboxOrRadio.config.templateConfig.page = page || 1;
    temp.generic.config.rectConfig.page = page || 1;
    temp.checkboxOrRadio.config.rectConfig.page = page || 1;
    return temp;
};

export const filterAllowedFields = (formElements) => {
    const allowedFields = [
        'id',
        '_id',
        'type',
        'fieldName',
        'key',
        'instruction',
        'required',
        'autofill',
        'defaultValue',
        'data',
        'config',
        'options',
    ];
    return formElements.map((element) => {
        return Object.fromEntries(Object.entries(element).filter(([key]) => allowedFields.includes(key)));
    });
};

export const convertToRelativeCoordinates = (rect, canvasRef, currentPage, pdfDimensions) => {
    if (!canvasRef.current || !pdfDimensions.width || !pdfDimensions.height) return null;

    const canvas = canvasRef.current;
    const { clientWidth: canvasWidth, clientHeight: canvasHeight } = canvas;

    let x = rect.x;
    let y = rect.y;
    let width = rect.width > canvasWidth ? canvasWidth : rect.width;
    let height = rect.height > canvasHeight ? canvasHeight : rect.height;

    if (width < 0) {
        x += width;
        width = Math.abs(width);
    }
    if (height < 0) {
        y += height;
        height = Math.abs(height);
    }

    return {
        x: x / canvasWidth,
        y: y / canvasHeight,
        width: width / canvasWidth,
        height: height / canvasHeight,
        page: currentPage,
    };
};

export const updateRectResizing = (index, direction, newPoint, canvasDimensions, callback) => {
    callback((prev) => {
        const updated = [...prev];
        const rect = updated[index];
        const adjustSquare = () => {
            // Ensure width equals height
            if (rect.width > rect.height) {
                rect.height = rect.width;
            } else {
                rect.width = rect.height;
            }
        };
        switch (direction) {
            case 'top-left':
                rect.width += rect.x - newPoint.x;
                rect.height += rect.y - newPoint.y;
                rect.x = newPoint.x;
                rect.y = newPoint.y;
                break;
            case 'top-right':
                rect.width = newPoint.x - rect.x;
                rect.height += rect.y - newPoint.y;
                rect.y = newPoint.y;
                break;
            case 'bottom-left':
                rect.width += rect.x - newPoint.x;
                rect.x = newPoint.x;
                rect.height = newPoint.y - rect.y;
                break;
            case 'bottom-right':
                rect.width = newPoint.x - rect.x;
                rect.height = newPoint.y - rect.y;
                break;
            case 'top-center':
                rect.height += rect.y - newPoint.y;
                rect.y = newPoint.y;
                break;
            case 'right-center':
                rect.width = newPoint.x - rect.x;
                break;
            case 'bottom-center':
                rect.height = newPoint.y - rect.y;
                break;
            case 'left-center':
                rect.width += rect.x - newPoint.x;
                rect.x = newPoint.x;
                break;
            default:
                break;
        }
        if (rect.x < 0) rect.x = 0;
        if (rect.y < 0) rect.y = 0;
        if (rect.x + rect.width > canvasDimensions.width) {
            rect.width = canvasDimensions.width - rect.x;
        }
        if (rect.y + rect.height > canvasDimensions.height) {
            rect.height = canvasDimensions.height - rect.y;
        }
        const isOption = rect?.config?.parentFormElement;
        const allowedSquare = ['Checkbox', 'Radio'];
        if (isOption && allowedSquare.includes(isOption?.key)) {
            adjustSquare();
        }

        return updated;
    });
};

export const updateRectDragging = (e, canvasRef, draggingIdx, tempRects, callback) => {
    e.stopPropagation();
    if (!canvasRef.current || draggingIdx === null) return;
    const isDraggingFound = tempRects.find((r) => r.isDragging);
    if (!isDraggingFound) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const currentX = e.clientX - rect.left;
    const currentY = e.clientY - rect.top;

    callback((prev) =>
        prev.map((r, i) => {
            if (i === draggingIdx) {
                const newX = currentX - r.offsetX;
                const newY = currentY - r.offsetY;

                // Prevent dragging out of bounds
                const canvasWidth = canvasRef.current.clientWidth;
                const canvasHeight = canvasRef.current.clientHeight;
                const _r = {
                    ...r,

                    width: r.width > canvasWidth ? canvasWidth : r.width,
                    height: r.height > canvasHeight ? canvasHeight : r.height,
                };

                let x = Math.min(Math.max(0, newX), canvasWidth - _r.width);
                let y = Math.min(Math.max(0, newY), canvasHeight - _r.height);
                if (x < 0) {
                    x = 0;
                }
                if (y < 0) {
                    y = 0;
                }
                if (isNaN(x) || isNaN(y) || isNaN(_r.width) || isNaN(_r.height)) {
                    return { ...r, isDragging: false };
                }

                return {
                    ..._r,
                    x,
                    y,
                };
            }
            return { ...r, isDragging: false };
        })
    );
};

export const generateMaxLength = ({
    width,
    height,
    fontSize = DEFAULT_FONT_SIZE,
    lineHeightFactor = DEFAULT_LINE_HEIGHT_FACTOR,
}) => {
    const PDF_LIB_EXTRA_SPACING = 6;
    const PDF_LIB_EXTRA_PADDING = 4;
    const HEIGHT_WITDH_RATIO = 1.1;
    const heightWithoutPadding = height - 2 * PDF_LIB_EXTRA_PADDING;

    const lineHeight = fontSize * lineHeightFactor + PDF_LIB_EXTRA_SPACING;
    const maxPossibleLines = Math.floor(heightWithoutPadding / lineHeight);
    const maxLines = Math.max(1, maxPossibleLines);

    const charWidth = fontSize * HEIGHT_WITDH_RATIO;
    const charsPerLine = Math.floor(width / charWidth) - 2;

    return {
        maxLength: maxLines * charsPerLine,
        // maxLines,
    };
};

export const getNonOverlappingCoordinates = (existingOptions = []) => {
    const lastOption = JSON.parse(JSON.stringify(existingOptions[existingOptions.length - 1].config));

    const rectXOffset = 1;
    // calculated by dividing the difference between templateConfig and rectConfig
    const templateXOffset = 0.0008952551477171;
    const ratio = templateXOffset / rectXOffset;
    const newRectXOffset = 5;
    const newTemplateXOffset = newRectXOffset * ratio;

    return {
        templateConfig: {
            ...lastOption.templateConfig,
            x: lastOption.templateConfig?.x + newTemplateXOffset,
        },
        rectConfig: {
            ...lastOption.rectConfig,
            x: lastOption.rectConfig?.x + newRectXOffset,
        },
    };
};
