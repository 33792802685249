import { css } from 'twin.macro';
import { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Linkify from 'react-linkify';
import { marginColor } from './Checkbox';
import { validateRateFormat } from '../../../../_helpers';
import { ElementStyled } from './styles/ElementStyles';

const InputFieldCSS = css`
    width: 100%;
    .form-group {
        width: 100%;
    }
    .form-label {
        margin-bottom: 3%;
    }
    .form-input-textarea {
        min-height: 60px;
        max-height: 400px;
        overflow-y: auto;
    }
`;

const InputField = ({ data, isPreview, errors, error, elementRef, defaultValue, isDisabled, currUser = {} }) => {
    const [rateError, setRateError] = useState(null);
    const validationLength = data.validation?.maxLength?.value;

    if (data.validation?.pattern?.value) {
        data.validation.pattern.value = new RegExp(data.validation?.pattern.value);
    }
    const textareaRef = useRef(null);

    const resizeTextarea = (textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    };

    useEffect(() => {
        if (textareaRef.current) {
            resizeTextarea(textareaRef.current);
        }
    }, [defaultValue]);

    const getDefaultValue = () => {
        try {
            const isAutofieldEnabled = data?.config?.autofillField && !isPreview;
            if (isAutofieldEnabled && !defaultValue && !data.value) {
                return `${currUser[data?.config?.autofillField] || ''}`.trim();
            }
            return data?.value || defaultValue || '';
        } catch (error) {
            return '';
        }
    };

    return (
        <ElementStyled className={data?.modified && data?.draftMode ? 'modified' : ''}>
            <div css={InputFieldCSS}>
                <Form.Group controlId={data.name || data._id}>
                    <Form.Label
                        // id="questionField"
                        css={css`
                            margin-bottom: 0 !important;
                        `}
                    >
                        {data.fieldName}
                        <span css={marginColor}>{(data.hasOwnProperty('required') ? data.required : true) && '*'}</span>
                    </Form.Label>
                    <Form.Control
                        as={data.as || 'textarea'}
                        row="3"
                        name={!isPreview ? data.name || `InputField-${data._id}` : 'InputField'}
                        ref={
                            (elementRef &&
                                elementRef({
                                    required: data.hasOwnProperty('required') ? data.required : true,
                                    ...(data.validation || {}),
                                })) ||
                            textareaRef
                        }
                        onInput={(e) => resizeTextarea(e.target)}
                        onBlur={(e) => {
                            if (data.isRate) {
                                let value = e.target.value;
                                if (validateRateFormat(value)) {
                                    setRateError(null);
                                } else {
                                    setRateError(
                                        'Not valid rate! Please enter only 2 digits after the decimal, eg: 12.32'
                                    );
                                }
                            }
                        }}
                        placeholder={data.placeholder}
                        maxLength={data?.maxLength || data?.options?.maxLength || validationLength}
                        disabled={isPreview || isDisabled || data.isDisabled}
                        defaultValue={getDefaultValue()}
                        className="form-input-textarea"
                    />
                    <div>
                        {data?.options?.maxLength && (
                            <span className="badge badge-warning mr-2">
                                Maximum characters allowed : {data?.options?.maxLength}
                            </span>
                        )}
                    </div>
                    {data.instruction && (
                        <Form.Text
                            className="small text-muted warpIt preStyles"
                            css={css`
                                margin-bottom: 0 !important;
                            `}
                        >
                            <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}
                            >
                                {data.instruction}
                            </Linkify>
                        </Form.Text>
                    )}
                    <Form.Text className="text-danger">
                        {error &&
                            (error.type === 'required' ? error.message || 'This field is required' : error.message)}
                    </Form.Text>
                    <Form.Text className="text-danger">{rateError && rateError}</Form.Text>
                    <Form.Text className="text-danger">
                        {errors && data && errors[data.name || `InputField-${data._id}`]?.message}
                    </Form.Text>
                </Form.Group>
            </div>
        </ElementStyled>
    );
};

InputField.propTypes = {};

export default InputField;
