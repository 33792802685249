import React, { Fragment } from 'react';
import { css } from 'twin.macro';

// icons

// styles
import SortedTableHeaderIcon from '../../SortedTableHeaderIcon';
// import DeleteTeam from './DeleteTeam';
// import EditTeam from './EditTeam';
import { ActionStyles, AdminTable } from '../../reusableStyles';
import AdminTablesImg from '../../reusableComponents/AdminTablesImg';
import LoadingSpiner from '../../../../_elements/LoadingSpiner';
import { connect } from 'react-redux';

import { getAllUser } from '../../../../_redux/actions/ActionUser';
import { ArchiveAClient, UnarchiveAClient } from '../../../../_redux/actions/ActionEVV';

import EditClient from './EditClient';
import ViewClient from './ViewClient';

// assets
import Swal from 'sweetalert2';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import ChangeClientChannel from './ChangeClientChannel';
import { ToolTip } from '../../../../_elements/ToolTip';

const ClientList = ({
    allUsers,
    list,
    isLoading,
    handleSort,
    currentPage,
    search,
    setClientState,
    ArchiveAClient,
    UnarchiveAClient,
    clientState,
    team,
    isTeamSettings,
    sortConfig,
    isValidAdmin,
}) => {
    const handleArchive = (client) => {
        Swal.fire({
            title: `Are you sure you want to archive "${client.firstName} ${client.lastName}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, archive it!',
        }).then((result) => {
            if (result.isConfirmed) {
                ArchiveAClient(client.id, currentPage, team);
                Swal.fire('Archieved!', 'Your client has been archived', 'success');
            }
        });
    };

    const handleUnarchive = (client) => {
        Swal.fire({
            title: `Are you sure you want to unarchive "${client.firstName} ${client.lastName}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, unarchive it!',
        }).then((result) => {
            if (result.isConfirmed) {
                UnarchiveAClient(client.id, currentPage, team);
                setClientState('active');
                Swal.fire('UnArchived!', 'Your client has been unarchived', 'success');
            }
        });
    };

    return (
        <>
            <AdminTable className="w-100 text-dark-grey">
                <thead>
                    <tr>
                        <th>Avatar</th>
                        <th
                            onClick={() => handleSort('firstName')}
                            className="pointer costume-tooltip text-center"
                            data-tooltip="sort by firstName"
                        >
                            Name
                            <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="firstName" key="firstName" />
                        </th>
                        <th className="text-center">Provider</th>
                        <th
                            onClick={() => handleSort('createdAt')}
                            className="pointer costume-tooltip"
                            data-tooltip="sort by created date"
                        >
                            Created At
                            <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="createdAt" key="createdAt" />
                        </th>
                        <th className="text-center">Units</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="text-light-grey">
                    {isLoading ? (
                        <tr
                            css={css`
                                border: none !important;
                            `}
                        >
                            <td colSpan={7}>
                                <LoadingSpiner size={42} height={200} />
                            </td>
                        </tr>
                    ) : list && list.length ? (
                        list.map((client) => {
                            let createdDate = client.createdAt.split('T')[0].split('-');
                            let mmddyyyyFormatedDate = createdDate[1] + '/' + createdDate[2] + '/' + createdDate[0];
                            return (
                                <tr key={client.id}>
                                    <td style={{ width: '100px' }}>
                                        <AdminTablesImg item={client} />
                                    </td>
                                    <td className="text-center">
                                        {client.firstName} {client.lastName}
                                    </td>
                                    <td className="text-center">
                                        {client?.provider?.agencyName ? client?.provider?.agencyName : 'none'}
                                    </td>
                                    <td>{mmddyyyyFormatedDate}</td>
                                    <td className="text-center">{client?.availableUnits ?? '-'}</td>
                                    <td className="action">
                                        <ActionStyles>
                                            <ViewClient client={client} isTeamSettings={isTeamSettings} team={team} />
                                            {isValidAdmin || clientState !== 'archieved' ? (
                                                <EditClient client={client} />
                                            ) : null}
                                            {isValidAdmin ? (
                                                <Fragment>
                                                    <ChangeClientChannel client={client} />
                                                    {clientState === 'archieved' ? (
                                                        <BiArchiveOut
                                                            className="icon archiveOut"
                                                            onClick={() => handleUnarchive(client)}
                                                        />
                                                    ) : (
                                                        <ToolTip
                                                            text={'Archive'}
                                                            cssStyles={
                                                                'top:-70% !important; min-width: fit-content !important'
                                                            }
                                                        >
                                                            <BiArchiveIn
                                                                className="icon archiveIn"
                                                                onClick={() => handleArchive(client)}
                                                            />
                                                        </ToolTip>
                                                    )}
                                                </Fragment>
                                            ) : null}
                                        </ActionStyles>
                                    </td>
                                    <td className="verification-status"></td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={6}>
                                <div
                                    className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                    css={css`
                                        margin-top: 50px;
                                        width: 100%;
                                    `}
                                >
                                    No Client Found
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </AdminTable>
        </>
    );
};

const mapStateToProps = (state) => ({
    allUsers: state.user,
});
export default connect(mapStateToProps, { getAllUser, ArchiveAClient, UnarchiveAClient })(ClientList);
