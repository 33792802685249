import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Button, Form } from 'react-bootstrap';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';
import { ConsentToolTip } from '../../../../../../_elements/ToolTip';
import { Checkbox } from '../Reports/ReportCheckBox';
import { setPayallocationConfig } from '../../../../../../_redux';
import { connect } from 'react-redux';
import { isWeekend } from '../../PayrollUtils';
// import { IoMdArrowBack } from 'react-icons/io';

const PayScheduleForm = ({
    hideExtraOptions,
    companyID,
    teamId,
    mode,
    defaultReportingDataQuery,
    approvalRequired,
    defaultCheckDate,
    setDefaultCheckDate,
    defaultFLSAOvertime,
    setdefaultFLSAOvertime,
    setApprovalRequired,
    isBusy,
    gotoScreenOne,
    gotoScreenTwo,
    setPayallocationConfig,
    payallocation,
}) => {
    const [loading, setLoading] = useState(false);
    const [reportingData, setReportingData] = useState([]);
    const [speed, setSpeed] = useState('two_day');
    const [reportingQuery, setReportingQuery] = useState({
        ...(defaultReportingDataQuery || {}),
        ...(payallocation?.config || {}),
    });
    const fetchReportingPeriods = async (query = reportingQuery) => {
        if (!companyID) return;
        if (mode === 'view' && reportingData.length === 1) return;

        // Show loading modal
        Swal.fire({
            html: `<div style="text-align: center;">
                        <img src="/images/loading.gif" alt="loader" />
                        <h3>Fetching Reporting Periods...</h3>
                    </div>`,
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        setLoading(true);

        try {
            // const queryString = new URLSearchParams({
            //     ...query,
            //     ...(teamId && { team: teamId }),
            // }).toString();
            const allowedFields = ['paySchedule', 'startDate', 'endDate', 'team'];

            const filteredQuery = Object.fromEntries(
                Object.entries(query).filter(([key]) => allowedFields.includes(key))
            );
            if (teamId) {
                filteredQuery.team = teamId;
            }
            const queryString = new URLSearchParams(filteredQuery).toString();

            const response = await fetchWrapper.get(`evv/payroll/zeal/${companyID}/reporting-periods?${queryString}`);
            setReportingData(Array.isArray(response) ? response : [response]);
            if (response && response.length > 0 && !reportingQuery?.reportingPeriodID) {
                setReportingQuery((prev) => ({
                    ...prev,
                    reportingPeriodID: response[0].reportingPeriodID,
                }));
            }
        } catch (error) {
            if (error?.name === 'AbortError') {
                return;
            }
            toast.error(error?.message || "Couldn't fetch reporting periods");
        } finally {
            setLoading(false);
            Swal.close();
        }
    };

    // useEffect(() => {
    //     fetchReportingPeriods();
    // }, [companyID, teamId, mode]);
    useEffect(() => {
        fetchReportingPeriods();
    }, [companyID, teamId]);

    const handleChange = (field, value) => {
        const updatedQuery = { ...reportingQuery, [field]: value };
        setReportingQuery(updatedQuery);
        setPayallocationConfig({ [field]: value });
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target?.value;
        if (!selectedDate) return;
        if (isWeekend(selectedDate)) {
            return toast.error('Weekends not allowed. Please select a valid business day');
        } else {
            setDefaultCheckDate(selectedDate);
            setPayallocationConfig({ checkDate: selectedDate });
        }
    };

    return (
        <div
            className="d-flex flex-column"
            css={css`
                border: 2px solid #eee;
                border-radius: 5px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    gap: 48px;
                    align-items: flex-end;
                    padding: 12px;
                `}
            >
                <Form.Group style={{ width: '122px' }}>
                    <Form.Label>
                        <div className="d-flex">
                            <div>Pay Schedule</div>
                            <div>
                                <ConsentToolTip
                                    text={"Select your employees' pay frequency for this payroll run."}
                                    type="info"
                                />
                            </div>
                        </div>
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={reportingQuery.paySchedule}
                        onChange={(e) => handleChange('paySchedule', e.target.value)}
                        disabled={loading || hideExtraOptions}
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="biweekly">Biweekly</option>
                        <option value="semimonthly">Semimonthly</option>
                        <option value="monthly">Monthly</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Reporting Date Range</Form.Label>
                    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Form.Control
                            type="date"
                            value={reportingQuery.startDate}
                            onChange={(e) => handleChange('startDate', e.target.value)}
                            disabled={true}
                            css={css`
                                width: 120px;
                            `}
                        />
                        <span>-</span>
                        <Form.Control
                            type="date"
                            value={reportingQuery.endDate}
                            onChange={(e) => handleChange('endDate', e.target.value)}
                            disabled={true}
                            css={css`
                                width: 120px;
                            `}
                        />
                    </div>
                </Form.Group>

                <Form.Group style={{ width: '242px' }}>
                    <Form.Label>
                        <div className="d-flex">
                            <div>Reporting/Pay Period</div>
                            <div>
                                <ConsentToolTip
                                    text={"Select the employees' pay period for this payroll run."}
                                    type="info"
                                />
                            </div>
                        </div>
                    </Form.Label>
                    <Form.Control
                        as="select"
                        value={reportingQuery.reportingPeriodID || ''}
                        onChange={(e) => handleChange('reportingPeriodID', e.target.value)}
                        disabled={loading}
                    >
                        {reportingData?.map((period) => (
                            <option key={period.reportingPeriodID} value={period.reportingPeriodID}>
                                {`${period.start} - ${period.end}`}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group style={{ width: '164px' }}>
                    <Form.Label>
                        <div className="d-flex">
                            <div> Check/Pay Date</div>
                            <div>
                                <ConsentToolTip text={'Select when your employees will be paid.'} type="info" />
                            </div>
                        </div>
                    </Form.Label>
                    <Form.Control
                        type="date"
                        name="default_check_date"
                        disabled={isBusy}
                        value={defaultCheckDate}
                        onChange={handleDateChange}
                        required
                    />
                </Form.Group>

                {/* {loading && <Spinner animation="border" />} */}
            </div>
            {!hideExtraOptions && (
                <div
                    css={css`
                        display: flex;
                        gap: 48px;
                        align-items: flex-start;
                        padding: 12px;
                        margin-top: 18px;
                        flex-direction: row;
                        margin-top: 12px;
                    `}
                >
                    {/* <Form.Group
                        css={css`
                            display: flex;
                            align-items: flex-end;
                        `}
                    >
                        <div>
                            <Checkbox
                                label="Approval Required"
                                checked={approvalRequired}
                                onChange={(checked) => setApprovalRequired(checked)}
                                disabled={true}
                            />
                        </div>
                        <ConsentToolTip
                            text={
                                'Set to true if this check requires manual approval by the employer. Otherwise, by default, Zeal will automatically process the check on the day before the check date.'
                            }
                            className="ml-1"
                            type="info"
                        />
                    </Form.Group> */}

                    {/* <Form.Group
                        css={css`
                            display: flex;
                            align-items: flex-end;
                        `}
                    >
                        <div>
                            <Checkbox
                                label="FLSA OT Recommendation"
                                checked={defaultFLSAOvertime}
                                onChange={(checked) => setdefaultFLSAOvertime(checked)}
                                disabled={true}
                            />
                        </div>
                        <ConsentToolTip
                            text={
                                'If set to true, Zeal will automatically convert the check to include our OT recommendation at the time of processing. (Only applicable for FLSA Overtime Policy)'
                            }
                            className="ml-1"
                            type="info"
                        />
                    </Form.Group> */}
                    <Form.Group
                        css={css`
                            min-width: max-content;
                        `}
                    >
                        <Form.Label>ACH Speed of the Check</Form.Label>
                        <Form.Control
                            as="select"
                            name="speed"
                            disabled={isBusy}
                            value={speed}
                            onChange={(e) => setSpeed(e.target.value)}
                        >
                            <option value="two_day">Two Day</option>
                            <option value="one_day">One Day</option>
                        </Form.Control>
                    </Form.Group>
                </div>
            )}
            {!hideExtraOptions && (
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                        padding: 8px;
                    `}
                >
                    <Button
                        onClick={() => {
                            gotoScreenOne();
                        }}
                        variant="secondary"
                    >
                        {/* <IoMdArrowBack /> */}
                        Back
                    </Button>

                    <Button
                        className="ml-4"
                        onClick={() => {
                            setPayallocationConfig({
                                ...reportingQuery,
                                approvalRequired,
                                defaultFLSAOvertime,
                                speed,
                                checkDate: defaultCheckDate,
                            });
                            gotoScreenTwo();
                        }}
                    >
                        Save
                    </Button>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    payallocation: state.payallocation,
});
const mapDispatchToProps = {
    setPayallocationConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayScheduleForm);
