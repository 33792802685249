import React, { Fragment, useEffect, useState } from 'react';
import { css } from 'twin.macro';
import NavigationBar from '../../../../../Admin/reusableComponents/NavigationBar';
import { getOtherUserData } from '../../../../../../_redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWrapper, getChannelIdFromSettingsURL, getTeamIdFromURL } from '../../../../../../_helpers';
import { UserCertificateContainer } from '../Common/CertificateComponents';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';

function UserCertificateInfo({ handleBack, user }) {
    const [certificates, setCertificates] = useState({});
    const [loading, setLoading] = useState(false);
    const team = getTeamIdFromURL();
    const chatroom = getChannelIdFromSettingsURL();
    async function loadData() {
        try {
            if (!user?._id) {
                return;
            }
            if (!chatroom && !team) {
                return;
            }
            let query = `?user=${user?._id}`;
            query = chatroom ? query + `&chatroom=${chatroom}` : query + `&team=${team}`;
            setLoading(true);
            const response = await fetchWrapper.get(`user-certificates${query}`);
            setCertificates(response);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [user]);

    return (
        <div
            css={css`
                padding: 1rem 2rem;
            `}
        >
            <div className="d-flex justify-content-between mb-4">
                <NavigationBar mainTabName={`Users | ${user?.name || ''}`} goToTeamPage={handleBack} />
            </div>
            {loading ? (
                <LoadingSpiner size={42} />
            ) : (
                <>
                    {certificates?.length > 0 ? (
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                            `}
                        >
                            {certificates.map((certificate, index) => (
                                <UserCertificateContainer key={index} certificate={certificate} user={user} />
                            ))}
                        </div>
                    ) : (
                        <div className="custom-card">No Certificates</div>
                    )}
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    CurrUser: state.user.user,
});
export default connect(mapStateToProps, { getOtherUserData })(UserCertificateInfo);
