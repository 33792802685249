import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';
import { ActionStyles } from '../../reusableStyles';

const MessageModal = ({ sms }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return (
        <div>
            <ActionStyles className="view-icon">
                <BsEye className="icon" onClick={handleShow} />
            </ActionStyles>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    {sms?.memo && (
                        <div>
                            <span className="badge badge-danger">{sms.memo}</span>{' '}
                        </div>
                    )}
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="d-flex">
                        <span style={{ width: '60px' }}>From:</span>
                        <span className="badge badge-info">{sms?.fromPhoneNumber || ''}</span>
                    </div>
                    <div className="d-flex mt-2">
                        <span style={{ width: '60px' }}>To:</span>
                        <span className="badge badge-info">{sms?.toPhoneNumber || ''}</span>
                    </div>
                    <div className="d-flex mt-4">
                        <span style={{ width: '60px' }}>Text:</span>
                        <span className="ml-4"> {sms?.smsText || ''}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MessageModal;
