import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

export const CanvasContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const ResizableRect = styled.div`
    position: absolute;
    border: ${(props) => (props?.selected ? `${props?.width <= 25 ? 1 : 2}px solid ` : '1px solid')} #0099ff;
    background-color: ${(props) => (props.selected ? `rgba(14, 157, 236, 0.5)` : `rgba(14, 157, 236, 0.1)`)};
    left: ${(props) => props.left}px;
    top: ${(props) => props.top}px;
    width: ${(props) => Math.abs(props.width)}px;
    height: ${(props) => Math.abs(props.height)}px;
    cursor: all-scroll;
    &:hover {
        border: ${(props) => (props?.width <= 25 ? 1 : 2)}px solid #0099ff;
    }
    border-radius: ${(props) => (props?.isCircle ? '50%' : '')};
`;

export const ResizeHandle = styled.div`
    position: absolute;
    border: 2px solid #0099ff;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: white;
    cursor: ${(props) => props.cursor};
    z-index: 10;
    opacity: ${(props) => (props?.selected ? '1' : '0')};
    cursor: ${(props) => (props.selected ? '' : 'auto')};
    ${(props) => props.position}
`;

export const LabelContainer = styled.div`
    position: absolute;
    min-width: 224px;
    background-color: #e0f0ef;
    border: 1px solid #50aeb0;
    border-radius: 5px;
    padding-right: 5px;
    color: white;
    background-color: rgba(14, 157, 236, 1);
    left: ${(props) => props.left - 35}px;
    top: ${(props) => (props.top || 0) - 30}px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    z-index: 10;
`;

export const OptionParentLabel = styled.div`
    position: absolute;
    background-color: #e0f0ef;
    border: 1px solid #50aeb0;
    border-radius: 5px;
    padding-right: 5px;
    color: white;
    background-color: rgba(14, 157, 236, 1);
    left: ${(props) => props.left - 65}px;
    top: ${(props) => (props.top || 0) - 30}px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    z-index: 10;
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 3px;
    padding-bottom: 7px;
    width: 30px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #e0f0ef;
    border: 1px solid #50aeb0;
    background-color: rgba(14, 157, 236, 1);
    left: ${(props) => (props.left || 0) - 35}px;
    top: ${(props) => props.top || 0}px;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
    z-index: 10;
`;

export const ModalFormContainer = styled.div`
    border-radius: 5px;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

export const RemoveButton = styled.div`
    width: 20px;
    height: 20px;
    z-index: 999;
    border: none;
    margin-left: 3px;
    margin-top: 3px;
`;

export const EditDiv = styled.div`
    width: 20px;
    height: 20px;
    z-index: 999;
    border: none;
    margin-left: 5px;
`;

export const FloatingContainer = styled.div`
    position: fixed;
    top: ${(props) => props.top || '16px'};
    left: ${(props) => props.left || '16px'};
    z-index: ${(props) => props.zIndex || 1050};
`;

export const StyledButton = styled(Button)`
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    color: white;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: visible;
    transition: all 0.1s ease;

    &:hover {
        border-radius: 8px;
        width: auto;
        padding: 8px 16px;
    }

    div:first-of-type {
        font-size: 40px;
        font-weight: bold;
        margin-left: 8px;
    }

    div:last-of-type {
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.1s ease;
    }

    &:hover div:last-of-type {
        opacity: 1;
    }
`;

export const MappedFieldDiv = styled.div`
    padding: 32px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    width: 800px;
    position: relative;
`;
