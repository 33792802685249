import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { DocumentsStyles } from './styles/DocumentsStyled';
import Contacts from './Contacts';
import Members from './Members';
import { getChannelIdFromSettingsURL, isTeamOrChannelSettings } from '../../../../_helpers';

function TeamSettingsMembers({ currRoom, channelMembers, MemberOverflow, team, teamMembers, user, userId }) {
    const [key, setKey] = useState('members');
    const channelId = getChannelIdFromSettingsURL();
    const isChannelOrTeamSettings = isTeamOrChannelSettings();

    if (channelId && isChannelOrTeamSettings) {
        return (
            <Members
                currRoom={currRoom}
                channelMembers={channelMembers}
                MemberOverflow={MemberOverflow}
                team={team}
                teamMembers={teamMembers}
                user={user}
                userId={userId}
            />
        );
    }

    return (
        <div css={DocumentsStyles}>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="members" title="Members">
                    {key === 'members' && <Members />}
                </Tab>
                <Tab eventKey="contactList" title="Contact List">
                    {key === 'contactList' && <Contacts />}
                </Tab>
            </Tabs>
        </div>
    );
}

const mapStateToProps = (state) => ({
    teamRooms: state.rooms.teamRooms,
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(TeamSettingsMembers);
