import React, { useState, useEffect, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { fetchWrapper, SafeJSONParse } from '../../../_helpers';

export default function ProviderSelector({ selectProvider, provider, isDisabled, noAstrick, team }) {
    const [selectedProvider, setSelectedProvider] = useState();
    useEffect(() => {
        if (provider) {
            try {
                let providerObj = SafeJSONParse(provider)?.id || provider;
                if (providerObj.agencyName) {
                    let selectedProviderObj = {
                        label: providerObj.agencyName,
                        value: {
                            providerId: providerObj.id,
                            teamId: providerObj.team,
                        },
                    };
                    setSelectedProvider(selectedProviderObj);
                }
            } catch (err) {
                setSelectedProvider({
                    label: provider?.label,
                    value: {
                        providerId: provider.providerId,
                        teamId: provider.value,
                    },
                });
            }
        }
    }, [provider]);

    const handleSelect = (selected) => {
        selectProvider(selected.value);
        setSelectedProvider(selected);
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(async () => {
            let tempArray = [];
            try {
                const response = await fetchWrapper.get(
                    `evv/provider?limit=10&page=1${inputValue ? `&agencyName=${inputValue}` : ''}${
                        team ? `&team=${team}` : ''
                    }`
                );
                let results = response.results;
                let options = results.map((provider) => ({
                    label: provider.agencyName,
                    value: {
                        providerId: provider._id || provider.id,
                        teamId: provider.team?._id || provider.team?.id || provider.team,
                        state: provider.state || 'PA',
                        evv: provider.evv || {},
                        payroll: provider?.payroll || {},
                    },
                }));
                tempArray = options;
            } catch (err) {
                console.log(err);
            }
            callback(tempArray);
        }, 0);
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>Provider {noAstrick ? null : <span className="text-danger">*</span>}</Form.Label>
                <Fragment>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        name="provider"
                        loadOptions={loadOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={selectedProvider}
                        onChange={handleSelect}
                        isDisabled={isDisabled}
                    />
                </Fragment>
            </Form.Group>
        </div>
    );
}
