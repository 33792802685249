import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { Body } from './styles';

import ClientForm from './ClientForm';

const CreateClient = ({ isTeamSettings, provider, teamId, channelId, show, setShow }) => {
    return (
        <>
            <Modal show={show} backdropClassName="bg-dark" onHide={() => setShow(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <div className="header">
                        <h4
                            css={css`
                                margin: 0;
                            `}
                        >
                            Add New Client
                        </h4>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <Body>
                        <ClientForm
                            setShowModal={setShow}
                            isTeamSettings={isTeamSettings}
                            userClientProvider={provider}
                            team={teamId}
                            channel={channelId}
                        />
                    </Body>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateClient;
