import * as ActionTypes from './ActionTypes';
export const setPayallocationDraft = (data) => ({
    type: ActionTypes.SET_PAYALLOCATION_DRAFT,
    payload: { ...(data || {}) },
});
export const setPayallocationConfig = (configs) => ({
    type: ActionTypes.SET_PAYALLOCATION_CONFIG,
    payload: { ...(configs || {}) },
});
export const setPayallocationProvider = (provider) => ({
    type: ActionTypes.SET_PAYALLOCATION_PROVIDER,
    payload: { ...(provider || {}) },
});
export const setPayallocationEmployees = (employees) => ({
    type: ActionTypes.SET_PAYALLOCATION_EMPLOYEES,
    payload: employees || [],
});
export const updatePayallocationEmployee = (employee) => ({
    type: ActionTypes.UPDATE_PAYALLOCATION_EMPLOYEE,
    payload: employee || [],
});
export const setPayallocationEmployeeChecks = (data) => ({
    type: ActionTypes.SET_PAYALLOCATION_EMPLOYEE_CHECKS,
    payload: data || {},
});
export const updatePayallocationEmployeeCheck = (data) => ({
    type: ActionTypes.UPDATE_PAYALLOCATION_EMPLOYEE_CHECK,
    payload: data || {},
});

export const setPayallocationEmployeeEarnigns = (data) => ({
    type: ActionTypes.SET_PAYALLOCATION_EMPLOYEE_EARNINGS,
    payload: data || {},
});
export const updatePayallocationEmployeeEarning = (data) => ({
    type: ActionTypes.UPDATE_PAYALLOCATION_EMPLOYEE_EARNING,
    payload: data || {},
});

export const clearPayallocation = () => ({
    type: ActionTypes.CLEAR_PAYALLOCATION,
});
