import React from 'react';
import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Body } from './styles';

import StaffForm from './StaffForm';

const CreateStaff = ({ provider, show, setShow }) => {
    return (
        <>
            <Modal show={show} backdropClassName="bg-dark" onHide={() => setShow(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <div className="header">
                        <h4
                            css={css`
                                margin: 0;
                            `}
                        >
                            Create New Staff
                        </h4>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <Body>
                        <StaffForm setShowModal={setShow} provider={provider} formType={'create'} />
                    </Body>
                </Modal.Body>
            </Modal>
        </>
    );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CreateStaff);
