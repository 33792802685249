import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { isEmployeeOnboarded } from '../../PayrollUtils';
import { fetchWrapper } from '../../../../../../_helpers';
import { CheckError } from '../ViewPayments/ZealCompanyPayroll';
import { getCombinedShifts, getEmployeeName, prepareCheckDataFromConfig } from '../../PayallocationUtils';
import EmployeeCheckPreviewModal from '../EmployeeCheckPreviewModal';
import EmployeeCheckRow from '../EmployeeCheckRow';
import EarningsModal from './EarningsModal';
import DisbursementModal from './DisbursementModal';
import { VscLoading } from 'react-icons/vsc';
import MileageModal from './MileageModal';

// function prepareFormData(payrollType, formData) {
//     return { zeal: prepareZealFormData }[payrollType](formData) || (() => formData);
// }

export function EmployeeRow({ detailVisit, teamId, workLocations, payallocation, updatePayallocationEmployeeCheck }) {
    const [isBusy, setIsBusy] = useState(false);
    const companyID = payallocation?.provider?.companyID;
    const employeeMetadata = detailVisit?.employee?.payroll?.metadata;
    const employeeID = employeeMetadata?.employeeID || detailVisit?.employeeID;
    const [previewData, setPreviewData] = useState({
        ...detailVisit,
        ...(payallocation?.employeePreviewChecks[detailVisit?.employeeID] || {}),
    });
    const [showPreviewData, setShowPreviewData] = useState(false);

    const onHidePreviewModal = () => {
        setShowPreviewData(false);
    };

    async function getEmployeeCheckPreview() {
        setShowPreviewData(true);
        const checkData = await prepareCheckDataFromConfig(detailVisit, payallocation);
        const allShifts = [
            ...(checkData?.shifts || []),
            ...(payallocation?.employeesEarnings[employeeID]?.shifts || []),
        ];
        checkData.shifts = allShifts;
        try {
            setIsBusy(true);
            const data = await fetchWrapper.post(
                `/evv/payroll/zeal/employee/${companyID}/preview-check${teamId ? `?team=${teamId}` : ''}`,
                [checkData]
            );
            updatePayallocationEmployeeCheck(data[employeeID]);
            setPreviewData({
                ...detailVisit,
                ...(data[employeeID] || {}),
                ...(payallocation?.config || {}),
                ...(checkData || {}),
                shifts: getCombinedShifts({ payallocation, employeeID, shifts: detailVisit?.shifts }),
            });
            return data[employeeID];
        } catch (error) {
            const errorMessage = error.message || "Preview data couldn't be fetched";
            toast.error(errorMessage);
        } finally {
            setIsBusy(false);
        }
    }

    const employeeName = useMemo(() => {
        return getEmployeeName(detailVisit);
    }, [detailVisit]);

    useEffect(() => {
        if (payallocation) {
            setPreviewData({
                ...detailVisit,
                ...(payallocation?.employeePreviewChecks[detailVisit?.employeeID] || {}),
                shifts: getCombinedShifts({ payallocation, employeeID, shifts: detailVisit?.shifts }),
            });
        }
    }, [payallocation]);

    const errorMessage = payallocation?.employeePreviewChecks[detailVisit?.employeeID]?.error;
    const employeeOnboarded = isEmployeeOnboarded(detailVisit?.employee);

    if (!employeeOnboarded) {
        return <div>Employee not onboarded</div>;
    }
    return (
        <Fragment>
            <tr
                css={css`
                    ${errorMessage ? 'border: none !important;' : ''}
                `}
            >
                <td>{detailVisit?.index + 1}.</td>
                <td>
                    <div>{employeeName}</div>
                </td>
                <td>
                    $
                    {(
                        previewData?.grossPay / previewData?.totalHours ||
                        detailVisit?.totalAmount / detailVisit?.totalHours ||
                        0
                    ).toFixed(2)}
                </td>
                <td>{(previewData?.totalHours || detailVisit?.totalHours || 0)?.toFixed(2)} hrs</td>
                <td>
                    <EarningsModal
                        companyID={companyID}
                        isBusy={isBusy}
                        mode={employeeOnboarded ? 'create' : 'view'}
                        teamId={teamId}
                        employeeID={employeeID}
                        workLocations={workLocations}
                        setIsBusy={setIsBusy}
                    />
                </td>
                <td>
                    <MileageModal
                        employeeID={employeeID}
                        mileageReimbursementRate={payallocation?.provider?.payroll?.metadata?.mileageReimbursementRate}
                        isBusy={isBusy}
                        teamId={teamId}
                        setIsBusy={setIsBusy}
                    />
                </td>
                <td>
                    {isBusy ? (
                        <VscLoading className="spin" />
                    ) : (
                        <span>${previewData?.checks?.at(0)?.gross_pay || detailVisit?.totalAmount?.toFixed(2)}</span>
                    )}
                </td>
                <td>
                    {isBusy ? (
                        <VscLoading className="spin" />
                    ) : (
                        <span>${previewData?.checks?.at(0)?.net_pay || detailVisit?.totalAmount?.toFixed(2)}</span>
                    )}
                </td>
                <td>
                    <DisbursementModal
                        employeeID={employeeID}
                        disbursementMethod={detailVisit?.disbursementMethod}
                        isBusy={isBusy}
                    />
                </td>
                <td>
                    <Button onClick={getEmployeeCheckPreview} disabled={isBusy}>
                        Preview
                    </Button>
                </td>
            </tr>
            {errorMessage && (
                <tr>
                    <td></td>
                    <td
                        aria-colspan={9}
                        colSpan={9}
                        css={css`
                            // padding-top: 0px !important;
                        `}
                    >
                        <div
                            css={css`
                                margin-top: -30px !important;
                                margin-left: -10px;
                            `}
                        >
                            <CheckError error={errorMessage} />
                        </div>
                    </td>
                </tr>
            )}
            <EmployeeCheckPreviewModal
                show={showPreviewData}
                onHide={onHidePreviewModal}
                loading={isBusy}
                previewData={[previewData]}
                bulkView={false}
            >
                <EmployeeCheckRow
                    employeeCheck={{
                        ...previewData,
                        ...previewData?.checks?.at(0),
                        totals: {
                            employee_taxes: previewData?.total_employee_taxes,
                            employer_taxes: previewData?.total_employer_taxes,
                            employee_deductions: previewData?.total_employee_deductions,
                            employer_deductions: previewData?.total_employer_deductions,
                        },
                    }}
                    setShowEmployeeCheck={() => {}}
                    companyID={companyID}
                    teamId={teamId}
                    preview={true}
                    loading={isBusy}
                    showShiftBreakDown={true}
                />
            </EmployeeCheckPreviewModal>
        </Fragment>
    );
}
