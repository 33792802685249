import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import JSZip from 'jszip';
import { css } from 'twin.macro';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { MdOutlineFileDownload } from 'react-icons/md';
import { cleanUpString, fetchWrapper, getFileNameFromUrl } from '../../_helpers';
import { VscLoading } from 'react-icons/vsc';
import { connect } from 'react-redux';
import LoadingSpiner from '../LoadingSpiner';

const BulkDownload = ({ DOWNLOAD_LIMIT, currUser, downloadUrl, downloadFileName = '', handleClick = () => {} }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setIsDownloading(false);
        setFilesDownloaded(0);
        setTotalFilesToDownload(0);
        setShow(true);
        handleClick();
    };
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [filesDownloaded, setFilesDownloaded] = useState(0);
    const [totalFilesToDownload, setTotalFilesToDownload] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const downloadFilesAsZip = async () => {
        try {
            if (!downloadUrl) {
                return toast.error('Not valid downloadUrl');
            }
            const zipFileName = `${cleanUpString(downloadFileName)}-documents-${Date.now()}.zip`;
            setIsDownloading(true);
            const zip = new JSZip();
            const fileNames = {};

            const response = await fetchWrapper.get(downloadUrl);
            const totalFiles = (response || {})?.totalResults || 0;
            setTotalResults(totalFiles);
            setTotalFilesToDownload(totalFiles > DOWNLOAD_LIMIT ? DOWNLOAD_LIMIT : totalFiles);
            const fileUrls = (response?.results || []).map((element) => element?.url);

            if (fileUrls.length) {
                if (fileUrls.length > DOWNLOAD_LIMIT) {
                    toast.warning(`Only first ${DOWNLOAD_LIMIT} files will be downloaded`);
                }

                const filesToDownload = fileUrls.slice(0, DOWNLOAD_LIMIT);
                const batchSize = 10;
                const batches = [];

                for (let i = 0; i < filesToDownload.length; i += batchSize) {
                    batches.push(filesToDownload.slice(i, i + batchSize));
                }

                for (const batch of batches) {
                    const batchPromises = batch.map(async (fileUrl) => {
                        try {
                            const response = await fetch(fileUrl + '?noCache=' + Math.random().toString());
                            const blob = await response.blob();
                            let fileName = getFileNameFromUrl(fileUrl);
                            if (!fileNames[fileName]) {
                                fileNames[fileName] = 1;
                            } else {
                                fileNames[fileName] += 1;
                                fileName = `(${fileNames[fileName]})${fileName}`;
                            }
                            zip.file(fileName, blob, { binary: true });
                            setFilesDownloaded((prev) => Math.min(prev + 1, totalFiles));
                        } catch (error) {
                            console.error(`Error downloading file: ${fileUrl}`, error);
                        }
                    });

                    await Promise.allSettled(batchPromises);
                }

                zip.generateAsync({ type: 'blob' }).then((content) => {
                    saveAs(content, zipFileName);
                });
            }
        } catch (error) {
            if (error?.name === 'AbortError') {
                return;
            }
            toast.error(error?.message || 'Error while downloading files');
        } finally {
            setIsDownloading(false);
            handleClose();
        }
    };

    const getFileInfo = async (downloadUrl) => {
        try {
            setLoading(true);
            const response = await fetchWrapper.get(downloadUrl);
            const totalFiles = (response || {})?.totalResults || 0;
            setTotalResults(totalFiles);
        } catch (error) {
            if (error?.name === 'AbortError') {
                return;
            }
            toast.error(error?.message || 'Error while downloading files');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (show && downloadUrl) {
            getFileInfo(downloadUrl);
        }
    }, [show]);

    return (
        <Fragment>
            <Button disabled={isDownloading} onClick={handleShow}>
                <MdOutlineFileDownload />
                <span className="mr-2">
                    {isDownloading ? `${filesDownloaded} / ${totalFilesToDownload} files` : 'Bulk Download'}
                </span>
                {isDownloading && <VscLoading className="spin" />}
            </Button>
            <Modal show={show} centered backdrop="static" size="xl">
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Bulk File Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <LoadingSpiner size={40} />
                            </div>
                        ) : (
                            <div>
                                <p>Total Results Found: {totalResults}</p>
                                <p>Download Limit: {DOWNLOAD_LIMIT}</p>
                                <div className="d-flex justify-content-center">
                                    {isDownloading && (
                                        <div style={{ marginTop: '100px', marginBottom: '100px', width: '80%' }}>
                                            <LoadingSpiner size={40} />
                                            <ProgressBar
                                                animated
                                                min={0}
                                                now={Number(
                                                    `${((filesDownloaded || 0) / (totalFilesToDownload || 1)) * 100}`
                                                )}
                                                label={`${Math.round(((filesDownloaded || 0) / (totalFilesToDownload || 1)) * 100)} %`}
                                                css={css`
                                                    height: 30px;
                                                    font-size: 24px;
                                                    width: 100%;
                                                    margin-top: 30px;
                                                `}
                                            />
                                            <div className="text-center">{`( ${filesDownloaded} / ${totalFilesToDownload} ) files processed ...`}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                        {!downloadUrl ? (
                            'Url is missing'
                        ) : (
                            <Fragment>
                                {isDownloading ? null : (
                                    <Button disabled={isDownloading || loading} onClick={() => downloadFilesAsZip()}>
                                        <span className="mr-2">Start Download</span>
                                    </Button>
                                )}
                            </Fragment>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    currUser: state.user.user,
});
export default connect(mapStateToProps, {})(BulkDownload);
