import React, { useState } from 'react';
import { css } from 'twin.macro';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';
import { Button, Modal, Table, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { cleanUpString, fetchWrapper, getTeamIdFromURL, isValidPhone } from '../../../../_helpers';
import FileUploader from '../../Common/FileUploader';
import { showBulkReport } from '../Client/BulkModal';

const preparePayload = (items) => {
    return (items || [])
        .map((item) => {
            const { name: nameObj, phone: phoneObj, email } = item;
            const name = nameObj?.label;
            const phone = phoneObj?.label;
            if (!phone || !name || !isValidPhone(phone)) return null;
            return {
                name,
                phone,
                email,
            };
        })
        .filter(Boolean);
};

const BulkModal = ({ onCreateOrUpdate = async () => {}, bulkCategory = '' }) => {
    const [show, setShow] = useState(true);
    const [fileData, setFileData] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState([]);
    const teamId = getTeamIdFromURL();

    const handleFileUpload = async (formData) => {
        try {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (jsonData.length > 0) {
                    const tempItems = jsonData
                        .slice(1)
                        .map((row) => {
                            const cleanedRow = row.map(cleanUpString);
                            const [name, phone, email] = cleanedRow;

                            return !phone && !name
                                ? null
                                : {
                                      name: { label: name, error: !name && 'Name is required' },
                                      phone: {
                                          label: phone ? (phone?.includes('+') ? phone : `+1${phone}`) : '',
                                          error: !phone
                                              ? 'Phone is required'
                                              : !isValidPhone(phone) && 'Invalid phone number',
                                      },
                                      email,
                                  };
                        })
                        .filter(Boolean);

                    setItems(tempItems);
                    setErrors([]);
                } else {
                    setErrors(['No data found in the file']);
                }
                setFileData(jsonData);
                setIsUploading(false);
            };

            reader.onerror = () => {
                setErrors(['Failed to read the file. Please try again.']);
                setIsUploading(false);
            };

            reader.readAsBinaryString(formData.get('file'));
        } catch (error) {
            setErrors(['An error occurred while processing the file.']);
            setIsUploading(false);
            console.error('Error parsing Excel file:', error);
        }
    };

    const handleCancelUpload = () => {
        setFileData(null);
        setItems([]);
        setErrors([]);
    };

    const handleSubmit = async () => {
        try {
            const data = preparePayload(items || []);
            const response = await Swal.fire({
                title: `Confirm creating ${bulkCategory}`,
                html: `
                    <div>
                    <p style="font-size: 14px;">
                        Total ${bulkCategory} Count: ${(items || []).length}
                    </p>
                    <p style="font-size: 14px; color:green">
                        Valid ${bulkCategory}  Count: ${(data || []).length}
                    </p>
                     <p style="font-size: 14px;color:red;">
                        Invalid ${bulkCategory}  Count: ${(items || []).length - (data || []).length}
                    </p>
                    </div>
                `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });

            if (!response.isConfirmed) {
                return;
            }
            if (!teamId) {
                return toast.error('Team ID is missing');
            }
            const res = await fetchWrapper.post(`contacts/bulk?team=${teamId}`, {
                contacts: data,
                team: teamId,
            });
            if (res.error) {
                toast.error(res?.error);
            }
            toast.success(res?.message);
            await showBulkReport(res);
            onCreateOrUpdate();
        } catch (error) {
            console.log('ERROR => ', error);
            toast.error(error?.message);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Bulk {bulkCategory} Create</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!fileData ? (
                    <>
                        <FileUploader
                            accept=".xlsx, .xls"
                            onFileUpload={handleFileUpload}
                            buttonText="Upload Excel File"
                            loadingText="Uploading..."
                            successMessage="File uploaded successfully!"
                            errorMessage="File upload failed. Please try again."
                            fileLimit={5}
                            showTips={true}
                            tips={[
                                'Ensure the file is in .xlsx format.',
                                `Check that each ${bulkCategory}  has a valid name and phone number.`,
                            ]}
                        />

                        {isUploading && (
                            <div className="d-flex justify-content-center">
                                <VscLoading className="spin" size={30} />
                            </div>
                        )}
                        {errors.length > 0 && (
                            <div className="alert alert-danger mt-3">
                                <ul>
                                    {errors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        css={css`
                            width: 100%;
                            overflow-x: auto;
                        `}
                    >
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    {items.length > 0 &&
                                        Object.keys(items[0]).map((header, idx) => <th key={idx}>{header}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {item.name?.label}
                                            <p className="text-danger font-bold ">{item.name?.error}</p>
                                        </td>
                                        <td>
                                            {item.phone?.label}
                                            <p className="text-danger font-bold">{item.phone?.error}</p>
                                        </td>
                                        <td>{item?.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                {fileData && (
                    <Button variant="secondary" onClick={handleCancelUpload}>
                        Upload Another File
                    </Button>
                )}
                <Button variant="primary" onClick={handleSubmit} disabled={isUploading || !fileData}>
                    {isUploading ? <Spinner animation="border" size="sm" /> : `Create Bulk ${bulkCategory} `}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    teamMembers: state.teams.teamMembers,
});

export default connect(mapStateToProps)(BulkModal);
