import React from 'react';
import { css } from 'twin.macro';

// icons
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';

// styles
import SortedTableHeaderIcon from '../../SortedTableHeaderIcon';
// import DeleteTeam from './DeleteTeam';
// import EditTeam from './EditTeam';
import { ActionStyles, AdminTable } from '../../reusableStyles';
import LoadingSpiner from '../../../../_elements/LoadingSpiner';
import EditProvider from './EditProvider';
import ViewProvider from './ViewProvider';
import { ArchivedProvider, UnarchivedProvider } from '../../../../_redux/actions/ActionEVV';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { ToolTip } from '../../../../_elements/ToolTip';
import WorklioTaxSetup from '../components/WorklioTaxSetup';
import ZealCompanyTabsModal from '../Payroll/ZealComponents/ZealCompanyTabsModal';

const ProviderList = ({
    list,
    isLoading,
    handleSort,
    sortConfig,
    ArchivedProvider,
    UnarchivedProvider,
    currentPage,
    search,
    providerState,
    setProviderState,
    teamId,
}) => {
    const handleArchive = (provider) => {
        Swal.fire({
            title: 'Are you sure you want to Archive?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, archive it!',
        }).then((result) => {
            if (result.isConfirmed) {
                ArchivedProvider(provider.id, currentPage);
                Swal.fire('Archieved!', 'Your provider has been archived', 'success');
            }
        });
    };

    const handleUnarchive = (provider) => {
        Swal.fire({
            title: 'Are you sure you want to Unarchive?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, unarchive it!',
        }).then((result) => {
            if (result.isConfirmed) {
                UnarchivedProvider(provider.id, currentPage);
                setProviderState('active');
                Swal.fire('UnArchived!', 'Your provider has been unarchived', 'success');
            }
        });
    };

    return (
        <AdminTable className="w-100 text-dark-grey">
            <thead>
                <tr>
                    <th
                        className="pointer costume-tooltip"
                        onClick={() => handleSort('agencyName')}
                        data-tooltip="sort by name"
                    >
                        Agency Name
                        <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="agencyName" />
                    </th>
                    <th>Team Name</th>
                    <th
                        onClick={() => handleSort('createdAt')}
                        className="pointer costume-tooltip"
                        data-tooltip="sort by created date"
                    >
                        Created at
                        <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="createdAt" />
                    </th>
                    <th>EVV Type</th>
                    <th>Payroll Type</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody className="text-light-grey">
                {isLoading ? (
                    <tr>
                        <td colSpan={7}>
                            <LoadingSpiner size={42} height={200} />
                        </td>
                    </tr>
                ) : list && list.length ? (
                    list.map((item) => (
                        <tr key={item.id}>
                            <td>{item.agencyName}</td>
                            <td>{item.team?.name}</td>
                            <td>{new Date(item.createdAt || 0).toLocaleDateString()}</td>
                            <td>{item.evv.type}</td>
                            <td>{item.payroll.type}</td>
                            <td className="action">
                                <ActionStyles>
                                    <ViewProvider provider={item} />
                                    <EditProvider provider={item} teamId={teamId} />
                                    {item?.payroll?.type === 'worklio' && item?.payroll?.metadata?.companyId ? (
                                        <WorklioTaxSetup
                                            provider={item}
                                            companyId={item?.payroll?.metadata?.companyId}
                                            type="provider"
                                            teamId={teamId}
                                        />
                                    ) : null}
                                    {item?.payroll?.type === 'zeal' && item?.payroll?.metadata?.companyID ? (
                                        <ZealCompanyTabsModal
                                            provider={item}
                                            companyID={item?.payroll?.metadata?.companyID}
                                            payrollMetadata={item?.payroll?.metadata}
                                            teamId={teamId}
                                        />
                                    ) : null}

                                    {!!teamId ||
                                        (providerState === 'archieved' ? (
                                            <BiArchiveOut
                                                className="icon archiveOut"
                                                onClick={() => handleUnarchive(item)}
                                            />
                                        ) : (
                                            <ToolTip
                                                text={'Archive'}
                                                cssStyles={'top:-70% !important; min-width: fit-content !important'}
                                            >
                                                <BiArchiveIn
                                                    className="icon archiveIn"
                                                    onClick={() => handleArchive(item)}
                                                />
                                            </ToolTip>
                                        ))}
                                </ActionStyles>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={6}>
                            <div
                                className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                css={css`
                                    margin-top: 50px;
                                    width: 100%;
                                `}
                            >
                                No Providers Found
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </AdminTable>
    );
};

export default connect(null, { ArchivedProvider, UnarchivedProvider })(ProviderList);
