import React, { useState, Fragment, useEffect } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';
import { BiLinkExternal } from 'react-icons/bi';
import { BsGrid, BsList } from 'react-icons/bs';
import Select from 'react-select';
import dayjs from 'dayjs';
import { Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { css } from 'twin.macro';
import { ToolTip } from '../../../_elements/ToolTip';

//components
import PreviewEventModal from '../Tasks/PreviewEventModal';
import { AdminTable } from '../reusableStyles';
import { ActionStyles } from '../reusableStyles';
// import { EventPlannerCalendar } from './EventPlannerCalendar';
import EventPlannerCalendar from './EventPlannerCalendar';
import { areAllArraysUnorderedEqual, fetchWrapper, getTeamIdFromURL, prepareEvent } from '../../../_helpers';
import { setMessage } from '../../../_redux';
import EventModal from '../Tasks/EventModal';
import { editTheTask } from '../../../_redux/actions/ActionTasks';
import AssignChannelToPackageModal from './AssignChannelToPackageModal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ValidateUserAssignedToEntirePackageModal from './ValidateUserAssignedToEntirePackageModal';
import ChannelSelectFilter from '../ChannelSelectFilter';
import TaskSelectFilter from '../TaskSelectFilter';
import TeamSelectFilter from '../TeamSelectFilter';

const styles = {
    multiValue: (base, state) => {
        return { ...base, backgroundColor: 'gray' };
    },
    multiValueLabel: (base, state) => {
        return { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 };
    },
    multiValueRemove: (base, state) => {
        return { ...base, display: 'none' };
    },
};
const AssignChannelToPackage = ({ packageData, CurrUser }) => {
    const { role } = CurrUser;
    const packageId = packageData?.id || packageData?._id;
    const packageName = packageData?.name;
    const [key, setKey] = useState('event_list');

    const [show, setShow] = useState(false);
    const [reset, setReset] = useState(true);

    const handleClose = () => {
        if (reset) {
            setSelectedTasks(packageTasks);
        }
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [showModal, setShowModal] = useState(null);
    const { handleSubmit } = useForm({
        mode: 'onBlur',
    });
    const teamId = getTeamIdFromURL();

    const [showValidateModel, setShowValidateModel] = useState(false);
    const [teamsPrivatePublic, setTeamsPrivatePublic] = useState('');
    const [intersectionOfAssignes, setIntersectionOfAssignes] = useState([]);
    const [finalizedAssignedUsers, setFinalizedAssignedUsers] = useState([]);
    const [finalizedNotificationDisabledFor, setFinalizedNotificationDisabledFor] = useState([]);
    const [confirmAssignSubmisstion, setConfirmAssignSubmisstion] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formPostData, setFormPostData] = useState({});

    const dispatch = useDispatch();
    const history = useHistory();
    const handleShowEvent = (id) => {
        setShowModal(id);
    };

    const handleEventClose = () => {
        setShowModal(null);
    };

    const packageTasks = packageData.taskData.map((task) => ({
        label: task.title,
        value: task._id,
        start: dayjs(task.latestDatePointer || task.date).toDate(),
        end: dayjs(task.latestDueDatePointer || task.dueDate).toDate(),
        ...task,
    }));

    const [selectedTasks, setSelectedTasks] = useState(packageTasks);

    const selectedTeams = packageData.teamData.map((team) => ({
        label: team.name,
        value: team.id,
    }));
    const teamIds = selectedTeams.map((team) => team.value);

    const [globalChannel, setGlobalChannel] = useState(null);

    const onTaskUpdateCallback = (task, id) => {
        setSelectedTasks((prev) =>
            prev.map((exTask) =>
                exTask._id === id ? { ...task, _id: id, label: task.title, value: id } : { ...exTask }
            )
        );
        return task;
    };

    const handleEditClick = (id, newTask) => {
        return onTaskUpdateCallback(newTask, id);
    };

    const onAssignChannelToEvent = (_id, event) => {
        onTaskUpdateCallback(event, _id);
    };

    const onAssignChannelToAllEvents = (_, data) => {
        if (!data) {
            return null;
        }
        const { channel, assignedUsers: assignedUser } = data;
        const assignedChannel = [channel?.value];
        const assignedChannelInfo = [channel];
        setSelectedTasks((prev) =>
            prev.map((task) => ({ ...task, assignedChannel, assignedChannelInfo, assignedUser }))
        );
    };

    useEffect(() => {
        const completeSubmission = async (postPackageData) => {
            try {
                setIsSubmitting(true);
                const updatedPostPackageData = {
                    ...postPackageData,
                    assignedUser: finalizedAssignedUsers,
                    notificationDisabledFor: finalizedNotificationDisabledFor,
                };
                await fetchWrapper.put(`activityPackages/${packageId}`, updatedPostPackageData);
                dispatch(setMessage({ error: false, message: 'Plan Edited successfully' }));
                history.go(0);
            } catch (error) {
                dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
            } finally {
                setConfirmAssignSubmisstion(false);
                setIsSubmitting(false);
            }
        };
        if (confirmAssignSubmisstion) {
            completeSubmission(formPostData);
        }
    }, [confirmAssignSubmisstion]);

    const onSubmit = async (data) => {
        if (!data) {
            return null;
        }
        try {
            setTeamsPrivatePublic('');
            const selectedTeamId = teamIds[0];
            const postPackageData = {
                name: packageName,
                team: selectedTeamId,
                events: selectedTasks.map(prepareEvent),
                assignedUser: selectedTasks[0]?.assignedUser,
            };
            setFormPostData(postPackageData);

            const allEventAssignedUsers = postPackageData.events.map((event) => event.assignedUser);
            const { intersection } = areAllArraysUnorderedEqual(allEventAssignedUsers);
            setIntersectionOfAssignes(intersection);
            let privateChannels = [];
            let publicChannels = [];

            selectedTasks?.forEach(({ assignedChannelInfo }) => {
                const channelInfo = Array.isArray(assignedChannelInfo) ? assignedChannelInfo[0] : assignedChannelInfo;
                const channelId = channelInfo?._id || channelInfo?.id || channelInfo?.value;
                channelId && (channelInfo?.type === 'private' ? privateChannels : publicChannels).push(channelId);
            });

            setShowValidateModel(true);
            setTeamsPrivatePublic(
                `${privateChannels?.length ? `&private=${privateChannels}` : ''}${
                    publicChannels?.length ? `&public=${publicChannels}` : ''
                }`
            );

            return;
        } catch (e) {
            console.log(e);
            dispatch(setMessage({ error: true, message: `Error ${e.message}` }));
        }
    };

    const [showGlobal, setShowGlobal] = useState(false);

    const GlobalhandleClose = () => setShowGlobal(false);

    const GlobalhandleShow = () => setShowGlobal(true);

    const [isGlobal, setIsGlobal] = useState(false);
    const handleGlobalAssign = () => {
        setIsGlobal(true);
        GlobalhandleShow();
    };
    const isView = true;

    const [fetchedTasks, setFetchedTasks] = useState([]);

    const handleFetchTask = async (id) => {
        if (!id || fetchedTasks.includes(id)) {
            return;
        }
        const response = await fetchWrapper.get(`/task/${id}`);
        setSelectedTasks((prev) => prev.map((task) => (task._id === id ? { ...task, ...response } : task)));
        setFetchedTasks((prev) => [...prev, id]);
    };

    return (
        <Fragment>
            <ToolTip text={'Assign Channel'} cssStyles={'top:-70% !important; min-width: 105px !important'}>
                <BiLinkExternal className="icon" onClick={handleShow} />
            </ToolTip>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header className="h2 font-weight-bold d-flex justify-content-center">
                    Assign Channel
                </Modal.Header>
                <Modal.Body className="m-2">
                    <Form className="w-90 m-auto" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                            <Form.Group as={Col} md={12}>
                                <Form.Label>Plan Name</Form.Label>
                                <Form.Control type="text" value={packageData.name} name="packageName" readOnly />
                            </Form.Group>

                            <Form.Group as={Col} md={12}>
                                <Form.Label>Events</Form.Label>
                                <TaskSelectFilter
                                    selectedOption={selectedTasks}
                                    disabled
                                    disableTaskFetch={true}
                                    isMulti
                                    styles={styles}
                                />
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="event_list" title={<BsList />}>
                                        {key === 'event_list' && (
                                            <AdminTable className="mb-5 w-100">
                                                <thead>
                                                    <tr>
                                                        <th>S.N.</th>
                                                        <th>Event Name</th>
                                                        <th>Date</th>
                                                        <th>Channel</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedTasks
                                                        ?.sort(
                                                            (a, b) =>
                                                                new Date(a.latestDatePointer || a.date) -
                                                                new Date(b.latestDatePointer || b.date)
                                                        )
                                                        ?.map((event, index) => (
                                                            <tr key={event._id}>
                                                                <td className="text-left p-1">{index + 1}</td>
                                                                <td className=" text-left p-1 pl-4">{event.title}</td>
                                                                <td className="text-left p-1">
                                                                    {dayjs(
                                                                        event.latestDatePointer || event.date
                                                                    ).format('MMMM D, YYYY h:mm A')}
                                                                </td>
                                                                <td className="text-left p-1">
                                                                    <ChannelSelectFilter
                                                                        onRoomSelect={(selectedRoom) => {
                                                                            onAssignChannelToEvent(event._id, {
                                                                                ...event,
                                                                                assignedChannel: selectedRoom,
                                                                            });
                                                                        }}
                                                                        assignedChannel={
                                                                            Array.isArray(event.assignedChannel)
                                                                                ? event.assignedChannel[0]
                                                                                : event.assignedChannel?.value ||
                                                                                  event.assignedChannel
                                                                        }
                                                                        teamIds={teamIds}
                                                                    />
                                                                </td>
                                                                <td
                                                                    className=" text-left p-1"
                                                                    onClick={(clickedEvent) => {
                                                                        if (
                                                                            !['SVG', 'PATH'].includes(
                                                                                clickedEvent.target?.tagName?.toUpperCase()
                                                                            )
                                                                        ) {
                                                                            return;
                                                                        }
                                                                        handleFetchTask(event._id);
                                                                    }}
                                                                >
                                                                    <ActionStyles>
                                                                        <div>
                                                                            <ToolTip
                                                                                text={'View'}
                                                                                cssStyles={
                                                                                    'top:-70% !important; min-width: fit-content !important;'
                                                                                }
                                                                            >
                                                                                <BsEye
                                                                                    onClick={() =>
                                                                                        handleShowEvent(event._id)
                                                                                    }
                                                                                    className="icon view"
                                                                                />
                                                                            </ToolTip>
                                                                        </div>
                                                                        <EventModal
                                                                            data={event}
                                                                            handleSubmitTask={(newTasks) =>
                                                                                handleEditClick(event?._id, newTasks)
                                                                            }
                                                                            teamIds={teamIds}
                                                                        />
                                                                    </ActionStyles>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </AdminTable>
                                        )}
                                    </Tab>
                                    <Tab eventKey="event_grid" title={<BsGrid />}>
                                        {key === 'event_grid' && (
                                            <EventPlannerCalendar
                                                isView={isView}
                                                events={selectedTasks}
                                                handleShowEvent={async (taskId) => {
                                                    handleShowEvent(taskId);
                                                    await handleFetchTask(taskId);
                                                }}
                                                handleEditEvent={handleEditClick}
                                                eventModalProps={{ teamIds }}
                                            />
                                        )}
                                    </Tab>
                                </Tabs>
                            </Form.Group>
                            {showModal ? (
                                <PreviewEventModal
                                    data={selectedTasks.find((task) => task._id === showModal)}
                                    handleClose={handleEventClose}
                                    // getAllTasks={getAllTasks}
                                />
                            ) : null}

                            <Form.Group as={Col} md={12}>
                                <Fragment>
                                    <div
                                        css={css`
                                            display: flex;
                                            width: 100%;
                                            justify-content: flex-end;
                                        `}
                                    >
                                        <Button variant="primary" onClick={handleGlobalAssign} disabled={isSubmitting}>
                                            Assign Channel Globally
                                        </Button>

                                        {isGlobal && (
                                            <AssignChannelToPackageModal
                                                show={showGlobal}
                                                handleClose={GlobalhandleClose}
                                                onAssignChanneltoEventCallback={onAssignChannelToAllEvents}
                                                isGlobal={isGlobal}
                                                selectedRoomOption={globalChannel}
                                                setSelectedRoomOption={(room) => {
                                                    setGlobalChannel(room);
                                                }}
                                                teamIds={teamIds}
                                            />
                                        )}
                                    </div>
                                </Fragment>
                            </Form.Group>

                            {teamId ? null : (
                                <Form.Group as={Col} md={12}>
                                    <Form.Label>Teams</Form.Label>
                                    <TeamSelectFilter
                                        selectedTeamOption={selectedTeams}
                                        disabled
                                        isMulti
                                        styles={styles}
                                    />
                                </Form.Group>
                            )}

                            <Form.Group as={Col} md={12}>
                                <div
                                    css={css`
                                        padding: 2rem 0 0 0;
                                        display: flex;
                                        width: 100%;
                                        justify-content: space-between;
                                    `}
                                >
                                    <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        Confirm
                                    </Button>
                                </div>
                            </Form.Group>
                            <ValidateUserAssignedToEntirePackageModal
                                showValidateModel={showValidateModel}
                                show={false}
                                handleClose={GlobalhandleClose}
                                onAssignChanneltoEventCallback={onAssignChannelToAllEvents}
                                isGlobal={isGlobal}
                                selectedRoomOption={globalChannel}
                                setSelectedRoomOption={(room) => {
                                    setGlobalChannel(room);
                                }}
                                teamIds={teamIds}
                                setShowValidateModel={setShowValidateModel}
                                teamId={teamId || teamIds[0]}
                                teamsPrivatePublic={teamsPrivatePublic}
                                intersectionOfAssignes={intersectionOfAssignes}
                                setFinalizedAssignedUsers={setFinalizedAssignedUsers}
                                setFinalizedNotificationDisabledFor={setFinalizedNotificationDisabledFor}
                                finalizedAssignedUsers={finalizedAssignedUsers}
                                setConfirmAssignSubmisstion={setConfirmAssignSubmisstion}
                                confirmAssignSubmisstion={confirmAssignSubmisstion}
                                actionMode={'Edit'}
                            />
                        </Form.Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
const mapStateToProps = (state) => ({
    CurrUser: state.user.user,
});
const mapDispatchToProps = { setMessage, editTheTask };

export default connect(mapStateToProps, mapDispatchToProps)(AssignChannelToPackage);
