import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../../../../_helpers';
import { css } from 'twin.macro';

const SavePeriodModal = ({ payallocation }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const savePeriodDraft = async () => {
        try {
            setIsBusy(true);
            const team = payallocation?.provider?.teamId;
            if (!team) {
                return toast.error('Please select a team');
            }
            await fetchWrapper.post(`drafts?team=${team}`, {
                payallocation: { ...(payallocation || {}), provider: undefined },
            });
            toast.success('Period saved successfully');
            handleClose();
        } catch (error) {
            toast.error(error?.message || 'Something went wrong while saving period');
        } finally {
            setIsBusy(false);
        }
    };
    return (
        <div>
            <Button
                disabled={isBusy}
                variant="secondary"
                onClick={() => {
                    handleShow();
                }}
                css={css`
                    width: 128px;
                `}
            >
                Save Period
            </Button>
            <Modal show={show} onHide={() => handleClose()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Save Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to save this period?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" disabled={isBusy} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="btn btn-primary" disabled={isBusy} onClick={() => savePeriodDraft()}>
                        Confirm Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    payallocation: state.payallocation,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SavePeriodModal);
