import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { css } from 'twin.macro';

import { AdminTable } from '../../reusableStyles';

import LoadingSpiner from '../../../../_elements/LoadingSpiner';
import Pagination from '../../Pagination';

import { toast } from 'react-toastify';
import { fetchWrapper, getTeamIdFromURL, isAdminPanel, toastWrapper } from '../../../../_helpers';
import SortedTableHeaderIcon from '../../SortedTableHeaderIcon';
import TeamSelectFilter from '../../TeamSelectFilter';
import ChannelSelectFilter from '../../ChannelSelectFilter';
import ReactSelectComponent from '../../users/ReactSelectComponent';

import { AiOutlineClose } from 'react-icons/ai';
import AccessForm from './AccessForm';
import ReactSelect from 'react-select';
import { ToolTip } from '../../../../_elements/ToolTip';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Avatar from '../../reusableComponents/Avatar';
import TeamAccessForm from './TeamAccessForm';

const Access = () => {
    const teamId = getTeamIdFromURL();
    const [accesses, setAccesses] = useState({ results: [], page: 1, totalResults: 0, limit: 10 });

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);

    const history = useHistory();
    const isAdmin = isAdminPanel();

    const [query, setQuery] = useState({
        type: { label: 'Select Type', value: '' },
        team: { label: 'Select a Team', value: '' },
        room: { label: 'Select a Room', value: '' },
        user: { label: 'Type to search user...', value: '' },
    });

    const getAccesses = async (data) => {
        try {
            setIsLoading(true);
            const response = await fetchWrapper.get(
                `sms/access?limit=10&page=${Math.max(1, data.page || 1)}${
                    data.team?.value || teamId ? `&team=${data.team.value || teamId}` : ''
                }${data.room?.value ? `&room=${data.room.value}` : ''}${
                    data.type?.value ? `&type=${data.type.value}` : ''
                }${data.user?.value ? `&user=${data.user.value}` : ''}&sortBy=${data.sortBy || 'createdAt:desc'}`
            );
            setAccesses(response);
        } catch (err) {
            if (err?.name === 'AbortError') return;
            toastWrapper.error(err.message || err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (accessId) => {
        try {
            setIsDeleting(true);
            const shouldDelete = await Swal.fire('Are you sure?');
            if (!shouldDelete.value) {
                return;
            }
            await fetchWrapper.delete(`sms/access/${accessId}`);
            await getAccesses({
                ...query,
                page: currentPage,
                sortBy: `${sortConfig.key}:${sortConfig.direction}`,
            });
        } catch (err) {
            toast.error(err.message || err);
        } finally {
            setIsDeleting(false);
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

    const handleSort = (key) => {
        if (sortConfig.key === key) {
            return setSortConfig((prev) => ({ ...prev, direction: prev.direction === 'asc' ? 'desc' : 'asc' }));
        }
        setSortConfig({ key, direction: 'desc' });
    };

    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            history.push(`/sms?page=${data.currentPage}`);
            getAccesses({ ...query, page: data.currentPage, sortBy: `${sortConfig.key}:${sortConfig.direction}` });
        }
    };

    useEffect(() => {
        if (!isLoading) {
            getAccesses({ ...query, page: currentPage, sortBy: `${sortConfig.key}:${sortConfig.direction}` });
        }
    }, [sortConfig, query]);

    return (
        <div>
            {isAdmin ? (
                <div
                    className="d-flex flex-row align-items-center mb-4 flex-wrap"
                    css={css`
                        gap: 12px;
                    `}
                >
                    <div style={{ width: '152px', position: 'relative' }}>
                        <ReactSelect
                            options={[
                                { label: 'Teams', value: 'team' },
                                { label: 'Rooms', value: 'room' },
                                { label: 'Users', value: 'user' },
                            ]}
                            value={query.type}
                            onChange={(option) => setQuery((prev) => ({ ...prev, type: option }))}
                            isDisabled={isLoading}
                        />
                        <AiOutlineClose
                            style={{
                                position: 'absolute',
                                cursor: 'pointer',
                                top: '12px',
                                right: '42px',
                                opacity: query.type?.value ? (isLoading ? 0.25 : 0.5) : 0,
                            }}
                            onClick={() => {
                                setQuery((prev) => ({ ...prev, type: { label: 'Select Type' } }));
                            }}
                        />
                    </div>
                    <TeamSelectFilter
                        selectedTeamOption={query.team}
                        setSelectedTeamOption={(option) =>
                            setQuery((prev) => ({
                                ...prev,
                                team: option,
                                room: option?.value ? prev.room : { label: 'Select a Room' },
                            }))
                        }
                        disabled={isLoading}
                    />
                    <ChannelSelectFilter
                        teamIds={[query.team?.value]}
                        selectedOption={query.room}
                        onRoomSelect={(option) => setQuery((prev) => ({ ...prev, room: option }))}
                        disabled={isLoading}
                        tbdRoom={true}
                    />
                    <div style={{ width: '256px', position: 'relative' }}>
                        <ReactSelectComponent
                            selectedOption={query.user}
                            setSelectedOption={(option) => setQuery((prev) => ({ ...prev, user: option }))}
                            isDisabled={isLoading}
                        />
                        <AiOutlineClose
                            style={{
                                position: 'absolute',
                                cursor: 'pointer',
                                top: '12px',
                                right: '42px',
                                opacity: query.user?.value ? (isLoading ? 0.25 : 0.5) : 0,
                            }}
                            onClick={() => {
                                setQuery((prev) => ({ ...prev, user: { label: 'Type to search user...' } }));
                            }}
                        />
                    </div>
                    <AccessForm afterSubmit={getAccesses} />
                </div>
            ) : (
                <div
                    className="d-flex flex-row align-items-center mb-4 flex-wrap"
                    css={css`
                        gap: 12px;
                    `}
                >
                    <div style={{ width: '256px', position: 'relative' }}>
                        <ChannelSelectFilter
                            teamIds={[query.team?.value]}
                            selectedOption={query.room}
                            onRoomSelect={(option) => setQuery((prev) => ({ ...prev, room: option }))}
                            disabled={isLoading}
                            tbdRoom={true}
                        />
                    </div>
                </div>
            )}

            <div
                className="custom-card card-table d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <AdminTable className="w-100 text-dark-grey">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th className="text-center">
                                Applies To
                                {!isAdmin && ' (Channel)'}
                            </th>
                            <th className="text-center">Phone Number</th>
                            <th
                                className="text-center"
                                onClick={() => handleSort('characterLength')}
                                style={{ cursor: 'pointer' }}
                            >
                                Character Count
                                <SortedTableHeaderIcon
                                    sortConfig={sortConfig}
                                    fieldName="characterLength"
                                    key="characterLength"
                                />
                            </th>
                            <th
                                className="text-center"
                                onClick={() => handleSort('messages')}
                                style={{ cursor: 'pointer' }}
                            >
                                Messages
                                <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="messages" key="messages" />
                            </th>
                            <th
                                className="text-center"
                                onClick={() => handleSort('totalCost')}
                                style={{ cursor: 'pointer' }}
                            >
                                Total Cost
                                <SortedTableHeaderIcon sortConfig={sortConfig} fieldName="totalCost" key="totalCost" />
                            </th>
                            <th className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-light-grey">
                        {isLoading ? (
                            <td colSpan={7}>
                                <LoadingSpiner size={42} height={200} />
                            </td>
                        ) : accesses?.results?.length ? (
                            accesses.results.map((access, index) => {
                                return (
                                    <tr key={access._id || access.id}>
                                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                                        <td className="text-center">
                                            {access.type ? (
                                                <span className="badge badge-primary px-3 py-2">
                                                    All {access.type[0].toUpperCase()}
                                                    {access.type.slice(1)}s
                                                </span>
                                            ) : (
                                                <div
                                                    className="d-flex flex-column"
                                                    style={{ gap: '3px', alignItems: 'center' }}
                                                >
                                                    {access.team?.name && isAdmin ? (
                                                        <span className="text-center badge badge-info px-3 py-2">
                                                            Team: {access.team?.name}
                                                        </span>
                                                    ) : null}
                                                    {access.room.title ? (
                                                        <span className="text-center badge badge-info px-3 py-2">
                                                            Room: {access.room?.title}
                                                        </span>
                                                    ) : null}
                                                    {access.room.replacable ? (
                                                        <span className="text-center badge badge-warning px-3 py-2">
                                                            Room: Please select a room!
                                                        </span>
                                                    ) : null}
                                                    {access.user?.name ? (
                                                        <div className="d-flex flex-row  align-items-center">
                                                            <Avatar item={access.user} />
                                                            <div>
                                                                <span className="text-center badge badge-info">
                                                                    User: {access.user?.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </td>
                                        <td className="text-center">{access.phoneNumber || '-'}</td>
                                        <td className="text-center">{access.characterLength || '-'}</td>
                                        <td className="text-center">{access.messages || '-'}</td>
                                        <td className="text-center">
                                            {access.totalCost ? `$${Math.round(access.totalCost * 100) / 100}` : '-'}
                                        </td>
                                        <td className="text-center ">
                                            {isAdmin ? (
                                                <Fragment>
                                                    <AccessForm
                                                        accessData={access}
                                                        afterSubmit={() => getAccesses(query)}
                                                        mode="edit"
                                                        ButtonContent={
                                                            <ToolTip
                                                                text={'Edit'}
                                                                side={'top'}
                                                                cssStyles={
                                                                    'top:-70% !important; min-width: fit-content !important'
                                                                }
                                                            >
                                                                <BsPencil className="icon edit" />
                                                            </ToolTip>
                                                        }
                                                        buttonVariant="outline-primary"
                                                    />
                                                    <Button
                                                        variant="outline-danger"
                                                        className="ml-2"
                                                        onClick={() => handleDelete(access._id)}
                                                        disabled={isDeleting}
                                                    >
                                                        <BsTrash />
                                                    </Button>
                                                </Fragment>
                                            ) : (
                                                <TeamAccessForm
                                                    afterSubmit={getAccesses}
                                                    teamId={teamId}
                                                    accessData={access}
                                                    ButtonContent={
                                                        <ToolTip
                                                            text={'Edit'}
                                                            side={'top'}
                                                            cssStyles={
                                                                'top:-70% !important; min-width: fit-content !important'
                                                            }
                                                        >
                                                            <BsPencil className="icon edit" />
                                                        </ToolTip>
                                                    }
                                                    buttonVariant="outline-primary"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={9}>
                                    <div
                                        className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                        css={css`
                                            margin-top: 100px;
                                            width: 100%;
                                        `}
                                    >
                                        No Access Rule Found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </AdminTable>

                {accesses.totalResults ? (
                    <div className="m-4">
                        <Pagination
                            pageNo={currentPage}
                            totalRecords={accesses.totalResults}
                            pageLimit={10}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Access);
