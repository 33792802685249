import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Courses from './Courses';
import Certificates from './Certificates';
import { fetchWrapper, getChannelIdFromSettingsURL, getTeamIdFromURL } from '../../../../../_helpers';
import TeamUsers from './Users';
const TeamCourses = () => {
    const [key, setKey] = useState('users');
    const roomIdFromUrl = getChannelIdFromSettingsURL();
    const teamId = getTeamIdFromURL();
    const [userList, setUserList] = useState([]);
    const [usersObj, setUsersObj] = useState({});
    const [loading, setLoading] = useState(true);

    const handleAmolUserRegister = (userId) => {
        const updatedUserList = userList?.map((user) =>
            (user?.id || user?._id) === userId ? { ...user, isAmolRegistered: true } : user
        );
        setUserList(updatedUserList);
    };
    useEffect(() => {
        const fetchTeamUsers = async () => {
            try {
                if (!teamId && !roomIdFromUrl) {
                    return;
                }
                setLoading(true);
                let response;
                if (roomIdFromUrl) {
                    response = await fetchWrapper.get(`chatrooms/${roomIdFromUrl}/members?limit=1000`);
                } else {
                    response = await fetchWrapper.get(
                        `organizations/5f3569c2647fb5000c019e42/teams/${teamId}/members?limit=1000`
                    );
                }
                setUserList(
                    (response?.results || [])?.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
                );
                setUsersObj(
                    response?.results?.reduce((acc, user) => {
                        acc[user['email']] = user;
                        return acc;
                    }, {})
                );
            } catch (err) {
                if (err.name === 'AbortError') {
                    return;
                }
                console.log('err', err);
            } finally {
                setLoading(false);
            }
        };
        fetchTeamUsers();
    }, []);

    return (
        <div>
            <h1 className="mb-4">Courses</h1>
            <div className="top-items"></div>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="users" title="Users">
                    {key === 'users' && (
                        <div>
                            <TeamUsers
                                loading={loading}
                                users={userList}
                                handleAmolUserRegister={handleAmolUserRegister}
                            />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="courses" title="Courses">
                    {key === 'courses' && <Courses usersObj={usersObj} />}
                </Tab>
            </Tabs>
        </div>
    );
};

const mapStateToProps = (state) => ({
    // currUser: state.user.user,
});

export default connect(mapStateToProps, {})(TeamCourses);
