export const OPTION_COORDINATES = [
    {
        config: {
            templateConfig: {
                x: 0.04774692299756603,
                y: 0.05536332179930796,
                width: 0.03581020590868397,
                height: 0.02768166089965398,
                page: 1,
            },
            rectConfig: {
                x: 53.33331298828125,
                y: 80,
                width: 40,
                height: 40,
                page: 1,
            },
        },
    },
    {
        config: {
            templateConfig: {
                x: 0.04685166784984893,
                y: 0.09688581314878893,
                width: 0.03581020590868397,
                height: 0.02768166089965398,
                page: 1,
            },
            rectConfig: {
                x: 52.33331298828125,
                y: 140,
                width: 40,
                height: 40,
                page: 1,
            },
        },
    },
    {
        config: {
            templateConfig: {
                x: 0.04685166784984893,
                y: 0.13771626297577855,
                width: 0.03581020590868397,
                height: 0.02768166089965398,
                page: 1,
            },
            rectConfig: {
                x: 52.33331298828125,
                y: 199,
                width: 40,
                height: 40,
                page: 1,
            },
        },
    },
];

export const GENERIC_FIELD_DATA = {
    generic: {
        config: {
            templateConfig: {
                x: 0.021784523713770144,
                y: 0.016574585635359115,
                width: 0.21575649059982094,
                height: 0.04143646408839779,
                page: 1,
            },
            rectConfig: {
                x: 24.33331298828125,
                y: 24,
                width: 241,
                height: 60,
                page: 1,
            },
        },
    },
    checkboxOrRadio: OPTION_COORDINATES[0],
};
export const RESIZE_HANDLE_POSITIONS = {
    'top-left': `left: -5px; top: -5px;`,
    'top-right': `right: -5px; top: -5px;`,
    'bottom-left': `left: -5px; bottom: -5px;`,
    'bottom-right': `right: -5px; bottom: -5px;`,
    'top-center': `left: 50%; top: -5px; transform: translateX(-50%);`,
    'right-center': `right: -5px; top: 50%; transform: translateY(-50%);`,
    'bottom-center': `left: 50%; bottom: -5px; transform: translateX(-50%);`,
    'left-center': `left: -5px; top: 50%; transform: translateY(-50%);`,
};

// Smart PDF fields enums
export const SMART_PDF_ALLOWED_ELEMENT_KEYS = ['InputField', 'Signature', 'Checkbox', 'Radio', 'Select'];
export const NON_GENERIC_ELEMENT_KEYS = ['Checkbox', 'Radio', 'ServiceRadio'];
export const AUTO_MAX_LENGTH_FIELD_KEYS = ['InputField'];

// Smart PDF dimension constants
export const DEFAULT_FONT_SIZE = 9;
export const DEFAULT_LINE_HEIGHT_FACTOR = 1.1;
