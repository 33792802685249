import React, { Fragment, useState } from 'react';
import { css } from 'twin.macro';

import { connect } from 'react-redux';
import { Button, Modal, FormControl, FormLabel, InputGroup, FormCheck } from 'react-bootstrap';
import { StyledForm } from './styles';

import Select from 'react-select';

import ReactSelectComponent from '../../users/ReactSelectComponent';
import { toast } from 'react-toastify';

import TeamSelectFilter from '../../TeamSelectFilter';
import ChannelSelectFilter from '../../ChannelSelectFilter';

import { AiOutlineClose } from 'react-icons/ai';
import { fetchWrapper } from '../../../../_helpers';
import { ConsentToolTip } from '../../../../_elements/ToolTip';

const accessTypes = {
    team: 'Teams',
    room: 'Rooms',
    user: 'Users',
};

const getOptionFromData = (data = {}, noun) => ({
    label: data.name || data.title || data.label || 'Select a ' + noun,
    value: data.id || data._id || data.value || '',
});

const AccessForm = ({
    mode = 'create',
    accessData = {},
    afterSubmit = async () => {},
    ButtonContent = 'Create Access Rule',
    buttonVariant = 'primary',
}) => {
    const [data, setData] = useState({
        type: { value: accessData.type || 'team', label: accessTypes[accessData.type || 'team'] },
        team: getOptionFromData(accessData.team, 'Team'),
        room: getOptionFromData(accessData.room, 'Room'),
        user: getOptionFromData(accessData.user, 'User'),
        characterLength: accessData.characterLength,
        phoneNumber: accessData.phoneNumber,
        totalCost: accessData.totalCost,
        messages: accessData.messages,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDefaultAccess, setIsDefaultAccess] = useState(accessData ? !!accessData.type : true);
    const [isSelectRoomLater, setIsSelectRoomLater] = useState(
        accessData?.room && accessData?.room?.replacable ? true : false
    );
    const [showModal, setShowModal] = useState(false);

    const handleModalShow = () => {
        setShowModal(true);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const onSubmit = async () => {
        try {
            setIsSubmitting(true);
            if (!data.phoneNumber) {
                return toast.error('Phone number is required to send SMS');
            }
            if (!data.characterLength && !data.totalCost && !data.messages) {
                return toast.error('One of character count, total cost or message count is required.');
            }
            if (!isDefaultAccess && !data.team?.value && !data.user?.value && !data.room?.value) {
                return toast.error('One of team, room or user is required for specific access rule.');
            }
            const accessRule = {
                ...(data.characterLength ? { characterLength: parseInt(data.characterLength) } : {}),
                ...(data.totalCost ? { totalCost: parseInt(data.totalCost) } : {}),
                ...(data.messages ? { messages: parseInt(data.messages) } : {}),
                phoneNumber: data.phoneNumber,
            };
            const postData = isDefaultAccess
                ? {
                      type: data.type?.value || 'team',
                      ...accessRule,
                  }
                : {
                      ...(data.team?.value ? { team: data.team?.value } : {}),
                      ...(data.room?.value || isSelectRoomLater
                          ? { room: isSelectRoomLater ? '0'.repeat(24) : data.room?.value }
                          : {}),
                      ...(data.user?.value ? { user: data.user?.value } : {}),
                      ...accessRule,
                  };

            const response = await fetchWrapper[mode === 'create' ? 'post' : 'put'](
                `/sms/access/${mode === 'create' ? '' : accessData._id || accessData.id}`,
                postData
            );
            await afterSubmit(response);
            setShowModal(false);
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Button variant={buttonVariant} onClick={handleModalShow}>
                {ButtonContent}
            </Button>
            {showModal && (
                <Modal show={showModal} onHide={handleHideModal} centered backdrop="static">
                    <Modal.Header closeButton>
                        <div className="header">
                            <h4
                                className="d-flex align-items-center "
                                css={css`
                                    margin: 0;
                                `}
                            >
                                {{ create: 'Create', edit: 'Edit' }[mode]} Access Rule
                            </h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <StyledForm onSubmit={(event) => event.preventDefault()}>
                            <div
                                className="d-flex"
                                css={css`
                                    align-items: center;
                                    gap: 6px;
                                `}
                            >
                                <FormCheck
                                    className="mt-2"
                                    id="isDefault"
                                    checked={isDefaultAccess}
                                    onChange={(event) => {
                                        setIsDefaultAccess(event.target.checked);
                                    }}
                                    disabled={isSubmitting}
                                />
                                <FormLabel htmlFor="isDefault">Default Access Rule</FormLabel>
                            </div>
                            {!isDefaultAccess ? (
                                <>
                                    <FormLabel>Team</FormLabel>
                                    <TeamSelectFilter
                                        selectedTeamOption={data.team}
                                        setSelectedTeamOption={(option) =>
                                            setData((prev) => ({ ...prev, team: option }))
                                        }
                                        disabled={isSubmitting}
                                    />
                                    <hr />
                                    <div
                                        className="d-flex"
                                        css={css`
                                            align-items: center;
                                            gap: 6px;
                                        `}
                                    >
                                        <FormCheck
                                            className="mt-2"
                                            id="_isSetRoomLater"
                                            checked={isSelectRoomLater}
                                            onChange={(event) => {
                                                setIsSelectRoomLater(event.target.checked);
                                            }}
                                            disabled={isSubmitting}
                                        />
                                        <div className="d-flex flex-row align-items-center">
                                            <FormLabel htmlFor="_isSetRoomLater">Set Room Later</FormLabel>
                                            <ConsentToolTip text="Team Managers can assign room from team settings, if this is checked" />
                                        </div>
                                    </div>

                                    <FormLabel>Room</FormLabel>
                                    <div style={{ position: 'relative' }}>
                                        <ChannelSelectFilter
                                            teamIds={[data.team?.value]}
                                            selectedOption={data.room}
                                            onRoomSelect={(option) => setData((prev) => ({ ...prev, room: option }))}
                                            disabled={isSubmitting || isSelectRoomLater}
                                        />
                                        <AiOutlineClose
                                            style={{
                                                position: 'absolute',
                                                cursor: 'pointer',
                                                top: '12px',
                                                right: '48px',
                                                opacity: data.room?.value ? (isSubmitting ? 0.25 : 0.5) : 0,
                                            }}
                                            onClick={() => {
                                                setData((prev) => ({
                                                    ...prev,
                                                    room: { label: 'Select a Room' },
                                                }));
                                            }}
                                        />
                                    </div>
                                    <FormLabel>User</FormLabel>
                                    <div style={{ position: 'relative' }}>
                                        <ReactSelectComponent
                                            selectedOption={data.user}
                                            setSelectedOption={(option) =>
                                                setData((prev) => ({ ...prev, user: option }))
                                            }
                                            isDisabled={isSubmitting}
                                        />
                                        <AiOutlineClose
                                            style={{
                                                position: 'absolute',
                                                cursor: 'pointer',
                                                top: '12px',
                                                right: '42px',
                                                opacity: data.user?.value ? (isSubmitting ? 0.25 : 0.5) : 0,
                                            }}
                                            onClick={() => {
                                                setData((prev) => ({
                                                    ...prev,
                                                    user: { label: 'Type to search user...' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <FormLabel>Default rule applies to all:</FormLabel>
                                    <Select
                                        options={[
                                            { label: 'Teams', value: 'team' },
                                            { label: 'Rooms', value: 'room' },
                                            { label: 'Users', value: 'user' },
                                        ]}
                                        value={data.type}
                                        onChange={(option) => setData((prev) => ({ ...prev, type: option }))}
                                        isDisabled={isSubmitting}
                                    />
                                </>
                            )}
                            <FormLabel>Phone Number (To Send SMS From)</FormLabel>
                            <FormControl
                                type="tel"
                                name="phoneNumber"
                                placeholder="XXX-XXX-XXXX"
                                value={data.phoneNumber}
                                onChange={(event) => setData((prev) => ({ ...prev, phoneNumber: event.target.value }))}
                                disabled={isSubmitting}
                            ></FormControl>
                            <FormLabel>Total Cost</FormLabel>
                            <InputGroup>
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <FormControl
                                    type="number"
                                    name="totalCost"
                                    placeholder="eg: 100"
                                    value={data.totalCost}
                                    onChange={(event) =>
                                        setData((prev) => ({ ...prev, totalCost: event.target.value }))
                                    }
                                    disabled={isSubmitting}
                                ></FormControl>
                            </InputGroup>
                            <div className="d-flex flex-row align-items-center">
                                <FormLabel>SMS Count</FormLabel>
                                <ConsentToolTip text="Daily SMS limit count" />
                            </div>
                            <FormControl
                                type="number"
                                name="messages"
                                placeholder="eg: 500"
                                value={data.messages}
                                onChange={(event) => setData((prev) => ({ ...prev, messages: event.target.value }))}
                                disabled={isSubmitting}
                            ></FormControl>
                            <FormLabel>Character Count</FormLabel>
                            <FormControl
                                type="number"
                                name="characterLength"
                                placeholder="eg: 1000000"
                                value={data.characterLength}
                                onChange={(event) =>
                                    setData((prev) => ({ ...prev, characterLength: event.target.value }))
                                }
                                disabled={isSubmitting}
                            ></FormControl>
                        </StyledForm>
                        <div
                            css={css`
                                display: flex;
                                padding: 12px 0 0;
                                justify-content: flex-end;
                            `}
                        >
                            <Button onClick={onSubmit} disabled={isSubmitting}>
                                Submit
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AccessForm);
