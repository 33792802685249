import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import EvvCreateOptions from '../../../Admin/EVVContainer/EvvCreateModal/EvvCreateOptions';
import { AddMemberStyle } from '../../Teams/TeamSettings/Members';
import EvvCreateModal from '../../../Admin/EVVContainer/EvvCreateModal/EvvCreateModal';
import BulkModal from './BulkModal';
import SingleModal from './SingleModal';
import { getTeamIdFromURL } from '../../../../_helpers';
const bulkCategory = 'contact';
const CreateContacts = ({ onCreateOrUpdate = () => {} }) => {
    const teamId = getTeamIdFromURL();
    const [showModal, setShowModal] = useState(false);
    const [createMode, setCreateMode] = useState(null);

    const handleModalShow = () => {
        setCreateMode(null);
        setShowModal(true);
    };
    const handleCloseAllModal = () => {
        setShowModal(false);
    };

    const handleGoBack = () => {
        setCreateMode(null);
    };

    let content = null;
    switch (createMode) {
        default:
            content = (
                <EvvCreateOptions type={bulkCategory} setCreateProviderMode={setCreateMode} fileType="excel (.xlsx)" />
            );
            break;
    }

    return (
        <>
            <div css={AddMemberStyle}>
                <Dropdown.Item style={{ borderRadius: '4px' }} onClick={handleModalShow}>
                    <FaPlusCircle />
                </Dropdown.Item>
            </div>
            {showModal && !createMode && (
                <EvvCreateModal
                    type={bulkCategory}
                    showModal={showModal}
                    hideModal={handleCloseAllModal}
                    content={content}
                    goBack={createMode ? handleGoBack : null}
                />
            )}
            {createMode === 'individual' && (
                <SingleModal
                    hideModal={handleCloseAllModal}
                    show={showModal}
                    setShow={setShowModal}
                    teamId={teamId}
                    isTeamSettings={true}
                    onCreateOrUpdate={onCreateOrUpdate}
                />
            )}
            {createMode === 'bulk' && (
                <BulkModal
                    bulkCategory={bulkCategory}
                    hideModal={handleCloseAllModal}
                    show={showModal}
                    setShow={setShowModal}
                    onCreateOrUpdate={onCreateOrUpdate}
                />
            )}
        </>
    );
};

export default connect(null, {})(CreateContacts);
