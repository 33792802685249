import { css } from '@emotion/react';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DownloadBtn from '../../ShareBtn/DownloadBtn';

const courseStyles = css`
    background-color: #f5f5f5;

    .course-certificate-body {
        padding: 10px;
        @media only screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
    @media only screen and (max-width: 767px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .iFrame {
        width: 100%;
        height: calc(95vh - 100px);
    }
`;

function CertificateModal({ show, handleClose, courseId, user, amolUserId, certificateUrl }) {
    return (
        <Modal show={Boolean(show)} onHide={handleClose} centered size="xl">
            <Modal.Body>
                <div css={courseStyles}>
                    <div className="course-certificate-body">
                        <div
                            className="holds-the-iframe"
                            css={css`
                                background: url(/images/ball-triangle.svg) center center no-repeat;
                            `}
                        >
                            {certificateUrl ? (
                                <div style={{ maxHeight: '100%', overflow: 'auto', border: '1px solid #ddd' }}>
                                    <div className="d-flex flex-row justify-content-end">
                                        <div
                                            css={css`
                                                    height:40px;
                                                            display: flex;
                                                            flex-direction: row;
                                                            .shareBtn {
                                                                z-index 10;
                                                                opacity: 1;
                                                            }
                                                                position:relative;
                                                        `}
                                            className="cursor-pointer "
                                        >
                                            <DownloadBtn url={certificateUrl} buttonText={'Download Now'} />
                                        </div>
                                    </div>
                                    {certificateUrl.endsWith('.pdf') ? (
                                        <iframe
                                            title="certificate-pdf"
                                            className="iFrame"
                                            src={certificateUrl}
                                            style={{ width: '100%', border: 'none' }}
                                        />
                                    ) : (
                                        <img
                                            src={certificateUrl}
                                            alt="Certificate"
                                            style={{ width: '100%', height: 'auto', display: 'block' }}
                                        />
                                    )}
                                </div>
                            ) : (
                                <iframe
                                    title="iframe-123"
                                    className="iFrame"
                                    src={`${
                                        process.env.REACT_APP_AMOL_URL
                                    }/AMOL/index.php?page=certificate&course_id=${courseId}&user_id=${amolUserId}&sso_secret=6978191bdce4dab98379961c7050886210395241dbbf706ebcf3f6fee224a13d&sso_first_name=${
                                        user.name.split(' ')[0]
                                    }&sso_last_name=${
                                        user.name.split(' ')[user.name.split(' ').length - 1]
                                    }&sso_email_address=${user.email}`}
                                ></iframe>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CertificateModal;
