import { css } from 'twin.macro';
import { Modal } from 'react-bootstrap';
import { ModalCSS } from '../../styles/DocumentsStyled';
import SubmittedFormsView from '../../../../../SubmittedFormsView/SubmittedFormsView';

const BulkSubmissionModal = ({ form, teamId, teamIds, channel, member, startDate, endDate, hideFormPreview }) => {
    return (
        <Modal css={ModalCSS} show={form} backdropClassName="bg-dark" onHide={hideFormPreview} centered size="lg">
            <Modal.Header closeButton>
                <h4
                    css={css`
                        margin: 0;
                    `}
                >
                    {form.name}
                </h4>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <SubmittedFormsView
                    teamId={teamId}
                    teamIds={teamIds}
                    form={form}
                    filters={{
                        channel: channel?.value,
                        userId: member?.value,
                        startDate: startDate,
                        endDate: endDate,
                    }}
                />
            </Modal.Body>
        </Modal>
    );
};

export default BulkSubmissionModal;
