import { useState, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { BootstrapToolTip } from '../../../../../../_elements/ToolTip';
import { ActionStyles } from '../../../../reusableStyles';
import { BsPencil } from 'react-icons/bs';
import AddShiftForm from './AddShiftForm';
import { connect } from 'react-redux';
import { VscLoading } from 'react-icons/vsc';
function getShiftAmount(shift, shiftName) {
    let amount = 0;
    try {
        switch (shift?.type || shiftName) {
            case 'hourly':
            case 'overtime':
            case 'doubletime':
            case 'pto':
            case 'vacation':
            case 'sick':
            case 'holiday':
            case 'correction':
                amount =
                    parseFloat(shift?.hours || shift?.[shiftName]?.hours || 0) *
                    parseFloat(shift?.wage || shift?.[shiftName]?.wage || 0);
                break;
            case 'reimbursement':
            case 'commodity':
            case 'commission':
            case 'nondiscretionary_bonus':
            case 'discretionary_bonus':
            case 'charged_tips':
            case 'flat':
                amount = parseFloat(shift?.amount || shift?.[shiftName]?.amount || 0);
                break;
            case 'piecework':
                amount =
                    parseFloat(shift?.amount_per_unit || shift?.[shiftName]?.amount_per_unit || 0) *
                    parseInt(shift?.units || shift?.[shiftName]?.units || 0);
                break;
            case 'gross_up':
                amount = parseFloat(shift?.desired_net || shift?.[shiftName]?.desired_net || 0);
                break;
            case 'shareholder_healthcare_reimbursement':
                amount = parseFloat(shift?.flat || shift?.[shiftName]?.flat || 0);
                break;
            default:
                amount = 0;
        }
        return amount?.toFixed(2);
    } catch (err) {
        console.log('err', err);
        return 0;
    }
}
function EarningsModal({
    companyID,
    employeeID,
    isBusy,
    handleSubmit,
    mode,
    teamId,
    workLocations,
    payallocation,
    setIsBusy,
}) {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };

    const shifts = (payallocation?.employeesEarnings[employeeID]?.shifts || []).filter(
        (shift) => shift?.metadata?.label !== 'mileage' && shift?.metadata?.auto !== true
    );

    const totalAmount = shifts.reduce((sum, shift) => {
        return parseFloat(sum) + parseFloat(getShiftAmount(shift));
    }, 0);
    return (
        <Fragment>
            {isBusy ? (
                <VscLoading className="spin" />
            ) : (
                <div className="d-flex align-items-center">
                    <div>$ {totalAmount}</div>
                    <ActionStyles>
                        <BootstrapToolTip text="Edit Earnings/Shifts">
                            <BsPencil className="icon edit" onClick={handleShow} />
                        </BootstrapToolTip>
                    </ActionStyles>
                </div>
            )}

            <Modal size="lg" centered show={show} onHide={handleClose} backdrop="static">
                <Modal.Header>Shifts</Modal.Header>
                <Modal.Body>
                    <AddShiftForm
                        companyID={companyID}
                        data={{
                            employeeID,
                            check_date: payallocation?.config?.defaultCheckDate,
                            shifts,
                        }}
                        mode={mode}
                        isBusy={mode === 'view' || isBusy}
                        onSubmit={handleSubmit}
                        teamId={teamId}
                        workLocations={workLocations}
                        handleClose={handleClose}
                        setIsBusy={setIsBusy}
                    />
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}
const mapStateToProps = (state) => ({
    payallocation: state.payallocation,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EarningsModal);
