import { useState } from 'react';
import styled from '@emotion/styled';

// Styled components
const ButtonContainer = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 50px;
    border: 1px solid ${(props) => props.borderColor || '#000'};
    background-color: ${(props) => props.backgroundColor || '#fff'};
    color: ${(props) => props.textColor || '#000'};
    padding: 0;
    height: 48px;
    width: ${(props) => (props.isHovered ? 'auto' : '48px')};
    transition: width 1s ease-in-out;
    box-shadow: ${(props) => (props.isHovered ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 2px 5px rgba(0, 0, 0, 0.1)')};
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
`;

const TextContainer = styled.div`
    padding-right: 24px;
    white-space: nowrap;
    opacity: ${(props) => (props.isHovered ? 1 : 0)};
    transform: translateX(${(props) => (props.isHovered ? '0' : '-10px')});
    transition:
        opacity 0.2s ease-in-out,
        transform 0.2s ease-in-out;
`;

export const ExpandableButton = ({ icon, text, backgroundColor, textColor, borderColor, onClick, className }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <ButtonContainer
            backgroundColor={backgroundColor}
            textColor={textColor}
            borderColor={borderColor}
            className={className}
            isHovered={isHovered}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
        >
            <IconContainer>{icon}</IconContainer>
            <TextContainer isHovered={isHovered}>{text}</TextContainer>
        </ButtonContainer>
    );
};
