import { getUniqueShifts } from '../../components/Admin/EVVContainer/Payroll/PayallocationUtils';
import * as ActionTypes from '../actions/ActionTypes';

export default function Payallocation(
    state = {
        isLoading: true,
        employeePreviewChecks: {},
        employees: {},
        employeesEarnings: {},
        config: {},
        provider: {},
    },
    action
) {
    let _employees = [];
    switch (action.type) {
        case ActionTypes.CLEAR_PAYALLOCATION:
            return {
                ...state,
                employeePreviewChecks: {},
                employees: {},
                employeesEarnings: {},
                config: {},
                provider: {},
            };
        case ActionTypes.SET_PAYALLOCATION_DRAFT:
            return {
                ...state,
                ...(action.payload || {}),
            };
        case ActionTypes.SET_PAYALLOCATION_CONFIG:
            const updatedConfigs = {
                ...state.config,
                ...(action.payload || {}),
            };
            return {
                ...state,
                config: updatedConfigs,
            };
        case ActionTypes.SET_PAYALLOCATION_PROVIDER:
            return {
                ...state,
                provider: { ...(action.payload || {}) },
            };
        case ActionTypes.SET_PAYALLOCATION_EMPLOYEES:
            _employees = action.payload || {};
            return {
                ...state,
                employees: _employees,
            };
        case ActionTypes.UPDATE_PAYALLOCATION_EMPLOYEE:
            if (action.payload && action?.payload?.employeeID) {
                return {
                    ...state,
                    employees: {
                        ...state.employees,
                        [action.payload.employeeID]: {
                            ...(state.employees[action.payload.employeeID] || {}),
                            ...(action.payload || {}),
                        },
                    },
                };
            }

            return state;

        case ActionTypes.SET_PAYALLOCATION_EMPLOYEE_CHECKS:
            return {
                ...state,
                employeePreviewChecks: action.payload || {},
                isLoading: false,
            };
        case ActionTypes.UPDATE_PAYALLOCATION_EMPLOYEE_CHECK:
            if (action.payload && action?.payload?.employeeID) {
                return {
                    ...state,
                    employeePreviewChecks: {
                        ...(state?.employeePreviewChecks || {}),
                        [action.payload.employeeID]: action.payload,
                    },
                    isLoading: false,
                };
            }
            return state;
        case ActionTypes.SET_PAYALLOCATION_EMPLOYEE_EARNINGS:
            return {
                ...state,
                employeesEarnings: action.payload || {},
                isLoading: false,
            };
        case ActionTypes.UPDATE_PAYALLOCATION_EMPLOYEE_EARNING:
            if (action.payload && action?.payload?.employeeID) {
                const filteredShifts = getUniqueShifts(
                    (action.payload.shifts || []).filter((shift) => {
                        // const shift = !shift?.metadata?.auto
                        const reduxXhfit = shift?.metadata;
                        console.log('reduxXhfit', reduxXhfit);
                        return !shift?.metadata?.auto;
                    })
                );
                return {
                    ...state,
                    employeesEarnings: {
                        ...(state?.employeesEarnings || {}),
                        [action.payload.employeeID]: {
                            ...action.payload,
                            shifts: filteredShifts,
                        },
                    },
                    isLoading: false,
                };
            }
            return state;

        default:
            return state;
    }
}
