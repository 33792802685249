import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect } from 'react';
import { IMAGE_EXTENSIONS, PHONE_NUMBER_REGEX } from '../contants';
import * as Yup from 'yup';

var colors = {
    a: '#1abc9c',
    b: '#f1c40f',
    c: '#3498db',
    d: '#9b59b6',
    e: '#34495e',
    f: '#16a085',
    0: '#27ae60',
    1: '#2980b9',
    2: '#7f8c8d',
    3: '#2c3e50',
    4: '#21878A',
    5: '#e67e22',
    6: '#e74c3c',
    7: '#99B523',
    8: '#f39c12',
    9: '#5E44D2',
};
export const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const getUniqueObjects = (...arraysOfObjects) => {
    if (arraysOfObjects.some((arrayOfObjects) => !Array.isArray(arrayOfObjects))) {
        return arraysOfObjects.at(-1);
    }
    const allObjects = arraysOfObjects.flat();
    const uniqueIds = [...new Set(allObjects.map((obj) => obj.id || obj._id))];
    return uniqueIds.map((id) => allObjects.reverse().find((obj) => (obj.id || obj._id) === id));
};

export const getInitials = (name) => {
    if (!name) return null;
    var nameArray = name.split(' ');

    if (nameArray.length <= 1) {
        return name.charAt(0).toUpperCase();
    }

    return nameArray[0].charAt(0).toUpperCase() + nameArray[nameArray.length - 1].charAt(0).toUpperCase();
};

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

export const getRandomColor = (id) => {
    const lastChar = id?.slice(-1) || 'abcdef9876543210'[Math.round(Math.random() * 16)];
    return colors[lastChar];
};

export const distance = (lat1, lon1, lat2, lon2) => {
    // https://www.geodatasource.com/developers/javascript
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        return dist;
    }
};

export const isTheUserNearTheAddressess = (roomAddresses, userPosition, approxDistance) => {
    let userLat = userPosition.coords.latitude;
    let userLong = userPosition.coords.longitude;
    for (let address in roomAddresses) {
        if (roomAddresses[address]) {
            let distanceBetweenAddressAndUser = distance(
                roomAddresses[address].latitude,
                roomAddresses[address].longitude,
                userLat,
                userLong
            );
            if (distanceBetweenAddressAndUser < approxDistance) {
                return true;
            }
        }
    }
    return false;
};

export const sortingMethod = Intl.Collator('en-US').compare;

export const sortingOptionMethod = (a, b) => {
    if ((a.label || '').toLowerCase() < (b.label || '').toLowerCase()) {
        return -1;
    }
    if ((a.label || '').toLowerCase() > b.label.toLowerCase()) {
        return 1;
    }
    return 0;
};

export const getTimeZoneDiff = () => {
    // https://usefulangle.com/post/30/javascript-get-date-time-with-offset-hours-minutes
    var timezone_offset_min = new Date().getTimezoneOffset(),
        offset_hrs = parseInt(Math.abs(timezone_offset_min / 60)),
        offset_min = Math.abs(timezone_offset_min % 60),
        timezone_standard;

    if (offset_hrs < 10) offset_hrs = '0' + offset_hrs;

    if (offset_min < 10) offset_min = '0' + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0) timezone_standard = '+' + offset_hrs + ':' + offset_min;
    else if (timezone_offset_min > 0) timezone_standard = '-' + offset_hrs + ':' + offset_min;
    else if (timezone_offset_min === 0) timezone_standard = 'Z';

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    return timezone_standard;
};
export const getDateTime = (date) => {
    var dt = new Date(date),
        current_date = dt.getDate(),
        current_month = dt.getMonth() + 1,
        current_year = dt.getFullYear(),
        current_hrs = dt.getHours(),
        current_mins = dt.getMinutes(),
        current_secs = dt.getSeconds(),
        current_datetime;

    // Add 0 before date, month, hrs, mins or secs if they are less than 0
    current_date = current_date < 10 ? '0' + current_date : current_date;
    current_month = current_month < 10 ? '0' + current_month : current_month;
    current_hrs = current_hrs < 10 ? '0' + current_hrs : current_hrs;
    current_mins = current_mins < 10 ? '0' + current_mins : current_mins;
    current_secs = current_secs < 10 ? '0' + current_secs : current_secs;

    // Current datetime
    // String such as 2016-07-16T19:20:30
    current_datetime =
        current_year +
        '-' +
        current_month +
        '-' +
        current_date +
        'T' +
        current_hrs +
        ':' +
        current_mins +
        ':' +
        current_secs;

    return current_datetime;
};

export const getSequenceNumber = () => {
    let date = new Date().toISOString();
    let dateAndTime = date.split('.')[0];
    let sequenceNumber = dateAndTime.replaceAll('-', '').replaceAll('T', '').replaceAll(':', '');
    return sequenceNumber;
};
export const getCompleteZipValue = (zipVal) => {
    let zipValLen = zipVal?.length;
    let completeZipValue = zipVal;
    if (zipValLen <= 9) {
        for (let i = 1; i <= 9 - zipValLen; i++) {
            completeZipValue = completeZipValue.concat('0');
        }
    }
    return completeZipValue;
};

export const getTeamIdFromURL = () => {
    try {
        let URLSTRING = window.location.href;
        let BrokenURL = URLSTRING.split('/teams/');
        if (BrokenURL) {
            // return BrokenURL[1]?.split('/')[0];
            let teamId = BrokenURL[1]?.split('/')[0];
            // Check for '#' in teamId and remove it
            if (teamId?.includes('#')) {
                teamId = teamId.split('#')[0];
            }
            return teamId;
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
    }
};

export const getChannelIdFromURL = () => {
    try {
        let URLSTRING = window.location.href;
        let BrokenURL = URLSTRING.split('/teams/');
        if (BrokenURL) {
            return BrokenURL[1]?.split('/')[3];
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
    }
};

export const getChannelIdFromSettingsURL = () => {
    try {
        let URLSTRING = window.location.href;
        let BrokenURL = URLSTRING.split('/channels/');
        if (BrokenURL) {
            let channelId = BrokenURL[1]?.split('/')[0];
            if (channelId?.includes('#')) {
                channelId = channelId.split('#')[0];
            }
            return channelId;
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
    }
};

export const getRoomIdFromURL = () => {
    try {
        let URLSTRING = window.location.href;
        let BrokenURL = URLSTRING.split('/teams/');
        if (BrokenURL) {
            let channelId = BrokenURL[1]?.split('/')[0];
            if (channelId?.includes('#')) {
                channelId = channelId.split('#')[0];
            }
            return channelId;
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
    }
};

export const getSubchannelIdFromURL = () => {
    try {
        let URLSTRING = window.location.href;
        let BrokenURL = URLSTRING.split('/event-channel/');
        if (BrokenURL) {
            return BrokenURL[1];
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
    }
};

export const isTeamSettings = () => {
    try {
        const URLSTRING = window.location.href || '';
        return URLSTRING.includes('/teams/') && URLSTRING.includes('settings');
    } catch (err) {
        console.log(err);
    }
};

export const isTeamOrChannelSettings = () => {
    try {
        const URLSTRING = window.location.href || '';
        const containsSettings = URLSTRING.includes('settings');
        const containsChannelOrTeam = URLSTRING.includes('/teams/') || URLSTRING.includes('/channels/');
        return containsSettings && containsChannelOrTeam;
    } catch (err) {
        console.log(err);
    }
};

export const isAdminPanel = () => {
    try {
        const URLSTRING = window.location.href || '';
        return URLSTRING.includes('/admin#/');
    } catch (err) {
        console.log(err);
    }
};

export const isAdminOrTeamSettings = () => {
    try {
        return isAdminPanel() || isTeamSettings();
    } catch (err) {
        console.log(err);
    }
};

export const getLatLngFromAddress = async (location, latName, longName, setLocation, setValue) => {
    const encodedAddress = encodeURI(location);
    if (setLocation) {
        setLocation(null);
    }
    try {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        const OutputLocation = await response.json();
        let latLong = {
            latitude: OutputLocation.results[0].geometry.location.lat,
            longitude: OutputLocation.results[0].geometry.location.lng,
        };
        if (setLocation) {
            setLocation(latLong);
        }
        if (setValue) {
            setValue(latName, latLong.latitude);
            setValue(longName, latLong.longitude);
        }

        return {
            latitude: OutputLocation.results[0].geometry.location.lat,
            longitude: OutputLocation.results[0].geometry.location.lng,
        };
    } catch (err) {
        console.log(err, 'getLatLngFromAddress ERROR');
        return null;
    }
};

export const getAddressFromLatLng = async (lat, lng, addressName, latName, longName, setValue) => {
    try {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        const OutputLocation = await response.json();
        let address = OutputLocation.results[0].formatted_address;
        if (addressName && setValue) {
            setValue(addressName, address);
            setValue(latName, lat);
            setValue(longName, lng);
        }
        return address;
    } catch (err) {
        console.log(err, 'getAddressFromLatLng ERROR');
        return null;
    }
};
export const moveMarker = async (t, map, coord, addressName, latName, longName, setValue) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    try {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        );
        const results = await response.json();
        const tempAddress = results.results[0].formatted_address;
        setValue(addressName, tempAddress);
        setValue(latName, lat);
        setValue(longName, lng);
        // setLocation({ latitude: lat, longitude: lng });
    } catch (err) {
        return null;
    }
};

export const debounce = (func, delay = 500) => {
    let timeout;
    return function (...args) {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const newDebounce = (func, timeout = 300) => {
    let timer;
    console.log(func, timer, 'timer');
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const getTheDate = (date, separator = '/') => {
    let day = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    let month = date.getMonth();
    month = month + 1;
    if (month < 10) {
        month = '0' + month;
    }
    let year = date.getFullYear();
    return `${month}${separator}${day}${separator}${year}`;
};

const filterByDate = (tasks, date, selectedDate) => {
    if (tasks) {
        let newTasks = tasks.filter((task) => {
            let eventTime = new Date(dayjs(task.date)?.format('YYYY-MM-DD')).getTime();
            let selectedDateTime = new Date(selectedDate).getTime();
            if (task.dueDate) {
                let dueTime = new Date(dayjs(task.dueDate)?.format('YYYY-MM-DD')).getTime();
                return eventTime <= selectedDateTime && selectedDateTime <= dueTime;
            }
            return eventTime <= selectedDateTime;
        });
        return newTasks;
    }
    return [];
};
function flatten(arr, dateDate) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(
            Array.isArray(toFlatten)
                ? flatten(toFlatten, toFlatten['dateDate'])
                : (toFlatten = { ...toFlatten, dateDate: dateDate })
        );
    }, []);
}

export const flattenObj = (obj) => {
    const tempObj = { ...obj };
    const flattenObj = {};
    Object.keys(tempObj).forEach((key) => {
        if (tempObj[key] && typeof tempObj[key] === 'object') {
            if (Array.isArray(tempObj[key])) {
                tempObj[key].forEach((item, index) => {
                    if (typeof item === 'object') {
                        for (const property in item) {
                            flattenObj[`${key}[${index}].${property}`] = item[property];
                        }
                    } else flattenObj[`${key}[${index}]`] = item;
                });
            } else if (tempObj[key] !== null) {
                for (const property in tempObj[key]) {
                    flattenObj[`${key}.${property}`] = tempObj[key][property];
                }
            }
        } else flattenObj[key] = tempObj[key];
    });
    return flattenObj;
};
export const WeekDaysData = {
    sun: {
        order: 1,
        name: 'Sunday',
    },
    mon: {
        order: 2,
        name: 'Monday',
    },
    tue: {
        order: 3,
        name: 'Tuesday',
    },
    wed: {
        order: 4,
        name: 'Wednesday',
    },
    thu: {
        order: 5,
        name: 'Thursday',
    },
    fri: {
        order: 6,
        name: 'Friday',
    },
    sat: {
        order: 7,
        name: 'Saturday',
    },
};
export const getTasksOfTheMonth = (tasks) => {
    const taskInstances = (Array.isArray(tasks) ? tasks : [])
        .map((task) =>
            (task.eventMode === 'live' ? task.occurrences || [task.latestDatePointer] : [task.latestDatePointer]).map(
                (occurence, index) => {
                    const latestDatePointer = new Date(occurence);
                    const taskDuration =
                        new Date(task.latestDueDatePointer || task.dueDate) -
                        new Date(task.latestDatePointer || task.date);
                    const latestDueDatePointer = new Date(latestDatePointer.valueOf() + taskDuration);
                    const taskInstance = {
                        ...task,
                        latestDatePointer,
                        latestDueDatePointer,
                        start: latestDatePointer,
                        end: latestDueDatePointer,
                        key: `${task._id || task.id}_${index}`,
                    };
                    delete taskInstance.occurrences;
                    return taskInstance;
                }
            )
        )
        .flat();

    return {
        liveTasks: taskInstances.filter((task) => task.eventMode !== 'onDemand'),
        onDemandTasks: taskInstances.filter((task) => task.eventMode === 'onDemand'),
    };
};

export const getTasksOfTheDay = (tasks, selectedDate) =>
    tasks?.filter((task) => dayjs(task?.latestDatePointer || task?.date)?.format('YYYY-MM-DD') === selectedDate);

export const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
};

export const formatDate = (dateObject, includeMonth = true, includeYear = true) => {
    var month = dateObject.toLocaleString('default', { month: 'long' });
    var date = dateObject.getDate();
    var hours = dateObject.getHours();
    var minutes = dateObject.getMinutes();
    var year = dateObject.getFullYear();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var strDate = `${includeYear ? year : ''}${includeMonth ? ` ${month}` : ' day'} ${date}`;
    return strDate + ', ' + strTime;
};

export const linkChannelToTeams = (channel, teamsObj) => {
    let newChannel = Object.assign([], channel);
    newChannel = newChannel
        .filter((channel) => teamsObj[channel.team])
        .map((channel) => ({
            ...channel,
            teamData: teamsObj[channel.team],
        }));
    return newChannel;
};

// removes dublicate teams from the array and returns the new array
export const getUniqueTeams = (teamsArr) => {
    /*
			 Set datatype is similar to Array but it doesnot allow duplication
				it ignores duplicate value, So if we have two same teamId then it
				will only accept one of it. 

			1.	"teamsArr" contains dublicate objects, so we map through it and make a set
				of team._id || team.id, (all the teamId in the set are unique ones)

			2.	we convert the set into Array.

			3.	we map the new dublicate removed array and get the array of team objects.

				
		*/

    const uniqueTeamArray = Array.from(new Set(teamsArr.map((team) => team.id || team._id))).map((id) => {
        return teamsArr.find((team) => (team.id || team._id) === id);
    });
    return uniqueTeamArray;
};

// removes dublicate channels from the array and returns the new array
export const getUniqueChannels = (channelsArr) => {
    /*
			 Set datatype is similar to Array but it doesnot allow duplication
				it ignores duplicate value, So if we have two same roomId then it
				will only accept one of it. 

			1.	"channelsArr" contains dublicate objects, so we map through it and make a set
				of room._id || room.id, (all the roomId in the set are unique ones)

			2.	we convert the set into Array.

			3.	we map the new dublicate removed array and get the array of room objects.

				
		*/
    const uniqueRoomArray = Array.from(new Set(channelsArr.map((room) => room.id || room._id))).map((id) => {
        return channelsArr.find((room) => (room.id || room._id) === id);
    });
    return uniqueRoomArray;
};

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const timeConvert = (seconds) => {
    const remainderSeconds = seconds % 60;
    const minutes = parseInt(seconds / 60);
    const remainderMinutes = minutes % 60;
    const remainderHours = parseInt(minutes / 60) % 60;
    const hh = `${remainderHours < 10 ? '0' : ''}${remainderHours}`;
    const mm = `${remainderMinutes < 10 ? '0' : ''}${remainderMinutes}`;
    const ss = `${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`;
    return `${hh}:${mm}:${ss}`;
};
export const scrollMeToMyRoom = (roomId, roomsRef) => {
    if (roomsRef[roomId]?.current) {
        roomsRef[roomId].current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
        });
        return true;
    }
    return false;
};
function roundDownToNearestQuater(minutes, base = 15, forceDown = true) {
    // console.log(minutes, forceDown , base, minutes - forceDown * base / 2, minutes - forceDown * base / 2 ,'minutes')
    // minutes = minutes - forceDown * base / 2; // time - (half of base)  > to get lower Quater 15 mins
    const remainder = minutes % base;
    const quotient = Math.floor(minutes / base);
    const limit = forceDown ? base : Math.ceil(base / 2);
    // console.log("MINUTES", minutes, quotient, remainder)
    return (quotient + (remainder >= limit)) * base;
}

function convertToSeconds(min) {
    return min * 60;
}

export function roundDown(timeInMinutes, minutesPerUnit = 15) {
    let roundedDownMinute = roundDownToNearestQuater(timeInMinutes, minutesPerUnit);
    let seconds = convertToSeconds(roundedDownMinute);
    // console.log(timeInMinutes, roundedDownMinute, seconds);
    return timeConvert(seconds);
}
export function getEvvServiceUnit(timeInMinutes, minutesPerUnit = 15) {
    let roundedDownMinute = roundDownToNearestQuater(timeInMinutes, minutesPerUnit);
    return roundedDownMinute / minutesPerUnit;
}

export function getEvvStaffUnit(timeInMinutes, minutesPerUnit = 15) {
    let roundedDownMinute = roundDownToNearestQuater(timeInMinutes, minutesPerUnit, false);
    return roundedDownMinute / minutesPerUnit;
}

export function validateRateFormat(rate) {
    // rate must be number, can be whole number or
    // a decimal number with fixed position of 2.
    if (String(rate).length === 0) return true;
    return /^\d*\.?\d{1,2}$/.test(rate);
}
export function numberWithCommas(number) {
    if (!number) return '0.00';
    let [whole, decimal] = number.toString().split('.');
    let commaAdded = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (decimal) {
        return `${commaAdded}.${decimal}`;
    }
    return commaAdded;
}

export function roundOffToTwoDecimalDigits(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
}
export const DateSorter = (a, b) => {
    return (
        new Date(a.latestDatePointer || a.viewDate || a.date).getTime() -
        new Date(b.latestDatePointer || b.viewDate || b.date).getTime()
    );
};
export const TimeSorter = (a, b) => {
    return dayjs(a.latestDatePointer || a.date)
        .format('HH:mm:ss')
        .localeCompare(dayjs(b.latestDatePointer || b.date).format('HH:mm:ss'));
};
export function addHours(numOfHours, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

    return date;
}
export function getNextWeekDayDifference(selected, target) {
    let noOfDays = 7;
    if (selected === target) {
        return 0;
    } else if (selected < target) {
        return target - selected;
    } else {
        return noOfDays - selected + target;
    }
}

export function getWeekDayDifference(selected, target) {
    if (selected === target) {
        return 0;
    } else {
        return target - selected;
    }
}

export function getUserRole(currUser = {}, teamId, channelId, subChannelId) {
    if (currUser.role !== 'user') {
        return currUser.role;
    }
    const teamRole = currUser?.teamList?.find(
        (teamObj) => teamObj?.team?.id === teamId || teamObj?.team?._id === teamId
    )?.role;
    if (teamRole !== 'user' && !subChannelId) {
        return teamRole;
    }
    const channelRole = currUser?.roomList?.find(
        (roomObj) => roomObj?.room?.id === channelId || roomObj?.room?._id === channelId
    )?.role;

    if (channelRole !== 'user' && !subChannelId) {
        return channelRole;
    }
    const subChannelRole = currUser?.subChannelList?.find(
        (subChannelObj) =>
            subChannelObj?.subChannel?.id === subChannelId || subChannelObj?.subChannel?._is === subChannelId
    )?.role;

    // treat owner of subchannel as normal user and other users (like admin, team owners, managers and supervisors) as admin
    return subChannelRole === 'owner' ? 'user' : teamRole !== 'user' || channelRole === 'supervisor' ? 'admin' : 'user';
}

export function useDebouncedEffect(callback, dependencies = [], delay = 500) {
    useEffect(() => {
        let timeoutId;
        const debouncedCallback = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(callback, delay);
        };
        debouncedCallback();
        return () => {
            clearTimeout(timeoutId);
        };
    }, [...dependencies]);
}

export function serviceToCounter(data) {
    const createdAt = new Date(data.createdAt);
    const currentDate = moment(new Date()).toISOString();
    const timeLapse = moment(new Date(currentDate)).diff(moment(new Date(createdAt)));
    if (timeLapse > 86400000) {
        return null;
    }
    const counterTime = moment.utc(timeLapse).format('HH  :  mm  :  ss');
    return {
        code: data.serviceCode,
        username: data.employee,
        availableUnits: data.availableUnits || 0,
        minutesPerUnit: data.minutesPerUnit || 15,
        time: counterTime,
        offset: timeLapse / 1000,
        template: data?.template,
    };
}

export function removeMongoidAndTimestamp(message = '') {
    let fileName;
    try {
        fileName = JSON.parse(message)?.text || 'Unnamed File';
    } catch (error) {
        fileName = message;
    } finally {
        const mongoidRegex = /[a-f0-9]{24}_/;
        const timestampRegex = /^[\d]{13}_/;
        const withoutMongoid = fileName?.replace(mongoidRegex, '');
        return decodeURIComponent(withoutMongoid?.replace(timestampRegex, ''));
    }
}

export function SafeJSONParse(text) {
    let result = {};
    try {
        result = JSON.parse(text);
    } catch (error) {
        console.log(error);
    } finally {
        return result;
    }
}

export function isValidMongoId(id = '') {
    try {
        const mongoidRegex = /^[a-f0-9]{24}$/;
        return mongoidRegex.test(id);
    } catch (error) {
        return false;
    }
}
export function getFormDataWithValue(formDataElementsArr = [], valueDataArr = []) {
    try {
        return (
            formDataElementsArr?.map((form, index) => {
                const elementValue = valueDataArr?.find((f) => form?._id === f.fieldName.split('-')[1])?.value;
                return {
                    ...form,
                    isFetched: true,
                    value: elementValue,
                };
            }) || []
        );
    } catch (err) {
        console.log('Error while appending form values', err);
        return [];
    }
}

export function canSeePublicTeams(user = {}) {
    try {
        if (user?.role === 'admin' || user?.showPublicChannel) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}

export function isItTimeToForceResetPassword(user = {}) {
    try {
        if (!user?.id) {
            throw new Error('Please pass valid user');
        }
        let lastPasswordChangedDate = dayjs(user.lastPasswordChangedAt).format('YYYY-MM-DD');
        let changePasswordDays = 90;
        let forcePasswordChangeDate = dayjs(lastPasswordChangedDate).add(changePasswordDays, 'day');
        forcePasswordChangeDate = dayjs(forcePasswordChangeDate).format('YYYY-MM-DD');
        let todayDate = dayjs().format('YYYY-MM-DD');
        return dayjs(forcePasswordChangeDate).diff(dayjs(todayDate)) <= 0;
    } catch (err) {
        console.log('err', err);
        return true;
    }
}

export function mapArrayToObject(array, key) {
    try {
        if (!key) {
            throw new Error('Key is required');
        }
        if (!Array.isArray(array)) {
            throw new Error('Input in not array');
        }
        return (array || []).reduce((acc, obj) => {
            if (obj[key]) {
                acc[obj[key]] = obj;
            }
            return acc;
        }, {});
    } catch (err) {
        console.log('err', err);
        return {};
    }
}

export function pickObjectKeys(object, keys) {
    return keys.reduce((obj, key) => {
        if (object && Object.prototype.hasOwnProperty.call(object, key)) {
            obj[key] = object[key];
        }
        return obj;
    }, {});
}

export function convertDateToISO(date, options = { start: false, end: false }) {
    try {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
            throw new Error('Invalid date');
        }
        if (options.end) {
            parsedDate.setHours(23, 59, 59, 999);
        } else if (options.start) {
            parsedDate.setHours(0, 0, 0, 0);
        }

        return parsedDate.toISOString();
    } catch (err) {
        console.error(`Error converting date: ${err.message}`);
        // return null;
        return date;
    }
}

export function getStandardDate(date, throwError = true) {
    const tempDate = new Date(date);
    if (throwError && (!date || isNaN(tempDate.getTime()))) {
        throw new Error('Date is invalid');
    }
    return tempDate.toLocaleDateString('en-CA');
}

export function buildQueryString(params = {}) {
    return Object.entries(params)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
}

export function decimalHoursToHoursAndMinutes(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return {
        hours,
        minutes,
        balance: decimalHours,
    };
}

export function getBadgeClass(status, type) {
    switch (status) {
        case 'draft':
            return 'badge-dark';
        case 'payment_pending':
            return 'badge-warning';
        case 'payment_processed':
            return 'badge-success';
        case 'approved':
        case 'verified':
            return 'badge-primary';
        case 'rejected':
            return 'badge-danger';
        default:
            return '';
    }
}

export const getFileNameFromUrl = (url) => {
    try {
        const filenameWithExtension = url.split('/').pop();
        const decodedFilename = removeMongoidAndTimestamp(filenameWithExtension);
        const match = decodedFilename.match(/_\d{13}_(.*)/);
        return match ? match[1] : decodedFilename;
    } catch (err) {
        return url;
    }
};

export const getContactType = (value) => {
    try {
        if ((value || '').toString().includes('@')) {
            return 'email';
        }
        return 'phone';
    } catch (err) {
        return 'email';
    }
};

export const isValidPhone = (value) => {
    try {
        if (PHONE_NUMBER_REGEX.test(value)) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
};

export const isValidEmail = (value) => {
    try {
        if (Yup.string().email().isValidSync(value)) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
};

export const generateUserName = (firstName, lastName) => {
    let username = `${firstName || ''}${lastName || ''}`.toLowerCase();
    try {
        username = username.replace(/\s+/g, '').replace(/[^a-z0-9]/g, '');
    } catch (err) {
    } finally {
        return username + Date.now().toString().slice(-3);
    }
};

export const generateNewTeamName = (fullName) => {
    try {
        return `${fullName}'s Team`;
    } catch (err) {
        return '';
    }
};

export const isImage = (file) => {
    try {
        const extension = file?.split('.').pop();
        return IMAGE_EXTENSIONS.includes(`.${extension}`);
    } catch (err) {
        return false;
    }
};

export const cleanUpString = (value) => {
    const _value = String(value || '');
    return _value.replace(/\t/g, ' ').replace(/\s+/g, ' ').trim();
};

export const getSortConfig = (sortBy = '') => {
    try {
        return {
            key: sortBy.split(':')[0],
            direction: sortBy.split(':')[1],
        };
    } catch (error) {
        console.log(error);
        return {};
    }
};
