import { css } from 'twin.macro';
import { Button, Modal } from 'react-bootstrap';
import LoadingSpiner from '../../../../../_elements/LoadingSpiner';
import { VscLoading } from 'react-icons/vsc';
import { BsExclamationTriangle } from 'react-icons/bs';
import { PayHighlight } from './ViewPayments/ZealCompanyPayroll';

const EmployeeCheckPreviewModal = ({
    show,
    onHide,
    onConfirm,
    loading,
    submitting,
    previewData = [],
    previewError,
    getTotalGrossPay = () => {},
    bulkView,
    allowCreateCheck,
    children,
}) => {
    const errorList = [];
    previewData.forEach((emp) => {
        if (emp?.error) {
            errorList.push(`${emp?.first_name || ''} ${emp?.middle_name || ''} ${emp?.last_name || ''}: ${emp?.error}`);
        }
    });
    if (previewError) {
        errorList.push(previewError);
    }
    return (
        <Modal show={show} onHide={() => onHide()} centered scrollable backdrop="static" size="xl">
            <Modal.Header>
                <Modal.Title>
                    <h3>Employee Check Preview </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '80vh' }}>
                {loading || errorList.length || !bulkView ? null : (
                    <div className="d-flex flex-row-reverse">
                        <PayHighlight amount={getTotalGrossPay()} label="Total Gross Pay" />
                    </div>
                )}
                {loading ? (
                    <div
                        css={css`
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                        `}
                    >
                        <LoadingSpiner size={42} />
                        <p>Fetching and Sequencing Preview Checks from Zeal ...</p>
                    </div>
                ) : errorList.length ? (
                    <div
                        css={css`
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                        `}
                    >
                        <BsExclamationTriangle
                            size={42}
                            color="#ea9941"
                            css={css`
                                margin-bottom: 24px;
                            `}
                        />
                        {errorList?.map((error, errorIndex) => (
                            <p
                                key={errorIndex}
                                css={css`
                                    font-size: 18px;
                                `}
                            >
                                {error}
                            </p>
                        ))}
                        <p>Please review and correct the data !</p>
                    </div>
                ) : (
                    children
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={loading || submitting}
                    variant="secondary"
                    onClick={() => onHide()}
                    css={css`
                        height: 100%;
                    `}
                >
                    Close
                </Button>
                {allowCreateCheck && (
                    <Button
                        disabled={loading || submitting || errorList?.length}
                        variant="primary"
                        onClick={() => onConfirm()}
                        css={css`
                            height: 100%;
                        `}
                    >
                        {submitting ? (
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 8px;
                                `}
                            >
                                <div>
                                    <VscLoading className="spin" />
                                </div>
                                <div>Submitting</div>
                            </div>
                        ) : (
                            'Confirm Create'
                        )}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default EmployeeCheckPreviewModal;
