import { css } from 'twin.macro';
import { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ChannelSelectFilter from '../../../Admin/ChannelSelectFilter';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { VscLoading } from 'react-icons/vsc';
import { fetchWrapper, getTeamIdFromURL } from '../../../../_helpers';
const modalStyles = css`
    .modal-content {
        padding: 2rem 1rem;
    }
`;

function CreateContactModal({ channelId, data, onCreateOrUpdate = async () => {}, show, setShow }) {
    const [contact, setContact] = useState({ name: '', phone: '', email: '', chatroom: null, chatroomTitle: '' });
    const [error, setError] = useState({});
    const [isBusy, setIsBusy] = useState(false);
    const teamId = getTeamIdFromURL();

    const handleClose = () => {
        setContact({ name: '', phone: '', email: '', chatroom: null, chatroomTitle: '' });
        setError({});
        setIsBusy(false);
        setShow(false);
    };

    const handleChange = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };

    const handleRoomSelect = (roomInfo) => {
        setContact((prev) => ({ ...prev, chatroom: roomInfo ? roomInfo.value : null }));
    };

    const validate = () => {
        const errors = {};
        if (!contact.name.trim()) errors.name = 'Name is required';
        if (!contact.phone.trim()) errors.phone = 'Phone is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        try {
            e?.preventDefault();
            const errors = validate();
            if (Object.keys(errors).length) return setError(errors);
            setIsBusy(true);
            if (contact?.chatroom && contact?.chatroomTitle) {
                return toast.error('Please select either chatroom or new Channel Name');
            }
            const payload = {
                ...contact,
                // chatroomTitle: contact?.chatroomTitle || undefined,
                chatroomTitle: undefined,
                chatroom: contact?.chatroom || undefined,
                email: contact?.email,
                phone: contact?.phone?.startsWith('+') ? contact.phone : `+${contact.phone}`,
                team: teamId,
            };

            if (data && data?._id) {
                await fetchWrapper.put(`contacts/${data._id}?team=${teamId}`, payload);
                toast.success('Contact updated successfully');
            } else {
                await fetchWrapper.post(`contacts?team=${teamId}`, payload);
                toast.success('Contact created successfully');
            }
            handleClose();
            await onCreateOrUpdate();
        } catch (err) {
            toast.error(err?.message || 'Something went wrong');
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {
        if (data && data?._id) {
            setContact({ name: data.name, phone: data.phone, email: data.email, chatroom: data.chatroom });
        }
    }, [data]);

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose} backdrop="static" css={modalStyles} size="md">
                <Modal.Header closeButton>
                    <h3 className="text-capitalize">{data?._id ? 'Edit' : 'Create'} Contact</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Contact Name</Form.Label>
                            <span className="text-danger">*</span>
                            <Form.Control
                                type="text"
                                name="name"
                                value={contact.name}
                                onChange={handleChange}
                                isInvalid={!!error.name}
                            />
                            <Form.Text className="text-danger">{error.name}</Form.Text>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <span className="text-danger">*</span>
                            <PhoneInput
                                country={'us'}
                                value={contact.phone}
                                onChange={(phone) => setContact((prev) => ({ ...prev, phone: phone }))}
                                css={css`
                                    &.react-tel-input input {
                                        width: 100%;
                                    }
                                `}
                            />
                            <Form.Text className="text-danger">{error.phone}</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={contact.email}
                                onChange={handleChange}
                                isInvalid={!!error.email}
                            />
                            <Form.Text className="text-danger">{error.email}</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Channel</Form.Label>
                            <ChannelSelectFilter
                                assignedChannel={contact?.chatroom || 'undefined'}
                                onRoomSelect={handleRoomSelect}
                            />
                            <Form.Text className="text-danger">{error.email}</Form.Text>
                        </Form.Group>
                        {/* <Form.Group>
                            <Form.Label>New Channel Name (#Create)</Form.Label>
                            <Form.Control
                                type="text"
                                name="chatroomTitle"
                                value={contact.chatroomTitle}
                                onChange={handleChange}
                                isInvalid={!!error.chatroomTitle}
                            />
                            <Form.Text className="text-danger">{error.chatroomTitle}</Form.Text>
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={isBusy}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={isBusy}>
                        {isBusy ? <VscLoading className="spin" /> : data?._id ? 'Update' : 'Create'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default CreateContactModal;
