import React, { useState, Fragment, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { fetchWrapper } from '../../../../../../_helpers';
import { toast } from 'react-toastify';
import { BootstrapToolTip, ToolTip } from '../../../../../../_elements/ToolTip';
import { ActionStyles } from '../../../../../Admin/reusableStyles';
import { BiSend } from 'react-icons/bi';

import { css } from 'twin.macro';
import { connect, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import Select from 'react-select';
import { BsArrowBarRight } from 'react-icons/bs';
import { customCheckbox } from '../../../../../../_elements/CustomCheckbox';

const roles = ['user', 'doctor', 'family', 'supervisor'];

const SendToChannel = ({ sendDocumentToChannel }) => {
    const [showSelect, setShowSelect] = useState(false);
    const handleShowSelect = () => setShowSelect(true);
    const handleCloseSelect = () => setShowSelect(false);

    return (
        <div>
            {!showSelect ? (
                <span
                    css={css`
                        cursor: pointer;
                        color: green;
                    `}
                    onClick={handleShowSelect}
                >
                    Send
                </span>
            ) : (
                <form
                    onSubmit={(e) => {
                        sendDocumentToChannel(e);
                        handleCloseSelect();
                    }}
                    css={css`
                        min-width: 250px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 8px;
                        position: relative;
                    `}
                >
                    <div
                        css={css`
                            margin-top: 4px;
                        `}
                    >
                        <label htmlFor={`chatInput_message`} className="mr-4">
                            Include a message (optional)
                        </label>
                        <textarea
                            id={`chatInput_message`}
                            name={`message`}
                            type="text"
                            rows="3"
                            css={css`
                                width: 100%;
                                outline: none;
                                border: 1px solid #ccc;
                                padding: 8px 12px;
                                min-height: 48px;
                                height: 48px;
                                max-height: 120px;
                            `}
                        />
                    </div>
                    <div>
                        Visible To:
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                padding: 3px 8px;
                                background: #fff;
                                border: 1px solid #ccc;
                                border-radius: 4px;
                            `}
                        >
                            {roles.map((role) => (
                                <div key={role}>
                                    <input
                                        id={`visibleTo_${role}`}
                                        name={`visibleTo`}
                                        type="checkbox"
                                        css={customCheckbox}
                                        value={role}
                                        defaultChecked
                                    />
                                    <label
                                        htmlFor={`visibleTo_${role}`}
                                        className="mr-4"
                                        css={css`
                                            text-transform: capitalize;
                                        `}
                                    >
                                        {{ doctor: 'Third Party' }[role] ?? role}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="d-flex mt-2 w-100">
                        <button
                            className={'btn text-white bg-secondary rounded border w-50'}
                            css={css`
                                font-size: 14px;
                            `}
                            type="button"
                            onClick={handleCloseSelect}
                        >
                            Cancel
                        </button>
                        <button
                            className={'btn text-white bg-primary rounded border ml-2 w-50'}
                            css={css`
                                font-size: 14px;
                            `}
                        >
                            Send File
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

const ChannelRow = ({ channelData, index, file, handleSendDocumentToChannel }) => {
    const [isLoading, setIsLoading] = useState(false);
    const position = useSelector((state) => state.user.position);

    const sendDocumentToChannel = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const form = e.target;
            const formData = new FormData(form);
            const data = {
                files: [
                    {
                        url: file.url,
                        name: file.name,
                        tags: file.tags?.map((tag) => tag._id),
                    },
                ],
                message: { text: formData.get('message') },
                visibleTo: formData.getAll('visibleTo'),
            };
            if (position) {
                data.message.location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
            } else {
                data.message.location = { latitude: null, longitude: null };
            }
            data.message = JSON.stringify(data.message);
            const response = await fetchWrapper.post(`chatrooms/${channelData._id}/forward-message`, data);
            toast.success(response?.message);
            // const updatedMember = getUpdatedMember(memberData, channelData, true);
            handleSendDocumentToChannel();
        } catch (e) {
            toast.error(e?.message || 'Error while sending the file');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <tr key={channelData._id}>
                <td>{index + 1}.</td>
                <td>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: row;
                        `}
                    >
                        <span className="mr-2">{channelData.title}</span>

                        <BootstrapToolTip text="Go to channel">
                            <a href={`/teams/${channelData?._id}`} target="_blank" rel="noreferrer">
                                <BsArrowBarRight className="text-primary font-bold" />
                            </a>
                        </BootstrapToolTip>
                    </div>
                </td>
                <td
                    css={css`
                        width: 300px;
                    `}
                >
                    {(channelData.subTitle || '').slice(0, 100)}
                    {channelData.subTitle && (channelData.subTitle || '').length > 100 && '...'}
                </td>
                <td>
                    <span className="badge badge-primary">{channelData?.teamData?.name || ''}</span>
                </td>
                <td>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            width: 200px;
                            align-items: flex-start;
                        `}
                    >
                        {isLoading ? (
                            <div>
                                <VscLoading className="spin" />
                            </div>
                        ) : (
                            <SendToChannel file={file} sendDocumentToChannel={sendDocumentToChannel} />
                        )}
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

const Channels = ({
    currUser,
    file,
    handleFetchAllRoomsData = () => {},
    handleSendDocumentToChannel = () => {},
    allRoomsData,
    selectedTeams: selectedTeamsProp,
}) => {
    const [show, setShow] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredChannels, setFilteredChannels] = useState(allRoomsData || []);
    const [selectedTeams, setSelectedTeams] = useState(selectedTeamsProp || []);

    const managedTeams = (currUser?.teamList || []).filter(
        (item) => item.role === 'owner' || item.role === 'teamManager'
    );
    const managedTeamsOptions = managedTeams.map(({ team }) => ({
        label: team?.name,
        value: team?.id || team?._id,
    }));

    const channelFilterHandler = () => {
        const searchLower = (searchTerm ?? '').toLowerCase().trim();
        const selectedTeamIds = (selectedTeams || []).map(({ value }) => value);

        return (allRoomsData || []).filter(
            (channel) =>
                (!selectedTeamIds.length || selectedTeamIds.includes(channel?.team)) &&
                ((channel?.title || '').toLowerCase().includes(searchLower) ||
                    (channel?.subTitle || '').toLowerCase().includes(searchLower))
        );
    };

    const handleChangeTeam = (_value) => {
        setSelectedTeams(_value);
    };
    const handleSearchTermChange = () => {
        const updatedChannels = channelFilterHandler();
        setFilteredChannels(updatedChannels);
    };
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            setSearchTerm('');
            handleFetchAllRoomsData();
            // fetchMemberData();
        }
    }, [show]);

    useEffect(() => {
        handleSearchTermChange();
    }, [searchTerm, show, allRoomsData, selectedTeams]);

    return (
        <Fragment>
            <ToolTip
                text="Send to Channels"
                side="left"
                cssStyles={`top:-90% !important; min-width: 126px !important; left: -75px !important;`}
            >
                <button
                    css={css`
                        padding: 1rem;
                        border: none;
                        text-align: center;
                        border-radius: 50%;
                        display: flex;
                        &:hover {
                            cursor: pointer;
                            transform: scale(1.1);
                            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 8px;
                        }
                    `}
                    className="bg-primary text-white"
                    onClick={() => handleShow()}
                >
                    <BiSend fontSize={18} />
                </button>
            </ToolTip>
            <Modal show={show} onHide={handleClose} centered size="xl">
                <Modal.Header closeButton>
                    <h1 className="modal-headers">Send {file.displayName} to Channels</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="option-members-content">
                        <div
                            css={css`
                                margin-bottom: 1rem;
                            `}
                        >
                            <div
                                css={css`
                                    min-width: 264px;
                                    width: clamp(264px, max-content);
                                    z-index: 999 !important;
                                    margin-bottom: 16px;
                                `}
                            >
                                <Select
                                    value={selectedTeams}
                                    className="bolder"
                                    name="Team Name"
                                    isClearable={true}
                                    options={managedTeamsOptions}
                                    onChange={handleChangeTeam}
                                    placeholder="Select Teams"
                                    isMulti={true}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#c3e4e2',
                                            primary: '#50aeb0',
                                        },
                                    })}
                                />
                            </div>
                            <input
                                type="text"
                                placeholder="Search Channels"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                css={css`
                                    width: 100%;
                                    padding: 8px;
                                    font-size: 16px;
                                    border-radius: 4px;
                                    border: 1px solid #dddddd;
                                    outline: 1px solid transparent;
                                    &:focus {
                                        border-color: transparent;
                                        outline: 2px solid #50aeb0;
                                    }
                                `}
                            />
                        </div>

                        <div
                            css={css`
                                min-height: 200px;
                                max-height: 500px;
                                overflow: auto;
                                > div {
                                    height: 100%;
                                }
                            `}
                        >
                            {allRoomsData && allRoomsData.length > 0 ? (
                                <Table>
                                    <thead
                                        css={css`
                                            position: sticky;
                                            top: 0;
                                            background-color: #dddddd;
                                        `}
                                    >
                                        <tr>
                                            <th>S.N</th>
                                            <th>Channel Name</th>
                                            <th>Channel Description</th>
                                            <th>Team Name</th>

                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredChannels.length > 0 ? (
                                            filteredChannels.map((item, idx) => (
                                                <ChannelRow
                                                    channelData={item}
                                                    index={idx}
                                                    key={item?._id || item?.id}
                                                    file={file}
                                                    handleSendDocumentToChannel={handleSendDocumentToChannel}
                                                />
                                            ))
                                        ) : (
                                            <div className="p-4 d-flex justify-content-center align-items-center">
                                                No Channels Found
                                            </div>
                                        )}
                                    </tbody>
                                </Table>
                            ) : (
                                <div
                                    className="loader"
                                    key={0}
                                    css={css`
                                        height: 100%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    `}
                                >
                                    <VscLoading fontSize={34} className="spin" />
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Channels);
