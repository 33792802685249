import React, { useState } from 'react';
import { css } from 'twin.macro';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';
import { Button, Modal, Table, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import { cleanUpString, fetchWrapper, getTeamIdFromURL } from '../../../../_helpers';
import FileUploader from '../../Common/FileUploader';
import ProviderSelector from '../../../Admin/EVVContainer/ProviderSelector';

export const showBulkReport = async (res) => {
    if (res?.errors?.length) {
        const errorList = res.errors
            .map(
                (item) => `
            <li style="font-size: 14px; margin-bottom: 4px;">
                <strong>${item?.name || 'Unknown'}</strong>: 
                <span style="color: red;">${item?.error || 'No details provided'}</span>
            </li>
        `
            )
            .join('');

        const showInformation = await Swal.fire({
            title: `Failed items:`,
            html: `<ul style="text-align: left; padding-left: 20px;">${errorList}</ul>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Close',
            reverseButtons: true,
            customClass: {
                popup: 'swal-custom-width',
            },
        });

        if (!showInformation.isConfirmed) {
            return;
        }
    }
};
const preparePayload = (items) => {
    return (items || [])
        .map((item) => {
            const commonData = {
                firstName: item?.firstName?.label,
                middleName: item?.middleName?.label,
                lastName: item?.lastName?.label,
            };
            const addresses = [
                {
                    street1: item?.street1?.label || '',
                    street2: item?.street2?.label || '',
                    city: item?.city?.label || '',
                    state: item?.state?.label || '',
                    zip: item?.zip?.label || '',
                    latitude: '',
                    longitude: '',
                    phoneNumber: '',
                },
            ];

            const channel = item?.channel?.label;
            if (!commonData.firstName || !commonData.lastName || !channel) return null;

            return {
                channel,
                ...commonData,
                _evv: {
                    data: {
                        ...commonData,
                        addresses,
                    },
                },
            };
        })
        .filter(Boolean);
};

const BulkModal = ({ onCreateOrUpdate = async () => {}, bulkCategory = '' }) => {
    const [show, setShow] = useState(true);
    const [fileData, setFileData] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [items, setItems] = useState([]);
    const [errors, setErrors] = useState([]);
    const teamId = getTeamIdFromURL();
    const [provider, setProvider] = useState(null);

    const handleFileUpload = async (formData) => {
        try {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (jsonData.length > 0) {
                    const tempItems = jsonData
                        .slice(1)
                        .map((row) => {
                            const cleanedRow = row.map(cleanUpString);
                            const [firstName, middleName, lastName, street1, street2, city, state, zip, channel] =
                                cleanedRow;
                            return !firstName || !lastName
                                ? null
                                : {
                                      firstName: { label: firstName, error: !firstName && 'First Name is required' },
                                      middleName: { label: middleName || '' },
                                      lastName: { label: lastName, error: !lastName && 'Last Name is required' },
                                      street1: {
                                          label: street1,
                                      },
                                      street2: {
                                          label: street2,
                                      },
                                      city: {
                                          label: city,
                                      },
                                      state: {
                                          label: state,
                                      },
                                      zip: {
                                          label: zip,
                                      },
                                      channel: { label: channel, error: !channel && 'Client Channel is required' },
                                  };
                        })
                        .filter(Boolean);

                    setItems(tempItems);
                    setErrors([]);
                } else {
                    setErrors(['No data found in the file']);
                }
                setFileData(jsonData);
                setIsUploading(false);
            };

            reader.onerror = () => {
                setErrors(['Failed to read the file. Please try again.']);
                setIsUploading(false);
            };

            reader.readAsBinaryString(formData.get('file'));
        } catch (error) {
            setErrors(['An error occurred while processing the file.']);
            setIsUploading(false);
            console.error('Error parsing Excel file:', error);
        }
    };

    const handleCancelUpload = () => {
        setFileData(null);
        setItems([]);
        setErrors([]);
    };

    const handleSelectProvider = (provider) => {
        setProvider(provider);
    };

    const handleSubmit = async () => {
        try {
            const data = preparePayload(items || []);
            const response = await Swal.fire({
                title: `Confirm creating ${bulkCategory}`,
                html: `
                    <div>
                    <p style="font-size: 14px;">
                        Total ${bulkCategory} Count: ${(items || []).length}
                    </p>
                    <p style="font-size: 14px; color:green">
                        Valid ${bulkCategory}  Count: ${(data || []).length}
                    </p>
                     <p style="font-size: 14px;color:red;">
                        Invalid ${bulkCategory}  Count: ${(items || []).length - (data || []).length}
                    </p>
                    </div>
                `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });

            if (!response.isConfirmed) {
                return;
            }
            if (!teamId && !provider) {
                return toast.error('Please select a provider/team');
            }
            const res = await fetchWrapper.post(`evv/client/bulk?team=${teamId || provider?.teamId}`, data);
            if (res.error) {
                toast.error(res?.error);
            }
            toast.success(res?.message);

            await showBulkReport(res);
            onCreateOrUpdate();
        } catch (error) {
            console.log('ERROR => ', error);
            toast.error(error?.message);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Bulk {bulkCategory} Create</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!teamId && (
                    <div className="ml-4 mr-4">
                        <ProviderSelector provider={provider} selectProvider={handleSelectProvider} />
                    </div>
                )}
                {!fileData ? (
                    <>
                        <FileUploader
                            accept=".xlsx, .xls"
                            onFileUpload={handleFileUpload}
                            buttonText="Upload Excel File"
                            loadingText="Uploading..."
                            successMessage="File uploaded successfully!"
                            errorMessage="File upload failed. Please try again."
                            fileLimit={5}
                            showTips={true}
                            tips={[
                                'Ensure the file is in .xlsx format.',
                                `Check that each ${bulkCategory}  has a valid name and phone number.`,
                            ]}
                        />

                        {isUploading && (
                            <div className="d-flex justify-content-center">
                                <VscLoading className="spin" size={30} />
                            </div>
                        )}
                        {errors.length > 0 && (
                            <div className="alert alert-danger mt-3">
                                <ul>
                                    {errors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        css={css`
                            width: 100%;
                            overflow-x: auto;
                        `}
                    >
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.N.</th>
                                    {items.length > 0 &&
                                        Object.keys(items[0]).map((header, idx) => <th key={idx}>{header}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {item?.firstName?.label}
                                            <p className="text-danger font-bold ">{item?.firstName?.error}</p>
                                        </td>
                                        <td>
                                            {item?.middleName?.label}
                                            <p className="text-danger font-bold ">{item?.middleName?.error}</p>
                                        </td>
                                        <td>
                                            {item?.lastName?.label}
                                            <p className="text-danger font-bold ">{item?.lastName?.error}</p>
                                        </td>
                                        <td>
                                            {item?.street1?.label}
                                            <p className="text-danger font-bold ">{item?.street1?.error}</p>
                                        </td>
                                        <td>
                                            {item?.street2?.label}
                                            <p className="text-danger font-bold ">{item?.street2?.error}</p>
                                        </td>
                                        <td>
                                            {item?.city?.label}
                                            <p className="text-danger font-bold ">{item?.city?.error}</p>
                                        </td>
                                        <td>
                                            {item?.state?.label}
                                            <p className="text-danger font-bold ">{item?.state?.error}</p>
                                        </td>
                                        <td>
                                            {item?.zip?.label}
                                            <p className="text-danger font-bold ">{item?.zip?.error}</p>
                                        </td>
                                        <td>
                                            {item?.channel?.label}
                                            <p className="text-danger font-bold ">{item?.channel?.error}</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                {fileData && (
                    <Button variant="secondary" onClick={handleCancelUpload}>
                        Upload Another File
                    </Button>
                )}
                <Button variant="primary" onClick={handleSubmit} disabled={isUploading || !fileData}>
                    {isUploading ? <Spinner animation="border" size="sm" /> : `Create Bulk ${bulkCategory} `}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    teamMembers: state.teams.teamMembers,
});

export default connect(mapStateToProps)(BulkModal);
