import { css } from 'twin.macro';

import React, { useEffect, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Modal } from 'react-bootstrap';

// Helpers
import ID from '../../../_helpers/uuid';

//Icons
import { FaEdit } from 'react-icons/fa';
import ServicesElement from './ServicesElement';
import AccrualPolicyElement from './Speciality/AccrualPolicyElement';
import { toast } from 'react-toastify';
import { customCheckbox } from '../../../_elements/CustomCheckbox';
import ParagraphEditor from './ParagraphEditor';
import { IMAGESTYPES } from '../../../contants/enums';
import DraggableOptions from './FormBuilder/DraggableOptions';
import { ModalFormContainer } from './smartpdf/styles';
import FieldOptions from './FormBuilder/FieldOptions';
import { getNonOverlappingCoordinates } from './smartpdf/utils';

const AUTOFILL_FIELD_OPTIONS = [
    { label: 'None', value: '' },
    { label: 'Address', value: 'address' },
    { label: 'Email', value: 'email' },
    { label: 'Name', value: 'name' },
    { label: 'Phone', value: 'phone' },
];

const isBiruficationEnabled = (fieldKey) => {
    return ['InputField'].includes(fieldKey);
};

const EditElement = (props) => {
    const [options, setOptions] = useState([]);
    const [isRequired, setIsRequired] = useState(true);
    const [autofillField, setAutofillField] = useState('');
    const [show, setShow] = useState(false);
    let feature = props.element.data?.length ? props.element.data[0]?.feature : {};
    const [isDownloadable, setIsDownloadable] = useState(feature?.isDownloadable || false);
    const [downloadPermission, setDownloadPermission] = useState(feature?.downloadableChannelRoles || []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        setIsRequired(props.element.hasOwnProperty('required') ? props.element.required : true);
        if (
            props.element.key === 'ServiceRadio' ||
            props.element.key === 'Radio' ||
            props.element.key === 'Checkbox' ||
            props.element.key === 'Select'
        ) {
            if (props.element.data) {
                setOptions([...props.element.data]);
            }
        }
        if (isBiruficationEnabled(props.element.key) && props.element.config?.autofillField) {
            setAutofillField(props.element.config?.autofillField || '');
        }
    }, [props.element]);

    const { register, handleSubmit } = useForm({
        mode: 'onBlur',
    });
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handleCheckboxClick = (e) => {
        setIsDownloadable(e.target.checked);
    };
    const handlePermissionChange = (event) => {
        let role = event.target.value;
        let newPermissions = [...downloadPermission];

        let roleIndex = newPermissions.indexOf(role);
        let permissionGiven = Boolean(roleIndex >= 0);
        if (permissionGiven) {
            // remove role permission
            newPermissions.splice(roleIndex, 1);
        } else {
            // add role in permission
            newPermissions.push(role);
        }
        setDownloadPermission(newPermissions);
    };
    const onSubmit = (data) => {
        const tempData = { ...props.element };

        // bifurication
        if (isBiruficationEnabled(tempData?.key)) {
            tempData.config = tempData?.config || {};
            tempData.config.autofillField = autofillField || '';
        }
        tempData.fieldName = data.fieldName;
        tempData.instruction = data.instruction;
        tempData.data = tempData?.data || {};
        tempData.options = data.options;
        if (data.url) tempData.data = { url: data.url };
        if (data.required) {
            tempData.required = true;
        } else {
            tempData.required = false;
        }
        if (
            props.element.key === 'AccrualPolicySelect' ||
            props.element.key === 'ServiceRadio' ||
            props.element.key === 'Radio' ||
            props.element.key === 'Checkbox' ||
            props.element.key === 'Select'
        ) {
            tempData.data = options;
        }
        if (downloadPermission.length) {
            tempData.data.feature = {
                isDownloadable: true,
                downloadableChannelRoles: downloadPermission,
            };
        }

        if (data.file) {
            const maxFileSize = 20 * 1024 * 1024;
            if (data.file.length === 0) {
                toast.error('Cannot Upload empty file.');
            } else {
                const File = data.file[0];
                if (File && File.size < maxFileSize) {
                    getBase64(File).then((file) => {
                        tempData.data = [
                            {
                                ...(Array.isArray(tempData.data) ? tempData.data[0] : tempData.data),
                                fileName: File.name,
                                file: file,
                            },
                        ];
                        props.onEdit(tempData);
                        handleClose();
                    });
                } else {
                    toast.warn('File Must be less than 20 mb in size!');
                }
            }
        } else {
            props.onEdit(tempData);
            handleClose();
        }
    };

    const handleNewOption = () => {
        const newOption = {
            value: ID.uuid(),
            text: `option ${options.length + 1}`,
            ...(props?.genericCheckboxOrRadio || {}),
        };
        if (props?.genericCheckboxOrRadio || options[0]?.config) {
            newOption.config = newOption?.config || {};
            newOption.config.parentFormElement = {};
            const { key, id } = props.element;
            newOption.config.parentFormElement = {
                key,
                id,
            };
            const newCoordinates = getNonOverlappingCoordinates(options);
            newOption.config.templateConfig = newCoordinates.templateConfig;
            newOption.config.rectConfig = newCoordinates.rectConfig;
        }
        const tempData = [...options, newOption];
        setOptions(tempData);
    };

    const handleRemoveOption = (optionId) => {
        const tempData = [...options];
        const index = tempData.findIndex((option) => option.value === optionId);
        if (index !== -1) {
            tempData.splice(index, 1);
            setOptions(tempData);
        }
    };

    const handleOptionEdit = (e, optionId) => {
        const tempData = [...options];
        const index = tempData.findIndex((option) => option.value === optionId);
        if (index !== -1) {
            tempData[index].text = e.target.value;
            setOptions(tempData);
        }
    };

    const toggleIsRequired = () => {
        setIsRequired((prevIsRequired) => !prevIsRequired);
    };

    const handlePrepopulateChange = (e) => {
        setAutofillField(e.target.value);
    };
    return (
        <Fragment>
            <span onClick={handleShow} className="pointer">
                <FaEdit color={props?.color || 'blue'} />
            </span>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <ModalFormContainer>
                    <Modal.Body className=" position-relative">
                        <div
                            className="form-element filter"
                            css={css`
                                margin-bottom: 5px;
                                padding: 1rem 0.5rem 1rem 1rem;
                            `}
                        >
                            <Form>
                                <Form.Group controlId="formDescription">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Title"
                                        defaultValue={props.element.fieldName}
                                        name="fieldName"
                                        ref={register}
                                    />
                                </Form.Group>

                                {props.element.key === 'Paragraph' ? (
                                    <ParagraphEditor defaultValue={props.element.instruction} register={register} />
                                ) : (
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>Instruction</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="instruction"
                                            defaultValue={props.element.instruction}
                                            name="instruction"
                                            ref={register}
                                        />
                                    </Form.Group>
                                )}

                                {props.element.key === 'Link' && (
                                    <React.Fragment>
                                        <Form.Group controlId="formDescription">
                                            <Form.Label>URL: </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Paste the URL"
                                                defaultValue={props.element?.data?.url}
                                                name="url"
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </React.Fragment>
                                )}
                                {props.element.key === 'Photo' && (
                                    <React.Fragment>
                                        <Form.Group controlId="formDescription">
                                            <Form.Label>Upload file: </Form.Label>
                                            <Form.Control type="file" accept={IMAGESTYPES} name="file" ref={register} />
                                        </Form.Group>
                                    </React.Fragment>
                                )}

                                {props.element.key === 'PdfPreviewer' && (
                                    <div>
                                        <React.Fragment>
                                            <Form.Group controlId="formDescription" className="d-flex">
                                                <Form.Label>is Downloadable?: </Form.Label>
                                                <input
                                                    type="checkbox"
                                                    css={css`
                                                        margin-left: 1rem;
                                                    `}
                                                    value={isDownloadable}
                                                    defaultValue={isDownloadable}
                                                    onChange={handleCheckboxClick}
                                                />
                                            </Form.Group>
                                            {isDownloadable && (
                                                <div
                                                    css={css`
                                                        padding-bottom: 1.5rem;
                                                        display: flex;
                                                        justify-content: flex-start;
                                                        align-items: flex-start;
                                                        flex-direction: column;
                                                    `}
                                                >
                                                    <div>
                                                        <h6>Downloadable by:</h6>
                                                    </div>
                                                    <div
                                                        css={css`
                                                            margin-left: 2rem;
                                                            label + label {
                                                                margin-left: 1.5rem;
                                                            }
                                                        `}
                                                    >
                                                        <label htmlFor="thirdparty">
                                                            <input
                                                                id="thirdparty"
                                                                type="checkbox"
                                                                onChange={handlePermissionChange}
                                                                value="doctor"
                                                                checked={downloadPermission.includes('doctor')}
                                                            />{' '}
                                                            Third Party
                                                        </label>
                                                        <label htmlFor="family">
                                                            <input
                                                                id="family"
                                                                type="checkbox"
                                                                onChange={handlePermissionChange}
                                                                value="family"
                                                                checked={downloadPermission.includes('family')}
                                                            />{' '}
                                                            Family
                                                        </label>
                                                        <label htmlFor="supervisor">
                                                            <input
                                                                id="supervisor"
                                                                type="checkbox"
                                                                onChange={handlePermissionChange}
                                                                value="supervisor"
                                                                checked={downloadPermission.includes('supervisor')}
                                                            />{' '}
                                                            Supervisor
                                                        </label>
                                                        <label htmlFor="user">
                                                            <input
                                                                id="user"
                                                                type="checkbox"
                                                                onChange={handlePermissionChange}
                                                                value="user"
                                                                checked={downloadPermission.includes('user')}
                                                            />{' '}
                                                            User
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            <Form.Group controlId="formDescription">
                                                <Form.Label>Upload file: </Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    accept="application/pdf"
                                                    name="file"
                                                    ref={register}
                                                />
                                            </Form.Group>
                                        </React.Fragment>
                                    </div>
                                )}

                                {props.element.key === 'Header' ||
                                props.element.key === 'Paragraph' ||
                                props.element.key === 'Link' ||
                                props.element.key === 'PdfPreviewer' ||
                                props.element.key === 'Photo' ? null : (
                                    <Form.Group
                                        controlId="required"
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            .requiredCheckbox {
                                                width: 20px;
                                                height: 20px;
                                                margin: 0 1rem;
                                                display: inline;
                                                position: relative !important;
                                                opacity: 1 !important;
                                            }
                                        `}
                                    >
                                        <Form.Label
                                            css={css`
                                                margin: 0;
                                            `}
                                        >
                                            Required
                                        </Form.Label>
                                        <Form.Control
                                            className="requiredCheckbox"
                                            type="checkbox"
                                            css={customCheckbox}
                                            value={isRequired}
                                            checked={isRequired}
                                            onChange={toggleIsRequired}
                                            name="required"
                                            ref={register}
                                        />
                                    </Form.Group>
                                )}

                                <FieldOptions element={props.element} register={register} />

                                <Fragment>
                                    {isBiruficationEnabled(props.element.key) && (
                                        <Form.Group controlId="autofillField">
                                            <Form.Label>Choose Autofill Field:</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={autofillField}
                                                onChange={handlePrepopulateChange}
                                            >
                                                {AUTOFILL_FIELD_OPTIONS.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                </Fragment>

                                {(props.element.key === 'Radio' ||
                                    props.element.key === 'Checkbox' ||
                                    props.element.key === 'Select') && (
                                    <DraggableOptions
                                        options={options}
                                        setOptions={setOptions}
                                        handleOptionEdit={handleOptionEdit}
                                        handleRemoveOption={handleRemoveOption}
                                        handleNewOption={handleNewOption}
                                    />
                                )}
                                {props.element.key === 'ServiceRadio' && (
                                    <ServicesElement setOptions={setOptions} options={options} />
                                )}
                                {props.element.key === 'AccrualPolicySelect' && (
                                    <AccrualPolicyElement setOptions={setOptions} options={options} />
                                )}
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <button className="btn button-orange" variant="secondary" onClick={handleClose}>
                            Close
                        </button>
                        <button className="btn button-green" type="btn" onClick={handleSubmit(onSubmit)}>
                            Done
                        </button>
                    </Modal.Footer>
                </ModalFormContainer>
            </Modal>
        </Fragment>
    );
};
export default EditElement;
