import React, { Fragment, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { fetchWrapper } from '../../../../_helpers';
import { toast } from 'react-toastify';
import { prepareFormData as prepareZealFormData } from './ZealComponents/ZealEmployeeCheckForm';
import EmployeeCheckRow from './ZealComponents/EmployeeCheckRow';
import Swal from 'sweetalert2';
import EmployeeCheckPreviewModal from './ZealComponents/EmployeeCheckPreviewModal';
import { groupFunction } from './PayrollUtils';
import { AdminTable } from '../../reusableStyles';
import { EmployeeRow } from './ZealComponents/Payallocation/EmployeeTableRow';
import {
    clearPayallocation,
    setPayallocationEmployeeChecks,
    setPayallocationEmployees,
    updatePayallocationEmployeeCheck,
} from '../../../../_redux';
import { connect } from 'react-redux';
import { getUniqueShifts, prepareCheckDataFromConfig } from './PayallocationUtils';
import SavePeriodModal from './ZealComponents/Payallocation/SavePeriodModal';
// import { IoMdArrowBack } from 'react-icons/io';

function prepareFormData(payrollType, formData) {
    return { zeal: prepareZealFormData }[payrollType](formData) || (() => formData);
}

function PayAllocationTableContainer({
    selectedVisits,
    gotoScreenOne,
    provider,
    teamId,
    defaultReportingDataQuery = {},
    setPayallocationEmployees,
    setPayallocationEmployeeChecks,
    payallocation,
    updatePayallocationEmployeeCheck,
}) {
    const [isBusy, setIsBusy] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showBulkPreview, setShowBulkPreview] = useState(false);
    const [previewError, setPreviewError] = useState('');
    const [bulkPreviewDataFromZeal, setBulkPreviewDataFromZeal] = useState([]);
    const companyID = provider?.payroll?.metadata?.companyID;

    const onHide = () => {
        setShowBulkPreview(false);
    };

    async function togglePreviewModal() {
        setShowBulkPreview(true);
        const _groupedVisit = Object.values(payallocation?.employees || {}) || [];
        const _groupedVisitWithAllShifts = _groupedVisit.map((employee) => {
            const employeeID = employee?.employeeID;
            const shifts = getUniqueShifts([
                ...(employee?.shifts || []),
                ...(payallocation?.employeesEarnings[employeeID]?.shifts || []),
            ]);
            return { ...employee, shifts };
        });
        fetchPreviewDataArr(_groupedVisitWithAllShifts, {});
    }

    const groupedVisits = groupFunction(selectedVisits, {
        workLocations: payallocation ? payallocation?.provider?.workLocations || [] : [],
    });

    async function getEmployeeCheckPreview(checkData) {
        try {
            setIsBusy(true);
            const data = await fetchWrapper.post(
                `/evv/payroll/zeal/employee/${companyID}/preview-check${teamId ? `?team=${teamId}` : ''}`,
                checkData
            );
            setPreviewError('');
            return data;
        } catch (error) {
            const errorMessage = error.message || "Preview data couldn't be fetched";
            toast.error(errorMessage);
            setPreviewError(errorMessage);
            return [];
        } finally {
            setIsBusy(false);
        }
    }

    async function onConfirmBulkCreateCheck() {
        const result = await Swal.fire({
            title: 'Do you confirm creating checks?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#50aeb0',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        });
        if (result?.isConfirmed) {
            await bulkUpdateEmployeePayrolls();
        }
    }
    async function bulkUpdateEmployeePayrolls() {
        try {
            setSubmitting(true);
            const _groupedVisit = Object.values(payallocation?.employees || {}) || [];
            const _groupedVisitWithAllShifts = _groupedVisit.map((employee) => {
                const employeeID = employee?.employeeID;
                const shifts = getUniqueShifts([
                    ...(employee?.shifts || []),
                    ...(payallocation?.employeesEarnings[employeeID]?.shifts || []),
                ]);
                return { ...employee, shifts };
            });
            const checkDataArray = [];
            for (const _employee of _groupedVisitWithAllShifts) {
                const result = await prepareCheckDataFromConfig(_employee, payallocation);
                if (result) {
                    checkDataArray.push(result);
                }
            }
            const providerIdentifier =
                { zeal: provider?.payroll?.metadata?.companyID }[provider.payroll.type] || provider._id;
            const response = await fetchWrapper.post(
                `evv/payroll/${provider.payroll.type}/employee/${providerIdentifier}/checks${teamId ? `?team=${teamId}` : ''}`,
                checkDataArray
            );
            if (response?.createdChecks?.length) {
                toast.success(`Created ${response?.createdChecks?.length} checks with ${response?.visitCount} visits`);
            }
            if (response?.failedChecks?.length) {
                toast.error(`Failed to created ${response?.failedChecks?.length} checks`);
                (response?.failedChecks || [])?.forEach((errorResponse) => {
                    const errorMessage = errorResponse?.error?.message;
                    if (errorMessage) {
                        toast.error(errorMessage);
                    }
                });
            }
            if (response?.createdChecks?.length) {
                onHide();
                gotoScreenOne();
            }
        } catch (error) {
            console.log('ERROR', error);
            toast.error('Something went wrong!');
        } finally {
            setSubmitting(false);
        }
    }

    async function fetchPreviewDataArr(groupedVisitsArr = [], options = {}) {
        if (options?.updateEmployees) {
            Swal.fire({
                html: `<div style="text-align: center;">
                                    <img src="/images/loading.gif" alt="loader" />
                                    <h3>Fetching Preview Checks...</h3>
                                </div>`,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
        }
        const checkDataArray = [];
        for (const _employee of groupedVisitsArr) {
            const result = await prepareCheckDataFromConfig(_employee, payallocation);
            if (result) {
                checkDataArray.push(result);
            }
        }
        const response = await getEmployeeCheckPreview((checkDataArray || []).filter((x) => x));
        const responseWithShifts = checkDataArray.reduce((responseAcc, empCheckDetails) => {
            responseAcc[empCheckDetails?.employeeID] = { ...empCheckDetails, ...response[empCheckDetails?.employeeID] };
            return responseAcc;
        }, {});
        setPayallocationEmployeeChecks(response);
        setBulkPreviewDataFromZeal(Object.values(responseWithShifts) || []);

        if (options?.updateEmployees) {
            const _payallocationEmployees = groupedVisits.reduce((acc, obj) => {
                if (obj.employeeID) {
                    acc[obj.employeeID] = obj;
                }
                return acc;
            }, {});
            setPayallocationEmployees(_payallocationEmployees);
        }
        Swal?.close();
    }

    useEffect(() => {
        if (groupedVisits && groupedVisits?.length && payallocation?.config?.reportingPeriodID) {
            fetchPreviewDataArr(groupedVisits, { updateEmployees: true });
        }
    }, []);

    if (!payallocation?.provider?.onboarded) {
        return <div>Provider is not onboarded</div>;
    }
    if (payallocation?.provider?.payrollType !== 'zeal') {
        return <div>Payroll process has not been set up for {payallocation?.provider?.payrollType}</div>;
    }
    return (
        <Fragment>
            <div
                css={css`
                    background: #fff;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    padding: 12px;
                    height: 90vh;
                    position: relative;
                `}
            >
                <div
                    css={css`
                        // max-width: 1024px;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        margin: auto;
                        overflow: auto;
                        height: 100%;
                    `}
                >
                    <div
                        css={css`
                            overflow: auto;
                            padding: 8px 12px;
                            // border: 1px solid #eee;
                            margin-bottom: 48px;
                            height: 100%;
                        `}
                    >
                        {(Object.values(payallocation?.employees) || [])?.length ? (
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    gap: 12px;
                                `}
                            >
                                <AdminTable className="w-100 text-dark-grey">
                                    <thead style={{ position: 'sticky', top: '-16px' }}>
                                        <tr>
                                            <th>SN</th>
                                            <th>Employee</th>
                                            <th>Staff Rate</th>
                                            <th>Total Time</th>
                                            <th>Add Earnings</th>
                                            <th>Total Mileage</th>
                                            <th>Staff Amount(Gross)</th>
                                            <th>Net Pay</th>
                                            <th>Disbursement Method</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(Object.values(payallocation?.employees) || [])?.map((visit, idx) => (
                                            <EmployeeRow
                                                key={idx}
                                                detailVisit={{ index: idx, ...visit }}
                                                teamId={teamId}
                                                gotoScreenOne={gotoScreenOne}
                                                payallocation={payallocation}
                                                workLocations={payallocation?.provider?.workLocations || []}
                                                updatePayallocationEmployeeCheck={updatePayallocationEmployeeCheck}
                                            />
                                        ))}
                                    </tbody>
                                </AdminTable>
                            </div>
                        ) : (
                            <div
                                className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                css={css`
                                    margin-top: 100px;
                                    width: 100%;
                                `}
                            >
                                No Visits Selected
                            </div>
                        )}
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%);
                        margin: 12px 0;
                        background: #fff;
                        width: calc(100% - 24px);
                        // max-width: 1024px;
                    `}
                >
                    <Button
                        disabled={isBusy}
                        variant="secondary"
                        onClick={() => {
                            gotoScreenOne();
                        }}
                        css={css`
                            width: 164px;
                        `}
                    >
                        {/* <IoMdArrowBack /> */}
                        Pay Allocation
                    </Button>
                    <SavePeriodModal />
                    <Button
                        disabled={isBusy}
                        onClick={togglePreviewModal}
                        css={css`
                            width: 162px;
                        `}
                    >
                        Create all Checks
                    </Button>
                </div>
            </div>
            <EmployeeCheckPreviewModal
                show={showBulkPreview}
                onHide={onHide}
                loading={isBusy}
                submitting={submitting}
                onConfirm={() => onConfirmBulkCreateCheck()}
                previewData={bulkPreviewDataFromZeal}
                previewError={previewError}
                allowCreateCheck={true}
            >
                {(bulkPreviewDataFromZeal || []).map((previewData, index) => (
                    <div
                        key={index}
                        css={css`
                            margin-top: 8px;
                        `}
                    >
                        <EmployeeCheckRow
                            employeeCheck={{
                                ...previewData,
                                ...previewData?.checks?.at(0),
                                totals: {
                                    employee_taxes: previewData?.total_employee_taxes,
                                    employer_taxes: previewData?.total_employer_taxes,
                                    employee_deductions: previewData?.total_employee_deductions,
                                    employer_deductions: previewData?.total_employer_deductions,
                                },
                            }}
                            setShowEmployeeCheck={() => {}}
                            companyID={companyID}
                            teamId={teamId}
                            preview={true}
                            bulk={true}
                            expand={index === 0}
                            loading={isBusy}
                            showShiftBreakDown={true}
                        />
                    </div>
                ))}
            </EmployeeCheckPreviewModal>
        </Fragment>
    );
}
const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    payallocation: state.payallocation,
});
const mapDispatchToProps = {
    setPayallocationEmployees,
    setPayallocationEmployeeChecks,
    updatePayallocationEmployeeCheck,
    clearPayallocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayAllocationTableContainer);
