import { useState, useEffect, useCallback } from 'react';
import { debounce } from '../methods';

export const useQuery = (fetchFunction, initialQuery, delay = 200) => {
    const [query, setQuery] = useState(initialQuery);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Debounced fetch function
    const debouncedFetch = useCallback(
        debounce(async (query) => {
            setIsLoading(true);
            setError(null);
            try {
                const result = await fetchFunction(query);
                setData(result);
                setIsLoading(false);
            } catch (err) {
                if (err?.name === 'AbortError') return;
                setError(err);
                setIsLoading(false);
            }
        }, delay),
        [fetchFunction]
    );

    // Update query and trigger debounced fetch
    const updateQuery = useCallback((newQuery) => {
        setQuery((prevQuery) => ({
            ...prevQuery,
            ...newQuery,
        }));
    }, []);

    // Automatically fetch data when query changes
    useEffect(() => {
        debouncedFetch(query);
    }, [query, debouncedFetch]);

    // Handle sorting
    const handleSort = useCallback(
        (sortMethod) => {
            const [currentSortKey, currentSortDirection] = query.sortBy.split(':');
            const newDirection = currentSortKey === sortMethod && currentSortDirection === 'asc' ? 'desc' : 'asc';
            const newSortBy = `${sortMethod}:${newDirection}`;

            updateQuery({
                sortBy: newSortBy,
                page: 1,
            });
        },
        [query.sortBy, updateQuery]
    );

    return {
        isLoading,
        data,
        error,
        query,
        updateQuery,
        handleSort,
    };
};
