import React from 'react';
import { css } from 'twin.macro';
import { BootstrapToolTip } from '@/_elements/ToolTip';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Form } from 'react-bootstrap';
import { BsTrash, BsGripVertical } from 'react-icons/bs';

const DraggableOptions = ({ options = [], setOptions, handleOptionEdit, handleRemoveOption, handleNewOption }) => {
    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedOptions = Array.from(options);
        const [movedOption] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, movedOption);
        setOptions(reorderedOptions);
    };

    return (
        <React.Fragment>
            <p>
                <strong>Options:</strong>
            </p>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="options">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {options.map((option, index) => (
                                <Draggable key={option.value} draggableId={option.value} index={index}>
                                    {(provided) => (
                                        <div
                                            className="d-flex mb-2"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <div
                                                {...provided.dragHandleProps}
                                                css={css`
                                                    cursor: grab;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                `}
                                            >
                                                <BsGripVertical size={24} />
                                            </div>

                                            <Form.Control
                                                type="text"
                                                placeholder="Title"
                                                value={option.text}
                                                onChange={(e) => handleOptionEdit(e, option.value)}
                                            />

                                            {options.length > 2 && (
                                                <p
                                                    css={css`
                                                        padding: 2px;
                                                        cursor: pointer;
                                                    `}
                                                >
                                                    <BootstrapToolTip
                                                        text={'Delete'}
                                                        side={'top'}
                                                        cssStyles={
                                                            'top:-70% !important; min-width: fit-content !important'
                                                        }
                                                    >
                                                        <BsTrash
                                                            color="red"
                                                            size="24"
                                                            onClick={() => handleRemoveOption(option.value)}
                                                        />
                                                    </BootstrapToolTip>
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className="mb-4 ml-4">
                <Button variant="primary" onClick={handleNewOption}>
                    Add new
                </Button>
            </div>
        </React.Fragment>
    );
};

export default DraggableOptions;
