import React, { useMemo } from 'react';
import { BootstrapToolTip } from '../ToolTip';
import { BsArrowBarRight } from 'react-icons/bs';
export const GoToChannel = ({ channel = {}, size }) => {
    const title = useMemo(() => {
        return channel?.title || channel?.label;
    }, [channel]);
    const _id = useMemo(() => {
        return channel?._id || channel?.value;
    }, [channel]);
    if (!_id) {
        return <span className="badge badge-warning">No Channel</span>;
    }
    return (
        <div className="d-flex">
            {title}
            <BootstrapToolTip text="Go to channel">
                <a href={`/teams/${_id}`} target="_blank" rel="noreferrer">
                    <BsArrowBarRight size={24} className="text-primary ml-2" />
                </a>
            </BootstrapToolTip>
        </div>
    );
};
