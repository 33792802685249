import { css } from 'twin.macro';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { deleteUser, setUser } from '../../_redux/actions/ActionUser';
import { getInitials, getRandomColor } from '../../_helpers';
import noImg from '../../assets/no-img.png';
import loaderStyles from '../../_elements/loader';
import { loginUser, startLoading, stopLoading } from '../../_redux/actions/ActionAuthentication';
import { getUserData } from '../../_redux/actions/ActionUser';
import { avatarContainer, profileContainer } from './styles/ProfileStyles';
import 'react-phone-input-2/lib/style.css';
import Profile from './Profile';
import ProfileCertificates from './ProfileCertificates';

function ProfileContainer({ user = {}, ui, fadeOutAvatarWarning, showCertificate }) {
    const [show, setShow] = useState(false);
    const [tabKey, setTabKey] = useState('profile');

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const userList = useMemo(() => {
        user.initials = getInitials(user.name);
        user.color = getRandomColor(user.id);
        return user;
    }, [user]);

    return (
        <div css={profileContainer}>
            <Link className="profile-icon text-decoration-none " to="#0" onClick={handleShow}>
                <div css={avatarContainer}>
                    {user.avatar ? (
                        <img
                            className="sidebar-avatar"
                            src={
                                (user.avatar.startsWith('http:')
                                    ? user.avatar.split('http:').join('https:')
                                    : user.avatar) || noImg
                            }
                            alt="logo"
                            css={css`
                                width: 2.7rem;
                                height: 2.7rem;
                                border-radius: 50%;
                                object-fit: cover;
                                object-position: center;
                            `}
                        />
                    ) : (
                        <div className="replacementImg avatar" style={{ background: userList.color }}>
                            <span
                                css={css`
                                    font-size: 18px !important;
                                    margin-top: 0 !important;
                                    color: white !important;
                                `}
                            >
                                {userList.initials}
                            </span>
                        </div>
                    )}
                </div>
            </Link>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-headers">My Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ui.loading && (
                        <div css={loaderStyles}>
                            <img src="/images/loading.gif" alt="loader" />
                        </div>
                    )}
                    <Tabs id="zeal-controlled-tab" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                        <Tab eventKey="profile" title="Profile">
                            <Profile fadeOutAvatarWarning={fadeOutAvatarWarning} ui={ui} />
                        </Tab>
                        {showCertificate && (
                            <Tab eventKey="certificates" title="Certificates">
                                <ProfileCertificates show={tabKey === 'certificates'} />
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>
            </Modal>
        </div>
    );
}
const mapStateToProps = (state) => ({
    user: state.user.user,
    ui: state.ui,
});

export default connect(mapStateToProps, { loginUser, deleteUser, setUser, startLoading, stopLoading, getUserData })(
    ProfileContainer
);
