import React, { Fragment, useEffect, useState } from 'react';
import { css } from 'twin.macro';
import NavigationBar from '../../../../../Admin/reusableComponents/NavigationBar';
import { getOtherUserData } from '../../../../../../_redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWrapper, getChannelIdFromSettingsURL, getTeamIdFromURL } from '../../../../../../_helpers';
import { CourseMetricContainer } from '../Common/CourseMetricContainer';
import LoadingSpiner from '../../../../../../_elements/LoadingSpiner';

function UserCoursesInfo({ handleBack, user }) {
    const [coursesData, setCoursesData] = useState({});
    const [loading, setLoading] = useState(false);
    const [certificates, setCertificates] = useState({});
    const team = getTeamIdFromURL();
    const chatroom = getChannelIdFromSettingsURL();

    async function loadData() {
        try {
            if (!user?._id) {
                return;
            }
            setLoading(true);
            const response = await fetchWrapper.get(`amol/${user?._id}`);
            if (!response.data) {
                throw new Error("Couldn't fetch courses data");
            }
            setCoursesData(response?.data?.data);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }
    async function loadUserCertificates() {
        try {
            if (!user?._id) {
                return;
            }
            if (!chatroom && !team) {
                return;
            }
            let query = `?user=${user?._id}`;
            query = chatroom ? query + `&chatroom=${chatroom}` : query + `&team=${team}`;
            const response = await fetchWrapper.get(`user-certificates${query}`);
            const tempCertificates = (response || []).map((certificate) => ({
                course_name: certificate.courseName || '',
                course_description: certificate?.trainingObjectives || '',
                course_completion_percentage: '100',
                has_certificate: true,
                certificateUrl: certificate?.certificateUrl || '',
                form: certificate?.form || '',
            }));
            setCertificates(tempCertificates);
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        }
    }

    useEffect(() => {
        if (user && user?._id) {
            loadData();
            loadUserCertificates();
        }
    }, [user]);

    return (
        <div
            css={css`
                padding: 1rem 2rem;
            `}
        >
            <div className="d-flex justify-content-between mb-4">
                <NavigationBar mainTabName={`Users | ${user?.name || ''}`} goToTeamPage={handleBack} />
            </div>
            <span
                css={css`
                    font-weight: bold;
                `}
            >
                Submitted Certificates:
            </span>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                `}
            >
                {coursesData?.length > 0 && (
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 6px;
                        `}
                    >
                        {certificates.map((course, index) => (
                            <CourseMetricContainer key={index} course={course} user={user} />
                        ))}
                    </div>
                )}
            </div>
            <div
                css={css`
                    font-weight: bold;
                    margin-top: 24px;
                `}
            >
                Courses Certificates:
            </div>
            {loading ? (
                <LoadingSpiner size={42} />
            ) : (
                <>
                    {coursesData?.length > 0 ? (
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                            `}
                        >
                            {coursesData.map((course, index) => (
                                <CourseMetricContainer key={index} course={course} user={user} />
                            ))}
                        </div>
                    ) : (
                        <div className="custom-card">No Course Group Assigned</div>
                    )}
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    CurrUser: state.user.user,
});
export default connect(mapStateToProps, { getOtherUserData })(UserCoursesInfo);
