import { css } from '@emotion/react';
import React, { Fragment } from 'react';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import dayjs from 'dayjs';
import { Button, FormLabel, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import ChannelSelectFilter from '../../../../../Admin/ChannelSelectFilter';
import TagSelectFilter from '../../../../Common/Tags/TagSelectFilter';
import SortedTableHeaderIcon from '../../../../../Admin/SortedTableHeaderIcon';
import AccumulatedView from '../../SubmittedForms/AccumulatedView';
import { MdOutlineFileDownload } from 'react-icons/md';

const Filters = ({
    managerView,
    formName,
    member,
    channel,
    members,
    startDate,
    endDate,
    handleClearFormName,
    handleClearMember,
    handleChangeChannel,
    handleStartDateClear,
    handleEndDateChange,
    handleChangeMember,
    handleEndDateClear,
    handleFormName,
    formNames,
    channelIdFromUrl,
    channelDetails,
    loadingFiles,
    submissionType,
    submissionTypes,
    sortBy,
    handleSort,
    generatePdfAndDownloadZip,
    handleSubmissionType,
    handleStartDateChange,
    isDownloading,
    searchTags,
    handleTagSelect,
}) => {
    return (
        <Fragment>
            {!managerView && (
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 0 8px;
                    `}
                    className="row"
                >
                    <div>
                        {(formName?.label || member?.label || channel?.label || startDate || endDate) && (
                            <div
                                className=" border rounded mb-4 py-4 "
                                css={css`
                                    width: 400px;
                                    padding: 0 5px;
                                `}
                            >
                                <b>Search Filter: </b>
                                {formName?.label && (
                                    <div className="d-flex justify-content-between">
                                        Form name:
                                        <div>
                                            <b className="text-primary">{formName.label}</b>
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    handleClearFormName({});
                                                }}
                                            >
                                                <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {member && (
                                    <div className="d-flex justify-content-between">
                                        {' '}
                                        Submitted by:
                                        <div>
                                            <b className="text-primary">{member.label}</b>
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    handleClearMember({});
                                                }}
                                            >
                                                <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {channel && (
                                    <div className="d-flex justify-content-between">
                                        {' '}
                                        Channel:
                                        <div>
                                            <b className="text-primary">{channel.label}</b>
                                            <button
                                                className="btn"
                                                onClick={() => {
                                                    handleChangeChannel({});
                                                }}
                                            >
                                                <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    {startDate && (
                                        <div className="d-flex justify-content-between">
                                            From:
                                            <div>
                                                <b className="text-primary">
                                                    {dayjs(startDate).format('MM-DD-YYYY, h:mm A')}
                                                </b>
                                                <button
                                                    className="btn"
                                                    onClick={() => {
                                                        handleStartDateClear();
                                                    }}
                                                >
                                                    <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {endDate && (
                                        <div className="d-flex justify-content-between">
                                            To:
                                            <div>
                                                <b className="text-primary">
                                                    {dayjs(endDate).format('MM-DD-YYYY, h:mm A')}
                                                </b>
                                                <button
                                                    className="btn"
                                                    onClick={() => {
                                                        handleEndDateClear();
                                                    }}
                                                >
                                                    <AiOutlineMinusCircle fontSize="24px" color="f30e00" />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div
                className="row"
                css={css`
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    // justify-content: space-between;
                    width: 100%;
                    align-items: end;
                    z-index: 20;
                    gap: 5px;
                    margin-left: 2px;
                `}
            >
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className="mr-2 ">Form Name: </FormLabel>
                        <Select
                            value={formName}
                            className="bolder"
                            name="form name"
                            isClearable={true}
                            options={formNames}
                            onChange={handleFormName}
                            placeholder="Enter Form Name"
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className="mr-2 ">Submitted by: </FormLabel>
                        <Select
                            value={member}
                            className="bolder"
                            name="member"
                            isClearable={true}
                            options={members}
                            onChange={handleChangeMember}
                            placeholder="Enter Member Name"
                        />
                    </InputGroup>
                </div>
                {!managerView && (
                    <div
                        css={css`
                            min-width: 264px;
                        `}
                    >
                        <InputGroup className="mb-3 flex-column">
                            <FormLabel className="mr-2 ">Channel: </FormLabel>
                            <ChannelSelectFilter
                                disabled={!!channelIdFromUrl}
                                selectedOption={channelDetails?.id ? channelDetails : channel}
                                selectedRoomOption={channel}
                                onRoomSelect={handleChangeChannel}
                                placeholder="Enter Channel Name"
                                className="bolder"
                                css={css`
                                    width: 264px;
                                `}
                            />
                        </InputGroup>
                    </div>
                )}

                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className=" mr-2">From: </FormLabel>
                        <DateTimePicker
                            onChange={handleStartDateChange}
                            value={startDate ? new Date(startDate) : startDate}
                            format="MM-dd-y hh:mm a"
                            disableClock={true}
                            calendarType={'US'}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                    `}
                >
                    <InputGroup className="mb-3 flex-column">
                        <FormLabel className="mr-2 ">To: </FormLabel>
                        <DateTimePicker
                            onChange={handleEndDateChange}
                            value={endDate ? new Date(endDate) : endDate}
                            format="MM-dd-y hh:mm a"
                            disableClock={true}
                            calendarType={'US'}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: clamp(264px, max-content);
                    `}
                >
                    <InputGroup className="mb-3 mr-2 flex-column">
                        <FormLabel className="mr-2 ">Tags: </FormLabel>
                        <TagSelectFilter
                            className="bolder"
                            css={css`
                                min-width: 264px;
                            `}
                            handleSelectCallback={handleTagSelect}
                            type="userform"
                            disabled={loadingFiles}
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: clamp(264px, max-content);
                    `}
                >
                    <InputGroup className="mb-3 mr-2 flex-column">
                        <FormLabel className="mr-2 ">Submission Type: </FormLabel>
                        <Select
                            value={submissionType}
                            className="bolder"
                            name="submission type"
                            isClearable={true}
                            options={submissionTypes}
                            onChange={handleSubmissionType}
                            placeholder="Enter Submission Type"
                        />
                    </InputGroup>
                </div>
                <div
                    css={css`
                        min-width: 264px;
                        width: clamp(264px, max-content);
                    `}
                >
                    <InputGroup className="mb-3 mr-2 flex-column">
                        <FormLabel>Sort By ( Submitted At ): </FormLabel>
                        <div
                            style={{ display: 'flex', alignItems: 'center', gap: '3px' }}
                            css={css`
                                color: #333;
                                border: 1px solid #ccc;
                                height: 37.5px;
                                border-radius: 4px;
                                cursor: pointer;
                            `}
                            onClick={() => handleSort('createdAt')}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: center;
                                `}
                            >
                                <span>{sortBy?.direction === 'asc' ? 'Ascending' : 'Descending'}</span>
                                <SortedTableHeaderIcon size={16} sortConfig={sortBy} fieldName={'createdAt'} />
                            </div>
                        </div>
                    </InputGroup>
                </div>
            </div>
            <div
                css={css`
                    width: full;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 5px;
                `}
            >
                <Button
                    disabled={
                        isDownloading ||
                        !(
                            startDate?.length ||
                            endDate?.length ||
                            channel?.label?.length ||
                            member?.label?.length ||
                            formName?.label?.length ||
                            searchTags?.length
                        )
                    }
                    onClick={() => generatePdfAndDownloadZip(true)}
                >
                    <MdOutlineFileDownload />
                    {isDownloading ? 'Downloading...' : 'Bulk Download'}
                </Button>
                <AccumulatedView
                    form={formName}
                    channel={channel}
                    startDate={startDate}
                    endDate={endDate}
                    user={member}
                    sortBy={sortBy}
                />
            </div>
        </Fragment>
    );
};

export default Filters;
