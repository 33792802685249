import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { BiTransferAlt } from 'react-icons/bi';
import { VscLoading } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchWrapper } from '../../../../_helpers';
import { MdArrowForwardIos } from 'react-icons/md';
import Select from 'react-select';
import { css } from 'twin.macro';
import { UpdateAClient } from '../../../../_redux/actions/ActionEVV';
import { toast } from 'react-toastify';
import { ToolTip } from '../../../../_elements/ToolTip';

function ChangeClientChannel({ client }) {
    const [show, setShow] = useState(false);

    return (
        <div>
            <ToolTip text={'Transfer ownership'} cssStyles={'top:-70% !important; min-width: 126px !important'}>
                <BiTransferAlt className="icon purple" onClick={() => setShow(true)} />
            </ToolTip>
            {show && <ChangeClientChannelModal client={client} show={show} setShow={setShow} />}
        </div>
    );
}

export default ChangeClientChannel;

const ChangeClientChannelModal = ({ show, setShow, client }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState();
    const teamId = client.provider.team?._id || client.provider.team?.id || client.provider.team;
    useEffect(() => {
        const getProviderChatrooms = async () => {
            try {
                const response = await fetchWrapper.get(`/chatrooms?teamId=${teamId}&limit=2000&page=1`);
                let results = response?.results.map((chatroom) => {
                    return {
                        label: chatroom.title,
                        value: chatroom.id,
                    };
                });
                setChannels(results);
            } catch (err) {
                console.log(err);
            }
        };
        if (client.provider) {
            getProviderChatrooms();
        }
    }, [client]);

    const handleUpdate = async () => {
        try {
            setIsLoading(true);
            const response = await fetchWrapper.patch(`evv/client/${client.id || client._id}/changeChatroom`, {
                chatroom: selectedChannel.value,
            });
            dispatch(UpdateAClient(response));
            toast.success('Client channel updated successfully');
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            toast.error(error.message || 'Oops! Something went wrong');
        }
    };
    const handleChange = (channel) => {
        setSelectedChannel(channel);
    };
    return (
        <Modal
            dialogClassName="dialog-rounded"
            backdropClassName="modal-backdrop"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="md"
        >
            <Modal.Header className="h3 d-flex justify-content-center">Change Client Channel</Modal.Header>
            <Modal.Body className="modal-body">
                <div className="mb-3 d-flex  align-items-center">
                    <div className="d-flex align-items-center">
                        <div
                            className="d-flex align-items-center justify-content-center"
                            css={css`
                                background-color: ${client.color};
                                color: white;
                                width: 62px;
                                height: 62px;
                                border-radius: 5px;
                                font-size: 2.4rem;
                            `}
                        >
                            <span>{client.initials}</span>
                        </div>
                        <div
                            css={css`
                                margin-left: 20px;
                            `}
                        >
                            <div
                                css={css`
                                    font-size: 2rem;
                                `}
                            >
                                Client Name: {client.ClientFirstName} {client.ClientLastName}
                            </div>
                            {client.chatroom ? (
                                <div
                                    css={css`
                                        font-size: 1.8rem;
                                    `}
                                >
                                    <span>Channel Name: </span>
                                    <span>{client.chatroom.title}</span>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <h4>No chatroom found</h4>
                                    <p>Please create a chatroom for this client</p>
                                </div>
                            )}{' '}
                        </div>
                    </div>
                </div>

                {client.provider ? (
                    <div>
                        <div
                            css={css`
                                font-size: 2rem;
                            `}
                        >
                            Provider:
                            <span className="ml-2">{client.provider.agencyName}</span>
                        </div>
                    </div>
                ) : (
                    <div className="text-center">
                        <h4>No Provider found</h4>
                    </div>
                )}
                <div className="mt-4">Change Client Channel</div>
                <div className="border rounded p-2 mt-2 ">
                    <div className="d-flex jusity-content-between align-items-center">
                        <div
                            css={css`
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                            `}
                        >
                            <span
                                css={css`
                                    font-size: 1.4rem;
                                    color: #444;
                                `}
                            >
                                Current channel
                            </span>
                            {client.chatroom.title}
                        </div>
                        <div
                            css={css`
                                margin: 0 1rem;
                                font-size: 3rem;
                                color: #50aeb0;
                            `}
                        >
                            <MdArrowForwardIos />
                        </div>
                        <div
                            css={css`
                                flex: 2;
                            `}
                        >
                            <span
                                css={css`
                                    font-size: 1.4rem;
                                    color: #444;
                                `}
                            >
                                New channel
                            </span>
                            <Select options={channels} onChange={handleChange} />
                        </div>
                    </div>
                    {selectedChannel && (
                        <div className="d-flex justify-content-end align-items-center mt-4">
                            <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
                                {isLoading ? <VscLoading /> : 'Update'}
                            </Button>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex mt-4 flex-row justify-content-between w-100">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
