import { css } from 'twin.macro';
import { useState, Fragment, useRef, useEffect } from 'react';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { BootstrapToolTip } from '../../../../../../_elements/ToolTip';
import { ActionStyles } from '../../../../reusableStyles';
import { BsPencil } from 'react-icons/bs';
import { connect } from 'react-redux';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { formatDateToLocalISO } from '../ZealEmployeeCheckForm';
import { BsTrash } from 'react-icons/bs';
import { updatePayallocationEmployeeCheck, updatePayallocationEmployeeEarning } from '../../../../../../_redux';
import { fetchWrapper } from '../../../../../../_helpers';
import { getUniqueShifts, getUniqueShiftUUID, prepareCheckDataFromConfig } from '../../PayallocationUtils';

function MileageModal({
    employeeID,
    teamId,
    isBusy,
    setIsBusy,
    payallocation,
    mileageReimbursementRate,
    updatePayallocationEmployeeCheck,
    updatePayallocationEmployeeEarning,
}) {
    const [show, setShow] = useState(false);
    const formRef = useRef(null);
    const [shifts, setShifts] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShiftChange = (index, field, value) => {
        if (!value) return;

        setShifts((prevShifts) =>
            prevShifts.map((shift, i) =>
                i === index
                    ? {
                          ...shift,
                          [field]: value,
                          amount: parseFloat(value) * mileageReimbursementRate,
                      }
                    : shift
            )
        );
    };

    const handleAddShift = () => {
        setShifts([
            ...shifts,
            {
                shiftID: `NEW_SHIFT_${getUniqueShiftUUID()}`,
                time: new Date().toISOString().slice(0, 16),
                type: 'reimbursement',
                reimbursementcustom_name: `reimbursement-${getUniqueShiftUUID()}`,
                amount: 0,
                miles: '',
                workLocationID: payallocation?.provider?.workLocationID,
                metadata: { label: 'mileage', uuid: getUniqueShiftUUID(), shiftCreatedAt: new Date().toISOString() },
            },
        ]);
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        try {
            setIsBusy(true);

            const invalidShifts = shifts.filter((shift) => !shift.time || shift.amount <= 0);

            if (invalidShifts.length > 0) {
                toast.error('Please enter a valid date and miles for all shifts.');
                return;
            }

            const nonReimbursementShifts =
                payallocation?.employeesEarnings[employeeID]?.shifts.filter(
                    (shift) => shift?.metadata?.label !== 'mileage'
                ) || [];
            const fixedShifts = (payallocation?.employees || {})[employeeID]?.shifts || [];
            const mileageShifts =
                shifts.map((shift) => ({
                    ...shift,
                    time: formatDateToLocalISO(shift.time),
                    amount: parseFloat(shift.amount),
                    miles: undefined,
                    metadata: { ...(shift?.metadata || {}), label: 'mileage', miles: shift.miles },
                })) || [];
            const allShifts = getUniqueShifts([...fixedShifts, ...nonReimbursementShifts, ...mileageShifts]);
            await getEmployeeCheckPreview({ employeeID, shifts: allShifts });
            updatePayallocationEmployeeEarning({ employeeID, shifts: allShifts });
            handleClose();
        } catch (errror) {
            console.log(errror);
        } finally {
            setIsBusy(false);
        }
    };

    const totalMiles = (shifts || []).reduce((acc, shift) => acc + parseFloat(shift.miles || 0), 0).toFixed(2);
    async function getEmployeeCheckPreview(data) {
        const checkData = await prepareCheckDataFromConfig(data, payallocation);
        try {
            const response = await fetchWrapper.post(
                `/evv/payroll/zeal/employee/${payallocation?.provider?.companyID}/preview-check${teamId ? `?team=${teamId}` : ''}`,
                [checkData]
            );

            updatePayallocationEmployeeCheck(response[data?.employeeID]);
        } catch (error) {
            console.log('eeee', error);
            const errorMessage = error.message || "Preview data couldn't be fetched";
            toast.error(errorMessage);
        }
    }

    useEffect(() => {
        setShifts(
            (payallocation?.employeesEarnings[employeeID]?.shifts || [])
                .filter((shift) => shift?.metadata?.label === 'mileage')
                .map((_shift) => ({ ..._shift, miles: (_shift.amount / mileageReimbursementRate).toFixed(2) }))
        );
    }, [show]);
    return (
        <Fragment>
            {isBusy ? (
                <VscLoading className="spin" />
            ) : (
                <div className="d-flex align-items-center">
                    <div>{totalMiles}</div>
                    <div>
                        <ActionStyles>
                            <BootstrapToolTip text="Edit Mileage">
                                <BsPencil className="icon edit" onClick={handleShow} />
                            </BootstrapToolTip>
                        </ActionStyles>
                    </div>
                </div>
            )}

            <Modal size="lg" centered show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Mileages (Reimbursement Rate: {mileageReimbursementRate})</Modal.Title>
                </Modal.Header>
                <Form ref={formRef} onSubmit={handleSaveChanges}>
                    <Modal.Body>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Date & Time</th>
                                    <th>Miles</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shifts.map((shift, index) => {
                                    return (
                                        <tr key={shift.shiftID}>
                                            <td>
                                                <BsTrash
                                                    color={'red'}
                                                    onClick={() => setShifts(shifts.filter((_, i) => i !== index))}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    required
                                                    type="datetime-local"
                                                    name={`shift-${index}-time`}
                                                    disabled={isBusy}
                                                    value={new Date(shift?.time).toISOString().slice(0, 16)}
                                                    onChange={(e) => handleShiftChange(index, 'time', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="number"
                                                    required
                                                    placeholder="Miles"
                                                    value={shift.miles}
                                                    onChange={(e) =>
                                                        handleShiftChange(index, 'miles', parseFloat(e.target.value))
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-center">
                            <strong>Total Miles:</strong>
                            <span className="ml-2">{totalMiles}</span>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="primary" type="button" onClick={handleAddShift} disabled={isBusy}>
                                + Add Mileage
                            </Button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                padding: 12px;
                                width: 100%;
                            `}
                        >
                            <Button variant="secondary" onClick={handleClose} disabled={isBusy}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" disabled={isBusy}>
                                Save Changes
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    payallocation: state.payallocation,
});

const mapDispatchToProps = {
    updatePayallocationEmployeeEarning,
    updatePayallocationEmployeeCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(MileageModal);
