import React, { Fragment, useEffect, useState } from 'react';

import { Form, Tab, Tabs } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { css } from 'twin.macro';
import { fetchWrapper, getDateTimeFromDateTime } from '../../_helpers';
import MultiSubmissionView from '../Admin/Form/MultiSubmissionView';
import StaticPreview from '../Admin/Form/StaticPreview';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
import { FormPdf } from '../../_elements/FormPdf';
import { PDFViewer } from '@react-pdf/renderer';
import { TagPreviewWithContainer } from '../Features/Common/Tags/TagsPreview';
import { VscLoading } from 'react-icons/vsc';

function SubmittedFormsView({ teamId, teamIds, form, filters = {} }) {
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [userForm, setUserForm] = useState({});
    const [userForms, setUserForms] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const userId = useSelector((state) => state.user.user.id);
    const [key, setKey] = useState('staticform');

    useEffect(() => {
        const getUserForms = async () => {
            try {
                setIsLoadingForm(true);
                let url = `/userFormData?limit=3000&form=${form._id || form.id}`;
                if (teamId || teamIds) {
                    url += teamId ? `&team=${teamId}` : `&teamIds=${teamIds}`;
                }

                if (filters.formName) {
                    url += `&name=${filters.formName}`;
                }
                if (filters.userId) {
                    url += `&user=${filters.userId}`;
                }
                if (filters.channel) {
                    url += `&chatroom=${filters.channel}`;
                }
                if (filters.startDate) {
                    url += `&startDate=${filters.startDate}`;
                }
                if (filters.endDate) {
                    url += `&endDate=${filters.endDate}`;
                }
                let response = await fetchWrapper.get(url);
                let results = response.results;

                setUserForms(results);
                let tempUserList = [];
                let tempUserForm = {};
                tempUserList = results.map((userform) => {
                    if (userform.userData) {
                        return {
                            ...userform.userData,
                            formId: userform._id,
                            createdDate: userform.createdAt,
                        };
                    }
                    return null;
                });
                setUsersList(tempUserList);
                let currentForm = results[0];
                if (currentForm) {
                    tempUserForm.id = currentForm._id;
                    tempUserForm.formElements = currentForm.formData.data.map((formElement) => ({
                        ...formElement,
                        value: currentForm.data.find((element) => element.fieldName.split('-')[1] === formElement._id)
                            ?.value,
                    }));
                    setUserForm({ ...currentForm, ...tempUserForm });
                    setIsLoadingForm(false);
                }
                setIsLoadingForm(false);
            } catch (err) {
                setIsLoadingForm(false);
                console.log(err);
            }
        };
        if (form._id || form.id) {
            getUserForms();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const handleUserChange = (e) => {
        const userForm = userForms.find((form) => form._id === e.target.value);
        let currentForm = userForm;
        let tempUserForm = {};
        if (currentForm) {
            tempUserForm.id = currentForm._id;
            tempUserForm.formElements = currentForm.formData.data.map((formElement) => ({
                ...formElement,
                value: currentForm.data.find((element) => element.fieldName.split('-')[1] === formElement._id)?.value,
            }));
            setUserForm({ ...currentForm, ...tempUserForm });
        }
    };

    const generatePdfAndDownloadZip = async (singleFile = true) => {
        setIsDownloading(true);

        // const pdfName = `${formName?.label||""}_${member?.label||""}_${startDate ? new Date(startDate).toLocaleDateString() : ""}_${endDate ? new Date(endDate).toLocaleDateString(): ""}.pdf`
        try {
            if (singleFile) {
                const blob = await FormPdf(userForms, 'combined');
                return FileSaver.saveAs(blob, `${userForms[0].formData.name}.pdf`);
            }

            const formPdfBlobs = await Promise.all(
                userForms.map(async (userForm) => {
                    return FormPdf(userForm);
                })
            );

            const zip = JsZip();
            formPdfBlobs.forEach((pdfBlob, i) => {
                zip.file(
                    `${getDateTimeFromDateTime(userForms[i].updatedAt)}-${userForms[i].userData.name}-${userForms[i].formData.name}.pdf`,
                    pdfBlob
                );
            });

            zip.generateAsync({ type: 'blob' }).then((zipFile) => {
                const currentDate = new Date().getTime();
                const fileName = `Bulk-${currentDate}.zip`;
                return FileSaver.saveAs(zipFile, fileName);
            });
        } catch (error) {
            console.log('ERROR IN PDF GENERATION => ', error);
        } finally {
            setIsDownloading(false);
        }
    };
    return (
        <div>
            {isLoadingForm ? (
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        <VscLoading className="spin" size={42} />
                    </div>
                    <span className="text-center">Loading ...</span>
                </div>
            ) : (
                <>
                    {usersList.length > 0 ? (
                        <Fragment>
                            <Form.Group>
                                <Form.Label>Submission</Form.Label>
                                {isDownloading ? (
                                    <p className="float-right">Downloading...</p>
                                ) : (
                                    <button
                                        className="btn btn-info float-right border border-white btn-lg mb-2"
                                        onClick={() => generatePdfAndDownloadZip()}
                                    >
                                        Download PDF
                                    </button>
                                )}

                                <Form.Control as="select" onChange={handleUserChange}>
                                    {usersList.length > 0 &&
                                        usersList.map((user, idx) =>
                                            user ? (
                                                <option key={user.id + idx} value={user.formId}>
                                                    {user.name} ({new Date(user.createdDate).toLocaleString()})
                                                </option>
                                            ) : (
                                                <option>Deleted User</option>
                                            )
                                        )}
                                </Form.Control>
                            </Form.Group>
                            <div
                                css={css`
                                    padding-bottom: 1rem;
                                    margin-top: 0.5rem;
                                `}
                            >
                                <TagPreviewWithContainer tags={userForm?.tags || []} />
                            </div>
                            <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                                <Tab eventKey="staticform" title="Static form">
                                    {key === 'staticform' && (
                                        <StaticPreview
                                            key={userForm?.id}
                                            formElements={userForm?.formElements || []}
                                            onlyView={true}
                                        />
                                    )}
                                </Tab>
                                <Tab
                                    eventKey="pdf"
                                    title="PDF"
                                    className="tabtab"
                                    css={css`
                                        color: $495057;
                                    `}
                                >
                                    {key === 'pdf' && (
                                        <div
                                            css={css`
                                                height: 70vh;
                                                overflow: auto;
                                            `}
                                        >
                                            <PDFViewer
                                                width="100%"
                                                height="100%"
                                                css={css`
                                                    border: none;
                                                    outline: none;
                                                `}
                                                showToolbar={false}
                                            >
                                                {FormPdf(userForm, 'component')}
                                            </PDFViewer>
                                        </div>
                                    )}
                                </Tab>
                            </Tabs>
                            <MultiSubmissionView
                                showSignature={true}
                                form={userForm}
                                userId={userId}
                                id={userForm?.id}
                            />
                        </Fragment>
                    ) : (
                        <div>
                            <h3>No submissions made on this Form</h3>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default SubmittedFormsView;
