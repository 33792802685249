import React, { useState, useRef, useEffect } from 'react';
import { css } from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Nav } from 'react-bootstrap';
import { GetProvider } from '../../../../_redux/actions/ActionEVV';
import { getSortConfig, getTeamIdFromURL } from '../../../../_helpers';

import ProviderList from './ProviderList';
import Pagination from '../../Pagination';
import CreateProvider from './CreateProvider';
import AdminSearchBox from '../../reusableComponents/AdminSearchBox';
import { useQuery } from '../../../../_helpers/hooks/useQuery';

const Provider = ({ GetProvider, providers, isLoading }) => {
    const isTeamSettings = !!useLocation().pathname.match(/user-evv/);
    const teamId = getTeamIdFromURL();
    const evvBasePath = isTeamSettings ? 'user-evv' : 'evv';

    const history = useHistory();
    const [providerState, setProviderState] = useState('active');
    const [search, setSearch] = useState('');
    const inputRef = useRef();

    const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
    const { query, updateQuery, handleSort } = useQuery(GetProvider, {
        page: currentPage,
        searchText: '',
        sortBy: 'createdAt:desc',
        team: teamId,
        state: providerState,
    });

    const onSearch = (e) => {
        setSearch(e);
        const page = 1;
        updateQuery({ searchText: e, page });
        history.push(`/${evvBasePath}?page=${page}`);
    };

    const onPageChanged = (data) => {
        if (currentPage !== data.currentPage && data.currentPage > 0) {
            history.push(`/${evvBasePath}?page=${data.currentPage}`);
        }
    };

    useEffect(() => {
        updateQuery({ page: currentPage });
    }, [currentPage]);

    useEffect(() => {
        if (!isTeamSettings && search === inputRef.current.value) {
            updateQuery({ searchText: search, page: 1 });
        }
    }, [search, inputRef, isTeamSettings]);

    useEffect(() => {
        updateQuery({ state: providerState, page: 1 });
    }, [providerState]);

    return (
        <div>
            {!!isTeamSettings || (
                <div
                    className="d-flex flex-row align-items-center mb-4"
                    css={css`
                        justify-content: space-between;
                    `}
                >
                    <AdminSearchBox
                        search={search}
                        onSearch={onSearch}
                        placeholder="Search Provider"
                        inputRef={inputRef}
                    />
                    <Nav
                        variant="pills"
                        className="p-2"
                        css={css`
                            background: #e1e1e1;
                            border-radius: 5px;
                        `}
                    >
                        <Nav.Item>
                            <Nav.Link
                                as={Button}
                                variant={providerState === 'active' ? 'primary' : 'link'}
                                onClick={() => setProviderState('active')}
                                style={{ fontSize: '1.6rem' }}
                                className={providerState === 'active' ? 'bg-primary text-white' : ''}
                            >
                                Active
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ml-1">
                            <Nav.Link
                                as={Button}
                                variant={providerState === 'archieved' ? 'primary' : 'link'}
                                onClick={() => setProviderState('archieved')}
                                style={{ fontSize: '1.6rem' }}
                                className={providerState === 'archieved' ? 'bg-primary text-white' : ''}
                            >
                                Archived
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <CreateProvider />
                </div>
            )}
            <div
                className="custom-card d-flex flex-column justify-content-between"
                css={css`
                    min-height: 675px;
                `}
            >
                <ProviderList
                    list={providers.providers}
                    handleSort={handleSort}
                    isLoading={isLoading}
                    sortConfig={getSortConfig(query.sortBy)}
                    currentPage={currentPage}
                    providerState={providerState}
                    setProviderState={setProviderState}
                    teamId={teamId}
                />
                {providers.providers.length ? (
                    <div className="m-4">
                        <Pagination
                            totalRecords={providers.totalProviders}
                            pageLimit={10}
                            pageNeighbours={1}
                            pageNo={currentPage}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.evv.isLoading,
    providers: state.evv.providers,
    organizations: state.organizations.organizations,
});

export default connect(mapStateToProps, { GetProvider })(Provider);
