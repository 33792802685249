import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { css } from 'twin.macro';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';

//components

import ReactChannelSelectComponent from '../Tasks/ReactChannelSelectComponent';
import { fetchWrapper } from '../../../_helpers';
import { setMessage } from '../../../_redux';
import { CustFormLabel } from '../../../_elements/formLabel';
import { StyledLinkBtn } from '../Tasks/EventModalStyles';
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im';
import UserInRoom from '../Tasks/UserInRoom';
import OurAccordion from '../../../_elements/OurAccordion';
import { FaBellSlash } from 'react-icons/fa';
import { ConsentToolTip } from '../../../_elements/ToolTip';

const AssignChannelToPackageModal = ({
    CurrUser,
    handleClose,
    teamId,
    showValidateModel,
    setShowValidateModel,
    teamsPrivatePublic,
    intersectionOfAssignes,
    setFinalizedAssignedUsers,
    setFinalizedNotificationDisabledFor = () => {},
    // finalizedAssignedUsers,
    finalizedNotificationDisabledFor,
    setConfirmAssignSubmisstion,
    // confirmAssignSubmisstion,
    actionMode,
}) => {
    const { handleSubmit } = useForm({
        mode: 'onBlur',
    });
    const [usersInRoom, setUsersInRoom] = useState();
    const [allUsersInRoom, setAllUsersInRoom] = useState(null);
    const [selectedUserOption, setSelectedUserOption] = useState([CurrUser.id || CurrUser._id]);
    const [notificationDisabledFor, setNotificationDisabledFor] = useState([]);

    const [loadingUsers, setLoadingUsers] = useState(false);
    const onSubmit = async () => {
        setFinalizedAssignedUsers(selectedUserOption);
        setFinalizedNotificationDisabledFor(notificationDisabledFor);
        setConfirmAssignSubmisstion(true);
        setShowValidateModel(false);
        // console.log('submit modal', selectedUserOption);
        // console.log('finalizedAssignedUsers', finalizedAssignedUsers);
        // console.log('confirm submisttion', confirmAssignSubmisstion);
    };
    const fetchUsersData = async () => {
        try {
            setLoadingUsers(true);
            let res;
            res = await fetchWrapper.get(`/activityPackages/members/${teamId}?${teamsPrivatePublic}`);
            const resAssignes = [...res].map((user) => user._id);
            const commonAssignees = [...resAssignes].filter((user) => intersectionOfAssignes.includes(user));
            setSelectedUserOption(commonAssignees.filter((user) => user !== (CurrUser._id || CurrUser.id)));
            const usersInRoomList = [...res].filter((user) => (user._id || user.id) !== (CurrUser._id || CurrUser.id));
            setUsersInRoom(usersInRoomList);
            setAllUsersInRoom([
                ...new Map([...(res || []), CurrUser].map((user) => [user._id || user.id, user])).values(),
            ]);
            setLoadingUsers(false);
        } catch (err) {
            setLoadingUsers(false);
            console.log(err);
        }
    };

    useEffect(() => {
        if (teamsPrivatePublic.length) {
            fetchUsersData();
        }
    }, [teamsPrivatePublic]);

    const handleSelectAllUsers = () => {
        const usersInRoomIds = usersInRoom.map((user) => user._id || user.id);
        setSelectedUserOption(usersInRoomIds);
    };

    const handleNotifySelectAllUsers = () => {
        setNotificationDisabledFor(allUsersInRoom.map((user) => user._id));
    };

    const handleDeselectAllUsers = () => {
        setSelectedUserOption([]);
    };

    const handleNotifyDeselectAllUsers = () => {
        setNotificationDisabledFor([]);
    };

    const handleUserSelect = (userId) => {
        let tempSelectedUserOption;
        if (selectedUserOption.length !== 0) {
            tempSelectedUserOption = [...selectedUserOption];
        } else {
            tempSelectedUserOption = [];
        }
        let indexOfUserId = tempSelectedUserOption.indexOf(userId);
        let isUserPresent = Boolean(indexOfUserId !== -1);
        if (isUserPresent) {
            tempSelectedUserOption.splice(indexOfUserId, 1);
        } else {
            tempSelectedUserOption.push(userId);
        }
        setSelectedUserOption(tempSelectedUserOption);
    };

    const handleNotifyUserSelect = (userId) => {
        let tempSelectedUserOption;
        if (notificationDisabledFor.length !== 0) {
            tempSelectedUserOption = [...notificationDisabledFor];
        } else {
            tempSelectedUserOption = [];
        }
        let indexOfUserId = tempSelectedUserOption.indexOf(userId);
        let isUserPresent = Boolean(indexOfUserId !== -1);
        if (isUserPresent) {
            tempSelectedUserOption.splice(indexOfUserId, 1);
        } else {
            tempSelectedUserOption.push(userId);
        }
        setNotificationDisabledFor(tempSelectedUserOption);
    };
    // const isUserSelected = (userId) => selectedUserOption?.includes(userId);
    useEffect(() => {
        if (finalizedNotificationDisabledFor) {
            setNotificationDisabledFor([...(finalizedNotificationDisabledFor || [])]);
        }
    }, [finalizedNotificationDisabledFor]);
    return (
        <Fragment>
            <Modal show={showValidateModel} onHide={handleClose} size="xl">
                <Modal.Header className="h2 font-weight-bold d-flex justify-content-center">Assign Users</Modal.Header>
                <Modal.Body className="m-2">
                    <Form className="w-90 m-auto" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                            <Form.Group as={Col} md={12}>
                                <CustFormLabel className="d-flex justify-content-between">
                                    <div>
                                        <span style={{ color: 'red' }}>*&nbsp;</span>
                                        <span>
                                            Please make sure that you have selected the users to assign to the Activity
                                            Plan.
                                        </span>
                                    </div>

                                    <div className="w-25 d-flex justify-content-end">
                                        {usersInRoom && usersInRoom?.length !== selectedUserOption?.length ? (
                                            <StyledLinkBtn onClick={handleSelectAllUsers}>
                                                <ImCheckboxUnchecked />
                                                Select all
                                            </StyledLinkBtn>
                                        ) : (
                                            <StyledLinkBtn onClick={handleDeselectAllUsers}>
                                                <ImCheckboxChecked />
                                                Remove all
                                            </StyledLinkBtn>
                                        )}
                                    </div>
                                </CustFormLabel>
                                <div
                                    className="m-0 p-0"
                                    sm="12"
                                    css={css`
                                        display: flex;
                                        border: 1px solid #cacaca;
                                        border-radius: 5px;
                                        padding: 0.2rem;
                                        flex-wrap: wrap;
                                        max-height: 300px;
                                        overflow-y: scroll;
                                        opacity: 1;
                                    `}
                                >
                                    {loadingUsers ? (
                                        <div
                                            css={css`
                                                margin: 0;
                                                padding-left: 0.8rem;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;
                                            `}
                                        >
                                            <VscLoading className="spin" />
                                        </div>
                                    ) : usersInRoom ? (
                                        usersInRoom.map((user) => (
                                            <UserInRoom
                                                key={user._id}
                                                user={user}
                                                onSelect={handleUserSelect}
                                                isSelected={selectedUserOption?.includes(user._id || user.id)}
                                            />
                                        ))
                                    ) : (
                                        <div>No one found</div>
                                    )}
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md={12}>
                                <Fragment>
                                    <br />
                                    <OurAccordion
                                        title={
                                            <CustFormLabel>
                                                <div className="d-flex align-items-center">
                                                    <FaBellSlash />
                                                    <span className="ml-2">Turn off Notifications For</span>
                                                    <ConsentToolTip text="The following unchecked users may receive email, push and SMS notifications for this event" />
                                                </div>
                                            </CustFormLabel>
                                        }
                                        titleTag="span"
                                        defaultShow={true}
                                        style={{ border: '1px solid #cacaca', borderRadius: '5px' }}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <div></div>
                                            <div
                                                className="w-25 d-flex justify-content-end"
                                                css={css`
                                                    [disabled] {
                                                        pointer-events: none;
                                                        cursor: not-allowed;
                                                    }
                                                `}
                                            >
                                                {allUsersInRoom &&
                                                allUsersInRoom?.length !== selectedUserOption?.length ? (
                                                    <StyledLinkBtn onClick={handleNotifySelectAllUsers}>
                                                        <ImCheckboxUnchecked />
                                                        Select all
                                                    </StyledLinkBtn>
                                                ) : (
                                                    <StyledLinkBtn onClick={handleNotifyDeselectAllUsers}>
                                                        <ImCheckboxChecked />
                                                        Remove all
                                                    </StyledLinkBtn>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="m-0 p-0"
                                            sm="12"
                                            css={css`
                                                display: flex;
                                                border-radius: 5px;
                                                padding: 0.2rem;
                                                flex-wrap: wrap;
                                                max-height: 300px;
                                                overflow-y: scroll;
                                                opacity: 1;
                                            `}
                                        >
                                            {loadingUsers ? (
                                                <div
                                                    css={css`
                                                        margin: 0;
                                                        padding-left: 0.8rem;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 100%;
                                                    `}
                                                >
                                                    <VscLoading className="spin" />
                                                </div>
                                            ) : allUsersInRoom ? (
                                                allUsersInRoom.map((user) => (
                                                    <UserInRoom
                                                        key={user._id}
                                                        user={user}
                                                        onSelect={handleNotifyUserSelect}
                                                        isSelected={notificationDisabledFor?.includes(user._id)}
                                                    />
                                                ))
                                            ) : (
                                                <div>No one found</div>
                                            )}
                                        </div>
                                    </OurAccordion>
                                </Fragment>
                            </Form.Group>
                            <Form.Group as={Col} md={12}>
                                <div
                                    css={css`
                                        padding: 2rem 0 0 0;
                                        display: flex;
                                        width: 100%;
                                        justify-content: space-between;
                                    `}
                                >
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            setConfirmAssignSubmisstion(false);
                                            setShowValidateModel(false);
                                        }}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={loadingUsers}
                                    >
                                        Confirm Assign and {actionMode} Plan
                                    </Button>
                                </div>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
const mapStateToProps = (state) => ({
    CurrUser: state.user.user,
});
const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(AssignChannelToPackageModal);
