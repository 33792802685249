import React, { useState, useRef, useEffect, Fragment } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Document, Page } from 'react-pdf';
import { Button } from 'react-bootstrap';
import ID from '../../../../_helpers/uuid';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../../_redux';
import FileUploader from '../../../Features/Teams/TeamSettings/Channels/FileUploader';
import MappedFields from './MappedFields';
import PdfPagination from './PdfPagination';
import PdfCanvas from './PdfCanvas';
import { PdfLoader } from './components';
import { getFileNameFromUrl } from '../../../../_helpers';

const PDFFieldMapper = ({ formElements, onUploadPdfTemplate, url, handleClose }) => {
    const [isBusy, setIsBusy] = useState(false);
    const dispatch = useDispatch();
    const [rects, setRects] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfDimensions, setPdfDimensions] = useState({ width: 0, height: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const canvasRef = useRef(null);
    const pdfContainerRef = useRef(null);
    const [fieldMappings, setFieldMappings] = useState({
        documentId: null,
        fields: [],
        pdfMetadata: {
            pageCount: 1,
            dimensions: { width: 0, height: 0 },
        },
    });
    const processPdf = async (arrayBuffer) => {
        try {
            const pdfDoc = await PDFDocument.load(arrayBuffer);
            const form = pdfDoc.getForm();
            form.flatten();
            const flattenedArrayBuffer = await pdfDoc.save();
            const fileBlob = new Blob([flattenedArrayBuffer], { type: 'application/pdf' });
            const fileUrl = URL.createObjectURL(fileBlob);
            setPdfUrl(fileUrl);
            const page = pdfDoc.getPages()[0];
            const { width, height } = page.getSize();
            const pageCount = pdfDoc.getPageCount();

            setPdfDimensions({ width, height });
            setMetadata({
                pageCount,
                dimensions: { width, height },
            });

            setFieldMappings((prev) => ({
                ...prev,
                documentId: ID.uuid(),
                pdfMetadata: {
                    pageCount,
                    dimensions: { width, height },
                },
            }));
        } catch (error) {
            console.error('Failed to process PDF:', error);
            toast.error('Error processing the PDF.');
            toast.warning(error?.message || '');
        }
    };

    const handleFileUploadFromUrl = async () => {
        try {
            setIsBusy(true);
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            await processPdf(arrayBuffer);
        } catch (error) {
            console.error('Failed to load PDF from URL:', error);
            toast.error('Error loading PDF from the provided URL.');
        } finally {
            setIsBusy(false);
        }
    };

    const handleFileUpload = async (file) => {
        try {
            if (file) {
                setSelectedFile(file);
                const arrayBuffer = await file.arrayBuffer();
                await processPdf(arrayBuffer);
            } else {
                toast.error('Invalid file type. Please upload a PDF.');
            }
        } catch (e) {
            console.log(e);
        } finally {
            setRects([]);
        }
    };

    const saveFieldMappings = async () => {
        try {
            setIsBusy(true);
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                const response = await uploadFile(formData);
                if (response && response.length) {
                    onUploadPdfTemplate({
                        url: response[0].url,
                        metadata,
                    });
                }
            }
            handleClose();
        } catch (error) {
            toast.error('Error uploading file');
        } finally {
            setIsBusy(false);
        }
    };
    const addFormDataElement = (form) => {
        dispatch({ type: 'ADD_FORM_DATA', payload: form });
    };
    const removeFormDataElement = (formId) => {
        dispatch({ type: 'DELETE_FORM_ELEMENT', payload: formId });
    };
    const addFormDataElementOption = (form) => {
        dispatch({ type: 'ADD_FORM_ELEMENT_OPTION', payload: form });
    };
    const removeFormDataElementOption = (optionIdorValue) => {
        dispatch({ type: 'DELETE_FORM_ELEMENT_OPTION', payload: optionIdorValue });
    };

    useEffect(() => {
        const _templateFormElements = (formElements || [])?.filter((x) => x?.config?.templateConfig);
        const checkBoxOrRadioElements = [];
        (formElements || []).forEach((element) => {
            if (element?.config?.hasTemplateConfig) {
                (element?.data || []).forEach((option) => {
                    const parentId = element?._id || element?.id;
                    const tempOption = { ...option };
                    tempOption.config.parentFormElement.id = parentId;
                    if (option?.config?.templateConfig) {
                        checkBoxOrRadioElements.push(tempOption);
                    }
                });
            }
        });
        const templateFormElements = [..._templateFormElements, ...checkBoxOrRadioElements];
        setRects(
            (templateFormElements || []).map((rect) => ({
                ...rect,
                ...(rect?.config?.rectConfig || {}),
                id: rect?._id || rect?.id,
            }))
        );
        setFieldMappings((prev) => {
            if (!prev?.pdfMetadata?.dimensions?.width) {
                return prev;
            } else {
                return {
                    ...prev,
                    fields: (templateFormElements || []).map((rect) => ({
                        ...rect,
                        ...(rect?.config?.templateConfig || {}),
                        ...(rect?.config?.templateConfig?.coordinates || {}),
                        id: rect?._id || rect?.id,
                    })),
                };
            }
        });
    }, [formElements]);

    useEffect(() => {
        if (url) {
            handleFileUploadFromUrl();
        }
    }, [url]);

    return (
        <Fragment>
            {isBusy && <PdfLoader />}

            {pdfUrl && !isBusy && (
                <Fragment>
                    <div
                        style={{
                            border: '1px solid black',
                            position: 'relative',
                            zIndex: 3,
                        }}
                    >
                        <div
                            ref={pdfContainerRef}
                            className=""
                            style={{
                                position: 'relative',
                            }}
                        >
                            <Document
                                style={{
                                    border: '1px solid black',
                                    padding: 0,
                                    margin: 0,
                                }}
                                file={pdfUrl}
                                onLoadSuccess={({ numPages }) => {
                                    setFieldMappings((prev) => ({
                                        ...prev,
                                        pdfMetadata: {
                                            ...prev.pdfMetadata,
                                            pageCount: numPages,
                                        },
                                    }));
                                }}
                            >
                                <Page
                                    pageNumber={currentPage}
                                    width={(pdfContainerRef.current || {}).clientWidth || 800}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                />
                            </Document>

                            <PdfCanvas
                                canvasRef={canvasRef}
                                currentPage={currentPage}
                                rects={rects}
                                addFormDataElement={addFormDataElement}
                                addFormDataElementOption={addFormDataElementOption}
                                removeFormDataElement={removeFormDataElement}
                                removeFormDataElementOption={removeFormDataElementOption}
                                pdfDimensions={pdfDimensions}
                                formElements={formElements || []}
                            />
                        </div>
                    </div>
                    <div className="mt-4 bg-gray-100  rounded sticky top-24">
                        <PdfPagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            fieldMappings={fieldMappings}
                        />
                        <MappedFields
                            formElements={(formElements || []).filter(
                                (x) => x?.config?.templateConfig || x?.config?.hasTemplateConfig
                            )}
                            addFormDataElement={addFormDataElement}
                            currentPage={currentPage}
                            removeFormDataElement={removeFormDataElement}
                        />
                    </div>
                </Fragment>
            )}
            <div className="sticky top-0 z-50 bg-white pb-4">
                <div className="flex gap-4 mb-4 pb-4">
                    <FileUploader
                        accept=".pdf"
                        onFileUpload={handleFileUpload}
                        buttonText="Upload PDF File"
                        loadingText="Uploading..."
                        successMessage={selectedFile?.name || '-'}
                        errorMessage="File upload failed. Please try again."
                        fileLimit={19}
                        showTips={true}
                        tips={[]}
                        getOriginalFile={true}
                        uploadedFileName={getFileNameFromUrl(url)}
                    />
                </div>
            </div>
            <div
                className="d-flex justify-content-end"
                style={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '5px',
                    zIndex: 9999,
                }}
            >
                <div>
                    {pdfUrl && (
                        <Button variant="primary" onClick={saveFieldMappings} disabled={isBusy}>
                            Save Pdf Template
                        </Button>
                    )}
                    <Button variant="secondary" onClick={handleClose} className="ml-2">
                        Close
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    formElements: state.formData.formElements,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PDFFieldMapper);
