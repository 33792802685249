import { css } from 'twin.macro';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MdCancel } from 'react-icons/md';
import { connect } from 'react-redux';
import { fetchWrapper } from '../../../../../../_helpers';
import { updatePayallocationEmployeeCheck, updatePayallocationEmployeeEarning } from '../../../../../../_redux';
import { getUniqueShiftUUID, prepareCheckDataFromConfig } from '../../PayallocationUtils';
import { getShiftBadge } from '../../PayAllocation';

export const shiftTypes = {
    hourly: ['hours', 'wage'],
    flat: ['hours', 'amount'],
    overtime: ['hours', 'wage'],
    doubletime: ['hours', 'wage'],
    reimbursement: ['amount'],
    pto: ['hours', 'wage'],
    vacation: ['hours', 'wage'],
    sick: ['hours', 'wage'],
    holiday: ['hours', 'wage'],
    piecework: ['hours', 'units', 'amount_per_unit'],
    charged_tips: ['amount'],
    discretionary_bonus: ['amount'],
    nondiscretionary_bonus: ['amount'],
    commission: ['amount'],
    commodity: ['amount'],
    shareholder_healthcare_reimbursement: ['flat'],
    gross_up: ['desired_net'],
    correction: ['hours', 'wage'],
};

const getCustomName = (shift) => {
    try {
        const shiftNames = Object.keys(shiftTypes);
        let visitId;

        for (let i = 0; i < shiftNames.length; i++) {
            const name = shiftNames[i];
            if (shift[name]) {
                visitId = shift[name]?.custom_name || shift[name]?.reimbursementcustom_name;
                if (visitId) break;
            }
        }
        return visitId;
    } catch (error) {
        console.log('Error:', error);
    }
};

export const groupShiftsByVisit = (shifts = []) => {
    return (shifts || []).reduce((acc, shift) => {
        const visitId =
            shift?.metadata?.id || shift?.custom_name || shift?.reimbursementcustom_name || getCustomName(shift);
        if (acc[visitId]) {
            acc[visitId].shifts.push(shift);
        } else {
            acc[visitId] = {
                shifts: [shift],
            };
        }
        return acc;
    }, {});
};

export function getRandomId() {
    return `NEW_SHIFT_${Math.round(Math.random() * 1000)}`;
}

export const formatDateToLocalISO = (date) => {
    const offset = new Date(date).getTimezoneOffset();
    const localDate = new Date(new Date(date).getTime() - offset * 60 * 1000);
    return localDate.toISOString();
};

export function prepareFormData(formData) {
    try {
        const shiftsEntries = Array.from(formData.entries()).filter((entry) => entry[0].startsWith('shift-'));
        const shiftsData = {};
        shiftsEntries.forEach((entry) => {
            const [key, value] = entry;
            const [_, id, shiftField] = key.split('-');
            shiftsData[id] = {
                ...shiftsData[id],
                shiftID: id,
                [shiftField]:
                    shiftField === 'time'
                        ? new Date(value).toISOString()
                        : shiftField === 'metadata'
                          ? JSON.parse(value || '{}')
                          : value,
            };
        });
        return {
            employeeID: formData.get('employeeID'),
            shifts: Object.values(shiftsData),
        };
    } catch (error) {
        toast.error('Please check missing fields in form');
    }
}
function AddShiftForm({
    data,
    workLocations,
    mode = 'create',
    isBusy,
    teamId,
    handleClose,
    updatePayallocationEmployeeCheck,
    updatePayallocationEmployeeEarning,
    payallocation,
    setIsBusy,
}) {
    const [shifts, setShifts] = useState(
        data?.shifts?.map((shift) => ({ id: shift.shiftID || getRandomId(), ...shift })) || []
    );

    const visitWithShifts = groupShiftsByVisit(shifts);

    async function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const { employeeID, shifts: earningShifts } = prepareFormData(formData);
        const autoShifts = payallocation?.employees[employeeID]?.shifts || [];
        const allShifts = [...earningShifts, ...autoShifts];

        updatePayallocationEmployeeEarning({ employeeID, shifts: earningShifts });
        const matchedEmployee = (payallocation?.employees)[employeeID];
        if (matchedEmployee) {
            await getEmployeeCheckPreview({ ...matchedEmployee, shifts: allShifts });
        }
        handleClose && handleClose();
    }

    async function getEmployeeCheckPreview(data) {
        const checkData = await prepareCheckDataFromConfig(data, payallocation);
        try {
            setIsBusy(true);
            const response = await fetchWrapper.post(
                `/evv/payroll/zeal/employee/${payallocation?.provider?.companyID}/preview-check${teamId ? `?team=${teamId}` : ''}`,
                [checkData]
            );
            updatePayallocationEmployeeCheck(response[data?.employeeID]);
        } catch (error) {
            const errorMessage = error.message || "Preview data couldn't be fetched";
            toast.error(errorMessage);
        } finally {
            setIsBusy(false);
        }
    }
    return (
        <Form
            onSubmit={handleSubmit}
            css={css`
                display: flex;
                flex-direction: column;
                gap: 6px;
            `}
            className="employee-payroll-form"
        >
            {/* Employee ID */}
            <Form.Control hidden readOnly value={data?.employeeID} name="employeeID" />

            {Object.values(visitWithShifts)
                ?.sort((a, b) => new Date(a?.shifts[0]?.time) - new Date(b?.shifts[0]?.time))
                ?.map((visitShifts, visitIndex) => {
                    const shiftGroupType = 'visit';
                    const shiftGroupClass = getShiftBadge(shiftGroupType);
                    const completedAt = new Date(visitShifts?.shifts[0]?.time).toDateString();
                    return (
                        <div
                            css={css`
                                margin-top: 8px;
                                border-radius: 3px;
                                border: 1px solid #17a2b8;
                                padding: 8px;
                                box-shadow: 0px 0px 7px 2px #dddddd;
                                position: relative;
                            `}
                            key={visitIndex}
                        >
                            <div
                                className={`badge badge-primary`}
                                css={css`
                                    width: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: flex !important;
                                    justify-content: space-between;
                                `}
                            >
                                <div
                                    css={css`
                                        text-transform: capitalize;
                                        font-size: 12px; !important
                                    `}
                                    className={`badge ${shiftGroupClass}`}
                                >
                                    {visitIndex + 1}. {shiftGroupType} Shifts
                                </div>
                                <div>Completed At: {completedAt}</div>
                            </div>

                            {visitShifts?.shifts
                                ?.filter(({ type }) => Object.keys(shiftTypes).includes(type))
                                ?.map((data, shiftIndex) => {
                                    return (
                                        <div
                                            key={data.id}
                                            css={css`
                                                display: ${data.id.startsWith('REMOVE_SHIFT') ? 'none' : 'auto'};
                                                position: relative;
                                                margin-top: 20px;
                                            `}
                                        >
                                            {mode !== 'view' && (
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        justify-content: flex-end;
                                                        position: absolute;
                                                        right: 0;
                                                    `}
                                                >
                                                    <MdCancel
                                                        css={css`
                                                            cursor: pointer;
                                                        `}
                                                        color="brown"
                                                        size="28px"
                                                        onClick={() => {
                                                            setShifts((prev) => {
                                                                if (data.id.startsWith('NEW_SHIFT')) {
                                                                    return prev.filter(({ id }) => id !== data.id);
                                                                }
                                                                return prev.map(({ id, ...rest }) => ({
                                                                    id: id === data.id ? `REMOVE_SHIFT_${id}` : id,
                                                                    ...rest,
                                                                }));
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <ShiftForm
                                                index={data.id}
                                                data={data}
                                                isBusy={isBusy}
                                                workLocations={workLocations}
                                                shiftIndex={shiftIndex}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    );
                })}

            <div
                css={css`
                    display: flex;
                    padding: 12px;
                    justify-content: flex-end;
                `}
            >
                <Button
                    type="button"
                    onClick={() => {
                        setShifts((prev) => [
                            ...prev,
                            {
                                id: getRandomId(),
                                type: 'hourly',
                                custom_name: `hourly-${getRandomId()}`,
                                time: new Date(),
                                metadata: {
                                    uuid: getUniqueShiftUUID(),
                                    shiftCreatedAt: new Date().toISOString(),
                                },
                            },
                        ]);
                    }}
                >
                    + Add Earnings/Shift
                </Button>
            </div>

            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    padding: 12px;
                    border-top: 2px solid #f2f2f2;
                `}
            >
                <Button variant="secondary" onClick={handleClose} disabled={isBusy}>
                    Close
                </Button>
                <Button type="submit" disabled={isBusy}>
                    Save Changes
                </Button>
            </div>
        </Form>
    );
}

function getFormLabel(name) {
    const [initial, ...other] = (name || 'Unnamed Field').replaceAll('_', ' ').split('');
    return initial.toUpperCase() + other.join('').replaceAll(/([a-z])([A-Z])/g, '$1 $2');
}

function ShiftForm({ data, index, isBusy, workLocations, shiftIndex }) {
    const [selectedType, setSelectedType] = useState(data?.type || 'hourly');
    const [_workLocation, _setWorkLocation] = useState(
        data?.workLocationID
            ? data.workLocationID
            : (workLocations || [])?.find((workLocation) => workLocation?.work_site_id === data?.work_site_id)
                  ?.workLocationID
    );
    const handleChangeWorkLocation = (e) => {
        _setWorkLocation(e.target.value);
    };

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px;
                border: 1px solid #ddd;
            `}
        >
            <span
                className="badge badge-info mr-2"
                css={css`
                    max-width: fit-content;
                    position: absolute;
                    top: 0;
                    left: 0;
                `}
            >
                Shift {shiftIndex + 1}
            </span>
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    padding: 8px 0;
                `}
            >
                <Form.Group
                    css={css`
                        width: 33%;
                    `}
                >
                    <Form.Label>Shift Completion Time</Form.Label>
                    <Form.Control
                        type="datetime-local"
                        name={`shift-${index}-time`}
                        disabled={isBusy}
                        defaultValue={formatDateToLocalISO(data?.time || new Date()).slice(0, 16)}
                    />
                </Form.Group>
                <Form.Group
                    css={css`
                        width: 162px;
                    `}
                >
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                        as="select"
                        name={`shift-${index}-type`}
                        disabled={isBusy}
                        value={selectedType}
                        onChange={(event) => {
                            setSelectedType(event.target.value);
                        }}
                    >
                        {Object.keys(shiftTypes).map((option) => (
                            <option value={option} key={option}>
                                {getFormLabel(option)}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group
                    css={css`
                        width: 40%;
                    `}
                >
                    {/* Metadata */}
                    <Form.Control
                        hidden
                        readOnly
                        value={data?.metadata?.uuid ? data?.metadata?.uuid : Date.now()}
                        name={`shift-${index}-${selectedType === 'reimbursement' ? 'reimbursementcustom_name' : 'custom_name'}`}
                    />
                    <Form.Control
                        hidden
                        readOnly
                        value={JSON.stringify(data?.metadata || {})}
                        name={`shift-${index}-metadata`}
                    />
                    <Form.Control hidden readOnly value={data?.employeeID} name="employeeID" />
                </Form.Group>
            </div>
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    justify-content: space-between;
                `}
            >
                {shiftTypes[selectedType]?.map((field) => (
                    <Form.Group
                        key={field}
                        css={css`
                            width: 160px;
                        `}
                    >
                        <Form.Label>{getFormLabel(field)}</Form.Label>
                        <Form.Control
                            required={true}
                            type="number"
                            name={`shift-${index}-${field}`}
                            disabled={isBusy}
                            defaultValue={data ? data[field] : ''}
                            step={0.01}
                            onWheel={(event) => event.currentTarget.blur()}
                        />
                    </Form.Group>
                ))}
                <Form.Group
                    css={css`
                        width: 80%;
                    `}
                >
                    <Form.Label>Work Location</Form.Label>
                    <Form.Control
                        as="select"
                        name={`shift-${index}-workLocationID`}
                        disabled={isBusy}
                        defaultValue={_workLocation}
                        onChange={handleChangeWorkLocation}
                    >
                        {(workLocations || [])?.map((workLocation) => (
                            <option value={workLocation.workLocationID} key={workLocation.workLocationID}>
                                {workLocation.street1} {workLocation.street2 || ''} {workLocation.city}{' '}
                                {workLocation.state} {workLocation.zip} ({workLocation.name})
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    payallocation: state.payallocation,
});
const mapDispatchToProps = {
    updatePayallocationEmployeeEarning,
    updatePayallocationEmployeeCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShiftForm);
